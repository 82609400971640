import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgHighRisk = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 7.992C16 3.584 12.416 0 8.008 0 3.6 0 .017 3.584 0 7.992c0 1.396.37 2.742 1.043 3.936a.679.679 0 0 0 .589.337h12.736a.664.664 0 0 0 .589-.337A7.831 7.831 0 0 0 16 7.992Zm-1.363.016c0 1.01-.235 2.02-.673 2.911H2.053a6.727 6.727 0 0 1-.673-2.927c0-3.651 2.978-6.612 6.628-6.612 3.651 0 6.63 2.978 6.63 6.628ZM7.941 3.684a.723.723 0 1 1 0-1.446.723.723 0 0 1 0 1.446ZM3.88 4.937a.723.723 0 1 1 1.023-1.023.723.723 0 0 1-1.023 1.023ZM2.187 7.975a.723.723 0 1 0 1.447 0 .723.723 0 0 0-1.447 0Zm10.768.74a.723.723 0 1 1 0-1.447.723.723 0 0 1 0 1.447Zm-5.889-2.12 3.954-2.456c.437-.27.96.235.69.673L9.338 8.816c-.286.488-.825.79-1.397.807a1.623 1.623 0 0 1-1.649-1.615c0-.572.303-1.11.774-1.413Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgHighRisk;
