import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgWarning = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.699 16h12.594c2.073 0 3.375-2.222 2.349-3.961L11.325 1.333c-1.026-1.777-3.632-1.777-4.658 0L.35 12.04C-.657 13.797.626 16 2.699 16Zm6.317-2.377a1 1 0 0 1-1.007-.985 1 1 0 0 1 1.007-.986.976.976 0 0 1 .987 1.005c.02.522-.454.966-.987.966Zm-.257-8.889c.474-.116.967.116 1.165.56.059.155.098.31.098.484-.02.483-.059.985-.098 1.468-.04.754-.1 1.527-.139 2.28-.02.252-.02.464-.02.715a.738.738 0 0 1-.75.715c-.414 0-.73-.29-.75-.695-.059-1.18-.138-2.358-.197-3.537l-.06-.946c-.019-.483.277-.908.751-1.044Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgWarning;
