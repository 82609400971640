import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgReddit = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.217 6.127c-.506 0-.95.222-1.278.568-1.203-.882-2.826-1.45-4.624-1.51L9.25.728l2.974.707c0 .77.594 1.4 1.322 1.4.742 0 1.338-.646 1.338-1.417C14.883.646 14.291 0 13.545 0c-.519 0-.967.332-1.19.785L9.07.015c-.165-.047-.327.078-.371.254L7.673 5.18c-1.784.079-3.389.646-4.596 1.529a1.748 1.748 0 0 0-1.295-.583c-1.875 0-2.489 2.664-.772 3.575-.06.281-.088.582-.088.882C.922 13.576 4.105 16 8.014 16c3.925 0 7.109-2.424 7.109-5.416 0-.3-.03-.615-.104-.897 1.682-.914 1.062-3.558-.802-3.56ZM3.734 9.891c0-.786.593-1.418 1.339-1.418.728 0 1.322.628 1.322 1.418 0 .771-.594 1.4-1.322 1.4-.742.004-1.339-.629-1.339-1.4Zm7.226 3.338c-1.227 1.299-4.69 1.299-5.919 0-.135-.126-.135-.347 0-.49a.311.311 0 0 1 .446 0c.937 1.018 4.046 1.036 5.024 0a.31.31 0 0 1 .446 0c.138.144.138.365.003.49Zm-.027-1.935c-.728 0-1.322-.628-1.322-1.399 0-.785.594-1.418 1.322-1.418.742 0 1.338.628 1.338 1.418-.003.767-.596 1.399-1.338 1.399Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgReddit;
