import { Address } from 'core/address';
import { ChartDatasetRange, ExecutorIntegration, RiskProfile } from 'core/api/schema';
import { ChainID } from 'core/chain';

export namespace Amplitude {
  export enum ChartType {
    Returns = 'Returns',
    Aum = 'AUM',
    Depositors = 'Depositors',
  }

  export enum ShareCardEventAction {
    Download = 'Download',
    Copy = 'Copy',
  }

  export enum ShareCardIntegration {
    VaultReturns = 'Vault returns',
  }

  export enum ClickVaultCardAction {
    Featured = 'Featured',
    DailyPerformance = 'Daily performance',
    WeeklyPerformance = 'Weekly performance',
    MonthlyPerformance = 'Monthly performance',
    YearlyPerformance = 'Yearly performance',
    AllTimePerformance = 'All time performance',
    ExplorePage = 'Explore page',
  }

  export const SelectAll = 'Select all';
  export enum VaultCreationAction {
    ClickInitCreateVault = 'Click init create vault',
    ClickReject = 'Click reject create vault',
    ClickSubmitCreateVault = 'Click submit create vault',
    ClickPrevious = 'Click previous step',
    ClickNext = 'Click next step',
    ClickLeaveProcess = 'Click leave process',
    ClickStayProcess = 'Click stay process',
  }

  export enum ExplorerFilterAction {
    Select = 'Select',
    Deselect = 'Deselect',
  }

  export enum ExplorerFilter {
    Chain = 'Chain',
    RiskProfile = 'Risk profile',
  }

  export enum Event {
    SignIn = 'SignIn',
    Bridge = 'Bridge',
    ExplorerFilter = 'Explorer Filter',
    Share = 'Share',
    RangeSwitch = 'Range Switch',
    ChartsSwitch = 'Charts Switch',
    ShareCard = 'Share Card',
    VaultCard = 'Vault Card Click',
    VaultCreation = 'Vault Creation',
    SetupCommunity = 'Setup Community',
    OpenTelegram = 'Open Telegram',
  }

  type OneOfAction = ShareCardEventAction | ClickVaultCardAction | VaultCreationAction | ExplorerFilterAction;
  type AmplitudeEventProperties = {
    action: OneOfAction;
  };

  export type SignInParams = {
    address: Address;
  };

  export type ShareCardParams = AmplitudeEventProperties & {
    integration: ShareCardIntegration | ExecutorIntegration;
  };

  export type ChartSwitchParams = {
    dateRange?: ChartDatasetRange;
    chartType?: ChartType;
  };

  type VaultInfo = {
    vaultName?: string;
  };

  export type ClickVaultCardParams = AmplitudeEventProperties &
    VaultInfo & {
      index?: number;
    };

  export type BridgeAssetsParams = {
    sourceChainName?: string;
    destinationChainName?: string;
    sourceAssetSymbol?: string;
    destinationAssetSymbol?: string;
  };

  type OneOfExplorerFilter = ChainID | RiskProfile | typeof SelectAll;

  export type ExplorerFilterParams = AmplitudeEventProperties & {
    filter: ExplorerFilter;
    value: OneOfExplorerFilter;
  };

  export type VaultCreationParams = AmplitudeEventProperties & {
    step?: number; // current step of the action if it has one
  };
}
