import { RewardEnumType } from 'core/api/schema';

import BoxOne from 'assets/img/box-1.png';
import BoxTwo from 'assets/img/box-2.png';
import BoxThree from 'assets/img/box-3.png';
import BoxFour from 'assets/img/box-4.png';

export namespace RevealSZN0 {
  export const STEP = 19;
  export const IMG_WIDTH = 230;
  export const ANIMATION_DURATION = 4;

  export type Inventory = Box & {
    count: number;
  };

  export type Box = {
    src: string;
    type: RewardEnumType;
  };

  export const initialInventory: Inventory[] = [
    { count: 0, type: RewardEnumType.Common, src: BoxOne },
    { count: 0, type: RewardEnumType.Rare, src: BoxTwo },
    { count: 0, type: RewardEnumType.Legendary, src: BoxThree },
    { count: 0, type: RewardEnumType.Mythic, src: BoxFour },
  ];

  export const initialBoxes: Box[] = [
    { src: BoxOne, type: RewardEnumType.Common },
    { src: BoxFour, type: RewardEnumType.Mythic },
    { src: BoxThree, type: RewardEnumType.Legendary },
    { src: BoxTwo, type: RewardEnumType.Rare },
    { src: BoxTwo, type: RewardEnumType.Rare },
    { src: BoxOne, type: RewardEnumType.Common },
    { src: BoxThree, type: RewardEnumType.Legendary },
    { src: BoxFour, type: RewardEnumType.Mythic },
    { src: BoxFour, type: RewardEnumType.Legendary },
  ];

  const shuffleArray = <T>(array: T[]): T[] => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }

    return shuffled;
  };

  export const getBoxByType = (type: RewardEnumType): Box => {
    switch (type) {
      case RewardEnumType.Common:
        return { src: BoxOne, type };
      case RewardEnumType.Rare:
        return { src: BoxTwo, type };
      case RewardEnumType.Legendary:
        return { src: BoxThree, type };
      case RewardEnumType.Mythic:
        return { src: BoxFour, type };
      default:
        return { src: BoxOne, type };
    }
  };

  export const generateNewBoxes = (length: number): Box[] => {
    const shuffledBoxes = shuffleArray<Box>(initialBoxes);

    const newBoxes = Array.from({ length }, (_, i) => {
      const randomItem = shuffledBoxes[i % shuffledBoxes.length];

      return { src: randomItem.src, type: randomItem.type };
    });

    return newBoxes;
  };
}
