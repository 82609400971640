import { PropsWithChildren, memo } from 'react';

import { Snackbars } from 'core/snackbars';

import { Backdrop } from 'lib/backdrop';
import { Modals } from 'lib/modals/modals';
import { Widgets } from 'lib/widgets';

type BaseLayoutProps = PropsWithChildren<unknown>;

export const BaseLayout = memo((props: BaseLayoutProps) => (
  <>
    {props.children}
    <Backdrop />
    <Snackbars />
    <Modals />
    <Widgets />
  </>
));
