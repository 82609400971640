import { useTheme } from 'styles/themes/use-theme.hook';

import { H1, Secondary } from 'components/typography';

import { Description, Image, Slide } from './slide';

export const SlideTwo = () => {
  const { tutorial } = useTheme();

  return (
    <Slide className="flex flex-col items-center justify-center">
      <Image src={tutorial?.slideTwoSrc} />
      <Description className="flex flex-col items-center justify-center gap-3.5">
        <H1>Fund your account</H1>
        <Secondary className="text-center break-words w-[324px]">
          Add funds to your account either with crypto, credit card or wire transfers.
        </Secondary>
      </Description>
    </Slide>
  );
};
