import { ChainID } from 'core/chain';

export namespace Debank {
  enum ShortFormChain {
    Arb = 'arb',
    Op = 'op',
    Eth = 'eth',
  }

  export type Data = {
    data: {
      fast: {
        price: number;
      };
      normal: {
        price: number;
      };
      slow: {
        price: number;
      };
    };
  };

  export const chainIdToSymbol = (chainId: ChainID): ShortFormChain => {
    switch (chainId) {
      case ChainID.Arbitrum:
        return ShortFormChain.Arb;
      case ChainID.Optimism:
        return ShortFormChain.Op;
      case ChainID.Ethereum:
        return ShortFormChain.Eth;
    }
  };

  export const fetchData = async (chainId: ChainID): Promise<Data> => {
    const shortFormChain = chainIdToSymbol(chainId);

    const result = await fetch(`https://api.debank.com/chain/gas_price_dict_v2?chain=${shortFormChain}`);
    const data = await result.json();

    return data;
  };
}
