import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgSwap = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.973.279a1.038 1.038 0 0 0-1.42.003L.266 2.419a.845.845 0 0 0-.05 1.178.808.808 0 0 0 1.157.051 2.802 2.802 0 0 1 3.808.003.808.808 0 0 0 1.156-.056.845.845 0 0 0-.055-1.178L3.973.279Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.274.033c.452 0 .819.373.819.833v10.3c0 .46-.367.834-.819.834a.826.826 0 0 1-.818-.834V.866c0-.46.366-.833.818-.833ZM11.424 11.721a1.038 1.038 0 0 1-1.42-.003L7.718 9.581a.845.845 0 0 1-.05-1.178.808.808 0 0 1 1.156-.051 2.802 2.802 0 0 0 3.808-.003.808.808 0 0 1 1.156.056c.304.341.28.868-.055 1.178l-2.308 2.138Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.726 11.967a.826.826 0 0 0 .818-.834V.833A.826.826 0 0 0 10.726 0a.826.826 0 0 0-.819.834v10.3c0 .46.367.833.819.833Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgSwap;
