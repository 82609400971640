import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgDesktopNeon = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#desktop-neon_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 13h44a5.006 5.006 0 0 1 5 5v34a5.006 5.006 0 0 1-5 5H47.22l1.6 8H54a1 1 0 0 1 0 2H26a1 1 0 0 1 0-2h5.18l1.6-8H18a5.006 5.006 0 0 1-5-5V18a5.006 5.006 0 0 1 5-5Zm16.82 44-1.6 8h13.56l-1.6-8H34.82Zm29.3-2.88A3.003 3.003 0 0 0 65 52V18a3.003 3.003 0 0 0-3-3H18a3.003 3.003 0 0 0-3 3v34a3.003 3.003 0 0 0 3 3h44c.795 0 1.558-.317 2.12-.88ZM50 26a1 1 0 0 0-1 1v15a1 1 0 1 0 2 0V27a1 1 0 0 0-1-1Zm-16 5a1 1 0 1 1 2 0v11a1 1 0 1 1-2 0V31Zm-5 6a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0v-5Zm11-1a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-5a1 1 0 0 0-1-1Zm4-3a1 1 0 1 1 2 0v9a1 1 0 1 1-2 0v-9Z"
        fill="#fff"
      />
    </g>
    <defs>
      <filter
        id="desktop-neon_svg__a"
        x={0.737}
        y={0.737}
        width={78.526}
        height={78.526}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={6.131} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_7263_24837" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={3.344} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_7263_24837" result="effect2_dropShadow_7263_24837" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.115} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_7263_24837" result="effect3_dropShadow_7263_24837" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_7263_24837" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgDesktopNeon;
