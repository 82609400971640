import { Form } from '@mobx-form-state/react';
import Fuse from 'fuse.js';
import { injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { debounceTime, map, merge } from 'rxjs';

import { ApiService } from 'core/api/api.service';
import { GetVaults } from 'core/api/gql/vault/get-vaults.gql.generated';
import { VaultListFragment } from 'core/api/gql/vault/vault-list.fragment.generated';
import { SortedResource } from 'core/sorted-resource/sorted-resource';

import { didChange } from 'utils/did-change';
import { Loadable } from 'utils/loadable';

import { VaultStore, VaultUUID } from '../vault.store';
import { VaultFiltersModel } from './vault-filters.model';

const resultPerPage = 20;

const isMarketcapInRange = (marketcap: number[], segment: number[]): boolean => {
  const [min, max] = segment;
  const [marketcapMin, marketcapMax] = marketcap;

  return marketcapMin >= min && marketcapMax <= max;
};

@injectable()
export class ExploreVaultsStore extends Loadable {
  @observable
  private vaultsMap = new Map<VaultUUID, VaultListFragment>();

  @observable
  private limit = resultPerPage;

  @observable.ref
  private result: VaultListFragment[] = [];

  @observable.ref
  private fuse: Fuse<VaultListFragment>;

  sorted: SortedResource<VaultListFragment>;

  readonly filters = new Form(VaultFiltersModel);

  constructor(private readonly vaultStore: VaultStore, private readonly apiService: ApiService) {
    super();
    makeObservable(this);

    this.sorted = new SortedResource(() => this.getAll());

    const options: Fuse.IFuseOptions<VaultListFragment> = {
      keys: [
        'name',
        {
          name: 'managers',
          getFn: (vault) => [
            vault.manager.account.name ?? vault.manager.account.address,
            vault.manager.account.address,
          ],
        },
      ],
      minMatchCharLength: 0,
      findAllMatches: true,
      threshold: 0.01,
    };

    this.fuse = new Fuse(this.collection, options);

    const collectionDidChange$ = didChange(() => this.collection);

    const searchTermDidChange$ = didChange(() => this.filters.values.searchTerm);

    const find$ = merge(collectionDidChange$, searchTermDidChange$.pipe(debounceTime(300))).pipe(
      map(() => this.filters.values)
    );

    const updateCollection$ = merge(this.vaultStore.vaultCreated$, this.vaultStore.vaultUpdated$).pipe(
      map(() => this.filters.values)
    );

    this.autoDispose(merge(this.vaultStore.vaultCreated$, this.vaultStore.vaultUpdated$).subscribe(this.setVault));
    this.autoDispose(find$.subscribe(this.find));
    this.autoDispose(updateCollection$.subscribe(this.updateCollection));
  }

  @computed
  get total(): number {
    return this.result.length;
  }

  @computed
  get items(): VaultListFragment[] {
    return this.result.slice(0, this.limit);
  }

  @computed
  private get collection(): VaultListFragment[] {
    const { tags, riskProfile, managerSentiment, managerFocusTime, marketCapSegment, chains } = this.filters.values;

    return this.sorted.items.filter((vault) => {
      if (vault.stats.aum.eq(0)) {
        return false;
      }

      if (vault.stats.roiTotal?.lte(-99)) {
        return false;
      }

      if (tags.length && (!vault.tags || !vault.tags.some((tag) => tags.includes(tag)))) {
        return false;
      }

      if (riskProfile.length && !riskProfile.includes(vault.riskProfile)) {
        return false;
      }

      if (managerSentiment.length && vault.managerSentiment && !managerSentiment.includes(vault.managerSentiment)) {
        return false;
      }

      if (managerFocusTime.length && vault.managerFocusTime && !managerFocusTime.includes(vault.managerFocusTime)) {
        return false;
      }

      if (chains.length && (!vault.chains || !vault.chains.some((chain) => chains.includes(chain.chainId)))) {
        return false;
      }

      if (
        marketCapSegment.length &&
        (!vault.marketCapSegment || !isMarketcapInRange(vault.marketCapSegment, marketCapSegment))
      ) {
        return false;
      }

      // TODO: Handle other filters as needed

      return true;
    });
  }

  @action
  loadMore = (): void => {
    const hasMore = this.result.length > this.limit;

    if (!hasMore) return;

    this.limit += resultPerPage;
  };

  @action
  private updateCollection = (filters: VaultFiltersModel): void => {
    this.fuse.setCollection(this.collection);

    if (filters.searchTerm.length === 0) {
      this.result = this.collection;

      return;
    }

    this.result = this.fuse.search(filters.searchTerm).map(({ item }) => item);
  };

  @action
  private find = (filters: VaultFiltersModel): void => {
    this.limit = resultPerPage;

    this.updateCollection(filters);
  };

  @action
  private setVaultsMap = (vaults: VaultListFragment[]): void => {
    this.vaultsMap = new Map(vaults.map((vault) => [vault.slug, vault]));
  };

  @action
  private setVault = (vault: VaultListFragment): void => {
    this.vaultsMap.set(vault.slug, vault);
  };

  readonly getAll = (): VaultListFragment[] =>
    Array.from(this.vaultsMap.values()).sort(
      (a, b) => (b.stats.roiTotal?.toNumber() || 0) - (a.stats.roiTotal?.toNumber() || 0)
    );

  protected load = async (): Promise<void> => {
    const result = await this.apiService.query(GetVaults);

    this.setVaultsMap(result.vaults);
  };
}
