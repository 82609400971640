import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgCrossedPulse = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 47 71" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#crossed-pulse_svg__a)">
      <path
        d="M11.165 38.702c-.462.774.103 1.704.977 1.704h6.244a1.25 1.25 0 0 1 1.24 1.403l-2.088 16.93c-.154 1.187 1.439 1.755 2.056.722 1.858-3.145 4.462-7.506 7.1-11.881a1.25 1.25 0 0 0-.018-1.323l-9.283-14.435a1.25 1.25 0 0 0-2.123.034l-4.105 6.846ZM35.835 32.298c.462-.774-.103-1.704-.977-1.704h-6.244a1.25 1.25 0 0 1-1.24-1.403l2.088-16.93c.154-1.187-1.44-1.755-2.056-.722L20.3 23.473a1.25 1.25 0 0 0 .024 1.317l9.281 14.389a1.249 1.249 0 0 0 2.122-.038c1.713-2.878 3.163-5.33 4.109-6.842ZM13.53 17.58c-.412-.619-1.234-.826-1.85-.412-.617.413-.823 1.239-.412 1.859L33.47 53.419c.258.413.669.62 1.131.62.257 0 .514-.052.72-.207.616-.413.822-1.24.41-1.859L13.53 17.581Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="crossed-pulse_svg__a"
        x={0}
        y={0}
        width={47}
        height={71}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={5.5} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_6367_42638" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={3} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_6367_42638" result="effect2_dropShadow_6367_42638" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_6367_42638" result="effect3_dropShadow_6367_42638" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_6367_42638" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgCrossedPulse;
