/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { ReferralFragmentDoc } from './referral.fragment.gql.generated';

export type ReferralSubscriptionSubscriptionVariables = Types.Exact<{ [key: string]: never }>;

export type ReferralSubscriptionSubscription = {
  __typename?: 'SubscriptionRoot';
  referral: {
    __typename?: 'ReferralType';
    points: number;
    referral: { __typename?: 'AccountType'; address: string; createdAt: string; chain: string };
  };
};

export const ReferralSubscriptionDocument = gql`
  subscription ReferralSubscription {
    referral {
      ...Referral
    }
  }
  ${ReferralFragmentDoc}
`;

export function ReferralSubscription<
  R extends ReferralSubscriptionSubscription,
  V extends ReferralSubscriptionSubscriptionVariables
>(subscription: GetSubscription<R, V>, variables?: V): Observable<R> {
  return subscription(ReferralSubscriptionDocument, variables);
}
