import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgGmxPerpetuals = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M32 23.999 16.024 0 0 23.999h22.326l-6.302-9.16L12.9 19.6H9.577l6.45-9.569L25.35 24 32 23.999Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgGmxPerpetuals;
