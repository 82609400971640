import BigNumber from 'bignumber.js';

import { VaultActionFragment } from 'core/api/gql/vault-actions/vault-action.fragment.generated';
import { ExecutorAction, ExecutorIntegration } from 'core/api/schema';
import { GetAssetBySymbol } from 'core/asset/assets.store';
import { Decimal } from 'core/decimal';

import { isSomething } from 'utils/is-something';

import { VaultActionInfo } from './vault-action.info';

export const mapVaultActionsFromApi = (items: VaultActionFragment[], getAsset: GetAssetBySymbol): VaultActionInfo[] =>
  items.map((item) => mapVaultActionFromApi(item, getAsset)).filter(isSomething);

export const mapVaultActionFromApi = (
  item: VaultActionFragment,
  getAsset: GetAssetBySymbol
): VaultActionInfo | null => {
  if (item.__typename === 'GmxActionType') {
    const inputAsset = getAsset(item.inputToken.symbol, item.inputToken.chainId, item.inputToken.type);
    const outputAsset = getAsset(item.outputToken.symbol, item.outputToken.chainId, item.outputToken.type);

    if (!inputAsset || !outputAsset) {
      return null;
    }

    return {
      action: item.action,
      integration: ExecutorIntegration.Gmx,
      indexToken: [
        ExecutorAction.PerpLongIncrease,
        ExecutorAction.PerpShortDecrease,
        ExecutorAction.PerpLongDecrease,
      ].includes(item.action)
        ? inputAsset
        : outputAsset,

      positionDelta: Decimal.parse(
        [ExecutorAction.PerpShortDecrease, ExecutorAction.PerpLongDecrease].includes(item.action)
          ? item.inputTokenAmount
          : item.outputTokenAmount,
        Decimal.Precision.GMX
      ),

      increase: [ExecutorAction.PerpLongIncrease, ExecutorAction.PerpShortIncrease].includes(item.action),
      isLong: [ExecutorAction.PerpLongIncrease, ExecutorAction.PerpLongDecrease].includes(item.action),
      unitPrice: Decimal.parse(item.unitPrice, Decimal.Precision.GMX),
      createdAt: new Date(item.createdAt),
      transaction: item.transaction,
      chainId: item.chainId,
      memo: item.memo || null,
    };
  }

  if (item.__typename === 'ZeroXActionType') {
    const inputAsset = getAsset(item.inputToken.symbol, item.inputToken.chainId, item.inputToken.type);
    const outputAsset = getAsset(item.outputToken.symbol, item.outputToken.chainId, item.outputToken.type);

    if (!inputAsset || !outputAsset) {
      return null;
    }

    return {
      integration: ExecutorIntegration.ZeroX,
      action: item.action,
      inputToken: inputAsset,
      outputToken: outputAsset,
      inputTokenAmount: new BigNumber(item.inputTokenAmount),
      outputTokenAmount: new BigNumber(item.outputTokenAmount),
      createdAt: new Date(item.createdAt),
      transaction: item.transaction,
      chainId: item.inputToken.chainId,
      memo: item.memo || null,
    };
  }

  if (item.__typename === 'KwentaActionSubmitOrderType' || item.__typename === 'KwentaActionSubmitCloseOrderType') {
    const asset = getAsset(item.perpMarket.symbol, item.perpMarket.chainId, item.perpMarket.type);

    if (!asset) return null;

    const positionDelta =
      item.__typename === 'KwentaActionSubmitOrderType'
        ? Decimal.parse(item.sizeDelta ?? 0, Decimal.Precision.WEI)
        : Decimal.empty();

    const isLong =
      (item.action === ExecutorAction.KwentaSubmitOrder && positionDelta.isPositive()) ||
      (item.action === ExecutorAction.KwentaSubmitCloseOrder && positionDelta.isNegative());

    return {
      integration: ExecutorIntegration.Kwenta,
      action: item.action,
      market: asset,
      isLong,
      positionDelta,
      desiredFillPrice: Decimal.parse(item.desiredFillPrice, Decimal.Precision.WEI),
      chainId: item.perpMarket.chainId,
      transaction: item.transaction,
      createdAt: new Date(item.createdAt),
      memo: item.memo || null,
    };
  }

  return null;
};
