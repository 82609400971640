import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgList = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 13 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.312 0H1.685C.722 0 0 .807 0 1.737v13.525C0 16.255.782 17 1.685 17h9.627c.963 0 1.685-.807 1.685-1.737V1.738C13.057.807 12.275 0 11.312 0ZM9.266 12.657H3.67a.678.678 0 0 1-.662-.683c0-.372.301-.682.662-.682h5.596c.361 0 .662.31.662.682 0 .373-.3.683-.662.683Zm0-3.475H3.67a.678.678 0 0 1-.662-.682c0-.373.301-.683.662-.683h5.596c.361 0 .662.31.662.683 0 .372-.3.682-.662.682Zm0-3.536H3.67a.678.678 0 0 1-.662-.683c0-.372.301-.682.662-.682h5.596c.361 0 .662.31.662.682 0 .372-.3.683-.662.683Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgList;
