import { createContext, useState } from 'react';

export const TextFieldContext = createContext<UseTextFieldResult | null>(null);

type UseTextFieldResult = {
  active: boolean;
  setActive: (active: boolean) => void;
  error: boolean;
  setError: (error: boolean) => void;
};

export const useTextField = (): UseTextFieldResult => {
  const [active, setActive] = useState(false);
  const [error, setError] = useState(false);

  return {
    active,
    error,
    setActive,
    setError,
  };
};
