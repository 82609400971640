import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgForbidden = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.5 0C3.813 0 0 3.813 0 8.5 0 13.187 3.813 17 8.5 17c4.687 0 8.5-3.813 8.5-8.5C17 3.813 13.187 0 8.5 0Zm0 1.717c1.515 0 2.916.5 4.046 1.343L3.06 12.546A6.748 6.748 0 0 1 1.717 8.5 6.79 6.79 0 0 1 8.5 1.717Zm0 13.566a6.745 6.745 0 0 1-3.946-1.271l9.458-9.458a6.744 6.744 0 0 1 1.27 3.946A6.79 6.79 0 0 1 8.5 15.283Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgForbidden;
