import { ComponentPropsWithoutRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const CallToAction = () => (
  <>
    <SnakeAnimation />
    <SnakeAnimation className="rotate-180" />
  </>
);

const SnakeAnimation = (props: ComponentPropsWithoutRef<'div'>) => {
  const { className } = props;

  return (
    <div className={twMerge('absolute top-0 right-0 bottom-0 left-0', className)}>
      <Snake className="stroke-[#f8fcff]" />
      <Snake className="stroke-[6px] blur-[20px] stroke-[#c9e9ff]" />
      <Snake className="stroke-[5px] blur-[6px] stroke-[#c9e9ff]" />
      <Snake className="stroke-[10px] blur-[56px] stroke-[#c9e9ff]" />
    </div>
  );
};

const Snake = (props: ComponentPropsWithoutRef<'div'>) => {
  const { className } = props;

  return (
    <svg
      className={twMerge(
        'block absolute inset-0 overflow-visible [stroke-width:2px] w-full h-full [stroke-dasharray:2,10] [stroke-dashoffset:14] group-hover/icon-btn:animate-snake group-hover/btn:animate-snake',
        className
      )}
    >
      <rect x="0" y="0" fill="none" width="100%" height="100%" rx={8} ry={8} pathLength="9" />
    </svg>
  );
};
