import { Web3Provider } from '@ethersproject/providers';
import { User } from '@privy-io/react-auth';
import { injectable } from 'inversify';
import { Observable, distinctUntilChanged, filter, map, merge, share, shareReplay, skip } from 'rxjs';

import { Address } from 'core/address';
import { ChainID, isSupportedChain } from 'core/chain';

import { Disposable } from 'utils/disposable';
import { isSomething } from 'utils/is-something';

import { Privy } from './privy';
import { PrivyClient } from './privy.client';

@injectable()
export class NetworkService extends Disposable {
  readonly user$: Observable<User | null>;

  readonly address$: Observable<Address | undefined>;

  readonly chain$: Observable<ChainID | undefined>;

  readonly disconnect$: Observable<void>;

  readonly requestNetworkSwitch$: Observable<Privy.NetworkState | null>;

  readonly provider$: Observable<Web3Provider | undefined>;

  constructor(readonly connector: PrivyClient) {
    super();

    const networkState$: Observable<Privy.NetworkState<ChainID> | null> = this.connector.privyState$.pipe(
      map((state) => state.networkState),
      filter(
        (state): state is Privy.NetworkState<ChainID> | null =>
          (state && isSupportedChain(state.chainId)) || state === null
      ),
      share()
    );

    this.disconnect$ = this.connector.privyState$.pipe(
      map((state) => state.networkState?.address),
      distinctUntilChanged(),
      filter((address) => !address),
      skip(1),
      map(() => undefined),
      share()
    );

    this.requestNetworkSwitch$ = this.connector.privyState$.pipe(
      map((state) => state.networkState),
      filter((state): state is Privy.NetworkState => isSomething(state) && !isSupportedChain(state.chainId)),
      share()
    );

    this.provider$ = networkState$.pipe(
      map((state) => (state ? state.provider : undefined)),
      share()
    );

    this.address$ = merge(networkState$.pipe(map((state) => state?.address.toLowerCase()))).pipe(shareReplay(1));

    this.chain$ = networkState$.pipe(
      map((state) => state?.chainId),
      distinctUntilChanged(),
      shareReplay(1)
    );

    this.user$ = this.connector.privyState$.pipe(
      map((state) => state.user),
      shareReplay(1)
    );
  }

  switchNetwork = async (chainId: ChainID): Promise<void> => {
    await this.connector.switchChain(chainId);
  };
}
