import { ZeroXExecutor__factory } from '@valioxyz/valio-contracts';
import { BigNumberish, ContractTransaction } from 'ethers';
import { injectable } from 'inversify';
import invariant from 'tiny-invariant';

import { NetworkProvider } from 'core/network/network.provider';

import { SwapParams } from './quote.map';
import { Valio } from './valio';
import { ValioRegistry } from './valio.registry';
import { VaultExecutor } from './vault.executor';

@injectable()
export class ZeroXExecutor {
  constructor(
    private readonly valioRegistry: ValioRegistry,
    private readonly networkProvider: NetworkProvider,
    private readonly vaultExecutor: VaultExecutor
  ) {}

  swap = async (params: SwapParams): Promise<ContractTransaction> => {
    const txData = await this.getSwapTxData(params);

    return this.vaultExecutor.execute(Valio.ExecutorIntegration.ZeroX, txData);
  };

  estimateSwapGas = async (params: SwapParams): Promise<BigNumberish> => {
    const txData = await this.getSwapTxData(params);

    return this.vaultExecutor.estimateGas(Valio.ExecutorIntegration.ZeroX, txData);
  };

  private getSwapTxData = async (params: SwapParams): Promise<string> => {
    const executor = ZeroXExecutor__factory.connect(
      await this.valioRegistry.getZeroXExecutorAddress(),
      this.networkProvider.getSigner()
    );

    const tx = await executor.populateTransaction.swap(
      params.sellTokenAddress,
      params.sellAmount,
      params.buyTokenAddress,
      params.buyAmount,
      params.data
    );

    invariant(tx.data, 'ZeroXExecutor: tx.data is undefined');

    return tx.data;
  };
}
