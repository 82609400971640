import { injectable } from 'inversify';
import { Subject, filter, map, mergeMap, switchMap, timer } from 'rxjs';

import { Addresses } from 'core/address';
import { ApiService } from 'core/api/api.service';
import { Withdraw } from 'core/api/gql/withdraw/withdraw.gql.generated';
import { NetworkStore } from 'core/network/network.store';
import { VaultUUID } from 'core/vault/vault.store';
import { WalletStore } from 'core/wallet/wallet.store';

import { Disposable } from 'utils/disposable';
import { visibility$ } from 'utils/polling';

import { Snackbar } from '../snackbar';
import { SnackbarStore } from '../snackbar.store';
import { WithdrawSnackbarStatus } from './withdraw';

@injectable()
export class WithdrawSnackbarStore extends Disposable {
  private withdraw$ = new Subject<Snackbar.Withdraw>();

  constructor(
    private readonly snackbarStore: SnackbarStore,
    private readonly apiService: ApiService,
    private readonly walletStore: WalletStore,
    private readonly networkStore: NetworkStore
  ) {
    super();

    const pendingDeposit$ = this.withdraw$.pipe(filter(({ status }) => status === WithdrawSnackbarStatus.Pending));

    const withdrawSuccess$ = pendingDeposit$.pipe(
      mergeMap((deposit) => {
        return this.apiService.subscription(Withdraw, { uid: deposit.uid }).pipe(
          filter(
            ({
              withdraw: {
                deposit: { depositor },
              },
            }) => Addresses.areEqual(depositor, this.walletStore.address)
          ),
          map(() => deposit)
        );
      })
    );

    const withdrawExpired$ = pendingDeposit$.pipe(
      mergeMap((deposit) => {
        const { chainTime } = this.networkStore;

        const timeDifference = deposit.expiry * 1000 - chainTime;

        return timer(timeDifference).pipe(map(() => deposit));
      })
    );

    const closeSuccess$ = withdrawSuccess$.pipe(
      mergeMap((item) =>
        visibility$.pipe(
          filter((isPageVisible) => isPageVisible),
          switchMap(() => timer(Snackbar.SuccessCloseDelay).pipe(map(() => item.id)))
        )
      )
    );

    const closeExpired$ = withdrawExpired$.pipe(
      mergeMap((item) =>
        visibility$.pipe(
          filter((isPageVisible) => isPageVisible),
          switchMap(() => timer(Snackbar.SuccessCloseDelay).pipe(map(() => item.id)))
        )
      )
    );

    this.autoDispose(closeExpired$.subscribe(this.snackbarStore.removeSnackbar));
    this.autoDispose(closeSuccess$.subscribe(this.snackbarStore.removeSnackbar));
    this.autoDispose(pendingDeposit$.subscribe(this.snackbarStore.addSnackbar));
    this.autoDispose(withdrawExpired$.subscribe(this.updateWithdrawExpired));
    this.autoDispose(withdrawSuccess$.subscribe(this.updateWithdrawSuccess));
  }

  trackWithdraw = async (uid: VaultUUID, expiry: number, txId: string): Promise<void> => {
    const deposit: Snackbar.Withdraw = {
      id: uid,
      uid,
      status: WithdrawSnackbarStatus.Pending,
      type: Snackbar.Type.Withdraw,
      expiry,
      txId,
    };

    this.withdraw$.next(deposit);
  };

  cancelWithdraw = (uid: VaultUUID): void => {
    this.snackbarStore.removeSnackbar(uid);
  };

  private updateWithdrawSuccess = (item: Snackbar.Withdraw): void => {
    this.snackbarStore.updateSnackbar({ ...item, status: WithdrawSnackbarStatus.Success });
  };

  private updateWithdrawExpired = (item: Snackbar.Withdraw): void => {
    this.snackbarStore.updateSnackbar({ ...item, status: WithdrawSnackbarStatus.Expired });
  };
}
