/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { VaultActionFragmentDoc } from '../vault-actions/vault-action.fragment.generated';

export type TradeMemoFragment = {
  __typename?: 'TradingMemoType';
  message: string;
  createdAt: string;
  action:
    | {
        __typename: 'GmxActionType';
        id: string;
        action: Types.ExecutorAction;
        inputTokenAmount: string;
        outputTokenAmount: string;
        transaction: string;
        chainId: number;
        chain: string;
        memo?: string | null;
        unitPrice: string;
        createdAt: string;
        inputToken: {
          __typename?: 'AssetType';
          id: string;
          name: string;
          address: string;
          symbol: string;
          decimals?: number | null;
          price?: BigNumber | null;
          chain: string;
          chainId: number;
          priceChangeAmount?: BigNumber | null;
          priceChangePercentage?: BigNumber | null;
          marketCap?: string | null;
          totalSupply?: string | null;
          type: Types.AssetTypeEnum;
          deprecated: boolean;
          hardDeprecated: boolean;
          createdAt: string;
        };
        outputToken: {
          __typename?: 'AssetType';
          id: string;
          name: string;
          address: string;
          symbol: string;
          decimals?: number | null;
          price?: BigNumber | null;
          chain: string;
          chainId: number;
          priceChangeAmount?: BigNumber | null;
          priceChangePercentage?: BigNumber | null;
          marketCap?: string | null;
          totalSupply?: string | null;
          type: Types.AssetTypeEnum;
          deprecated: boolean;
          hardDeprecated: boolean;
          createdAt: string;
        };
      }
    | { __typename?: 'KwentaActionDepositType' }
    | {
        __typename: 'KwentaActionSubmitCloseOrderType';
        id: string;
        action: Types.ExecutorAction;
        wrapper: string;
        desiredFillPrice: string;
        memo?: string | null;
        chain: string;
        transaction: string;
        createdAt: string;
        perpMarket: {
          __typename?: 'AssetType';
          id: string;
          name: string;
          address: string;
          symbol: string;
          decimals?: number | null;
          price?: BigNumber | null;
          chain: string;
          chainId: number;
          priceChangeAmount?: BigNumber | null;
          priceChangePercentage?: BigNumber | null;
          marketCap?: string | null;
          totalSupply?: string | null;
          type: Types.AssetTypeEnum;
          deprecated: boolean;
          hardDeprecated: boolean;
          createdAt: string;
        };
      }
    | {
        __typename: 'KwentaActionSubmitOrderType';
        id: string;
        action: Types.ExecutorAction;
        wrapper: string;
        sizeDelta: string;
        desiredFillPrice: string;
        memo?: string | null;
        chain: string;
        transaction: string;
        createdAt: string;
        perpMarket: {
          __typename?: 'AssetType';
          id: string;
          name: string;
          address: string;
          symbol: string;
          decimals?: number | null;
          price?: BigNumber | null;
          chain: string;
          chainId: number;
          priceChangeAmount?: BigNumber | null;
          priceChangePercentage?: BigNumber | null;
          marketCap?: string | null;
          totalSupply?: string | null;
          type: Types.AssetTypeEnum;
          deprecated: boolean;
          hardDeprecated: boolean;
          createdAt: string;
        };
      }
    | { __typename?: 'KwentaActionWithdrawType' }
    | {
        __typename: 'ZeroXActionType';
        id: string;
        action: Types.ExecutorAction;
        inputTokenAmount: string;
        outputTokenAmount: string;
        transaction: string;
        chainId: number;
        chain: string;
        memo?: string | null;
        unitPrice: string;
        createdAt: string;
        inputToken: {
          __typename?: 'AssetType';
          id: string;
          name: string;
          address: string;
          symbol: string;
          decimals?: number | null;
          price?: BigNumber | null;
          chain: string;
          chainId: number;
          priceChangeAmount?: BigNumber | null;
          priceChangePercentage?: BigNumber | null;
          marketCap?: string | null;
          totalSupply?: string | null;
          type: Types.AssetTypeEnum;
          deprecated: boolean;
          hardDeprecated: boolean;
          createdAt: string;
        };
        outputToken: {
          __typename?: 'AssetType';
          id: string;
          name: string;
          address: string;
          symbol: string;
          decimals?: number | null;
          price?: BigNumber | null;
          chain: string;
          chainId: number;
          priceChangeAmount?: BigNumber | null;
          priceChangePercentage?: BigNumber | null;
          marketCap?: string | null;
          totalSupply?: string | null;
          type: Types.AssetTypeEnum;
          deprecated: boolean;
          hardDeprecated: boolean;
          createdAt: string;
        };
      };
};

export const TradeMemoFragmentDoc = gql`
  fragment TradeMemo on TradingMemoType {
    message
    action {
      ...VaultAction
    }
    createdAt
  }
  ${VaultActionFragmentDoc}
`;
