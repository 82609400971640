import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgThreeDotsLoader = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" {...props}>
    <circle fill="#fff" cx={12} cy={12} r={4}>
      <animate attributeName="r" dur="1s" values="4px; 8px; 4px" repeatCount="indefinite" begin={0.2} />
    </circle>
    <circle fill="#fff" cx={30} cy={12} r={4}>
      <animate attributeName="r" dur="1s" values="4px; 8px; 4px" repeatCount="indefinite" begin={0.4} />
    </circle>
    <circle fill="#fff" cx={48} cy={12} r={4}>
      <animate attributeName="r" dur="1s" values="4px; 8px; 4px" repeatCount="indefinite" begin={0.6} />
    </circle>
  </svg>
));
export default SvgThreeDotsLoader;
