import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export enum ChipVariant {
  Slim = 'slim',
  Medium = 'medium',
  Default = 'default',
}

export type ChipProps = ComponentPropsWithoutRef<'span'> & {
  label: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  variant?: ChipVariant;
  disabled?: boolean;
};

export const Chip = (props: ChipProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { label, startIcon, endIcon, variant = ChipVariant.Default, disabled, className, children, ...rest } = props;

  return (
    <span
      aria-disabled={disabled}
      className={twMerge(
        'chip-root',
        'rounded-[10px] text-sm font-medium inline-flex items-center px-2.5 py-0 h-[30px] whitespace-nowrap gap-x-1 text-brand-600 bg-brand-600/20 select-none',
        rest.onClick && 'cursor-pointer pointer-events-auto',
        variant === ChipVariant.Slim && 'h-[21px] text-xs rounded-md px-1.5',
        variant === ChipVariant.Medium && 'h-[25px] text-sm rounded-lg px-[7px]',
        disabled && 'pointer-events-none',
        className
      )}
      {...rest}
    >
      {startIcon && <span className="flex text-xs items-center [&_svg]:text-inherit">{startIcon}</span>}
      {label}
      {endIcon && (
        <span className="flex text-xs items-center [&_svg]:text-inherit [&_.icon-button]:border-0 [&_.icon-button]:-mx-2.5">
          {endIcon}
        </span>
      )}
    </span>
  );
};
