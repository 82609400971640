import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgNote = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 29 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M28.03 5.668a3.304 3.304 0 0 0-2.342-.962c-.885 0-1.717.341-2.342.962l-2.835 2.81V2.591C20.511 1.162 19.34 0 17.9 0H2.612C1.172 0 0 1.162 0 2.59v19.82C0 23.837 1.172 25 2.612 25H17.9c1.44 0 2.612-1.162 2.612-2.59v-4.64l7.519-7.457A3.25 3.25 0 0 0 29 7.991a3.25 3.25 0 0 0-.97-2.323Zm-9.35 16.741c0 .427-.35.775-.781.775H2.612a.778.778 0 0 1-.78-.775V2.591c0-.427.35-.775.78-.775H17.9c.43 0 .78.348.78.775v7.704l-3.444 3.416a4.842 4.842 0 0 0-1.309 2.342l-.51 2.195c-.125.54.036 1.095.431 1.486a1.604 1.604 0 0 0 1.498.426l2.213-.506c.392-.09.768-.226 1.122-.404v3.16Zm-.055-5.337a3.071 3.071 0 0 1-1.478.812l-1.866.427.43-1.85c.13-.556.413-1.063.82-1.466l6.708-6.653 2.094 2.077-6.708 6.653Zm8.11-8.043-.107.106-2.094-2.077.107-.106c.28-.277.651-.43 1.047-.43a1.477 1.477 0 0 1 1.48 1.469c0 .392-.154.76-.433 1.038Z"
      fill="currentColor"
    />
    <path
      d="M4.368 6.464h11.776a.912.912 0 0 0 .915-.908.912.912 0 0 0-.916-.909H4.368a.912.912 0 0 0-.916.909c0 .501.41.908.916.908ZM4.367 11.093h10.916a.912.912 0 0 0 .916-.909.912.912 0 0 0-.916-.908H4.367a.912.912 0 0 0-.916.909c0 .501.41.908.916.908ZM11.708 13.906H4.367a.912.912 0 0 0-.916.909c0 .501.41.908.916.908h7.34a.912.912 0 0 0 .917-.909.912.912 0 0 0-.916-.908Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgNote;
