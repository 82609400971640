/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';

export type AddTradeMemoMutationVariables = Types.Exact<{
  input: Types.AddTradeMemoInput;
}>;

export type AddTradeMemoMutation = { __typename?: 'Mutation'; addTradeMemo: boolean };

export const AddTradeMemoDocument = gql`
  mutation AddTradeMemo($input: AddTradeMemoInput!) {
    addTradeMemo(input: $input)
  }
`;

export function AddTradeMemo<R extends AddTradeMemoMutation, V extends AddTradeMemoMutationVariables>(
  request: Request<R, V>,
  variables: V,
  headers?: HeadersInit
): Promise<R> {
  return request(AddTradeMemoDocument, variables, headers);
}
