import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgDiscord = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.091 5.103c-.654-1.962-1.389-3.007-1.448-3.092l-.004-.005C14.596 1.96 13.517.833 10.92 0l-.344.816c1.236.397 2.114.88 2.596 1.193A19.416 19.416 0 0 0 8.5 1.403c-1.517 0-3.23.23-4.671.606.482-.313 1.36-.796 2.596-1.193L6.082 0c-2.6.833-3.678 1.96-3.721 2.006l-.004.005c-.06.086-.794 1.13-1.448 3.092C.257 7.06.009 9.818 0 9.93l.005.007C.116 10.092 1.472 12 5.037 12l.923-1.167a10.565 10.565 0 0 1-2.923-1.151l.551-.724c1.457.846 3.155 1.294 4.912 1.294 1.757 0 3.455-.448 4.912-1.294l.551.724c-.902.525-1.889.911-2.923 1.151L11.963 12c3.565 0 4.92-1.908 5.031-2.064L17 9.93c-.009-.11-.257-2.87-.909-4.826ZM6.197 7.378h-.996v-1.45h.996v1.45Zm4.606 0h.996v-1.45h-.996v1.45Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgDiscord;
