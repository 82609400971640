import Fuse from 'fuse.js';
import { injectable } from 'inversify';
import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { VaultListFragment } from 'core/api/gql/vault/vault-list.fragment.generated';

import { Disposable } from 'utils/disposable';

import { ExploreVaultsStore } from './explore/explore-vaults.store';

@injectable()
export class VaultSearchStore extends Disposable {
  @observable
  term = '';

  @observable
  isLoading = false;

  @observable.ref
  private fuse: Fuse<VaultListFragment>;

  @observable
  private loaded = false;

  constructor(private readonly exploreVaults: ExploreVaultsStore) {
    super();
    makeObservable(this);

    const options: Fuse.IFuseOptions<VaultListFragment> = {
      keys: [
        'name',
        {
          name: 'managers',
          getFn: (vault) => [
            vault.manager.account.name ?? vault.manager.account.address,
            vault.manager.account.address,
          ],
        },
      ],
      threshold: 0.01,
    };

    this.fuse = new Fuse(this.exploreVaults.getAll(), options);

    this.autoDispose(
      reaction(
        () => this.exploreVaults.getAll(),
        (result) => (this.fuse = new Fuse(result, options))
      )
    );
  }

  @computed
  get searchResults(): VaultListFragment[] {
    return this.fuse.search(this.term).map((item) => item.item);
  }

  @action
  setSearchTerm = (term: string): void => {
    this.term = term;

    if (!this.isLoading && !this.loaded) {
      this.loadVaults();
    }
  };

  @action
  private setIsLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading;
  };

  @action
  private setLoaded = (loaded: boolean): void => {
    this.loaded = loaded;
  };

  private loadVaults = async (): Promise<void> => {
    this.setIsLoading(true);

    await this.exploreVaults.wait();

    this.setLoaded(true);
    this.setIsLoading(false);
  };
}
