import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgPulse = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.375 7.333H15a.596.596 0 0 0-.329.1.653.653 0 0 0-.23.269l-.512 1.093-2.075-8.3a.657.657 0 0 0-.231-.368.603.603 0 0 0-.783.036.678.678 0 0 0-.205.385l-2 11.736-1.781-7.122a.674.674 0 0 0-.199-.336.605.605 0 0 0-.707-.078.642.642 0 0 0-.257.287L4.614 7.333H.625a.619.619 0 0 0-.541.334.706.706 0 0 0 0 .667.619.619 0 0 0 .541.333H5c.116 0 .23-.035.329-.1a.654.654 0 0 0 .23-.269l.512-1.092 2.075 8.3a.671.671 0 0 0 .223.356.6.6 0 0 0 .381.138h.025a.602.602 0 0 0 .385-.164c.107-.099.18-.234.205-.384l2-11.736 1.781 7.122a.662.662 0 0 0 .302.412.59.59 0 0 0 .488.053.637.637 0 0 0 .373-.338l1.077-2.298h3.989c.223 0 .43-.127.541-.334a.706.706 0 0 0 0-.666.619.619 0 0 0-.541-.334Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgPulse;
