/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { AssetFragmentDoc } from '../asset/asset.fragment.generated';

export type KwentaActionSubmitCloseOrderFragment = {
  __typename: 'KwentaActionSubmitCloseOrderType';
  id: string;
  action: Types.ExecutorAction;
  wrapper: string;
  desiredFillPrice: string;
  memo?: string | null;
  chain: string;
  transaction: string;
  createdAt: string;
  perpMarket: {
    __typename?: 'AssetType';
    id: string;
    name: string;
    address: string;
    symbol: string;
    decimals?: number | null;
    price?: BigNumber | null;
    chain: string;
    chainId: number;
    priceChangeAmount?: BigNumber | null;
    priceChangePercentage?: BigNumber | null;
    marketCap?: string | null;
    totalSupply?: string | null;
    type: Types.AssetTypeEnum;
    deprecated: boolean;
    hardDeprecated: boolean;
    createdAt: string;
  };
};

export const KwentaActionSubmitCloseOrderFragmentDoc = gql`
  fragment KwentaActionSubmitCloseOrder on KwentaActionSubmitCloseOrderType {
    __typename
    id
    action
    wrapper
    perpMarket {
      ...Asset
    }
    desiredFillPrice
    memo
    chain
    transaction
    createdAt
  }
  ${AssetFragmentDoc}
`;
