import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgPeopelsNeon = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 97 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#peopels-neon_svg__a)">
      <path
        d="m83.126 48.253.001.007c.089.526-.076.979-.581 1.431-.543.486-1.444.928-2.697 1.29-2.33.672-5.593.986-9.156.99-1.108-6.609-3.498-11.88-7.062-15.572l-.04-.041c1.734-.982 3.781-1.486 6.271-1.486 3.803 0 6.744 1.244 8.914 3.482 2.187 2.257 3.658 5.597 4.35 9.9Zm-56.742 3.792c-3.562-.04-6.84-.372-9.19-1.054-1.265-.367-2.179-.812-2.73-1.3-.515-.458-.68-.91-.591-1.431.733-4.346 2.225-7.688 4.412-9.936 2.17-2.228 5.09-3.453 8.852-3.453 2.448 0 4.563.517 6.323 1.52h-.001l-.007.008c-3.637 3.764-6.026 9.018-7.056 15.61a1.87 1.87 0 0 0-.012.036Zm30.779-29.647c0 4.711-3.869 8.548-8.663 8.548-4.794 0-8.663-3.837-8.663-8.548s3.868-8.548 8.663-8.548c4.794 0 8.663 3.837 8.663 8.548Zm18.943 2.143c0 3.391-2.786 6.157-6.244 6.157-3.457 0-6.243-2.766-6.243-6.157 0-3.39 2.786-6.157 6.243-6.157 3.458 0 6.244 2.766 6.244 6.157Zm-42.726 0c0 3.391-2.785 6.157-6.243 6.157s-6.243-2.766-6.243-6.157c0-3.39 2.785-6.157 6.243-6.157s6.243 2.766 6.243 6.157ZM48.5 58.15c-5.185 0-9.994-.453-13.386-1.44-1.702-.495-2.967-1.104-3.749-1.791-.747-.657-1.015-1.343-.886-2.126.983-5.829 2.996-10.345 5.976-13.396 2.962-3.033 6.948-4.69 12.045-4.69 5.094 0 9.08 1.676 12.044 4.72 2.98 3.062 4.993 7.58 5.977 13.366.129.784-.14 1.469-.886 2.126-.782.688-2.047 1.296-3.75 1.791-3.391.987-8.2 1.44-13.385 1.44Z"
        stroke="#fff"
        strokeWidth={1.7}
      />
    </g>
    <defs>
      <filter
        id="peopels-neon_svg__a"
        x={0.737}
        y={0.737}
        width={95.526}
        height={70.526}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={6.131} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_7263_24839" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={3.344} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_7263_24839" result="effect2_dropShadow_7263_24839" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.115} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_7263_24839" result="effect3_dropShadow_7263_24839" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_7263_24839" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgPeopelsNeon;
