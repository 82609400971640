import { ErrorIcon, SuccessfulIcon } from 'components/icons';
import { IconSize } from 'components/svg-icon';
import { Primary, Secondary } from 'components/typography';

import { Snackbar } from './snackbar';

type SnackbarProps = {
  data: Snackbar.Success | Snackbar.Failed;
};

export const SnackbarItem = (props: SnackbarProps) => {
  const { data } = props;

  return (
    <div className="flex items-center gap-2.5 flex-1">
      {Snackbar.isFailed(data) && <ErrorIcon size={IconSize.Bigger} />}
      {Snackbar.isSuccess(data) && <SuccessfulIcon size={IconSize.Bigger} />}
      <div className="flex flex-col max-h-[90px] overflow-hidden">
        <Primary>{data.message}</Primary>
        {Snackbar.isFailed(data) && <Secondary>{data.description}</Secondary>}
      </div>
    </div>
  );
};
