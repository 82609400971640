/* eslint-disable @typescript-eslint/naming-convention */
import { z } from 'zod';

export const configSchema = z.object({
  REACT_APP_ENV: z.enum(['stage', 'prod', 'test']),
  REACT_APP_GRAPHQL_WS_URL: z.string(),
  REACT_APP_GRAPHQL_URL: z.string(),
  REACT_APP_WC_PROJECT_ID: z.string(),
  REACT_APP_SEASON_ONE: z
    .string()
    .transform((r) => JSON.parse(r))
    .pipe(z.boolean()),
  REACT_APP_SEASON_ZERO: z
    .string()
    .transform((r) => JSON.parse(r))
    .pipe(z.boolean()),
  REACT_APP_ZERO_X_API_KEY: z.string(),
  REACT_APP_ALCHEMY_API_KEY_ARBITRUM: z.string(),
  REACT_APP_ALCHEMY_API_KEY_OPTIMISM: z.string(),
  REACT_APP_ALCHEMY_API_KEY_ETHEREUM: z.string(),
  REACT_APP_VALIO_REGISTRY_ARBITRUM: z.string(),
  REACT_APP_VALIO_TRANSPORT_ARBITRUM: z.string(),
  REACT_APP_VALIO_REGISTRY_OPTIMISM: z.string(),
  REACT_APP_VALIO_TRANSPORT_OPTIMISM: z.string(),
  REACT_APP_VALIO_REGISTRY_ETHEREUM: z.string(),
  REACT_APP_VALIO_TRANSPORT_ETHEREUM: z.string(),
  REACT_APP_AMPLITUDE_API_KEY: z.string(),
  REACT_APP_S3_BUCKET: z.string(),
  REACT_APP_SOCKET_API_KEY: z.string(),
  REACT_APP_PRIVY_APP_ID: z.string(),
  REACT_APP_ONRAMPER_API_KEY: z.string(),
});
