import { ArbIcon } from 'components/icons';

import { ChainID, ChainInfo } from './chain.info';

export const arbitrumChain = {
  name: 'Arbitrum',
  chainId: ChainID.Arbitrum,
  symbol: 'ETH',
  symbolAlias: 'WETH',
  blockExplorerUrl: 'https://arbiscan.io',
  Icon: ArbIcon,
} as const satisfies ChainInfo;
