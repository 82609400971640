import { HTMLProps, ImgHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type SlideProps = HTMLProps<HTMLDivElement>;

export const Slide = ({ className, children, ...rest }: SlideProps) => {
  return (
    <div className={twMerge('w-[566px] h-[493px] relative', className)} {...rest}>
      {children}
    </div>
  );
};

type ImageProps = ImgHTMLAttributes<HTMLImageElement>;

export const Image = ({ className, ...rest }: ImageProps) => {
  return (
    <img
      className={twMerge('absolute top-0 left-0 w-[566px] h-[245px] object-cover border-b border-outline', className)}
      {...rest}
    />
  );
};

export const Description = ({ className, children, ...rest }: SlideProps) => {
  return (
    <div className={twMerge('mt-[170px] w-[350px]', className)} {...rest}>
      {children}
    </div>
  );
};
