import { useService } from '@redtea/react-inversify';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { OneOfTheme } from 'styles/themes';
import { useTheme } from 'styles/themes/use-theme.hook';

import { ConfigService } from 'core/config/config.service';

import { Button, ButtonVariant } from 'components/button';
import { ArrowUpRightIcon } from 'components/icons';
import { H1, H3 } from 'components/typography';

export const NotFound = memo(() => {
  const { errorPage } = useTheme();
  const { theme } = useService(ConfigService);

  return (
    <div className="flex justify-center items-center h-full flex-col flex-1 gap-3">
      {errorPage?.icon}

      <div className="flex items-center flex-col">
        <H1>{getTitleByTheme(theme)}</H1>
        <H3>
          Try to change your request or go to{' '}
          <Link to="/">
            <Button variant={ButtonVariant.Text} endIcon={<ArrowUpRightIcon />} className="text-primary">
              home page
            </Button>
          </Link>
        </H3>
      </div>
    </div>
  );
});

const getTitleByTheme = (theme: OneOfTheme) => {
  switch (theme) {
    case 'anodos':
      return 'Page not found';
    case 'default':
      return "Cats didn't find the page you are looking for";
  }
};
