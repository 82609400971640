/* eslint-disable @typescript-eslint/naming-convention */
import { useService } from '@redtea/react-inversify';
import { FC, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ConfigService } from 'core/config/config.service';

import { IconSize } from 'components/svg-icon';

import { isSomething } from 'utils/is-something';

type CryptoAssetProps = {
  src: string;
  code: string;
  className?: string;
  isWrapped?: boolean;
  size?: IconSize;
};

const CryptoAsset = (props: CryptoAssetProps) => {
  const { code, src, size, isWrapped, className, ...rest } = props;

  const [error, setError] = useState(false);
  const { s3Bucket } = useService(ConfigService);

  const handleError = () => {
    if (!error) {
      setError(true);
    }
  };

  return (
    <img
      className={twMerge(
        'w-[1em] h-[1em] text-xl rounded-full box-border',
        size === IconSize.Smallest && 'text-[10px]',
        size === IconSize.Smaller && 'text-[12px]',
        size === IconSize.Small && 'text-[14px]',
        size === IconSize.Medium && 'text-[16px]',
        size === IconSize.Large && 'text-[18px]',
        size === IconSize.Bigger && 'text-[21px]',
        size === IconSize.Largest && 'text-[24px]',
        isWrapped && 'bg-white p-px',
        className
      )}
      alt={code}
      src={`${s3Bucket}/${error ? 'assets/tokens/default.svg' : src}`}
      onError={handleError}
      {...rest}
    />
  );
};

const symbolOverrides = {
  WETH: 'ETH',
  WBTC: 'BTC',
  WAVAX: 'AVAX',
  VLT: 'USDC',
  'USDC.e': 'USDC',
  sETH: 'ETH',
  sBTC: 'BTC',
} as const;

export const getIcon = (code: string): FC<{ className?: string }> => {
  const overridenSymbol = symbolOverrides[code as keyof typeof symbolOverrides];
  const isWrapped = isSomething(overridenSymbol);

  const src = `assets/tokens/${(overridenSymbol || code).toLowerCase()}.svg`;

  return (props) => <CryptoAsset {...props} src={src} code={code} isWrapped={isWrapped} />;
};
