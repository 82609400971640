import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';

import { appContainer } from 'core/app.container';
import { AppStore } from 'core/app/app.store';

import { ErrorPage } from 'components/error-page';
import { NotFound } from 'components/not-found/';

import { BaseLayout } from './base-layout';
import { Layout } from './layout';
import { manageRoutes } from './manage/manage.routes';
import { myPositionRoutes } from './my-positions/my-positions.routes';
import { RevealLayout } from './season-one/reveal/reveal-layout';
import { RevealSZN0Layout } from './season-zero/reveal/reveal-layout';
import { shouldRevalidate } from './should-revalidate';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: '/*',
    element: (
      <BaseLayout>
        <Layout />
      </BaseLayout>
    ),
    loader: () => appContainer.get(AppStore).init(),
    shouldRevalidate,
    ErrorBoundary: ErrorPage,
    children: [
      {
        index: true,
        lazy: () => import('pages/home'),
        shouldRevalidate,
      },
      {
        path: 'profile',
        lazy: () => import('./profile'),
        children: [
          {
            path: 'twitter-callback',
            lazy: () => import('./profile/twitter-callback'),
          },
        ],
      },
      {
        path: 'explore',
        lazy: () => import('pages/explore-vaults'),
      },
      {
        path: 'vault/:id',
        lazy: () => import('pages/vault-inner'),
      },
      {
        path: 'my-positions',
        lazy: () => import('pages/my-positions'),
        children: myPositionRoutes,
      },
      {
        path: 'manager/:id',
        lazy: () => import('pages/manager'),
      },
      {
        path: 'season-one',
        lazy: () => import('pages/season-one'),
      },
      {
        path: 'szn0',
        lazy: () => import('pages/season-zero'),
      },
      {
        path: 'manage/*',
        children: manageRoutes,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    element: (
      <BaseLayout>
        <RevealLayout />
      </BaseLayout>
    ),
    ErrorBoundary: ErrorPage,
    children: [
      {
        path: 'szn1/reveal',
        lazy: () => import('pages/season-one/reveal'),
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    element: (
      <BaseLayout>
        <RevealSZN0Layout />
      </BaseLayout>
    ),
    ErrorBoundary: ErrorPage,
    loader: () => appContainer.get(AppStore).init(),
    children: [
      {
        path: 'szn0/reveal',
        lazy: () => import('pages/season-zero/reveal'),
        shouldRevalidate,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);
