export namespace Errors {
  export const isChunkLoadError = (error: unknown): boolean => {
    return isError(error) && error.name === 'ChunkLoadError';
  };

  export const isError = (x: unknown): x is Error => {
    return x instanceof Error;
  };

  export const isNotFoundError = (error: unknown): boolean => {
    return isError(error) && error.message.includes('not found');
  };

  export const isInsufficientGasError = (error: unknown): boolean => {
    return isError(error) && hasData(error) && error.cause.data?.code === -32000;
  };

  export const isSecondInsufficientGasError = (error: unknown): boolean => {
    return isError(error) && hasSimpleData(error) && error.data?.code === -32000;
  };

  export const isUserRejectedAction = (error: Error): boolean => {
    return hasData(error) && error.cause.code === 4001;
  };

  export const isVaultNotInSync = (error: Error): boolean => {
    return isError(error) && hasReason(error) && error.reason.includes('vault not in sync');
  };

  export const isAlreadyLeviedThisPeriod = (error: Error): boolean => {
    return isError(error) && hasReason(error) && error.reason.includes('already levied this period');
  };

  export const isVaultClosed = (error: Error): boolean => {
    return isError(error) && hasReason(error) && error.reason.includes('vault closed');
  };

  export const isNotDepositAsset = (error: Error): boolean => {
    return isError(error) && hasReason(error) && error.reason.includes('not deposit asset');
  };

  export const isManagerAlreadyOwnsHoldings = (error: Error): boolean => {
    return isError(error) && hasReason(error) && error.reason.includes('manager already owns holdings');
  };

  export const isHoldsHardDeprecatedAsset = (error: Error): boolean => {
    return isError(error) && hasReason(error) && error.reason.includes('holds hard deprecated asset');
  };

  export const isVaultWillExceedCustomCap = (error: Error): boolean => {
    return isError(error) && hasReason(error) && error.reason.includes('vault will exceed custom cap');
  };

  export const isVaultWillExceedCap = (error: Error): boolean => {
    return isError(error) && hasReason(error) && error.reason.includes('vault will exceed cap');
  };

  export const isMinDepositNotMet = (error: Error): boolean => {
    return isError(error) && hasReason(error) && error.reason.includes('min deposit not met');
  };

  export const isExceedsMaxDeposit = (error: Error): boolean => {
    return isError(error) && hasReason(error) && error.reason.includes('exceeds max deposit');
  };

  export const isNoLiquidityPoolError = (error: Error): boolean => {
    return isError(error) && hasReason(error) && error.reason.includes('NLQ');
  };

  // https://github.com/valioxyz/valio-contracts/pull/198/files - errors explanation
  export type ErrorWithData = Error & {
    cause: {
      code: number;
      data?: {
        code: number;
        message: string;
      };
    };
  };

  export type ErrorWithSimpleData = Error & {
    code: number;
    data?: {
      code: number;
      message: string;
    };
  };

  export const hasData = (error: Error): error is ErrorWithData => {
    return 'cause' in error && typeof error.cause === 'object' && error.cause !== null;
  };

  export const hasSimpleData = (error: Error): error is ErrorWithSimpleData => {
    return 'data' in error && typeof error.data === 'object' && error.data !== null;
  };

  export type ErrorWithReason = Error & {
    code: string;
    reason: string;
  };

  export const hasReason = (error: Error): error is ErrorWithReason => {
    return 'reason' in error && typeof error.reason === 'string';
  };

  export type GQLError = {
    response: {
      errors: {
        message: string;
      }[];
    };
  };

  export const isGQLError = (error: unknown): error is GQLError => {
    return (
      typeof error === 'object' &&
      error !== null &&
      'response' in error &&
      typeof error.response === 'object' &&
      error.response !== null &&
      'errors' in error.response &&
      Array.isArray(error.response.errors) &&
      error.response.errors.length > 0
    );
  };
}
