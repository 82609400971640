import { useNavigation, useRevalidator } from 'react-router-dom';

import { Progress } from './progress';

export const PageProgress = () => {
  const navigation = useNavigation();
  const revalidate = useRevalidator();

  const isLoading = navigation.state === 'loading' || revalidate.state === 'loading';

  return <Progress isLoading={isLoading} />;
};
