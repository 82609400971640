import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgArrowDown = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 9 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.504 12a1.416 1.416 0 0 0 1.05-.506l3.228-3.677A.923.923 0 0 0 9 7.213a.923.923 0 0 0-.218-.604.753.753 0 0 0-.245-.188.67.67 0 0 0-.577 0 .753.753 0 0 0-.244.188l-2.462 2.82V.857a.923.923 0 0 0-.22-.606.707.707 0 0 0-.53-.251c-.2 0-.39.09-.531.251a.923.923 0 0 0-.22.606V9.43l-2.47-2.82a.708.708 0 0 0-.53-.254c-.199 0-.39.089-.531.25A.922.922 0 0 0 0 7.21c0 .227.078.446.218.607l3.227 3.677c.28.322.661.504 1.059.506Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgArrowDown;
