import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgUserWithCursorBlueNeon = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 101 82" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#user-with-cursor-blue-neon_svg__a)">
      <path
        d="M46.353 24.423c0 5.84-4.725 10.572-10.55 10.572-5.826 0-10.552-4.732-10.552-10.572 0-5.841 4.726-10.573 10.551-10.573 5.826 0 10.551 4.732 10.551 10.573Zm-30.82 38.494a1.686 1.686 0 0 1-1.683-1.688v-2.538c0-9.346 7.561-16.919 16.885-16.919H40.87c9.323 0 16.885 7.573 16.885 16.919v2.538c0 .934-.756 1.688-1.684 1.688H15.534Z"
        stroke="#fff"
        strokeWidth={1.7}
      />
    </g>
    <g filter="url(#user-with-cursor-blue-neon_svg__b)">
      <path
        d="m87.08 55.272.009.006c.476.33.368 1.153-.36 1.262l-.007.001-5.956.954-1.289.207.71 1.095 4.209 6.493c.62.963.354 2.184-.611 2.79a2.087 2.087 0 0 1-2.86-.607l-.001-.003-4.206-6.489-.696-1.075-.72 1.06-3.366 4.953c-.365.53-1.204.36-1.334-.272v-.002l-4.738-23.939v-.002a.7.7 0 0 1 .322-.74l.009-.006.009-.006c.22-.14.564-.144.83.04l20.046 14.28Z"
        stroke="#fff"
        strokeWidth={1.7}
      />
    </g>
    <defs>
      <filter
        id="user-with-cursor-blue-neon_svg__a"
        x={0.737}
        y={0.737}
        width={70.131}
        height={75.293}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={6.131} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_13174_2133" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={3.344} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_13174_2133" result="effect2_dropShadow_13174_2133" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.115} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_13174_2133" result="effect3_dropShadow_13174_2133" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_13174_2133" result="shape" />
      </filter>
      <filter
        id="user-with-cursor-blue-neon_svg__b"
        x={52.737}
        y={27.737}
        width={47.759}
        height={53.782}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={6.131} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_13174_2133" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={3.344} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_13174_2133" result="effect2_dropShadow_13174_2133" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.115} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_13174_2133" result="effect3_dropShadow_13174_2133" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_13174_2133" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgUserWithCursorBlueNeon;
