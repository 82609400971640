export enum Storage {
  Theme = 'theme',
  Connector = 'connector',
  GasPrice = 'gasPrice',
  SlippagePercentage = 'slippagePercentage',
  AccessToken = 'accessToken',
  VaultTermsConditions = 'vaultTermsConditions',
  TermsConditions = 'termsConditions',
  VereficationVault = 'vereficationVault',
  ReferrerCode = 'referrerCode',
}
