import jwtDecode from 'jwt-decode';

import { Address, Addresses } from 'core/address';

export namespace Auth {
  export type JWTPayload = {
    exp: number;
    sub: Address;
  };

  export type AuthData = {
    accessToken: string;
    payload?: JWTPayload;
  };

  export type AuthHeaders =
    | {
        Authorization: `Bearer ${string}`;
      }
    | {
        //
      };

  export const parseAccessToken = (token: string): AuthData | null => {
    try {
      const payload = jwtDecode<JWTPayload>(token);

      return {
        accessToken: token,
        payload: {
          exp: payload.exp * 1000,
          sub: payload.sub,
        },
      };
    } catch (e) {
      return null;
    }
  };

  export const isValidData = (data: AuthData, address: Address): boolean =>
    Boolean(data && data.payload && data.payload.exp > Date.now() && Addresses.areEqual(data.payload.sub, address));

  export const makeHeaders = (accessToken: string): AuthHeaders => {
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  };
}
