import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgShare = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.278 8.556a2.72 2.72 0 0 0-2.241 1.18L5.246 8.024a2.721 2.721 0 0 0 .006-2.039l3.787-1.72a2.72 2.72 0 1 0-.441-1.08l-4.02 1.824a2.722 2.722 0 1 0-.008 3.99l4.025 1.817a2.723 2.723 0 1 0 2.683-2.26Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgShare;
