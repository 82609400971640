/* the wrap component fixes react hot reload issue */
import { FC } from 'react';
import invariant from 'tiny-invariant';

import { isSomething } from 'utils/is-something';

export type ModalLike<P = object> = FC<P> & {
  displayName: string;
};

export const createModal = <P extends object = Record<string, unknown>>(
  ModalComponent: ModalLike<P>,
  renderProps: P = {} as P
) => {
  const component = () => <ModalComponent {...renderProps} />;

  component.displayName = ModalComponent.displayName;

  invariant(isSomething(component.displayName), 'Modal component must have a displayName');

  return component;
};
