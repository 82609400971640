import { injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { filter, map, switchMap } from 'rxjs';

import { Address } from 'core/address';
import { ApiService } from 'core/api/api.service';
import { ReferralFragment } from 'core/api/gql/referral/referral.fragment.gql.generated';
import { ReferralSubscription } from 'core/api/gql/referral/referral.gql.generated';
import { Referrals } from 'core/api/gql/referral/referrals.gql.generated';
import { SortedResource } from 'core/sorted-resource';
import { WalletStore } from 'core/wallet/wallet.store';

import { Disposable } from 'utils/disposable';
import { isSomething } from 'utils/is-something';

@injectable()
export class ReferralStore extends Disposable {
  @observable
  referralMap = new Map<Address, ReferralFragment>();

  @observable.ref
  sorted: SortedResource<ReferralFragment>;

  constructor(private readonly apiService: ApiService, private readonly walletStore: WalletStore) {
    super();
    makeObservable(this);

    this.sorted = new SortedResource(() => this.referrals);

    this.autoDispose(
      this.walletStore.address$
        .pipe(
          filter(isSomething),
          switchMap((address) => this.apiService.query(Referrals, { address })),
          map((result) => result.referrals)
        )
        .subscribe(this.setReferrals)
    );

    this.autoDispose(
      this.apiService
        .subscription(ReferralSubscription)
        .pipe(map((result) => result.referral))
        .subscribe(this.updateReferral)
    );
  }

  @computed
  get referrals(): ReferralFragment[] {
    return Array.from(this.referralMap.values());
  }

  @action
  updateReferral = (referral: ReferralFragment): void => {
    this.referralMap.set(referral.referral.address, referral);
  };

  setReferrals = (referrals: ReferralFragment[]): void => {
    this.referralMap = new Map(referrals.map((referral) => [referral.referral.address, referral]));
  };
}
