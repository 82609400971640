import { Registry, Registry__factory } from '@valioxyz/valio-contracts';
import { injectable } from 'inversify';
import invariant from 'tiny-invariant';

import { ConfigService } from 'core/config/config.service';
import { ContractCache } from 'core/contract/contract.cache';
import { NetworkProvider } from 'core/network/network.provider';

import { Valio } from './valio';

@injectable()
export class ValioRegistry {
  constructor(
    private readonly contractCache: ContractCache,
    private readonly networkProvider: NetworkProvider,
    private readonly configService: ConfigService
  ) {}

  get registry(): Registry {
    return Registry__factory.connect(this.contracts.Registry, this.networkProvider.getProvider());
  }

  get contracts(): Valio.Contracts {
    const chainId = this.networkProvider.chainId;

    invariant(chainId, 'Chain ID is not defined');
    invariant(Valio.isSupportedChain(chainId), 'Contracts are not available on this chain');

    return this.configService.valio[chainId];
  }

  getWithdrawAutomatorAddress = async (): Promise<string> =>
    this.contractCache.getData(
      () => this.registry.withdrawAutomator(),
      `${this.networkProvider.chainId}-WithdrawAutomator`
    );

  getDepositAutomatorAddress = async (): Promise<string> =>
    this.contractCache.getData(
      () => this.registry.depositAutomator(),
      `${this.networkProvider.chainId}-DepositAutomator`
    );

  getZeroXExecutorAddress = async (): Promise<string> =>
    this.contractCache.getData(
      () => this.registry.executors(Valio.ExecutorIntegration.ZeroX),
      `${this.networkProvider.chainId}-ZeroXExecutor`
    );

  getGmxExecutorAddress = async (): Promise<string> =>
    this.contractCache.getData(
      () => this.registry.executors(Valio.ExecutorIntegration.GMX),
      `${this.networkProvider.chainId}-GMXExecutor`
    );

  getGmxConfigAddress = async (): Promise<string> =>
    this.contractCache.getData(() => this.registry.gmxConfig(), `${this.networkProvider.chainId}-GMXConfig`);

  getGmxOrderBookExecutorAddress = async (): Promise<string> =>
    this.contractCache.getData(
      () => this.registry.executors(Valio.ExecutorIntegration.GMXOrderBook),
      `${this.networkProvider.chainId}-GMXOrderBookExecutor`
    );

  getMultiSwapAndBridgeAddress = (): Promise<string> =>
    this.contractCache.getData(
      () => this.registry.ancillaryContractAddressByString('MULTI_SWAP_AND_BRIDGE'),
      `${this.networkProvider.chainId}-MultiSwapAndBridge`
    );
}
