/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { ReferralFragmentDoc } from './referral.fragment.gql.generated';

export type ReferralsQueryVariables = Types.Exact<{
  address: Types.Scalars['String']['input'];
}>;

export type ReferralsQuery = {
  __typename?: 'Query';
  referrals: Array<{
    __typename?: 'ReferralType';
    points: number;
    referral: { __typename?: 'AccountType'; address: string; createdAt: string; chain: string };
  }>;
};

export const ReferralsDocument = gql`
  query Referrals($address: String!) {
    referrals(address: $address) {
      ...Referral
    }
  }
  ${ReferralFragmentDoc}
`;

export function Referrals<R extends ReferralsQuery, V extends ReferralsQueryVariables>(
  request: Request<R, V>,
  variables: V,
  headers?: HeadersInit
): Promise<R> {
  return request(ReferralsDocument, variables, headers);
}
