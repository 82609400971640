import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';
import { Link, NavLink } from 'react-router-dom';

import { WalletStore } from 'core/wallet/wallet.store';

import { Chip } from 'components/chip';
import { ChevronLeftIcon, ProfileIcon, SecondLogoIcon } from 'components/icons';
import { Secondary } from 'components/typography';

import { formatShortAddress } from 'utils/web3';

export const RevealHeader = observer(() => {
  const { address } = useService(WalletStore);

  if (!address) return null;

  return (
    <div className="flex justify-between w-screen h-14 px-5 py-3 box-border relative z-10">
      <div className="flex items-center gap-5">
        <Link
          data-testid="go-back-szn1"
          className="w-9 h-9 border border-outline rounded-lg cursor-pointer flex items-center justify-center"
          to="/season-one"
        >
          <ChevronLeftIcon />
        </Link>

        <NavLink to="/">
          <SecondLogoIcon className="text-[60px]" />
        </NavLink>

        <Chip label="SZN1" />
      </div>
      <div className="flex items-center gap-2.5">
        <Secondary>{formatShortAddress(address)} </Secondary>
        <ProfileIcon />
      </div>
    </div>
  );
});
