import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgLongTerm = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle opacity={0.2} cx={8.708} cy={8.708} r={8.708} fill="#9F44FF" />
    <path
      d="M1 8.708c-.552 0-1.006.449-.943.997A8.707 8.707 0 1 0 9.705.057c-.548-.063-.997.39-.997.943v6.708a1 1 0 0 1-1 1H1Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgLongTerm;
