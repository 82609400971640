/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { GmxActionFragmentDoc } from './gmx-action-type.fragment.generated';
import { KwentaActionSubmitCloseOrderFragmentDoc } from './kwenta-action-submit-close-order-type.fragment.generated';
import { KwentaActionSubmitOrderFragmentDoc } from './kwenta-action-submit-order-type.fragment.generated';
import { ZeroXActionFragmentDoc } from './zerox-action-type.fragment.generated';

export type VaultAction_GmxActionType_Fragment = {
  __typename: 'GmxActionType';
  id: string;
  action: Types.ExecutorAction;
  inputTokenAmount: string;
  outputTokenAmount: string;
  transaction: string;
  chainId: number;
  chain: string;
  memo?: string | null;
  unitPrice: string;
  createdAt: string;
  inputToken: {
    __typename?: 'AssetType';
    id: string;
    name: string;
    address: string;
    symbol: string;
    decimals?: number | null;
    price?: BigNumber | null;
    chain: string;
    chainId: number;
    priceChangeAmount?: BigNumber | null;
    priceChangePercentage?: BigNumber | null;
    marketCap?: string | null;
    totalSupply?: string | null;
    type: Types.AssetTypeEnum;
    deprecated: boolean;
    hardDeprecated: boolean;
    createdAt: string;
  };
  outputToken: {
    __typename?: 'AssetType';
    id: string;
    name: string;
    address: string;
    symbol: string;
    decimals?: number | null;
    price?: BigNumber | null;
    chain: string;
    chainId: number;
    priceChangeAmount?: BigNumber | null;
    priceChangePercentage?: BigNumber | null;
    marketCap?: string | null;
    totalSupply?: string | null;
    type: Types.AssetTypeEnum;
    deprecated: boolean;
    hardDeprecated: boolean;
    createdAt: string;
  };
};

export type VaultAction_KwentaActionDepositType_Fragment = { __typename?: 'KwentaActionDepositType' };

export type VaultAction_KwentaActionSubmitCloseOrderType_Fragment = {
  __typename: 'KwentaActionSubmitCloseOrderType';
  id: string;
  action: Types.ExecutorAction;
  wrapper: string;
  desiredFillPrice: string;
  memo?: string | null;
  chain: string;
  transaction: string;
  createdAt: string;
  perpMarket: {
    __typename?: 'AssetType';
    id: string;
    name: string;
    address: string;
    symbol: string;
    decimals?: number | null;
    price?: BigNumber | null;
    chain: string;
    chainId: number;
    priceChangeAmount?: BigNumber | null;
    priceChangePercentage?: BigNumber | null;
    marketCap?: string | null;
    totalSupply?: string | null;
    type: Types.AssetTypeEnum;
    deprecated: boolean;
    hardDeprecated: boolean;
    createdAt: string;
  };
};

export type VaultAction_KwentaActionSubmitOrderType_Fragment = {
  __typename: 'KwentaActionSubmitOrderType';
  id: string;
  action: Types.ExecutorAction;
  wrapper: string;
  sizeDelta: string;
  desiredFillPrice: string;
  memo?: string | null;
  chain: string;
  transaction: string;
  createdAt: string;
  perpMarket: {
    __typename?: 'AssetType';
    id: string;
    name: string;
    address: string;
    symbol: string;
    decimals?: number | null;
    price?: BigNumber | null;
    chain: string;
    chainId: number;
    priceChangeAmount?: BigNumber | null;
    priceChangePercentage?: BigNumber | null;
    marketCap?: string | null;
    totalSupply?: string | null;
    type: Types.AssetTypeEnum;
    deprecated: boolean;
    hardDeprecated: boolean;
    createdAt: string;
  };
};

export type VaultAction_KwentaActionWithdrawType_Fragment = { __typename?: 'KwentaActionWithdrawType' };

export type VaultAction_ZeroXActionType_Fragment = {
  __typename: 'ZeroXActionType';
  id: string;
  action: Types.ExecutorAction;
  inputTokenAmount: string;
  outputTokenAmount: string;
  transaction: string;
  chainId: number;
  chain: string;
  memo?: string | null;
  unitPrice: string;
  createdAt: string;
  inputToken: {
    __typename?: 'AssetType';
    id: string;
    name: string;
    address: string;
    symbol: string;
    decimals?: number | null;
    price?: BigNumber | null;
    chain: string;
    chainId: number;
    priceChangeAmount?: BigNumber | null;
    priceChangePercentage?: BigNumber | null;
    marketCap?: string | null;
    totalSupply?: string | null;
    type: Types.AssetTypeEnum;
    deprecated: boolean;
    hardDeprecated: boolean;
    createdAt: string;
  };
  outputToken: {
    __typename?: 'AssetType';
    id: string;
    name: string;
    address: string;
    symbol: string;
    decimals?: number | null;
    price?: BigNumber | null;
    chain: string;
    chainId: number;
    priceChangeAmount?: BigNumber | null;
    priceChangePercentage?: BigNumber | null;
    marketCap?: string | null;
    totalSupply?: string | null;
    type: Types.AssetTypeEnum;
    deprecated: boolean;
    hardDeprecated: boolean;
    createdAt: string;
  };
};

export type VaultActionFragment =
  | VaultAction_GmxActionType_Fragment
  | VaultAction_KwentaActionDepositType_Fragment
  | VaultAction_KwentaActionSubmitCloseOrderType_Fragment
  | VaultAction_KwentaActionSubmitOrderType_Fragment
  | VaultAction_KwentaActionWithdrawType_Fragment
  | VaultAction_ZeroXActionType_Fragment;

export const VaultActionFragmentDoc = gql`
  fragment VaultAction on VaultActionUnion {
    ... on ZeroXActionType {
      ...ZeroXAction
    }
    ... on GmxActionType {
      ...GmxAction
    }
    ... on KwentaActionSubmitCloseOrderType {
      ...KwentaActionSubmitCloseOrder
    }
    ... on KwentaActionSubmitOrderType {
      ...KwentaActionSubmitOrder
    }
  }
  ${ZeroXActionFragmentDoc}
  ${GmxActionFragmentDoc}
  ${KwentaActionSubmitCloseOrderFragmentDoc}
  ${KwentaActionSubmitOrderFragmentDoc}
`;
