import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgPyramid = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 0a1.25 1.25 0 1 0 0 2.5h3.5a1.25 1.25 0 1 0 0-2.5h-3.5ZM2 6.25C2 5.56 2.56 5 3.25 5h7.5a1.25 1.25 0 1 1 0 2.5h-7.5C2.56 7.5 2 6.94 2 6.25Zm-2 5C0 10.56.56 10 1.25 10h11.5a1.25 1.25 0 1 1 0 2.5H1.25C.56 12.5 0 11.94 0 11.25Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgPyramid;
