import { useService } from '@redtea/react-inversify';
import copyTextToClipboard from 'copy-text-to-clipboard';
import { observer } from 'mobx-react-lite';
import { Link, NavLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { AssetsStore } from 'core/asset/assets.store';
import { ChainService } from 'core/chain/chain.service';
import { NetworkProvider } from 'core/network/network.provider';
import { NetworkStore } from 'core/network/network.store';
import { Privy } from 'core/network/privy';
import { BalanceService } from 'core/wallet/balance.service';
import { WalletStore } from 'core/wallet/wallet.store';

import { Button, ButtonVariant } from 'components/button';
import { Divider } from 'components/divider/divider';
import { FormatMoney } from 'components/format-money';
import {
  ArrowUpRightIcon,
  ChevronDownIcon,
  CopyIcon,
  ExitIcon,
  GasIcon,
  ProfileIcon,
  WalletIcon,
} from 'components/icons';
import { Menu, MenuItem } from 'components/menu';
import { useMenu } from 'components/menu/use-menu.hook';
import { IconSize } from 'components/svg-icon';
import { Secondary } from 'components/typography';

import { formatShortAddress } from 'utils/web3';

export const UserMenu = observer(() => {
  const { address } = useService(WalletStore);
  const { nativeAssetsBalance, totalWalletBalance } = useService(BalanceService);
  const { nativeAsset } = useService(AssetsStore);
  const { disconnect } = useService(NetworkProvider);
  const { chain } = useService(ChainService);
  const { connection } = useService(NetworkStore);
  const { handleClick, state } = useMenu();

  const isValioWallet = connection?.method === Privy.ConnectionMethod.Wallet;

  if (!address) {
    return null;
  }

  return (
    <>
      <NavLink
        to="/profile"
        className={twMerge('app-bar-menu-item flex items-center h-[20px] mx-[2px] my-[0]')}
        onClick={(e) => {
          e.preventDefault();
          handleClick(e);
        }}
        data-testid="connected-account"
      >
        <div className="flex flex-col">
          <div className="text-current"> My account</div>

          <div className="flex items-center gap-1.5">
            <div className="flex items-center gap-1.5">
              <WalletIcon className="text-secondary" />
              <Secondary>
                <FormatMoney value={totalWalletBalance} dollarSign unstyled decimalPlaces={2} />
              </Secondary>
            </div>
            {isValioWallet && (
              <div className="flex items-center gap-1.5">
                <GasIcon className="text-secondary" />
                <Secondary>
                  <FormatMoney value={nativeAsset.toUSD(nativeAssetsBalance)} dollarSign unstyled decimalPlaces={2} />
                </Secondary>
              </div>
            )}
          </div>
        </div>
        <ChevronDownIcon
          className={twMerge('ml-[7px] transition-transform duration-200 text-current', state.isOpen && 'rotate-180')}
          size={IconSize.Smaller}
        />
      </NavLink>

      <Menu {...state} popoverProps={{ placement: 'bottom', indent: 24 }} className="w-[205px]">
        <div className="flex justify-between px-[14px] py-[4px] items-center">
          <div className="flex items-center [&>svg]:ml-1">
            <a href={chain.blockExplorerUrl + '/address/' + address} target="_blank" rel="noreferrer">
              <Button variant={ButtonVariant.Text} endIcon={<ArrowUpRightIcon size={IconSize.Smaller} />}>
                {formatShortAddress(address)}
              </Button>
            </a>
          </div>
          <div className="flex items-center [&>svg]:ml-1">
            <Button
              variant={ButtonVariant.Text}
              endIcon={<CopyIcon size={IconSize.Smaller} />}
              onClick={() => copyTextToClipboard(address)}
            >
              Copy
            </Button>
          </div>
        </div>
        <Divider className="my-[7px] mx-0" />
        <Link data-testid="user-menu__manage-profile" to="/profile">
          <MenuItem startIcon={<ProfileIcon />} text="Manage Profile" />
        </Link>
        <MenuItem data-testid="user-menu__disconnect" startIcon={<ExitIcon />} text="Disconnect" onClick={disconnect} />
      </Menu>
    </>
  );
});
