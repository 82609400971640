import { twMerge } from 'tailwind-merge';

import { Chip, ChipProps } from 'components/chip';

import { Percentage } from 'utils/percentage';

enum Direction {
  Up,
  Down,
  Default,
}

export type ChangeChipProps = Omit<ChipProps, 'label'> & {
  value?: number;
  showSymbol?: boolean;
  showZero?: boolean;
  outlined?: boolean;
  primary?: boolean;
  decimals?: number;
};

export const ChangeChip = (props: ChangeChipProps) => {
  const { value, decimals, showSymbol, showZero, outlined, className, primary, ...rest } = props;

  if (value === undefined) return null;

  let chipText = value !== 0 ? Percentage.format(value, decimals) : showZero ? '0%' : '--%';

  const change = value === 0 ? Direction.Default : value > 0 ? Direction.Up : Direction.Down;

  if (showSymbol && change === Direction.Up) {
    chipText = '+' + chipText;
  }

  return (
    <Chip
      className={twMerge(
        'h-7 border border-outline',
        '[&_svg]:text-xs [&_svg]:text-current',
        change === Direction.Default && primary ? 'text-primary' : 'text-secondary',
        change === Direction.Up && 'text-positive bg-positive/24',
        change === Direction.Down && 'text-negative bg-negative/24',
        outlined && 'bg-transparent border-0 border-none rounded-none p-0 h-auto',
        className
      )}
      {...rest}
      label={chipText}
    />
  );
};
