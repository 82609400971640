import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgLink = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 0 6.307.95l-4.614 2.6L0 4.5v9l1.693.95 4.656 2.6 1.693.95 1.693-.95 4.572-2.6L16 13.5v-9l-1.693-.95L9.693.95 8 0ZM3.386 11.6V6.4L8 3.797 12.614 6.4v5.202L8 14.2l-4.614-2.6Z"
      fill="#2A5ADA"
    />
  </svg>
));
export default SvgLink;
