import React, { ComponentPropsWithoutRef, ComponentType, createElement } from 'react';
import { To, useHref, useLinkClickHandler, useMatch, useResolvedPath } from 'react-router-dom';

export type NavLinkProps<AsC extends React.ComponentType> = ComponentPropsWithoutRef<AsC> & {
  component: AsC;
  to: To;
  end?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const NavLink = <AsC extends ComponentType<any>>({
  component,
  to,
  end = false,
  onClick,
  ...rest
}: ComponentPropsWithoutRef<AsC> & NavLinkProps<AsC>) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end });
  const linkClickHandler = useLinkClickHandler(to);
  const href = useHref(to);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    onClick && onClick(e);
    linkClickHandler(e);
  };

  const isActive = Boolean(match);

  return createElement(component, { isActive, onClick: handleClick, href, ...rest });
};
