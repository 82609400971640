import { BigNumber } from 'bignumber.js';

export namespace Vaults {
  export const StatsPollingInterval = 30000;

  export const HoldingPollingInterval = 5000;

  export type Stats = {
    totalShares: BigNumber;
    aum: BigNumber;
  };
}
