import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgPremiaOptions = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 29 28" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.887 6.323h13.226a.92.92 0 0 1 .745 1.46l-6.295 8.65a.92.92 0 0 0 .744 1.461h5.548a.92.92 0 0 0 .744-.38l6.225-8.553a.92.92 0 0 0 0-1.082l-5.458-7.5A.92.92 0 0 0 22.622 0H6.378a.92.92 0 0 0-.744.379l-5.458 7.5a.92.92 0 0 0 0 1.082l13.58 18.66a.921.921 0 0 0 1.488 0l2.774-3.81a.92.92 0 0 0 0-1.083L7.143 7.783a.92.92 0 0 1 .744-1.46Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgPremiaOptions;
