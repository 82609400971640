import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgKwentaPerpetuals = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 4.178 6.912 0 14 4.178v4.686L6.912 13 0 8.864V4.178Zm10.906 2.355L6.912 4.18 3.017 6.533l3.895 2.33 3.994-2.33Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgKwentaPerpetuals;
