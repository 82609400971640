export function getPortalElement(id: string): Element {
  const node = document.body.querySelector(`#${id}`);

  if (node) return node;

  const el = document.createElement('div');
  el.id = id;

  document.body.appendChild(el);

  return el;
}
