import { injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { Observable, filter, shareReplay } from 'rxjs';

import { NetworkService } from 'core/network/network.service';

import { Disposable } from 'utils/disposable';
import { isSomething } from 'utils/is-something';

import { type OneOfChain, chains } from './chain';
import { ChainID } from './chain.info';

@injectable()
export class ChainService extends Disposable {
  @observable
  private currentChainId: ChainID = ChainID.Arbitrum;

  readonly chain$: Observable<ChainID>;

  constructor(private readonly networkService: NetworkService) {
    super();
    makeObservable(this);

    this.chain$ = this.networkService.chain$.pipe(filter(isSomething), shareReplay(1));

    this.autoDispose(this.chain$.subscribe(this.updateChain));
  }

  @computed
  get chain(): OneOfChain {
    return chains[this.currentChainId];
  }

  @action
  private updateChain = (chainId: ChainID): void => {
    this.currentChainId = chainId;
  };
}
