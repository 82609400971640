import { ComponentPropsWithoutRef, PropsWithChildren, ReactNode } from 'react';

import { Callback } from 'types';

export enum TableSortDirection {
  ASC,
  DESC,
}

export type TableColumn<T> = ComponentPropsWithoutRef<'td'> & {
  id?: keyof T;
  label: ReactNode;
  comparator?: (a: T, b: T) => number;
  renderer?: (data: T) => ReactNode;
  sortable?: boolean;
  autoHide?: boolean;
  minWidth?: number;
};

export type TableProps<T> = PropsWithChildren<{
  items: T[];
  order?: TableSortDirection;
  orderBy?: keyof T;
  columns: TableColumn<T>[];
  onRowClick?: Callback<T>;
  onSortHandler?: (column: (a: T, b: T) => number, id: keyof T) => void;
  rowKey?: keyof T;
  emptyText?: string;
  loading?: boolean;
  loaded?: boolean;
  className?: string;
}>;
