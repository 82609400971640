/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../../schema';

export type RewardFragment = {
  __typename?: 'RewardType';
  rewardType?: Types.RewardEnumType | null;
  createdAt: string;
  revealedAt?: string | null;
};

export const RewardFragmentDoc = gql`
  fragment Reward on RewardType {
    rewardType
    createdAt
    revealedAt
  }
`;
