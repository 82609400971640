import { injectable } from 'inversify';

@injectable()
export class ContractCache {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private cache = new Map<string, any>();

  getData = async <T>(fn: () => Promise<T>, key: string): Promise<T> => {
    const data = this.cache.get(key) || (await fn());

    if (!this.cache.has(key)) {
      this.cache.set(key, data);
    }

    return data;
  };
}
