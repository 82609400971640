import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgLowRisk = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7.992C0 3.584 3.584 0 7.992 0 12.4 0 15.983 3.584 16 7.992c0 1.396-.37 2.742-1.043 3.936a.679.679 0 0 1-.589.337H1.632a.664.664 0 0 1-.589-.337A7.83 7.83 0 0 1 0 7.992Zm1.363.016c0 1.01.235 2.02.673 2.911h11.911a6.727 6.727 0 0 0 .673-2.927c0-3.651-2.977-6.612-6.628-6.612-3.651 0-6.63 2.978-6.63 6.628Zm6.696-4.324a.723.723 0 1 0 0-1.446.723.723 0 0 0 0 1.446Zm4.061 1.253a.724.724 0 1 0-1.023-1.023.724.724 0 0 0 1.023 1.023Zm1.693 3.038a.723.723 0 1 1-1.447 0 .723.723 0 0 1 1.447 0Zm-10.768.74a.723.723 0 1 0 0-1.447.723.723 0 0 0 0 1.447Zm5.889-2.12L4.98 4.139c-.437-.27-.96.235-.69.673l2.372 4.004c.286.488.825.79 1.397.807a1.623 1.623 0 0 0 1.649-1.615c0-.572-.303-1.11-.774-1.413Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgLowRisk;
