import { ChainID } from 'core/chain';
import { Valio } from 'core/valio/valio';

export namespace LayerZero {
  export enum InternalChainId {
    Ethereum = 101,
    Arbitrum = 110,
    Optimism = 111,
  }

  export const getInternalChainId = (chainId: Valio.SupportedChains): InternalChainId => {
    switch (chainId) {
      case ChainID.Ethereum:
        return InternalChainId.Ethereum;
      case ChainID.Optimism:
        return InternalChainId.Optimism;
      case ChainID.Arbitrum:
        return InternalChainId.Arbitrum;

      default:
        return chainId;
    }
  };

  export const getChainId = (chainId: InternalChainId): Valio.SupportedChains => {
    switch (chainId) {
      case InternalChainId.Ethereum:
        return ChainID.Ethereum;
      case InternalChainId.Optimism:
        return ChainID.Optimism;
      case InternalChainId.Arbitrum:
        return ChainID.Arbitrum;

      default:
        return chainId;
    }
  };

  export const getExplorerLink = (txHash: string): string => `https://layerzeroscan.com/tx/${txHash}`;
}
