import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgChevronUp = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 12 7" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m7.817.856 3.934 4.48c.16.182.249.43.249.687 0 .258-.09.506-.25.688a.86.86 0 0 1-.278.214.768.768 0 0 1-.66 0 .86.86 0 0 1-.278-.214L6.608 2.222a.86.86 0 0 0-.278-.213.768.768 0 0 0-.66 0 .86.86 0 0 0-.279.213l-3.925 4.49A.809.809 0 0 1 .861 7a.807.807 0 0 1-.608-.284A1.048 1.048 0 0 1 0 6.026c0-.258.089-.507.249-.69L4.183.855C4.665.308 5.319 0 6 0s1.335.308 1.817.856Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgChevronUp;
