import { TransactionReceipt } from '@ethersproject/providers';
import { MessageStatus, createClient } from '@layerzerolabs/scan-client';
import { injectable } from 'inversify';
import { Subject, delay, filter, lastValueFrom, map, switchMap, takeWhile, timer } from 'rxjs';

import { Asset } from 'core/asset/asset';
import { Snackbar } from 'core/snackbars/snackbar';
import { SnackbarStore } from 'core/snackbars/snackbar.store';

import { Disposable } from 'utils/disposable';

import { TransactionsTypes } from './transactions';

@injectable()
export class TransactionSnackbarStore extends Disposable {
  lzClient = createClient('mainnet');

  transactions$ = new Subject<Snackbar.Transaction>();

  constructor(private readonly snackbarStore: SnackbarStore) {
    super();

    const pendingTransaction$ = this.transactions$.pipe(filter(({ status }) => status === TransactionsTypes.Pending));

    const finishedTransaction$ = this.transactions$.pipe(
      filter(({ status }) => [TransactionsTypes.Successful, TransactionsTypes.Failed].includes(status))
    );

    const closeFinishedTransaction$ = finishedTransaction$.pipe(
      delay(5000),
      map((item) => item.id)
    );

    this.autoDispose(closeFinishedTransaction$.subscribe(this.snackbarStore.removeSnackbar));
    this.autoDispose(pendingTransaction$.subscribe(this.snackbarStore.addSnackbar));
    this.autoDispose(finishedTransaction$.subscribe(this.snackbarStore.updateSnackbar));
  }

  trackTx = async (txId: string, receipt: Promise<TransactionReceipt>, from?: Asset, to?: Asset): Promise<void> => {
    const transaction: Snackbar.Transaction = {
      id: txId,
      txId,
      status: TransactionsTypes.Pending,
      type: Snackbar.Type.Transaction,
      from,
      to,
    };

    try {
      this.transactions$.next(transaction);

      await receipt;

      this.transactions$.next({ ...transaction, status: TransactionsTypes.Successful });
    } catch (error) {
      this.transactions$.next({ ...transaction, status: TransactionsTypes.Failed });
    }
  };

  trackLZTx = async (txHash: string): Promise<void> => {
    const transaction: Snackbar.Transaction = {
      id: txHash,
      txId: txHash,
      status: TransactionsTypes.Pending,
      type: Snackbar.Type.Transaction,
      from: undefined,
      to: undefined,
    };

    try {
      this.transactions$.next(transaction);

      const delivered$ = timer(0, 1000).pipe(
        switchMap(() => this.lzClient.getMessagesBySrcTxHash(txHash)),
        takeWhile(({ messages }) => messages.every((message) => message.status !== MessageStatus.DELIVERED), true)
      );

      await lastValueFrom(delivered$);

      this.transactions$.next({ ...transaction, status: TransactionsTypes.Successful });
    } catch (error) {
      this.transactions$.next({ ...transaction, status: TransactionsTypes.Failed });
    }
  };
}
