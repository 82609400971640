import { useNProgress } from '@tanem/react-nprogress';

type ProgressProps = {
  isLoading: boolean;
};

export const Progress = (props: ProgressProps) => {
  const { isLoading } = props;

  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: isLoading,
  });

  return (
    <div
      className="pointer-events-none"
      style={{
        opacity: isFinished ? 0 : 1,
        pointerEvents: 'none',
        transition: `opacity ${animationDuration}ms linear`,
      }}
    >
      <div
        className="fixed h-[2px] left-0 top-0 w-full z-[1031] bg-negative"
        style={{
          marginLeft: `${(-1 + progress) * 100}%`,
          transition: `margin-left ${animationDuration}ms linear`,
        }}
      >
        <div className='[box-shadow:"0_0_10px_red,_0_0_5px_red"] block h-full opacity-100 absolute right-0 rotate-3 translate-x-0 -translate-y-1 w-full' />
      </div>
    </div>
  );
};
