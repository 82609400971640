import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgBullish = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 13 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.881 6.194H8.177c-.202 0-3.35-.17-2.24-3.073.101-.488.485-1.098.444-1.854-.08-1.342-1.009-1.683-.847-.708.242.683-.162 1.366-1.231 1.366-2.32 0-.505 0-2.603-.024C.005 1.535.994.608.73.096c0 0-.484-.561-.706 1.122-.242 1.83 1.352 1.902 1.332 4.366-.02 3.927-.02 7.66-.02 11.416h1.069v-4.318h2.603V17h1.13v-4.318h2.22c1.553-.049.504 2.098-.202 4.318h1.17l1.473-4.318h.989c-.04 2.05-.02 2.976-.02 4.318h1.21c-.02-5.537.021-6.781.021-8.757 0-1.22-.303-2.049-2.119-2.049Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgBullish;
