import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgStack = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 15 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.497 8.35c.312.153.654.231 1.001.23A2.27 2.27 0 0 0 8.5 8.35l5.43-2.73a1.484 1.484 0 0 0 0-2.656L8.5.234a2.263 2.263 0 0 0-2.003 0l-5.431 2.73a1.484 1.484 0 0 0 0 2.656l5.43 2.73Zm0 3.71c.312.152.654.231 1.001.23.347.001.69-.077 1.002-.23l6.091-3.064a.743.743 0 0 0 .327-.994.742.742 0 0 0-.994-.327l-6.092 3.057a.742.742 0 0 1-.667 0L1.073 7.675a.742.742 0 0 0-.994.327.742.742 0 0 0 .326.994l6.092 3.064ZM7.498 16c-.347 0-.69-.078-1.001-.23L.405 12.706a.742.742 0 0 1-.326-.994.742.742 0 0 1 .994-.327l6.091 3.057c.21.106.458.106.668 0l6.092-3.057a.742.742 0 0 1 .994.327.742.742 0 0 1-.327.994L8.5 15.77c-.312.152-.655.23-1.002.23Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgStack;
