import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgTractor = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.465 3.59v1.24h-1.97V3.59c0-.27.216-.487.486-.487h.243V1.89a.512.512 0 0 0-.513-.513.29.29 0 0 1-.27-.27.27.27 0 0 1 .27-.27c.594 0 1.052.487 1.052 1.053v1.214h.243c.243 0 .459.216.459.486Z"
      fill="currentColor"
    />
    <path
      d="M16.73 9.444h-.54V7.502c0-1.16-.944-2.105-2.104-2.105H9.12l-.297-3.454a.986.986 0 0 0 .81-.972A.967.967 0 0 0 8.662 0h-7.15A.967.967 0 0 0 .54.972c0 .458.324.863.756.944L.972 7.717a3.94 3.94 0 0 1 2.455-.863c2.186 0 3.994 1.78 3.994 3.967 0 .188 0 .404-.054.593h2.105a3.191 3.191 0 0 1 3.184-2.995c1.7 0 3.076 1.322 3.184 2.995h.89a.29.29 0 0 0 .27-.27V9.687c0-.135-.108-.243-.27-.243ZM2.645 5.64l.216-3.994h4.37l.136 1.376.243 2.618H2.645Z"
      fill="currentColor"
    />
    <path
      d="M3.454 7.42C1.565 7.42 0 8.96 0 10.849a3.46 3.46 0 0 0 3.454 3.454 3.46 3.46 0 0 0 3.454-3.454C6.88 8.958 5.343 7.42 3.454 7.42ZM12.655 8.986a2.649 2.649 0 0 0-2.644 2.644 2.649 2.649 0 0 0 2.645 2.645A2.649 2.649 0 0 0 15.3 11.63a2.632 2.632 0 0 0-2.645-2.644Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgTractor;
