import { usePrivy, useWallets } from '@privy-io/react-auth';
import { useService } from '@redtea/react-inversify';
import { useEffect } from 'react';

import { PrivyClient } from 'core/network/privy.client';

export const useInjectPrivyWallet = () => {
  const { nextPrivy, nextWallets } = useService(PrivyClient);

  const { wallets } = useWallets();
  const privy = usePrivy();

  useEffect(() => {
    nextPrivy(privy);
  }, [nextPrivy, privy]);

  useEffect(() => {
    nextWallets(wallets);
  }, [nextWallets, wallets]);
};
