import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgLock = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.25 6h-.875V4.286C11.375 1.92 9.415 0 7 0S2.625 1.92 2.625 4.286V6H1.75C.783 6 0 6.767 0 7.714v8.572C0 17.233.783 18 1.75 18h10.5c.967 0 1.75-.767 1.75-1.714V7.714C14 6.767 13.217 6 12.25 6ZM7 13.714c-.967 0-1.75-.767-1.75-1.714s.783-1.714 1.75-1.714 1.75.767 1.75 1.714-.783 1.714-1.75 1.714ZM9.712 6H4.288V4.286C4.288 2.82 5.504 1.629 7 1.629c1.496 0 2.712 1.191 2.712 2.657V6Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgLock;
