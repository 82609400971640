import { useTheme } from 'styles/themes/use-theme.hook';

import { H1, Secondary } from 'components/typography';

import { Description, Image, Slide } from './slide';

export const SlideOne = () => {
  const { appName, tutorial } = useTheme();

  return (
    <Slide className="flex flex-col items-center justify-center">
      <Image src={tutorial?.slideOneSrc} />
      <Description className="flex flex-col items-center justify-center gap-3.5">
        <H1>{appName} wallet created!</H1>
        <Secondary className="text-center break-words w-[324px]">{tutorial?.slideOneText}</Secondary>
      </Description>
    </Slide>
  );
};
