import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgIndexChart = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 13 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.2 1.133a1 1 0 0 1 1-1h.6a1 1 0 0 1 1 1v12.734a1 1 0 0 1-1 1h-.6a1 1 0 0 1-1-1V1.133ZM0 8.933a1 1 0 0 1 1-1h.6a1 1 0 0 1 1 1v4.934a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8.933ZM11.4 3.6a1 1 0 0 0-1 1v9.267a1 1 0 0 0 1 1h.6a1 1 0 0 0 1-1V4.6a1 1 0 0 0-1-1h-.6Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgIndexChart;
