/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';

export type SimulateTransactionMutationVariables = Types.Exact<{
  input: Types.SimulateTransactionInput;
}>;

export type SimulateTransactionMutation = { __typename?: 'Mutation'; simulateTransaction: string };

export const SimulateTransactionDocument = gql`
  mutation SimulateTransaction($input: SimulateTransactionInput!) {
    simulateTransaction(input: $input)
  }
`;

export function SimulateTransaction<
  R extends SimulateTransactionMutation,
  V extends SimulateTransactionMutationVariables
>(request: Request<R, V>, variables: V, headers?: HeadersInit): Promise<R> {
  return request(SimulateTransactionDocument, variables, headers);
}
