import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgTrezor = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 27 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M23.05 11.98H27v20.764h-.027L13.5 39 .027 32.744H0V11.98h3.951V9.066C3.951 4.067 8.236 0 13.502 0c5.263 0 9.548 4.067 9.548 9.066v2.915Zm-1.483 17.31V16.854H5.434V29.29l8.067 3.737 8.066-3.737ZM8.85 9.067v2.915h9.302V9.066c0-2.314-2.088-4.196-4.65-4.196-2.565 0-4.652 1.882-4.652 4.196Z"
      fill="#ACACAC"
    />
  </svg>
));
export default SvgTrezor;
