import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgSmile = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8Zm2.58 4.903a1.031 1.031 0 1 1 0 2.065 1.031 1.031 0 1 1 0-2.065Zm-5.16 0a1.031 1.031 0 1 1 0 2.065 1.031 1.031 0 1 1 0-2.065Zm2.838 8.774h-.516a4.65 4.65 0 0 1-4.61-4.064.518.518 0 0 1 .513-.58h8.71c.31 0 .551.27.513.58a4.65 4.65 0 0 1-4.61 4.064Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgSmile;
