import { injectable } from 'inversify';

import { ConfigService } from 'core/config/config.service';

import { BaseGQLClientService } from './base-gql.client';

@injectable()
export class GraphQLClientService extends BaseGQLClientService {
  constructor(configService: ConfigService) {
    super({
      url: configService.graphqlUrl,
    });
  }
}
