import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgNeonWallet = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#neon-wallet_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.476 17.098v.03c0 .69.657 1.134 1.175 1.59.219.192.349.476.349.88v3.475c0 .403-.13.687-.349.88-.518.456-1.175.9-1.175 1.59v.09c0 1.281-1.037 2.379-2.317 2.379H13.316c-1.28 0-2.317-1.098-2.317-2.378V13.439C11 12.098 12.159 11 13.5 11h11.524a2.316 2.316 0 0 1 2.317 2.317v.387c0 .619.478 1.112 1.013 1.423.68.396 1.122 1.08 1.122 1.97ZM13.5 12.22a1.25 1.25 0 0 0-1.28 1.28c0 .671.549 1.28 1.28 1.28h11.372c.69 0 1.25-.559 1.25-1.25v-.213c0-.61-.488-1.097-1.159-1.097H13.5Zm12.56 10.914h2.531c.69 0 1.25-.56 1.25-1.25v-1.097c0-.69-.56-1.25-1.25-1.25h-2.53c-2.317 0-2.317 3.597 0 3.597Zm1.342-2.805c.549 0 .975.427.975.976 0 .61-.426 1.036-.975 1.036a1.028 1.028 0 0 1-1.037-1.037c0-.548.488-.975 1.037-.975Z"
        fill="#F7FAFF"
      />
    </g>
    <defs>
      <filter
        id="neon-wallet_svg__a"
        x={0}
        y={0}
        width={42}
        height={39.012}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={5.5} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3083_18843" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={3} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_3083_18843" result="effect2_dropShadow_3083_18843" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_3083_18843" result="effect3_dropShadow_3083_18843" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_3083_18843" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgNeonWallet;
