/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';

export type GetVaultDepositorsQueryVariables = Types.Exact<{
  uid: Types.Scalars['String']['input'];
  dateRange: Types.ChartDatasetRange;
}>;

export type GetVaultDepositorsQuery = {
  __typename?: 'Query';
  depositorsDataset: Array<{ __typename?: 'DepositorsDataset'; key: string; value?: BigNumber | null }>;
};

export const GetVaultDepositorsDocument = gql`
  query GetVaultDepositors($uid: String!, $dateRange: ChartDatasetRange!) {
    depositorsDataset(uid: $uid, dateRange: $dateRange) {
      key
      value
    }
  }
`;

export function GetVaultDepositors<R extends GetVaultDepositorsQuery, V extends GetVaultDepositorsQueryVariables>(
  request: Request<R, V>,
  variables: V,
  headers?: HeadersInit
): Promise<R> {
  return request(GetVaultDepositorsDocument, variables, headers);
}
