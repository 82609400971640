import { Values } from '@mobx-form-state/react';
import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import { Observable, filter, map } from 'rxjs';

import { Addresses } from 'core/address';
import { ApiService } from 'core/api/api.service';
import { AccountUpdated } from 'core/api/gql/account/account-updated.gql.generated';
import { AccountFragment } from 'core/api/gql/account/account.fragment.generated';
import { account } from 'core/api/gql/account/account.gql.generated';
import { UpdateAccount } from 'core/api/gql/account/update-account.gql.generated';
import { UPLOAD_IMAGE } from 'core/api/gql/account/upload-image.gql';
import { EntityType } from 'core/api/schema';
import { WalletStore } from 'core/wallet/wallet.store';

import { ProfileEditModel } from 'pages/profile/profile-edit-modal/profile-edit.model';

import { Loadable } from 'utils/loadable';

@injectable()
export class AccountStore extends Loadable {
  @observable
  account: AccountFragment | null = null;

  readonly accountUpdated$: Observable<AccountFragment>;

  constructor(private readonly walletStore: WalletStore, private readonly apiService: ApiService) {
    super();
    makeObservable(this);

    this.accountUpdated$ = this.apiService
      .subscription(AccountUpdated)
      .pipe(map((result) => result.accountUpdated))
      .pipe(filter((account) => Addresses.areEqual(account.address, this.walletStore.address)));

    this.autoDispose(this.accountUpdated$.subscribe(this.updateAccount));
  }

  @action
  updateAvatar = async (file?: File): Promise<void> => {
    if (!this.walletStore.address || !file) return;

    const data = {
      input: {
        entityId: this.walletStore.address,
        entityType: EntityType.Account,
      },
      file,
    };

    await this.apiService.upload(UPLOAD_IMAGE, data);

    this.refetchAccount();
  };

  @action
  private updateAccount = (account: AccountFragment | null): void => {
    this.account = account;
  };

  updateProfile = async (input: Values.FromModel<ProfileEditModel>): Promise<void> => {
    if (!this.walletStore.address) return;

    const data = {
      input: {
        name: input.name,
        bio: input.bio,
      },
    };

    const result = await this.apiService.query(UpdateAccount, data);

    this.updateAccount(result.updateAccount);
  };

  protected load = async (): Promise<void> => {
    await this.walletStore.isConnected();

    if (!this.walletStore.address) return;

    await this.loadAccount(this.walletStore.address);
  };

  private loadAccount = async (address: string): Promise<void> => {
    const result = await this.apiService.query(account, { address });
    this.updateAccount(result.account);
  };

  private refetchAccount = (): void => {
    if (this.walletStore.address) {
      this.loadAccount(this.walletStore.address);
    }
  };
}
