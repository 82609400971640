import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgX = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.738 5.505 12.578 0h-1.146L7.227 4.78 3.871 0H0l5.076 7.228L0 13h1.147l4.437-5.048L9.129 13H13M1.56.846h1.762l8.109 11.35H9.669"
      fill="currentColor"
    />
  </svg>
));
export default SvgX;
