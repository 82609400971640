import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgHighRiskVault = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect y={10} width={14} height={3} rx={1} fill="currentColor" />
    <rect y={5} width={14} height={3} rx={1} fill="currentColor" />
    <rect width={14} height={3} rx={1} fill="currentColor" />
  </svg>
));
export default SvgHighRiskVault;
