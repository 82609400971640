import { Children, PropsWithChildren, cloneElement, isValidElement, memo } from 'react';

import { TextFieldBase } from 'components/text-field-base';

import { TabProps } from '.';

export type TabValue = string | number | boolean;

export type TabsProps<T extends TabValue> = PropsWithChildren<{
  onChange?: (value?: T) => void;
  value?: T;
  fullWidth?: boolean;
}>;

const CustomTabs = <T extends TabValue>({ children, value, onChange }: TabsProps<T>) => {
  return (
    <div className="flex justify-center flex-nowrap relative">
      {Children.map(children, (child, index) => {
        if (isValidElement<TabProps<T>>(child)) {
          const tabValue = typeof child.props.value === 'undefined' ? index : child.props.value;

          return cloneElement<TabProps<T>>(child, {
            ...child.props,
            value: tabValue as T,
            onClick: onChange,
            active: tabValue === value,
          });
        }

        return child;
      })}
    </div>
  );
};

export const Tabs = memo(CustomTabs) as typeof CustomTabs;

export const TabsControl: typeof CustomTabs = (props) => {
  const { fullWidth } = props;

  return (
    <TextFieldBase className="inline-block px-2.5 py-0" fullWidth={fullWidth} hoverEffect={false}>
      <Tabs {...props} />
    </TextFieldBase>
  );
};
