import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { VaultLoader } from 'components/loading';

import { TableProps, TableSortDirection } from '../table';
import { InfiniteTableBody } from './table-body';
import { TableLabel } from './table-label';
import { useColumns } from './use-columns.hook';
import { useInfiniteScroll } from './use-infinite-scroll.hook';

export type InfiniteTableProps<T> = TableProps<T> & {
  total?: number;
  isLoading?: boolean;
  isLoadingMore?: boolean;
  onLoadMore?: () => void;
  maxHeight: string;
};

const Table = <T,>(props: InfiniteTableProps<T>) => {
  const {
    items,
    isLoading,
    isLoadingMore = false,
    total = props.items.length,
    onLoadMore,
    rowKey,
    onSortHandler,
    orderBy,
    order,
    className,
  } = props;
  const tableInnerRef = useRef<HTMLDivElement>(null);

  const hasMore = total > items.length;

  const isScrolling = useInfiniteScroll({ hasMore, scrollContainer: tableInnerRef, isLoadingMore, onLoadMore });
  const columns = useColumns(tableInnerRef, props.columns);

  return (
    <div className={twMerge('relative overflow-hidden z-[1] rounded-xl border border-outline', className)}>
      {(isLoadingMore || isScrolling) && <div className="absolute top-0 right-0 bottom-0 left-0 bg-none z-[3]" />}
      <div className="overflow-auto" style={{ maxHeight: props.maxHeight }} ref={tableInnerRef}>
        <table className="table">
          <thead className="sticky-head">
            <tr>
              {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                columns.map(({ renderer, comparator, label, minWidth, sortable, id, ...rest }, index) => (
                  <td
                    key={index}
                    width={minWidth}
                    onClick={() => onSortHandler && id && comparator && onSortHandler(comparator, id)}
                    {...rest}
                  >
                    {sortable ? (
                      <TableLabel label={label} order={orderBy === id ? order : TableSortDirection.ASC} />
                    ) : (
                      label
                    )}
                  </td>
                ))
              }
            </tr>
          </thead>

          <InfiniteTableBody
            items={items}
            columns={columns}
            onRowClick={props.onRowClick}
            isLoading={isLoading}
            rowKey={rowKey}
          />

          {hasMore && (
            <tbody>
              <tr className="bg-transparent">
                <td colSpan={columns.length} className="border-none h-9" />
              </tr>
            </tbody>
          )}
        </table>
      </div>
      {isLoadingMore && (
        <div className="absolute top-0 right-0 bottom-0 left-0 z-[2] flex justify-center pb-4">
          <VaultLoader width={68} height={28} />
        </div>
      )}
    </div>
  );
};

export const InfiniteTable = observer(Table) as typeof Table;
