import { arbitrumChain } from './arbitrum-chain';
import { AnyChain, ChainID, ChainInfo } from './chain.info';
import { ethereumChain } from './ethereum-chain';
import { optimismChain } from './optimism-chain';

type ChainsInfo = {
  [keyof in ChainID]: ChainInfo;
};

export const chains = {
  [ChainID.Arbitrum]: arbitrumChain,
  [ChainID.Optimism]: optimismChain,
  [ChainID.Ethereum]: ethereumChain,
} as const satisfies ChainsInfo;

type ValueOf<T> = T[keyof T];

export type OneOfChain = ValueOf<typeof chains>;

export const isSupportedChain = (chainId: AnyChain): chainId is ChainID => chainId in chains;

export const caipToNumber = (caip: string): number => {
  const [, chainId] = caip.split(':');

  return Number(chainId);
};
