import { useMemo } from 'react';

export function useOffsetModifier(offset?: number | [number, number]) {
  const offsetModifier = useMemo(
    () => ({
      name: 'offset',
      options: {
        offset: () => {
          if (!offset) return [0, 0];

          if (Array.isArray(offset)) return offset;

          return [0, offset];
        },
      },
    }),
    [offset]
  );

  return offsetModifier;
}
