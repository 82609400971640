import { IReactionOptions, reaction } from 'mobx';
import { Observable } from 'rxjs';

type DidChangeValue<T> = T | (() => T);

export type DidChangeResult<T> = [T, T | undefined];

export const didChange = <T, FireImmediately extends boolean>(
  value: DidChangeValue<T>,
  options?: IReactionOptions<T, FireImmediately>
) =>
  new Observable<DidChangeResult<T>>((subscriber) => {
    const disposer = reaction(
      () => (value instanceof Function ? value() : value),
      (next, prev) => subscriber.next([next, prev]),
      options
    );

    return (): void => disposer();
  });
