import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgShortTerm = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle opacity={0.2} cx={8.708} cy={8.708} r={8.708} fill="#9F44FF" />
    <path
      d="M16.076 8.708c.74 0 1.35-.604 1.236-1.335a8.708 8.708 0 0 0-7.27-7.27C9.31-.011 8.708.6 8.708 1.34v6.028c0 .74.6 1.34 1.34 1.34h6.028Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgShortTerm;
