import { Asset } from 'core/asset/asset';
import { TransactionsTypes } from 'core/snackbars/transactions';
import { VaultUUID } from 'core/vault/vault.store';

import { Milliseconds } from 'types';

import { DepositSnackbarStatus } from './depoposit/deposit';
import { WithdrawSnackbarStatus } from './withdraw/withdraw';

export namespace Snackbar {
  export const isTransaction = (item: OneOfSnackbar): item is Transaction => item.type === Type.Transaction;

  export const isDeposit = (item: OneOfSnackbar): item is Deposit => item.type === Type.Deposit;

  export const isSuccess = (item: OneOfSnackbar): item is Success => item.type === Type.Success;

  export const isFailed = (item: OneOfSnackbar): item is Failed => item.type === Type.Failed;

  export const isWithdraw = (item: OneOfSnackbar): item is Withdraw => item.type === Type.Withdraw;

  export type ID = string | number;

  export type OneOfSnackbar = Transaction | Success | Failed | Deposit | Withdraw;

  export enum Type {
    Transaction,
    Success,
    Failed,
    Deposit,
    Withdraw,
  }

  export type Transaction = Base & {
    type: Type.Transaction;
    txId: string;
    status: TransactionsTypes;
    from?: Asset;
    to?: Asset;
  };

  export type Deposit = Base & {
    type: Type.Deposit;
    status: DepositSnackbarStatus;
    uid: VaultUUID;
    expiry: number;
    txId: string;
  };

  export type Withdraw = Base & {
    type: Type.Withdraw;
    status: WithdrawSnackbarStatus;
    uid: VaultUUID;
    expiry: number;
    txId: string;
  };

  export type Success = Base & {
    type: Type.Success;
    message: string;
  };

  export type Failed = Base & {
    type: Type.Failed;
    message: string;
    description?: string;
  };

  export type Base = {
    id: ID;
  };

  export type WithTimestamp<T> = T & {
    timestamp: number;
  };

  export const FailedCloseDelay: Milliseconds = 5000;

  export const SuccessCloseDelay: Milliseconds = 4000;

  export const getRandomId = (): ID => Math.random().toString(36).substring(2, 9);
}
