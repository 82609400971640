import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgClockCheck = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.498 15.3A6.797 6.797 0 0 1 1.7 8.5a6.8 6.8 0 0 1 11.606-4.808A6.8 6.8 0 0 1 15.297 8.5a.85.85 0 1 0 1.7 0A8.5 8.5 0 0 0 8.498 0 8.498 8.498 0 0 0 0 8.5 8.501 8.501 0 0 0 8.498 17a.85.85 0 1 0 0-1.7Z"
      fill="currentColor"
    />
    <path
      d="M8.498 3.4a.85.85 0 0 0-.85.85V8.15l-2.303 2.295a.85.85 0 0 0 0 1.207.85.85 0 0 0 1.207 0l2.55-2.55a.85.85 0 0 0 .246-.603V4.25a.85.85 0 0 0-.85-.85ZM15.543 11.296l-2.796 2.806-1.096-1.105a.853.853 0 1 0-1.207 1.207l1.7 1.7a.85.85 0 0 0 1.207 0l3.4-3.4a.854.854 0 0 0-1.208-1.207Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgClockCheck;
