import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgCaseNeon = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 84 76" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#case-neon_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.322 19.93h10.58c3.36 0 6.098 2.736 6.098 6.077v30.916C71 60.265 68.262 63 64.901 63H19.1C15.739 63 13 60.265 13 56.923V26.007c0-3.341 2.738-6.076 6.099-6.076h10.58v-2.092c0-2.66 2.177-4.839 4.853-4.839h14.936c2.676 0 4.854 2.178 4.854 4.84v2.09Zm-19.79-4.702a2.617 2.617 0 0 0-2.613 2.611v2.092H52.08v-2.092a2.625 2.625 0 0 0-2.613-2.611H34.532Zm30.37 45.544a3.861 3.861 0 0 0 3.858-3.849V26.007a3.861 3.861 0 0 0-3.859-3.849H19.098a3.861 3.861 0 0 0-3.858 3.85v30.915a3.861 3.861 0 0 0 3.859 3.85H64.9ZM35.38 37.298l5.896-5.04c.423-.335.968-.354 1.45 0l5.897 5.04c.437.371.519 1.087.11 1.525-.392.417-1.124.478-1.561.107l-4.065-3.477V48.92c0 .596-.495 1.08-1.106 1.08-.61 0-1.105-.484-1.105-1.08V35.453l-4.065 3.477c-.437.371-1.18.32-1.56-.107a1.063 1.063 0 0 1 .109-1.525Z"
        fill="#fff"
      />
    </g>
    <defs>
      <filter
        id="case-neon_svg__a"
        x={0.737}
        y={0.737}
        width={82.526}
        height={74.526}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={6.131} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_7263_24838" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={3.344} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_7263_24838" result="effect2_dropShadow_7263_24838" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.115} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_7263_24838" result="effect3_dropShadow_7263_24838" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_7263_24838" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgCaseNeon;
