import { injectable } from 'inversify';
import { combineLatest, map } from 'rxjs';

import { AssetsStore } from 'core/asset/assets.store';
import { ContractStore } from 'core/contract/contract.store';
import { NetworkProvider } from 'core/network/network.provider';
import { BaseBalanceService } from 'core/wallet/base-balance.service';
import { WalletStore } from 'core/wallet/wallet.store';

import { VaultStore } from '../vault/vault.store';

@injectable()
export class VaultBalanceService extends BaseBalanceService {
  constructor(
    vaultStore: VaultStore,
    contractStore: ContractStore,
    walletStore: WalletStore,
    assetsStore: AssetsStore,
    networkProvider: NetworkProvider
  ) {
    super(
      contractStore,
      walletStore,
      assetsStore,
      networkProvider,
      combineLatest([networkProvider.chain$, vaultStore.vault$]).pipe(
        map(([chainId, vault]) => vault?.chains.find((vaultChain) => vaultChain.chainId === chainId)?.address)
      )
    );
  }
}
