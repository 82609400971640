import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgWbtc = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 109.26 109.26" width="1em" height="1em" {...props}>
    <defs>
      <style>{'.wbtc_svg__cls-1{fill:#5a5564}'}</style>
    </defs>
    <g id="wbtc_svg__Layer_2" data-name="Layer 2">
      <g id="wbtc_svg__Layer_1-2" data-name="Layer 1">
        <g id="wbtc_svg__Page-1">
          <g id="wbtc_svg__wbtc_colour" data-name="wbtc colour">
            <path
              id="wbtc_svg__Shape"
              className="wbtc_svg__cls-1"
              d="m89.09 22.93-3 3a42.47 42.47 0 0 1 0 57.32l3 3a46.76 46.76 0 0 0 0-63.39Z"
            />
            <path
              id="wbtc_svg__Shape-2"
              data-name="Shape"
              className="wbtc_svg__cls-1"
              d="M26 23.19a42.47 42.47 0 0 1 57.32 0l3-3a46.76 46.76 0 0 0-63.39 0Z"
            />
            <path
              id="wbtc_svg__Shape-3"
              data-name="Shape"
              className="wbtc_svg__cls-1"
              d="M23.19 83.28a42.47 42.47 0 0 1 0-57.29l-3-3a46.76 46.76 0 0 0 0 63.39Z"
            />
            <path
              id="wbtc_svg__Shape-4"
              data-name="Shape"
              className="wbtc_svg__cls-1"
              d="M83.28 86.05a42.47 42.47 0 0 1-57.32 0l-3 3a46.76 46.76 0 0 0 63.39 0Z"
            />
            <path
              id="wbtc_svg__Shape-5"
              data-name="Shape"
              d="M73.57 44.62c-.6-6.26-6-8.36-12.83-9V27h-5.28v8.46h-4.22V27H46v8.68H35.29v5.65s3.9-.07 3.84 0a2.73 2.73 0 0 1 3 2.32v23.76a1.85 1.85 0 0 1-.64 1.29 1.83 1.83 0 0 1-1.36.46c.07.06-3.84 0-3.84 0l-1 6.31H45.9v8.82h5.28V75.6h4.22v8.65h5.29v-8.72c8.92-.54 15.14-2.74 15.92-11.09.63-6.72-2.53-9.72-7.58-10.93C72.1 52 74 49.2 73.57 44.62Zm-7.4 18.78c0 6.56-11.24 5.81-14.82 5.81V57.57c3.58.01 14.82-1.02 14.82 5.83ZM63.72 47c0 6-9.38 5.27-12.36 5.27V41.69c2.98 0 12.36-.94 12.36 5.31Z"
              style={{
                fill: '#f09242',
              }}
            />
            <path
              id="wbtc_svg__Shape-6"
              data-name="Shape"
              d="M54.62 109.26a54.63 54.63 0 1 1 54.64-54.64 54.63 54.63 0 0 1-54.64 54.64Zm0-105A50.34 50.34 0 1 0 105 54.62 50.34 50.34 0 0 0 54.62 4.26Z"
              style={{
                fill: '#282138',
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
));
export default SvgWbtc;
