import { Control } from '@mobx-form-state/react';
import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import { ExecutorAction, ExecutorIntegration } from 'core/api/schema';
import { VaultActionInfo } from 'core/vault/vault-action.info';
import { VaultStore } from 'core/vault/vault.store';

import { Button, ButtonVariant } from 'components/button';
import { Divider } from 'components/divider';
import { NoteIcon } from 'components/icons';
import { TextField } from 'components/text-field';
import { H1, Primary, Secondary } from 'components/typography';

import { ActionIntegration } from 'common/actions-table/action-integration';
import StatsShare from 'common/stats-share';

import { DateUtils } from 'utils/date';

type TradesMemoProps = {
  vaultAction: VaultActionInfo;
  blockNumber: number;
};

export const TradeMemoModal = observer((props: TradesMemoProps) => {
  const { blockNumber, vaultAction } = props;

  const { activeVault } = useService(VaultStore);

  if (!activeVault || !activeVault.tradesMemo) return null;

  const { form } = activeVault.tradesMemo;

  useEffect(() => {
    form.reset({
      blockNumber: blockNumber,
      integration: vaultAction.integration,
      action: vaultAction.action,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentDate = DateUtils.format(Date.now(), 'shortDayWithYear');

  const isZeroX = vaultAction.integration === ExecutorIntegration.ZeroX;

  return (
    <div
      className={twMerge(
        'flex items-center justify-center flex-col flex-1 w-[479px]',
        isZeroX && 'h-[95vh] max-h-[870px]'
      )}
    >
      <div className='flex flex-col items-center justify-center gap-5 w-full pt-[30px] pr-[25px] pb-[20px] pl-[25px] relative overflow-hidden after:content-[""] after:absolute after:left-2/4 after:-translate-x-1/2 after:translate-y-[10px] after:-bottom-[65px] after:w-[401.511px] after:h-[127.6px] after:rounded-[401.511px] after:opacity-70 after:bg-[radial-gradient(50%_50%_at_50%_50%,_#9f44ff_0%,_rgba(159,_68,_255,_0)_100%)]'>
        <div className="flex flex-col items-center justify-center">
          <H1>Position {getHeaderText(vaultAction.action)}</H1>
          <Secondary>{currentDate}</Secondary>
        </div>

        <ActionIntegration data={vaultAction} />
      </div>

      <Divider className="w-full text-outline" />
      <div className="flex flex-col gap-5 px-[25px] py-[30px] w-full">
        <div className="flex gap-2.5 items-center">
          <NoteIcon className="text-[25px] text-brand-600" />
          <div className="flex flex-col">
            <div className="flex items-center gap-2.5">
              <Primary>Trade memo</Primary>
              <Secondary>Optional</Secondary>
            </div>
            <Secondary>Explain your reasoning</Secondary>
          </div>
        </div>

        <Control of={TextField} field={form.fields.message} multiline rows={5} showCharCount maxLength={360} />

        <Button
          fullWidth
          variant={ButtonVariant.Contained}
          disabled={form.fields.message.invalid || form.submitting}
          onClick={form.handleSubmit}
        >
          Add trade memo
        </Button>
      </div>
      {isZeroX && (
        <>
          <Divider className="w-full text-outline" />
          <div className="overflow-scroll">
            <div className="px-[25px] py-[30px] w-full">
              <StatsShare data={vaultAction} />
            </div>
          </div>
        </>
      )}
    </div>
  );
});

TradeMemoModal.displayName = 'TradeMemoModal';

export const getHeaderText = (action: ExecutorAction) => {
  switch (action) {
    case ExecutorAction.Swap:
    case ExecutorAction.PerpLongIncrease:
    case ExecutorAction.PerpShortIncrease:
    case ExecutorAction.KwentaSubmitOrder:
      return 'opened';
    case ExecutorAction.PerpLongDecrease:
    case ExecutorAction.PerpShortDecrease:
    case ExecutorAction.KwentaSubmitCloseOrder:
      return 'closed';
  }
};
