import { injectable } from 'inversify';

import { GraphQLClientService } from 'core/api/gql.client';
import { GetOrganizations } from 'core/api/gql/organizations/get-organizations.gql.generated';
import { OrganizationFragment } from 'core/api/gql/organizations/organization.fragment.gql.generated';

@injectable()
export class OrganizationsService {
  private items: OrganizationFragment[] = [];

  private selected?: OrganizationFragment;

  constructor(private readonly gqlClientService: GraphQLClientService) {}

  readonly getOrgHeaders = async (): Promise<Record<string, string>> => {
    if (!this.items.length) {
      await this.load();

      this.selected = this.items.find((org) => org.domain === window.location.hostname);
    }

    if (!this.selected) return {};

    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-Organization-ID': this.selected.id,
    };
  };

  private load = async (): Promise<void> => {
    const result = await GetOrganizations(this.gqlClientService.client.request.bind(this.gqlClientService.client));

    this.items = result.organizations;
  };
}
