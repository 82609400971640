import { useService } from '@redtea/react-inversify';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { createPortal } from 'react-dom';

import { usePortalElement } from 'lib/modals/use-portal-element';

import { BackdropStore } from './backdrop.store';

export const Backdrop = observer(() => {
  const portalEl = usePortalElement('modal');
  const { visible, onClick } = useService(BackdropStore);

  return createPortal(
    <AnimatePresence>
      {visible && (
        <motion.div
          className="absolute top-0 left-0 w-screen h-full backdrop-filter backdrop-blur-[23.5px] bg-background/80 z-[9] "
          variants={backdropAnimation}
          transition={{ duration: 0.2 }}
          exit="hide"
          animate="show"
          initial="hide"
          onClick={onClick}
        />
      )}
    </AnimatePresence>,
    portalEl
  );
});

const backdropAnimation: Variants = {
  show: {
    opacity: 1,
  },
  hide: {
    opacity: 0,
  },
};
