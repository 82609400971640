import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgWalletConnect = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 44 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.005 5.292c7.174-6.99 18.805-6.99 25.978 0l.864.841a.879.879 0 0 1 0 1.265l-2.953 2.878a.468.468 0 0 1-.65 0l-1.188-1.158c-5.005-4.875-13.119-4.875-18.123 0l-1.272 1.24a.468.468 0 0 1-.65 0L8.058 7.48a.879.879 0 0 1 0-1.266l.947-.923Zm32.086 5.95 2.629 2.561a.879.879 0 0 1 0 1.266L31.868 26.616a.936.936 0 0 1-1.3 0l-8.411-8.195a.234.234 0 0 0-.325 0l-8.412 8.195a.936.936 0 0 1-1.299 0L.27 15.07a.879.879 0 0 1 0-1.266l2.629-2.56a.936.936 0 0 1 1.298 0l8.413 8.195c.09.087.235.087.324 0l8.412-8.196a.936.936 0 0 1 1.299 0l8.412 8.196c.09.087.235.087.325 0l8.411-8.195a.936.936 0 0 1 1.3 0Z"
      fill="#3B99FC"
    />
  </svg>
));
export default SvgWalletConnect;
