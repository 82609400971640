/* eslint-disable @typescript-eslint/no-unused-vars */
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

import { Callback } from 'types';

import { TableColumn } from '../table';

type TableItemProps<T> = {
  item: T;
  columns: TableColumn<T>[];
  onRowClick?: Callback<T>;
};

export const TableItem = observer(<T,>(props: TableItemProps<T>) => {
  const { item, onRowClick, columns } = props;

  const createRowClick = useCallback((item: T) => (onRowClick ? () => onRowClick(item) : undefined), [onRowClick]);

  const handleClick = createRowClick(item);

  return (
    <tr className={twMerge(handleClick && 'cursor-pointer')} onClick={handleClick}>
      {columns.map(({ renderer, label, minWidth, ...rest }, index) =>
        renderer ? (
          <td key={index} width={minWidth} {...rest}>
            {renderer(item)}
          </td>
        ) : null
      )}
    </tr>
  );
});
