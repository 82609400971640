import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgNeonWarning = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 89 85" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#neon-warning_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.678 68.293h39.658c3.113 0 5.93-1.631 7.487-4.3 1.556-2.668 1.556-5.93.074-8.673L52.03 20.481c-1.557-2.743-4.374-4.373-7.561-4.373-3.188 0-6.004 1.63-7.56 4.373l-19.793 34.84c-1.556 2.742-1.556 5.93.075 8.672 1.556 2.743 4.373 4.3 7.486 4.3Zm-4.3-11.12 19.867-34.839c.89-1.556 2.52-2.52 4.3-2.52 1.778 0 3.409.963 4.298 2.52l19.866 34.84c.89 1.556.89 3.41 0 4.966-.89 1.557-2.52 2.446-4.299 2.446H24.678c-1.779 0-3.41-.89-4.3-2.446a4.955 4.955 0 0 1 0-4.967Zm23.795-6.449a1.835 1.835 0 0 0 1.853-1.853V33.305a1.835 1.835 0 0 0-1.853-1.853 1.835 1.835 0 0 0-1.853 1.853V48.87c0 1.038.816 1.853 1.853 1.853Zm1.853 7.043a1.835 1.835 0 0 1-1.853 1.853 1.835 1.835 0 0 1-1.853-1.853V54.8c0-1.037.816-1.853 1.853-1.853 1.038 0 1.853.816 1.853 1.853v2.965Z"
        fill="#F7FAFF"
      />
    </g>
    <defs>
      <filter
        id="neon-warning_svg__a"
        x={0.192}
        y={0.341}
        width={88.573}
        height={83.718}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={7.883} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9180_4680" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={4.3} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_9180_4680" result="effect2_dropShadow_9180_4680" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.433} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_9180_4680" result="effect3_dropShadow_9180_4680" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_9180_4680" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgNeonWarning;
