import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgArrowLeft = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 10 8" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 3.997c.003-.351.155-.687.421-.934L3.486.194A.74.74 0 0 1 3.989 0c.189 0 .37.07.504.194.067.062.12.136.156.217a.63.63 0 0 1-.156.73L2.143 3.33h7.143c.19 0 .37.07.505.195.134.125.209.295.209.472a.646.646 0 0 1-.21.471.74.74 0 0 1-.504.196H2.143l2.35 2.195c.134.125.21.294.211.471a.646.646 0 0 1-.208.473.74.74 0 0 1-.504.197.742.742 0 0 1-.506-.194L.42 4.937A1.292 1.292 0 0 1 0 3.997Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgArrowLeft;
