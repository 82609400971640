/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';

export type VaultChainFragment = {
  __typename?: 'VaultChainType';
  chainId: number;
  name: string;
  address: string;
  isParent: boolean;
  aum: BigNumber;
};

export const VaultChainFragmentDoc = gql`
  fragment VaultChain on VaultChainType {
    chainId
    name
    address
    isParent
    aum
  }
`;
