/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { AccountFragmentDoc } from './account.fragment.generated';

export type AccountUpdatedSubscriptionVariables = Types.Exact<{ [key: string]: never }>;

export type AccountUpdatedSubscription = {
  __typename?: 'SubscriptionRoot';
  accountUpdated: {
    __typename?: 'AccountType';
    address: string;
    chain: string;
    avatar?: string | null;
    name?: string | null;
    twitter?: string | null;
    bio?: string | null;
    createdAt: string;
    slug: string;
    referrerCode: string;
    referrerProvider?: Types.ReferrerProvider | null;
    referralCode?: string | null;
    experience: number;
    experienceSpins: number;
    rewards: Array<{
      __typename?: 'RewardType';
      rewardType?: Types.RewardEnumType | null;
      createdAt: string;
      revealedAt?: string | null;
    }>;
  };
};

export const AccountUpdatedDocument = gql`
  subscription AccountUpdated {
    accountUpdated {
      ...Account
    }
  }
  ${AccountFragmentDoc}
`;

export function AccountUpdated<R extends AccountUpdatedSubscription, V extends AccountUpdatedSubscriptionVariables>(
  subscription: GetSubscription<R, V>,
  variables?: V
): Observable<R> {
  return subscription(AccountUpdatedDocument, variables);
}
