import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgWallet = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.476 6.098v1.28c.853 0 1.524.305 1.524 1.22v3.475c0 .915-.67 1.22-1.524 1.22v1.34c0 1.281-1.037 2.379-2.317 2.379H2.316C1.037 17.012 0 15.914 0 14.634V2.439C0 1.098 1.159 0 2.5 0h11.524a2.316 2.316 0 0 1 2.318 2.317V3.78c1.219.183 2.134 1.037 2.134 2.318ZM2.5 1.22A1.25 1.25 0 0 0 1.22 2.5c0 .67.549 1.28 1.28 1.28h12.622V2.318c0-.61-.488-1.097-1.159-1.097H2.5Zm12.56 10.914h3.781V8.537h-3.78c-2.317 0-2.317 3.597 0 3.597Zm1.342-2.805c.549 0 .975.427.975.976 0 .61-.426 1.036-.975 1.036a1.028 1.028 0 0 1-1.037-1.036c0-.55.488-.976 1.037-.976Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgWallet;
