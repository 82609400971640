import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { useTheme } from 'styles/themes/use-theme.hook';

import { AccountStore } from 'core/account/account.store';
import { ConfigService } from 'core/config/config.service';
import { NotificationsStore } from 'core/notifications/notifications.store';
import { WalletStore } from 'core/wallet/wallet.store';

import { Chip, ChipVariant } from 'components/chip';

import { RevealSZN0Store } from 'pages/season-zero/reveal/reveal-szn0.store';

export const NavMenu = observer(() => {
  const { isSeasonOne, isSeasonZero } = useService(ConfigService);
  const { totalDeposits } = useService(NotificationsStore);
  const { availableBoxes } = useService(RevealSZN0Store);

  const { address } = useService(WalletStore);
  const { account } = useService(AccountStore);

  const { features } = useTheme();

  const isSeasonOneAvailable = isSeasonOne && address;

  const items = [
    {
      name: 'Home',
      path: '/',
      testid: 'home',
    },
    {
      name: 'Explore',
      path: '/explore',
      testid: 'explore',
    },
    {
      name: 'My positions',
      path: '/my-positions',
      testid: 'my-positions',
    },
    {
      name: <>Manage {!!totalDeposits && <Chip variant={ChipVariant.Slim} label={totalDeposits} />}</>,
      path: '/manage',
      testid: 'manage',
      hidden: !features?.manage,
    },
    {
      name: <> My SZN 0 {!!availableBoxes && <Chip variant={ChipVariant.Slim} label={availableBoxes} />}</>,
      path: '/szn0',
      hidden: !features?.szn0 || !address || !isSeasonZero,
    },
    {
      name: (
        <>SZN 1 {!!account?.experienceSpins && <Chip variant={ChipVariant.Slim} label={account?.experienceSpins} />}</>
      ),
      path: '/season-one',
      testid: 'my-szn-1',
      hidden: !isSeasonOneAvailable || !features?.szn1,
    },
  ];

  const filteredItems = items.filter((item) => !item.hidden);

  return (
    <div className="app-bar-menu">
      {filteredItems.map((item, idx) => (
        <NavLink className="app-bar-menu-item hover:text-white" key={idx} to={item.path} data-testid={item.testid}>
          {item.name}
        </NavLink>
      ))}
    </div>
  );
});
