/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { NotificationFragmentDoc } from './notifications.fragment.gql.generated';

export type UpdateNotificationsStatusMutationVariables = Types.Exact<{
  input: Types.UpdateNotificationStatusInput;
}>;

export type UpdateNotificationsStatusMutation = {
  __typename?: 'Mutation';
  updateNotificationsStatus: Array<{
    __typename?: 'NotificationType';
    type: Types.NotificationTypeEnum;
    status: Types.NotificationStatusEnum;
    context?: string | null;
    message: string;
    createdAt: string;
  }>;
};

export const UpdateNotificationsStatusDocument = gql`
  mutation UpdateNotificationsStatus($input: UpdateNotificationStatusInput!) {
    updateNotificationsStatus(input: $input) {
      ...Notification
    }
  }
  ${NotificationFragmentDoc}
`;

export function UpdateNotificationsStatus<
  R extends UpdateNotificationsStatusMutation,
  V extends UpdateNotificationsStatusMutationVariables
>(request: Request<R, V>, variables: V, headers?: HeadersInit): Promise<R> {
  return request(UpdateNotificationsStatusDocument, variables, headers);
}
