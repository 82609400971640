import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgGmx = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 10" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14 10 7.01 0 0 10h9.767L7.011 6.183 5.643 8.166H4.19L7.012 4.18 11.09 10H14Z" fill="currentColor" />
  </svg>
));
export default SvgGmx;
