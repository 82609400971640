import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';

import { ErrorStore } from 'core/app/error.store';

import { ErrorPage } from 'components/error-page';

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

export const ErrorBoundary = observer((props: ErrorBoundaryProps) => {
  const { children } = props;
  const { reloadNeeded } = useService(ErrorStore);

  if (reloadNeeded) {
    return <ErrorPage />;
  }

  return <>{children}</>;
});
