import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgExit = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.282 13.5v-1.25a.625.625 0 1 0-1.25 0v1.25c0 .69-.561 1.25-1.25 1.25H2.5c-.69 0-1.25-.56-1.25-1.25v-11c0-.69.56-1.25 1.25-1.25h6.282c.689 0 1.25.56 1.25 1.25v1.25a.625.625 0 1 0 1.25 0V2.5c0-1.378-1.122-2.5-2.5-2.5H2.5A2.503 2.503 0 0 0 0 2.5v11C0 14.88 1.122 16 2.5 16h6.282c1.378 0 2.5-1.12 2.5-2.5Zm2.861-7.973 1.4 1.4c.61.609.61 1.6 0 2.21l-1.4 1.4a.623.623 0 0 1-.884 0 .625.625 0 0 1 0-.885l.996-.995H6.75a.625.625 0 1 1 0-1.25h7.505l-.996-.996a.625.625 0 1 1 .884-.884Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgExit;
