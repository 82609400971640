import { TradeMemoFragment } from 'core/api/gql/vault/trade-memo.fragment.generated';
import { GetAssetBySymbol } from 'core/asset/assets.store';
import { mapVaultActionFromApi } from 'core/vault/vault-actions.map';

import { TradeMemoInfo } from './trade-memo.info';

export const mapTradesMemoFromApi = (items: TradeMemoFragment[], getAsset: GetAssetBySymbol): TradeMemoInfo[] =>
  items.map((tradeMemo) => mapTradeMemoFromApi(tradeMemo, getAsset));

const mapTradeMemoFromApi = (tradeMemo: TradeMemoFragment, getAsset: GetAssetBySymbol): TradeMemoInfo => {
  const action = mapVaultActionFromApi(tradeMemo.action, getAsset);

  if (!action) {
    throw new Error('Invalid action encountered in mapTradeMemoFromApi');
  }

  return {
    ...tradeMemo,
    action,
  };
};
