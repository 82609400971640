import BigNumber from 'bignumber.js';
import { BigNumberish } from 'ethers';
import { Container, injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { concatMap, delay, filter, from, map, merge, mergeMap, retry, share, switchMap, take } from 'rxjs';

import { ChainID, MainChainID, chains } from 'core/chain';
import type { AnyChain } from 'core/chain';
import { ChainSwitcherModal } from 'core/chain/chain-switcher';
import { Logger } from 'core/logger';
import { SnackbarStore } from 'core/snackbars/snackbar.store';
import { Valio } from 'core/valio/valio';

import { ModalsStore } from 'lib/modals/modals.store';

import { Disposable } from 'utils/disposable';
import { initIOC } from 'utils/init-ioc';
import { isSomething } from 'utils/is-something';
import { polling } from 'utils/polling';
import { RetryStrategy } from 'utils/retry-strategy';
import { IOCDescriptor } from 'utils/use-ioc';

import { Callback, Units } from 'types';

import { NetworkProvider } from './network.provider';
import { NetworkService } from './network.service';
import { Privy } from './privy';
import { StaticNetworkProvider } from './static-network.provider';

const logger = new Logger('NetworkStore');

@injectable()
export class NetworkStore extends Disposable {
  @observable
  requiredChainId?: ChainID;

  @observable
  currentChainId?: AnyChain;

  @observable
  isNetworkSwitching = false;

  @observable
  isWrongChain = false;

  @observable.ref
  gasPriceMap = new Map<ChainID, Units>();

  @observable
  chainTime = Date.now();

  @observable.ref
  state?: Privy.State;

  networkSwitchWait: Promise<void>;

  networks: Record<ChainID, Container>;

  private networkSwitchExecutor?: {
    resolve: Callback<void>;
    reject: Callback<void>;
  };

  constructor(
    private readonly networkService: NetworkService,
    private readonly modalsStore: ModalsStore,
    private readonly networkProvider: NetworkProvider,
    private readonly snackbarsStore: SnackbarStore,
    container: Container,
    multiChainDescriptors: IOCDescriptor[]
  ) {
    super();
    makeObservable(this);

    const switchNetworkModalClosed$ = this.modalsStore.openModal$.pipe(
      filter((modal) => modal.component.displayName === ChainSwitcherModal.displayName),
      switchMap(() => this.modalsStore.closeModal$.pipe(take(1))),
      share()
    );

    this.networkSwitchWait = new Promise((resolve, reject) => {
      this.networkSwitchExecutor = { resolve, reject };
    });

    this.networks = Object.values(chains).reduce((acc, chain) => {
      const networkContainer = container.createChild({ defaultScope: 'Singleton', skipBaseClassChecks: true });

      StaticNetworkProvider.bind(chain.chainId, networkContainer);

      initIOC(networkContainer, multiChainDescriptors, false);

      acc[chain.chainId] = networkContainer;

      return acc;
    }, {} as Record<ChainID, Container>);

    const gasPrice$ = polling(30000).pipe(
      switchMap(() =>
        from(Object.values(chains)).pipe(
          mergeMap((chain) =>
            from([chain]).pipe(
              concatMap(() => this.networks[chain.chainId].get(NetworkProvider).getProvider().getGasPrice()),
              map((gasPrice) => ({ chainId: chain.chainId, gasPrice })),
              retry(RetryStrategy({ label: `Fetch gas price failed ${chain.chainId}` }))
            )
          )
        )
      )
    );

    const chainTime$ = polling(5000).pipe(
      switchMap(() =>
        this.networkService.chain$.pipe(
          filter(isSomething),
          concatMap(() => this.networkProvider.getProvider().getBlock('latest')),
          map((block) => block.timestamp * 1000)
        )
      ),
      retry(RetryStrategy({ label: 'Fetch chain time failed' }))
    );

    const currentChain$ = this.networkService.connector.privyState$.pipe(
      map((state) => state.networkState?.chainId),
      share()
    );

    const requiredChainSelected$ = currentChain$.pipe(
      filter((chain): chain is ChainID => isSomething(this.requiredChainId) && chain === this.requiredChainId)
    );

    const supportedChainSelected$ = currentChain$.pipe(
      filter(isSomething),
      filter(() => !isSomething(this.requiredChainId)),
      filter((chain): chain is ChainID => Valio.isSupportedChain(chain))
    );

    const closeNetworkSwitch$ = merge(requiredChainSelected$, supportedChainSelected$).pipe(
      delay(300),
      map(() => false)
    );

    this.autoDispose(this.networkService.connector.privyState$.subscribe(this.setPrivy));
    this.autoDispose(currentChain$.subscribe(this.setCurrentChainId));
    this.autoDispose(switchNetworkModalClosed$.subscribe(this.updateSwitchState));
    this.autoDispose(chainTime$.subscribe(this.setChainTime));
    this.autoDispose(closeNetworkSwitch$.subscribe(this.closeNetworkSwitch));
    this.autoDispose(closeNetworkSwitch$.pipe(delay(1000)).subscribe(this.cleanup));
    this.autoDispose(gasPrice$.subscribe(this.setGasPrice));
    this.autoDispose(
      this.networkService.requestNetworkSwitch$.subscribe(() => this.requestNetworkSwitchTo(MainChainID, true))
    );
    this.autoDispose(this.networkService.requestNetworkSwitch$.subscribe(() => this.setIsWrongChain(true)));
  }

  @computed
  get connection(): Privy.Connection | undefined {
    return Privy.getConnection(this.state?.user);
  }

  @computed
  get isSocialConnection(): boolean {
    return this.connection?.method !== Privy.ConnectionMethod.Wallet;
  }

  @computed
  get gasPrice(): BigNumber {
    const chainId = this.currentChainId;

    if (!chainId) return new BigNumber(0);

    const gasPrice = this.gasPriceMap.get(chainId);

    return new BigNumber(gasPrice ?? 0);
  }

  @action
  requestNetworkSwitchTo = (chainId?: ChainID, permanent?: boolean): void => {
    this.requiredChainId = chainId;

    logger.debug('requestNetworkSwitchTo', chainId);

    this.networkSwitchWait = new Promise((resolve, reject) => {
      this.networkSwitchExecutor = { resolve, reject };
    });

    this.modalsStore.open(ChainSwitcherModal, { meta: { permanent } });
  };

  @action
  switchNetwork = async (chainId: ChainID): Promise<void> => {
    this.requiredChainId = chainId;
    this.setIsNetworkSwitching(true);

    logger.debug('switchNetwork', chainId);

    try {
      await this.networkService.switchNetwork(chainId);

      logger.debug('switchNetwork successful', chainId);
      this.snackbarsStore.openSuccess(`Network changed to ${chains[chainId].name}`);
    } catch (e) {
      logger.debug('switchNetwork canceled', chainId, e);

      this.snackbarsStore.openFailed(`Network switch to ${chains[chainId].name} failed`, e);
      this.setIsNetworkSwitching(false);
    }
  };

  @action
  private setPrivy = (privyState: Privy.State): void => {
    this.state = privyState;
  };

  @action
  private closeNetworkSwitch = (): void => {
    this.modalsStore.close(ChainSwitcherModal.displayName);

    this.networkSwitchExecutor?.resolve();
  };

  @action
  private cleanup = (): void => {
    this.requiredChainId = undefined;
    this.isWrongChain = false;
    this.isNetworkSwitching = false;
  };

  @action
  private setGasPrice = (result: { chainId: ChainID; gasPrice: BigNumberish }): void => {
    const { chainId, gasPrice } = result;

    this.gasPriceMap.set(chainId, gasPrice.toString() as Units);

    logger.debug('setGasPrice', chainId, gasPrice.toString());
  };

  @action
  private setChainTime = (chainTime: number): void => {
    this.chainTime = chainTime;

    logger.debug('setChainTime', chainTime);
  };

  @action
  private setIsNetworkSwitching = (isNetworkSwitching: boolean): void => {
    this.isNetworkSwitching = isNetworkSwitching;

    logger.debug('setIsNetworkSwitching', isNetworkSwitching);
  };

  @action
  private setCurrentChainId = (chainId?: AnyChain): void => {
    this.currentChainId = chainId;

    logger.debug('setCurrentChainId', chainId);
  };

  @action
  private setIsWrongChain = (isWrongChain: boolean): void => {
    this.isWrongChain = isWrongChain;
  };

  checkNetwork = async (chainId: ChainID): Promise<void> => {
    if (this.currentChainId !== chainId) {
      if (this.connection?.method !== Privy.ConnectionMethod.Wallet) {
        return this.switchNetwork(chainId);
      }

      this.requestNetworkSwitchTo(chainId);

      await this.networkSwitchWait;
    }
  };

  getGasPrice = (chainId: ChainID): BigNumber => {
    const gasPrice = this.gasPriceMap.get(chainId);

    return new BigNumber(gasPrice ?? 0);
  };

  private updateSwitchState = (): void => {
    if (isSomething(this.requiredChainId) && this.currentChainId !== this.requiredChainId) {
      logger.debug('updateSwitchState', 'reject', this.currentChainId, this.requiredChainId);
      this.networkSwitchExecutor?.reject();
    } else {
      logger.debug('updateSwitchState', 'resolve');
      this.networkSwitchExecutor?.resolve();
    }
  };
}
