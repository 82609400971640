/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';

export type SpinExperienceMutationVariables = Types.Exact<{ [key: string]: never }>;

export type SpinExperienceMutation = { __typename?: 'Mutation'; spinExperience: number };

export const SpinExperienceDocument = gql`
  mutation SpinExperience {
    spinExperience
  }
`;

export function SpinExperience<R extends SpinExperienceMutation, V extends SpinExperienceMutationVariables>(
  request: Request<R, V>,
  variables?: V,
  headers?: HeadersInit
): Promise<R> {
  return request(SpinExperienceDocument, variables, headers);
}
