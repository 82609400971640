import { useService } from '@redtea/react-inversify';
import { captureException } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'styles/themes/use-theme.hook';

import { MainChainID } from 'core/chain';
import { Logger } from 'core/logger';
import { NetworkStore } from 'core/network/network.store';
import { PrivyClient } from 'core/network/privy.client';

import { Button, ButtonProps, ButtonVariant } from 'components/button';

type ConnectButtonProps = ButtonProps & {
  contained?: boolean;
  text?: string;
};

const logger = new Logger('ConnectButton');

export const ConnectButton = observer((props: ConnectButtonProps) => {
  const { text, contained, ...rest } = props;
  const { connectButtonText } = useTheme();

  const { checkNetwork } = useService(NetworkStore);
  const { connect, isConnected } = useService(PrivyClient);

  const handleConnect = async () => {
    if (isConnected) {
      try {
        logger.debug('switching network');

        await checkNetwork(MainChainID);

        logger.debug('network switched');
      } catch (err) {
        logger.error(err);
        captureException(new Error('ConnectButton: connect issue'));
      }
    } else {
      connect();
    }
  };

  return (
    <Button onClick={handleConnect} variant={contained ? ButtonVariant.Contained : ButtonVariant.Outlined} {...rest}>
      {text ? text : connectButtonText}
    </Button>
  );
});
