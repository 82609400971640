import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgTrade = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 9 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.702 5h6.62a.7.7 0 0 0 .495-1.195L4.507.495a.7.7 0 0 0-.99 0l-3.31 3.31A.7.7 0 0 0 .702 5Zm0 1.768h6.62a.7.7 0 0 1 .495 1.195l-3.31 3.31a.7.7 0 0 1-.99 0l-3.31-3.31a.7.7 0 0 1 .495-1.195Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgTrade;
