import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgSpeedometerLow = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 35 31" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.525 0a17.38 17.38 0 0 1 10.618 4.4l-4.575 4.575a10.967 10.967 0 0 0-6.043-2.503V0Zm7.493 10.425 4.576-4.575a17.379 17.379 0 0 1 4.405 10.618h-6.472a10.968 10.968 0 0 0-2.509-6.043ZM.001 16.468h6.472a10.968 10.968 0 0 1 2.509-6.043L4.406 5.85A17.378 17.378 0 0 0 .001 16.468Zm6.473 2.05c.144 1.58.595 2.897 1.128 3.953-.52.802-.954 1.48-1.24 1.93a3.058 3.058 0 0 0 .42 3.827c.25.25.534.45.843.599l-1.066 1.065a1.024 1.024 0 0 1-1.452 0A17.4 17.4 0 0 1 0 18.518h6.474Zm28.526 0h-6.474a11.007 11.007 0 0 1-3.196 6.81 1.027 1.027 0 0 0 0 1.453l3.11 3.111a1.024 1.024 0 0 0 1.453 0A17.401 17.401 0 0 0 35 18.518ZM5.857 4.4A17.38 17.38 0 0 1 16.475 0v6.472c-2.23.203-4.32 1.07-6.043 2.503L5.857 4.4Zm13.921 10.832a3.226 3.226 0 0 0-4.556 0c-1.587 1.587-6.567 9.387-7.13 10.271a1.025 1.025 0 0 0 1.416 1.416c.884-.563 8.683-5.543 10.27-7.13a3.226 3.226 0 0 0 0-4.557Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgSpeedometerLow;
