import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgPulseNeonBlue = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 91 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#pulse-neon-blue_svg__a)">
      <path
        d="M41.67 13.006c-.309.031-.6.16-.832.365a1.464 1.464 0 0 0-.459.78l-5.882 26.003-4.014-5.98a1.473 1.473 0 0 0-1.223-.664H14.498a1.484 1.484 0 0 0-1.383.9 1.458 1.458 0 0 0 .326 1.61 1.479 1.479 0 0 0 1.057.423h13.98l5.466 8.156a1.473 1.473 0 0 0 1.484.614c.28-.051.54-.182.748-.376.207-.194.353-.444.421-.72l4.96-21.947 5.443 33.586c.053.34.224.651.485.879a1.482 1.482 0 0 0 2.422-.81l5.836-27.011 3.968 6.896a1.472 1.472 0 0 0 1.291.756l15.502-.023a1.484 1.484 0 0 0 1.383-.9 1.458 1.458 0 0 0-.326-1.61 1.477 1.477 0 0 0-1.057-.422l-14.625.022-5.49-9.53a1.486 1.486 0 0 0-2.316-.361c-.22.208-.37.478-.43.773L48.66 47.531l-5.397-33.31a1.465 1.465 0 0 0-.557-.918 1.484 1.484 0 0 0-1.035-.297Z"
        fill="#fff"
      />
    </g>
    <defs>
      <filter
        id="pulse-neon-blue_svg__a"
        x={0.737}
        y={0.737}
        width={89.526}
        height={68.526}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={6.131} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_13174_2136" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={3.344} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_13174_2136" result="effect2_dropShadow_13174_2136" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.115} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_13174_2136" result="effect3_dropShadow_13174_2136" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_13174_2136" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgPulseNeonBlue;
