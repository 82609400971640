import { injectable } from 'inversify';
import { makeObservable, observable } from 'mobx';
import { Observable, ReplaySubject, filter, firstValueFrom, map, merge, shareReplay, switchMap } from 'rxjs';

import { GraphQLClientService } from 'core/api/gql.client';
import { LoginWithSignature } from 'core/api/gql/account/login.gql.generated';
import { LoginSignatureInput, ReferrerInput } from 'core/api/schema';
import { LocalStorageService } from 'core/local-storage/local-storage.service';
import { NetworkProvider } from 'core/network/network.provider';
import { WalletStore } from 'core/wallet/wallet.store';

import { Disposable } from 'utils/disposable';
import { isSomething } from 'utils/is-something';

import { Auth } from './auth';

@injectable()
export class AuthService extends Disposable {
  @observable
  waitingForSignature = false;

  authData$: Observable<Auth.AuthData | null>;

  private loadingPromise?: Promise<Auth.AuthHeaders>;

  private readonly authData$$ = new ReplaySubject<Auth.AuthData | null>(1);

  constructor(
    private readonly gqlClientService: GraphQLClientService,
    private readonly localStorageService: LocalStorageService,
    private readonly networkProvider: NetworkProvider,
    private readonly walletStore: WalletStore
  ) {
    super();
    makeObservable(this);

    this.authData$ = merge(
      this.authData$$.asObservable(),
      this.walletStore.address$.pipe(
        map(() => {
          const accessToken = this.localStorageService.getAccessToken();

          return accessToken ? Auth.parseAccessToken(accessToken) : null;
        })
      )
    ).pipe(shareReplay(1));

    this.autoDispose(
      this.authData$
        .pipe(filter(isSomething))
        .subscribe((data) => this.localStorageService.setAccessToken(data.accessToken))
    );

    // requests a user to sign in again when the address changes
    this.autoDispose(this.walletStore.address$.pipe(switchMap(() => this.getAuthHeaders())).subscribe());
  }

  readonly getAuthHeaders = async (): Promise<Auth.AuthHeaders> => {
    if (!this.loadingPromise) {
      this.loadingPromise = this.loadAuthHeaders();
    }

    const result = await this.loadingPromise;

    this.loadingPromise = undefined;

    return result;
  };

  private loadAuthHeaders = async (): Promise<Auth.AuthHeaders> => {
    if (!this.walletStore.address) return {};

    let data = await firstValueFrom(this.authData$);

    if (!data || !Auth.isValidData(data, this.walletStore.address)) {
      try {
        // this.modalsManager.signRequest();

        const accessToken = await this.login();

        // this.modalsStore.close(Modals.SignRequest);

        data = Auth.parseAccessToken(accessToken);

        this.authData$$.next(data);
      } catch (e) {
        //
      }

      // this.modalsStore.close(Modals.SignRequest);
    }

    return data && Auth.isValidData(data, this.walletStore.address) ? Auth.makeHeaders(data.accessToken) : {};
  };

  private login = async (): Promise<string> => {
    const signer = this.networkProvider.getSigner();

    const timestamp = new Date().toISOString();

    const signature = await signer.signMessage(timestamp);

    const referrer = this.localStorageService.getReferrerCode();

    const loginParams: LoginSignatureInput = {
      timestamp,
      signature: signature.slice(2),
    };

    if (referrer) {
      const referrerInput: ReferrerInput = {
        referrerCode: referrer.code,
        referrerProvider: referrer.provider,
      };
      loginParams.referrer = referrerInput;
    }

    const result = await LoginWithSignature(this.gqlClientService.client.request.bind(this.gqlClientService.client), {
      input: loginParams,
    });

    return result.loginWithSignature.accessToken;
  };
}
