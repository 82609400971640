import { injectable } from 'inversify';

import { AssetsStore } from 'core/asset/assets.store';
import { ContractStore } from 'core/contract/contract.store';
import { NetworkProvider } from 'core/network/network.provider';

import { BaseBalanceService } from './base-balance.service';
import { WalletStore } from './wallet.store';

@injectable()
export class LazyWalletBalanceService extends BaseBalanceService {
  constructor(
    contractStore: ContractStore,
    walletStore: WalletStore,
    assetsStore: AssetsStore,
    networkProvider: NetworkProvider
  ) {
    super(contractStore, walletStore, assetsStore, networkProvider, walletStore.address$, 30000);
  }
}
