/* eslint-disable react-hooks/exhaustive-deps */
import { animate, useMotionValue, useScroll } from 'framer-motion';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

type UseInfiniteScrollParams = {
  scrollContainer: RefObject<HTMLElement>;
  hasMore: boolean;
  isLoadingMore: boolean;
  onLoadMore?: () => void;
};

export const useInfiniteScroll = (params: UseInfiniteScrollParams): boolean => {
  const { scrollContainer, hasMore, onLoadMore, isLoadingMore } = params;

  const [scrolling, setScrolling] = useState(false);
  const prevIsLoadingMore = useRef<boolean>(false);
  const isLoadMoreCalled = useRef<boolean>(false);
  const scrollHeight = useMotionValue(0);
  const tableInnerScroll = useScroll({ container: scrollContainer });

  // TODO remove scroll to

  const scrollTo = useCallback(
    (x: number) => {
      animate(scrollHeight, x, {
        delay: 0.15,
        duration: 0.3,
        onUpdate: (value) => {
          if (scrollContainer.current) {
            scrollContainer.current.scrollTo(0, value);
          }
        },

        onComplete: () => {
          setScrolling(false);
        },
      });
      scrollHeight.set(x, false);
    },
    [scrollHeight, scrollContainer]
  );

  useEffect(() => {
    const el = scrollContainer.current;
    if (!el) return;

    if (el.scrollHeight === el.clientHeight && hasMore && onLoadMore) {
      onLoadMore();
    }
  }, []);

  useEffect(() => {
    const unsubscribe = tableInnerScroll.scrollY.on('change', (v) => {
      if (!scrollContainer.current) return;

      const { clientHeight, scrollHeight } = scrollContainer.current;

      if (scrollHeight - clientHeight - v < 10 && onLoadMore && hasMore) {
        isLoadMoreCalled.current = true;
        onLoadMore();
      }
    });

    return () => unsubscribe();
  }, [hasMore]);

  useEffect(() => {
    if (prevIsLoadingMore.current !== isLoadingMore && !isLoadingMore && scrollContainer.current) {
      const { scrollHeight, clientHeight } = scrollContainer.current;

      setScrolling(true);

      scrollTo(scrollHeight - clientHeight - 46);

      isLoadMoreCalled.current = false;
    }

    prevIsLoadingMore.current = isLoadingMore;
  }, [isLoadingMore, scrollTo]);

  return scrolling;
};
