import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';

import { ChainID, chains } from 'core/chain';
import { NetworkStore } from 'core/network/network.store';

import { IconSize } from 'components/svg-icon';
import { Primary, Secondary } from 'components/typography';

import { stringToEnum } from 'utils/string-to-enum';

export const NetworkInfo = observer(() => {
  const { currentChainId } = useService(NetworkStore);

  const currentChain = stringToEnum(currentChainId, ChainID);

  if (!currentChain) return null;

  const chainInfo = chains[currentChain];

  return (
    <div className="flex items-center gap-1.5">
      <div className="flex flex-col">
        <Secondary className="text-[12px] opacity-50 leading-[10px]">Your chain</Secondary>
        <Primary className="text-secondary" data-testid="network-info__chain-name">
          {chainInfo.name}
        </Primary>
      </div>
      <chainInfo.Icon size={IconSize.Bigger} />
    </div>
  );
});
