import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgVerified = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.355 2.084 8 0 1.645 2.084c-.479.158-.896.46-1.19.865A2.358 2.358 0 0 0 0 4.339v5.154c0 5.956 7.36 9.246 7.675 9.383l.283.124.295-.097C8.57 18.8 16 16.283 16 9.493V4.339c0-.5-.159-.986-.454-1.39a2.398 2.398 0 0 0-1.19-.865Zm-6 8.984a1.499 1.499 0 0 1-1.068.437h-.026a1.512 1.512 0 0 1-1.076-.475L4.34 9.13l1.154-1.094L7.29 9.89l4.144-4.102 1.132 1.12-4.21 4.16Z"
      fill="#9340F4"
    />
    <path
      d="m7.297 9.178 3.786-3.746.351-.348.352.348 1.131 1.12.36.355-.36.355-4.209 4.16a2.002 2.002 0 0 1-1.421.583m.01-2.827-.01 2.327m.01-2.327-1.444-1.49-.344-.355-.359.34-1.154 1.094-.367.349.352.363 1.842 1.897m1.474-2.198-1.474 2.197m1.464.63h-.001l.001-.5m0 .5v-.5m0 .5h-.034a2.008 2.008 0 0 1-.781-.17l.202-.458m.613.128h-.026a1.512 1.512 0 0 1-.587-.128m0 0-.202.457a1.997 1.997 0 0 1-.649-.458m.85 0a1.497 1.497 0 0 1-.488-.346l-.359.349-.003-.003M.5 4.339c0-.393.125-.776.358-1.096.233-.32.563-.559.943-.683L8 .526l6.2 2.034c.38.124.709.364.942.683.233.32.358.703.358 1.096v5.154c0 3.188-1.737 5.384-3.564 6.82-1.83 1.437-3.702 2.07-3.84 2.116l-.112.036-.108-.047h-.002c-.137-.06-2.006-.894-3.832-2.434C2.207 14.438.5 12.254.5 9.493V4.339Z"
      stroke="#fff"
      strokeOpacity={0.13}
    />
  </svg>
));
export default SvgVerified;
