/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';

export type GetVaultTransfersQueryVariables = Types.Exact<{
  input: Types.VaultTransfersInput;
}>;

export type GetVaultTransfersQuery = {
  __typename?: 'Query';
  vaultTransfers: {
    __typename?: 'VaultTransfers';
    numPages: number;
    numItems: number;
    items: Array<{
      __typename?: 'VaultTransfer';
      action: string;
      amount: BigNumber;
      account: string;
      transactionHash: string;
      createdAt: string;
    }>;
  };
};

export const GetVaultTransfersDocument = gql`
  query GetVaultTransfers($input: VaultTransfersInput!) {
    vaultTransfers(input: $input) {
      numPages
      numItems
      items {
        action
        amount
        account
        transactionHash
        createdAt
      }
    }
  }
`;

export function GetVaultTransfers<R extends GetVaultTransfersQuery, V extends GetVaultTransfersQueryVariables>(
  request: Request<R, V>,
  variables: V,
  headers?: HeadersInit
): Promise<R> {
  return request(GetVaultTransfersDocument, variables, headers);
}
