export namespace App {
  export const AdvancedModeActivationPhrase = 'godofui';

  export const AdvancedModeActivationPhraseSecond = 'ggg';

  export const AppInfoPollingInterval = 60 * 60 * 1000;

  export const AppInfoUrl = window.origin + '/info.json';

  export type AppInfo = {
    hash: string;
    timestamp: number;
  };

  export const isAppInfo = (x: unknown): x is AppInfo => {
    if (typeof x !== 'object' || x === null) {
      return false;
    }

    const { hash, timestamp } = x as AppInfo;

    return typeof hash === 'string' && typeof timestamp === 'number';
  };
}
