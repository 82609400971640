// Captures 0x + 4 characters, then the last 4 characters.
const truncateRegex = /^([a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

/**
 * Truncates address to the format 0x00…0000
 *
 * @param address Full address to truncate
 * @returns Truncated address
 */
export const formatShortAddress = (address: string) => {
  const match = address.match(truncateRegex);
  if (!match) {
    return address;
  }

  return `${match[1]}…${match[2]}`;
};
