import { useTheme } from 'styles/themes/use-theme.hook';

import { VaultActionInfo } from 'core/vault/vault-action.info';

import { Divider } from 'components/divider';

import { ShareCardBody } from './share-card.body';
import { ShareCardFooter } from './share-card.footer';

export type ShareCardProps = {
  data?: VaultActionInfo;
};

export const ShareCard = (props: ShareCardProps) => {
  const { brandLogoSrc, shareCardSrc } = useTheme();
  const { data } = props;

  return (
    <div
      className="w-[700px] h-[398px] relative overflow-hidden pt-10 px-10 pb-5  box-border flex flex-col justify-between"
      style={{
        backgroundImage: `url(${shareCardSrc})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <img src={brandLogoSrc} className="w-[111px]" />
      <ShareCardBody data={data} />
      <div>
        <Divider />
        <ShareCardFooter />
      </div>
    </div>
  );
};
