/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { DepositFragmentDoc } from '../deposit/deposit.fragment.generated';

export type WithdrawSubscriptionVariables = Types.Exact<{
  uid: Types.Scalars['String']['input'];
}>;

export type WithdrawSubscription = {
  __typename?: 'SubscriptionRoot';
  withdraw: {
    __typename?: 'WithdrawUnitType';
    deposit: {
      __typename?: 'DepositType';
      createdAt: string;
      depositor: string;
      entryPrice: BigNumber;
      pAndL: BigNumber;
      roi: BigNumber;
      shares: BigNumber;
      sharesValue: BigNumber;
      stake: BigNumber;
      depositedAmount: BigNumber;
    };
  };
};

export const WithdrawDocument = gql`
  subscription Withdraw($uid: String!) {
    withdraw(uid: $uid) {
      deposit {
        ...Deposit
      }
    }
  }
  ${DepositFragmentDoc}
`;

export function Withdraw<R extends WithdrawSubscription, V extends WithdrawSubscriptionVariables>(
  subscription: GetSubscription<R, V>,
  variables: V
): Observable<R> {
  return subscription(WithdrawDocument, variables);
}
