import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router';
import 'reflect-metadata';
import invariant from 'tiny-invariant';

import { isDev } from '../.webpack/parts/is-dev';
import { App } from './app';
import reportWebVitals from './report-web-vitals';
import './styles/index.css';

if (!isDev()) {
  Sentry.init({
    dsn: 'https://af2dc35a42ee8c23764b846146ee7d52@o4505619869794304.ingest.sentry.io/4505698017214464',

    integrations: [
      new Sentry.Replay(),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],

    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
  // eslint-disable-next-line @typescript-eslint/naming-convention
  posthog.init('phc_v3jtVXQ7l4T9NSAaRCSfqDK87WBM5FZ1pqJhthOagWH', { api_host: 'https://app.posthog.com' });
}

const rootEl = document.getElementById('root');

invariant(rootEl, 'Root element not found');

const root = createRoot(rootEl);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
