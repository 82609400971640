import { observer } from 'mobx-react-lite';
import { Key } from 'react';

import { VaultLoader } from 'components/loading';

import { Callback } from 'types';

import { TableColumn } from '../table';
import { TableItem } from './table-item';

type TableBodyProps<T> = {
  isLoading?: boolean;
  items: T[];
  columns: TableColumn<T>[];
  onRowClick?: Callback<T>;
  rowKey?: keyof T;
};

export const InfiniteTableBody = observer(<T,>(props: TableBodyProps<T>) => {
  const { isLoading, items, columns, onRowClick, rowKey = 'id' } = props;

  return (
    <tbody>
      {isLoading ? (
        <tr className="bg-transparent">
          <td colSpan={columns.length} className="border-none">
            <div className="absolute top-0 right-0 bottom-0 left-0 z-[2] flex justify-center pb-4">
              <VaultLoader width={68} height={28} />
            </div>
          </td>
        </tr>
      ) : (
        items.map((item, index) => (
          <TableItem
            item={item}
            key={(item[rowKey as keyof T] as Key) || index}
            columns={columns}
            onRowClick={onRowClick}
          />
        ))
      )}
    </tbody>
  );
});
