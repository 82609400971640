/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { RewardFragmentDoc } from './season-zero/reward.fragment.generated';

export type AccountFragment = {
  __typename?: 'AccountType';
  address: string;
  chain: string;
  avatar?: string | null;
  name?: string | null;
  twitter?: string | null;
  bio?: string | null;
  createdAt: string;
  slug: string;
  referrerCode: string;
  referrerProvider?: Types.ReferrerProvider | null;
  referralCode?: string | null;
  experience: number;
  experienceSpins: number;
  rewards: Array<{
    __typename?: 'RewardType';
    rewardType?: Types.RewardEnumType | null;
    createdAt: string;
    revealedAt?: string | null;
  }>;
};

export const AccountFragmentDoc = gql`
  fragment Account on AccountType {
    address
    chain
    avatar
    name
    twitter
    bio
    createdAt
    slug
    referrerCode
    referrerProvider
    referralCode
    experience
    experienceSpins
    rewards {
      ...Reward
    }
  }
  ${RewardFragmentDoc}
`;
