import BigNumber from 'bignumber.js';

import { Address } from 'core/address';
import { VaultTransfer } from 'core/api/schema';
import { ChainID } from 'core/chain';
import { Decimal } from 'core/decimal';

import { VaultTransferActionType, VaultTransferInfo } from './vault-transfer.info';

type VaultTransferInput = Pick<VaultTransfer, 'account' | 'action' | 'amount' | 'createdAt' | 'transactionHash'>;

export const mapVaultTransfersFromApi = (
  vaultTransfers: VaultTransferInput[],
  isManager: (address: Address) => boolean,
  chainId: ChainID
): VaultTransferInfo[] =>
  vaultTransfers.map((item) => {
    const amount = new BigNumber(item.amount);

    return {
      isManager: isManager(item.account),
      account: item.account,
      amount: Decimal.precise(amount),
      transactionHash: item.transactionHash,
      action: item.action === 'DEPOSIT' ? VaultTransferActionType.Deposit : VaultTransferActionType.Withdraw,
      createdAt: new Date(item.createdAt),
      chainId: chainId,
    };
  });
