import { Placement, State } from '@popperjs/core';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { MutableRefObject, ReactNode, Ref, memo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modifier, usePopper } from 'react-popper';
import { twMerge } from 'tailwind-merge';

import { useOffsetModifier } from './use-offset-modifier.hook';
import { getPortalElement } from './use-portal-element.hook';

export type PopoverProps = {
  isOpen?: boolean;
  placement?: Placement;
  anchorEl?: Element | null;
  indent?: number | [number, number];
  popperEl?: Ref<HTMLDivElement>;
  children: (state: State | null) => ReactNode;
  animation?: Variants;
  modifiers?: readonly Modifier<unknown>[];
  pointerEvents?: boolean;
  zIndex?: number;
};

const portalElement = getPortalElement('popper');

export const Popover = memo((props: PopoverProps) => {
  const {
    isOpen,
    placement,
    children,
    anchorEl,
    indent,
    popperEl,
    animation = animationVariants,
    modifiers = [],
    pointerEvents,
    zIndex,
  } = props;
  const [popperElRef, setPopperElRef] = useState<HTMLDivElement | null>(null);
  const offsetModifier = useOffsetModifier(indent);
  const { attributes, styles, state } = usePopper(anchorEl, popperElRef, {
    placement,
    modifiers: [offsetModifier, ...modifiers],
  });

  const containerRef = (el: HTMLDivElement) => {
    setPopperElRef(el);
    if (popperEl) {
      if (typeof popperEl === 'function') {
        popperEl(el);
      }
      if (typeof popperEl === 'object') {
        (popperEl as MutableRefObject<HTMLDivElement | null>).current = el;
      }
    }
  };

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          {...attributes.popper}
          style={{ ...styles.popper, zIndex }}
          ref={containerRef}
          className={twMerge('z-[11]', pointerEvents === false && 'pointer-events-none')}
        >
          {state?.placement ? (
            <motion.div
              variants={animation}
              initial={getPlacementVariant(state?.placement)}
              animate={{
                opacity: 1,
                y: 0,
                x: 0,
              }}
              exit={getPlacementVariant(state?.placement)}
              transition={{ duration: 0.2 }}
            >
              {children(state)}
            </motion.div>
          ) : (
            children(state)
          )}
        </motion.div>
      )}
    </AnimatePresence>,
    portalElement
  );
});

const getPlacementVariant = (placement?: Placement): string => {
  switch (placement) {
    case 'top':
      return 'top';
    case 'bottom':
      return 'bottom';
    case 'right':
      return 'right';
    case 'left':
      return 'left';

    default:
      return 'bottom';
  }
};

export const animationVariants: Variants = {
  top: {
    opacity: 0,
    y: -5,
  },
  bottom: {
    opacity: 0,
    y: 5,
  },
  right: {
    opacity: 0,
    x: 5,
  },
  left: {
    opacity: 0,
    x: -5,
  },
};
