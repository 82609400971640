import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgProfile = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 13 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.5 0c1.995 0 3.611 1.567 3.611 3.5S8.495 7 6.501 7C4.505 7 2.888 5.433 2.888 3.5S4.506 0 6.5 0Zm0 7.7C2.916 7.7 0 10.212 0 13.3c0 .387.323.7.722.7h11.556c.399 0 .722-.313.722-.7 0-3.088-2.916-5.6-6.5-5.6Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgProfile;
