/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';

export type GetVaultAumQueryVariables = Types.Exact<{
  uid: Types.Scalars['String']['input'];
  dateRange: Types.ChartDatasetRange;
}>;

export type GetVaultAumQuery = {
  __typename?: 'Query';
  aumDataset: Array<{ __typename?: 'AumDataset'; key: string; value: BigNumber }>;
};

export const GetVaultAumDocument = gql`
  query GetVaultAum($uid: String!, $dateRange: ChartDatasetRange!) {
    aumDataset(uid: $uid, dateRange: $dateRange) {
      key
      value
    }
  }
`;

export function GetVaultAum<R extends GetVaultAumQuery, V extends GetVaultAumQueryVariables>(
  request: Request<R, V>,
  variables: V,
  headers?: HeadersInit
): Promise<R> {
  return request(GetVaultAumDocument, variables, headers);
}
