/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { VaultChainFragmentDoc } from '../chain/vault-chain.fragment.generated';

export type VaultListFragment = {
  __typename?: 'VaultType';
  uid: string;
  name: string;
  symbol: string;
  slug: string;
  cover?: string | null;
  avatar?: string | null;
  riskProfile: Types.RiskProfile;
  marketCapSegment?: Array<number> | null;
  managerSentiment?: Types.ManagerSentiment | null;
  shortDescription?: string | null;
  managerParticipation?: Types.ManagerParticipation | null;
  tags?: Array<Types.VaultTag> | null;
  managerFocusTime?: Types.ManagerFocusTime | null;
  updatedAt: string;
  createdAt: string;
  chains: Array<{
    __typename?: 'VaultChainType';
    chainId: number;
    name: string;
    address: string;
    isParent: boolean;
    aum: BigNumber;
  }>;
  manager: {
    __typename?: 'ManagerType';
    deposit?: { __typename?: 'DepositType'; stake: BigNumber } | null;
    account: {
      __typename?: 'AccountType';
      name?: string | null;
      address: string;
      twitter?: string | null;
      avatar?: string | null;
    };
  };
  stats: {
    __typename?: 'VaultStatsType';
    aum: BigNumber;
    depositors: BigNumber;
    roiWeek?: BigNumber | null;
    roiDay?: BigNumber | null;
    roiTotal?: BigNumber | null;
  };
};

export const VaultListFragmentDoc = gql`
  fragment VaultList on VaultType {
    uid
    name
    symbol
    slug
    cover
    avatar
    riskProfile
    marketCapSegment
    managerSentiment
    shortDescription
    managerParticipation
    tags
    chains {
      ...VaultChain
    }
    managerFocusTime
    updatedAt
    createdAt
    manager {
      deposit {
        stake
      }
      account {
        name
        address
        twitter
        avatar
      }
    }
    stats {
      roiWeek: roi(dateRange: ONE_WEEK)
      roiDay: roi(dateRange: ONE_DAY)
      roiTotal: roi(dateRange: ALL)
      aum
      depositors
    }
  }
  ${VaultChainFragmentDoc}
`;
