import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgRefresh = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.515 6.17h5.17V4.172H3.942A6.97 6.97 0 0 1 9 2a7 7 0 0 1 7 7h2a9 9 0 0 0-9-9 8.963 8.963 0 0 0-6.484 2.77L2.515 0h-2v5.17c0 .553.448 1 1 1ZM16.484 11.83h-5.17v1.998h2.745A6.967 6.967 0 0 1 9 16a7 7 0 0 1-7-7H0a9 9 0 0 0 9 9 8.966 8.966 0 0 0 6.484-2.77V18h2v-5.17a1 1 0 0 0-1-1Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgRefresh;
