import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgSpeedometerHigh = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 35 31" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.475 0A17.38 17.38 0 0 0 5.857 4.4l4.575 4.575a10.967 10.967 0 0 1 6.043-2.503V0ZM8.982 10.425 4.406 5.85A17.378 17.378 0 0 0 .001 16.468h6.472a10.968 10.968 0 0 1 2.509-6.043Zm26.017 6.043h-6.472a10.969 10.969 0 0 0-2.509-6.043l4.576-4.575a17.378 17.378 0 0 1 4.405 10.618Zm-6.473 2.05a10.923 10.923 0 0 1-1.128 3.954c.52.802.954 1.48 1.24 1.93.78 1.225.607 2.799-.42 3.826-.25.25-.534.45-.843.6l1.066 1.065a1.024 1.024 0 0 0 1.452 0A17.4 17.4 0 0 0 35 18.519h-6.474ZM0 18.519h6.474A11.007 11.007 0 0 0 9.67 25.33a1.027 1.027 0 0 1 0 1.453l-3.11 3.111a1.024 1.024 0 0 1-1.453 0A17.4 17.4 0 0 1 0 18.519ZM29.143 4.4A17.38 17.38 0 0 0 18.525 0v6.472c2.23.204 4.32 1.07 6.043 2.503L29.143 4.4ZM15.222 15.232a3.226 3.226 0 0 1 4.556 0c1.587 1.587 6.567 9.387 7.13 10.27a1.025 1.025 0 0 1-1.416 1.416c-.884-.562-8.683-5.542-10.27-7.13a3.226 3.226 0 0 1 0-4.556Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgSpeedometerHigh;
