import { RiskProfile } from 'core/api/schema';

export const riskProfileToContractMap = (data: RiskProfile): number => {
  switch (data) {
    case RiskProfile.Low:
      return 0;
    case RiskProfile.Medium:
      return 1;
    case RiskProfile.High:
      return 2;

    default:
      return data;
  }
};
