import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

export const Primary = ({ className, ...rest }: ComponentProps<'div'>) => (
  <div className={twMerge('typo-primary', className)} {...rest} />
);

export const Secondary = ({ className, ...rest }: ComponentProps<'div'>) => (
  <div className={twMerge('typo-secondary', 'transition-colors duration-200', className)} {...rest} />
);

export const H1 = ({ className, ...rest }: ComponentProps<'h1'>) => (
  <h1 className={twMerge('text-primary text-3xl font-stratos my-2.5', className)} {...rest} />
);

export const H2 = ({ className, ...rest }: ComponentProps<'h2'>) => (
  <h2 className={twMerge('text-primary text-lg font-stratos my-2.5', className)} {...rest} />
);

export const H3 = ({ className, ...rest }: ComponentProps<'h3'>) => (
  <h3 className={twMerge('text-secondary text-base font-graphik font-normal my-2.5', className)} {...rest} />
);

export const Text5xl = ({ className, ...rest }: ComponentProps<'div'>) => (
  <div className={twMerge('text-primary text-5xl font-stratos font-medium', className)} {...rest} />
);
