import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgTimer = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.334 0a.741.741 0 0 0-.506.22L.226 2.787a.721.721 0 0 0-.01 1.037.74.74 0 0 0 1.05-.01L3.87 1.246a.72.72 0 0 0 0-1.041A.742.742 0 0 0 3.333 0Zm10.31 0a.741.741 0 0 0-.522.215.72.72 0 0 0 .01 1.031l2.601 2.567a.74.74 0 0 0 1.051.011.72.72 0 0 0-.01-1.037L14.172.22a.742.742 0 0 0-.527-.22ZM8.5.735a7.394 7.394 0 0 0-5.208 2.122 7.202 7.202 0 0 0 .001 10.27c.25.247.519.464.794.668l-.64 1.091a.717.717 0 0 0 .254 1.017.745.745 0 0 0 1.02-.291l.625-1.066a7.49 7.49 0 0 0 6.307 0l.626 1.066a.745.745 0 0 0 1.02.29.718.718 0 0 0 .254-1.016l-.64-1.091c.275-.204.544-.421.794-.668a7.202 7.202 0 0 0 0-10.27A7.396 7.396 0 0 0 8.5.735Zm0 1.441c1.506 0 3.012.57 4.165 1.707a5.743 5.743 0 0 1 0 8.218 5.94 5.94 0 0 1-8.332 0 5.744 5.744 0 0 1 0-8.218A5.917 5.917 0 0 1 8.5 2.176Zm0 .736v5.08H3.35a5.03 5.03 0 0 0 .867 2.821 5.136 5.136 0 0 0 2.312 1.872 5.218 5.218 0 0 0 2.976.288c1-.196 1.917-.68 2.637-1.39a5.059 5.059 0 0 0 1.41-2.6 5.015 5.015 0 0 0-.293-2.935 5.096 5.096 0 0 0-1.897-2.28A5.202 5.202 0 0 0 8.5 2.912Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgTimer;
