import { CSSProperties } from 'react';

import {
  CrosedClockNeonBlueIcon,
  DiscordIcon,
  GitbookIcon,
  PulseNeonBlueIcon,
  SentimentalCursorBlueIcon,
  UserWithCursorBlueNeonIcon,
  XIcon,
} from 'components/icons';

import { IconSize } from '../../../components/svg-icon';
import type { DefaultTheme } from '../default';
import anodosAvatar from './anodos-avatar.png';
import anodosFavicon from './anodos-favicon.png';
import anodosLogoSrc from './anodos-logo.png';
import brandLogoSrc from './anodos-logo.png';
import shareCardSrc from './anodos-share-card.png';
import slideOne from './anodos-slide-one.png';
import slideTwo from './anodos-slide-two.png';
import './anodos.css';

export const AnodosTheme: DefaultTheme = {
  name: 'anodos',
  appName: 'Anodos',
  connectButtonText: 'Sign in',
  connectButtonEmptyText: 'Sign in',
  logo: (
    <div className="flex items-center justify-center">
      <img src={anodosLogoSrc} alt="Anodos" className="w-[100px]" />
    </div>
  ),

  brandLogoSrc: brandLogoSrc,
  walletLogoSrc: anodosAvatar,
  shareCardSrc: shareCardSrc,
  sentimentalCursorIcon: (style: CSSProperties) => (
    <SentimentalCursorBlueIcon
      className="h-[189px] w-[54px] absolute bottom-[38px] left-[0] -m-[27px] text-brand-600"
      style={style}
    />
  ),
  meta: {
    title: 'AnoVault - Effortless Crypto Investing',
    description:
      'A seamless, multichain investment experience, ensuring transparency, security, and efficiency. Dive into the world of Web3 and DeFi with confidence and ease.',
    icon: anodosFavicon,
  },
  features: {
    szn0: false,
    szn1: false,
    manage: false,
    shareCard: false,
    socialLinks: false,
    allocationVideo: false,
    walletBalanceImg: false,
    dayPerformingVaults: false,
    footerLinks: true,
  },
  errorPage: {
    icon: <img src={anodosLogoSrc} alt="Anodos" className="w-[250px]" />,
    title: 'Oops! Something went wrong.',
    subtitle: 'Reload the page, and let us know if problems still persist.',
    contactIcon: <DiscordIcon />,
    contactUrl: 'https://dsc.gg/anodos',
    contactText: 'Go to Discord',
  },
  tutorial: {
    slideOneSrc: slideOne,
    slideOneText:
      'This is your self-custodial Anodos wallet for EVM-compatible blockchains. This is your public wallet address - you will need it to top up your balance.',
    slideTwoSrc: slideTwo,
  },
  myPositionsNullState: {
    title: 'Invest in crypto in 3 easy steps',
    subtitle:
      'Anodos is your decentralized investment partner. We take care of everything, while you still keep full control.',
    items: [
      {
        icon: <UserWithCursorBlueNeonIcon className="text-[90px]" />,
        title: 'Select portfolio to invest',
      },
      {
        icon: <PulseNeonBlueIcon className="text-[90px]" />,
        title: 'Deposit and monitor',
      },
      {
        icon: <CrosedClockNeonBlueIcon className="text-[90px]" />,
        title: 'Withdraw any time. No lockups',
      },
    ],
  },
  editProfile: {
    subtitle: 'Your profile will only be visible to you',
    placeholder: 'Add a description for your account',
  },
  aumNaming: 'TVL',
  socials: [
    {
      url: 'https://x.com/AnodosFinance',
      icon: <XIcon className="hover:text-primary text-white/30" />,
    },
    {
      url: 'https://dsc.gg/anodos',
      icon: <DiscordIcon className="hover:text-primary text-white/30" />,
    },
    {
      url: 'https://docs.anodos.finance/',
      icon: <GitbookIcon size={IconSize.Large} className="hover:text-primary text-white/30" />,
    },
  ],
};
