import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import { Subject, delay } from 'rxjs';

import { AccountStore } from 'core/account/account.store';
import { ApiService } from 'core/api/api.service';
import { SpinExperience } from 'core/api/gql/account/spin-experience.gql.generated';
import { SnackbarStore } from 'core/snackbars/snackbar.store';

import { Disposable } from 'utils/disposable';

import { Reveal } from './reveal';

@injectable()
export class RevealStore extends Disposable {
  @observable
  boxes: Reveal.Box[] = Reveal.initialBoxes;

  @observable
  currentPosition = 5;

  @observable
  isRevealing = false;

  @observable
  isOpening = false;

  private openBox$ = new Subject<Reveal.Box>();

  constructor(
    private readonly apiService: ApiService,
    private readonly snackbarStore: SnackbarStore,
    private readonly accountStore: AccountStore
  ) {
    super();

    makeObservable(this);

    this.autoDispose(
      this.openBox$.pipe(delay(Reveal.ANIMATION_DURATION * 1000)).subscribe(() => {
        this.setIsOpening(false);
        this.setIsRevealing(false);
      })
    );

    // this.autoDispose(
    //   this.openBox$
    //     .pipe(
    //       filter(() => !this.availableBoxes),
    //       delay(Reveal.ANIMATION_DURATION * 1000),
    //       map(() => this.modalsStore),
    //       repeat()
    //     )
    //     .subscribe(() => this.modalsManager.openRevealModal())
    // );
  }

  get avaliableSpins(): number {
    return this.accountStore.account?.experienceSpins ?? 0;
  }

  @action
  clear = (): void => {
    this.boxes = Reveal.initialBoxes;
    this.currentPosition = 5;
  };

  @action
  private setIsOpening = (value: boolean): void => {
    this.isOpening = value;
  };

  @action
  private setIsRevealing = (value: boolean): void => {
    this.isRevealing = value;
  };

  @action
  private updateCurrentPosition = (): void => {
    this.currentPosition = this.currentPosition + Reveal.STEP;
  };

  @action
  private updateBoxes = (boxes: Reveal.Box[]): void => {
    this.boxes.push(...boxes);
  };

  selectWinnerBox = async (): Promise<void> => {
    const beforeWinnerBoxes = Reveal.generateNewBoxes(14);
    const afterWinnerBoxes = Reveal.generateNewBoxes(4);
    this.setIsOpening(true);

    try {
      const { spinExperience } = await this.apiService.query(SpinExperience);

      if (spinExperience) {
        const winnerBox = Reveal.getBoxByType(spinExperience);
        this.setIsRevealing(true);

        this.updateCurrentPosition();
        this.updateBoxes([...beforeWinnerBoxes, winnerBox, ...afterWinnerBoxes]);

        this.openBox$.next(winnerBox);
      }
    } catch (error) {
      this.snackbarStore.openFailed('Failed to reveal', error);
      this.setIsOpening(false);
      this.setIsRevealing(false);
    }
  };
}
