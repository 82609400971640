import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgGitbook = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 21 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.452 12.505a.62.62 0 0 1 .567.377.606.606 0 0 1-.334.797.619.619 0 0 1-.67-.133.607.607 0 0 1 .437-1.04Zm9.646-3.77a.62.62 0 0 1-.568-.378.606.606 0 0 1 .333-.796.62.62 0 0 1 .67.133.607.607 0 0 1-.435 1.04Zm0-2.495c-.505 0-.988.199-1.345.552a1.862 1.862 0 0 0-.457 1.932l-6.283 3.317a1.89 1.89 0 0 0-1.561-.813c-.726 0-1.386.412-1.707 1.055L2.101 9.332c-.597-.31-1.043-1.284-.996-2.17.024-.463.185-.822.431-.96.156-.087.343-.08.543.023l.036.02c1.497.78 6.392 3.337 6.598 3.431.318.147.494.206 1.037-.05L19.869 4.41c.148-.056.322-.197.322-.411 0-.297-.31-.414-.311-.414-.576-.273-1.46-.683-2.323-1.084-1.845-.856-3.935-1.826-4.853-2.303-.793-.412-1.43-.065-1.544.005l-.22.108C6.806 2.337 1.276 5.051.961 5.241.4 5.583.05 6.26.005 7.1-.065 8.433.62 9.824 1.6 10.333l5.97 3.052c.065.448.291.858.637 1.155a1.913 1.913 0 0 0 2.577-.083c.355-.347.56-.819.568-1.313l6.575-3.534a1.91 1.91 0 0 0 2.514-.153A1.88 1.88 0 0 0 21 8.124c0-.5-.201-.979-.558-1.333a1.912 1.912 0 0 0-1.344-.552"
      fill="currentColor"
    />
  </svg>
));
export default SvgGitbook;
