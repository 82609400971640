import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgMenu = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 .8A.8.8 0 0 1 .8 0h10.4a.8.8 0 0 1 0 1.6H.8A.8.8 0 0 1 0 .8ZM0 5a.8.8 0 0 1 .8-.8h10.4a.8.8 0 0 1 0 1.6H.8A.8.8 0 0 1 0 5Zm.8 3.4a.8.8 0 1 0 0 1.6h10.4a.8.8 0 0 0 0-1.6H.8Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgMenu;
