/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { AccountFragmentDoc } from './account.fragment.generated';

export type AccountQueryVariables = Types.Exact<{
  address: Types.Scalars['String']['input'];
}>;

export type AccountQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'AccountType';
    address: string;
    chain: string;
    avatar?: string | null;
    name?: string | null;
    twitter?: string | null;
    bio?: string | null;
    createdAt: string;
    slug: string;
    referrerCode: string;
    referrerProvider?: Types.ReferrerProvider | null;
    referralCode?: string | null;
    experience: number;
    experienceSpins: number;
    rewards: Array<{
      __typename?: 'RewardType';
      rewardType?: Types.RewardEnumType | null;
      createdAt: string;
      revealedAt?: string | null;
    }>;
  };
};

export const AccountDocument = gql`
  query account($address: String!) {
    account(address: $address) {
      ...Account
    }
  }
  ${AccountFragmentDoc}
`;

export function account<R extends AccountQuery, V extends AccountQueryVariables>(
  request: Request<R, V>,
  variables: V,
  headers?: HeadersInit
): Promise<R> {
  return request(AccountDocument, variables, headers);
}
