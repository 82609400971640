import { ComponentPropsWithoutRef } from 'react';
import { twMerge } from 'tailwind-merge';

import avatarPlaceholder from 'assets/img/default-avatar.png';

import { AvatarSize } from './avatar-size.enum';

export type AvatarProps = ComponentPropsWithoutRef<'div'> & {
  size?: AvatarSize;
  src?: string | null | undefined;
  stroke?: boolean;
  minify?: boolean;
};

export const Avatar = (props: AvatarProps) => {
  const { src, stroke, size = AvatarSize.Medium, minify, className, ...rest } = props;

  return (
    <div
      {...rest}
      className={twMerge(
        'rounded-full overflow-hidden bg-cover bg-center bg-black border border-white/20 transition-all duration-300',
        stroke && 'border-white',
        size === AvatarSize.Small && 'w-4 h-4',
        size === AvatarSize.Medium && 'w-9 h-9',
        size === AvatarSize.Vault && 'w-[52px] h-[52px] m-[-5px]',
        size === AvatarSize.Big && 'w-[72px] h-[72px]',
        size !== AvatarSize.Small && minify && 'w-[31px] h-[31px]',
        className
      )}
      style={{
        backgroundImage: `url(${src || avatarPlaceholder})`,
      }}
    />
  );
};
