import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgSearch = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m13.836 13.012-3.479-3.479a5.837 5.837 0 1 0-.824.824l3.48 3.48a.583.583 0 0 0 .823-.825ZM5.847 10.51a4.663 4.663 0 1 1 0-9.325 4.663 4.663 0 0 1 0 9.325Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgSearch;
