import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgPause = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 31" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#pause_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0a2 2 0 0 0-2 2v25a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H3Zm11 0a2 2 0 0 0-2 2v25a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-3Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="pause_svg__a"
        x={0}
        y={0}
        width={20}
        height={31}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_6691_23986" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_6691_23986" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgPause;
