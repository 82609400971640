import { Values } from '@mobx-form-state/react';
import BigNumber from 'bignumber.js';
import { injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { Observable, ReplaySubject, catchError, filter, map, merge, switchMap } from 'rxjs';
import invariant from 'tiny-invariant';

import { ApiService } from 'core/api/api.service';
import { UPLOAD_IMAGE } from 'core/api/gql/account/upload-image.gql';
import { GetVault } from 'core/api/gql/vault/get-vault.gql.generated';
import { UpdateVault } from 'core/api/gql/vault/update-vault.gql.generated';
import { VaultCreated } from 'core/api/gql/vault/vault-created.gql.generated';
import { VaultDeleted } from 'core/api/gql/vault/vault-deleted.gql.generated';
import { VaultUpdated } from 'core/api/gql/vault/vault-updated.gql.generated';
import { VaultFragment } from 'core/api/gql/vault/vault.fragment.generated';
import { EntityType, RiskProfile, UpdateVaultInput } from 'core/api/schema';
import { Asset } from 'core/asset';
import { AssetsStore } from 'core/asset/assets.store';
import { ChainID, MainChainID } from 'core/chain';
import { ChainService } from 'core/chain/chain.service';
import { mapVaultChainsFromApi } from 'core/chain/vault-chain.map';
import { Decimal } from 'core/decimal';
import { logger } from 'core/logger';
import { NetworkService } from 'core/network/network.service';
import { NetworkStore } from 'core/network/network.store';
import { SnackbarStore } from 'core/snackbars/snackbar.store';
import { TransactionSnackbarStore } from 'core/snackbars/transactions/transaction-snackbar.store';
import { riskProfileToContractMap } from 'core/valio/risk-profile.map';
import { Valio } from 'core/valio/valio';
import { ValioRegistry } from 'core/valio/valio.registry';
import { WalletStore } from 'core/wallet/wallet.store';

import { ModalsStore } from 'lib/modals/modals.store';

import { Disposable } from 'utils/disposable';
import { isSomething } from 'utils/is-something';
import { polling } from 'utils/polling';

import { Units } from 'types';

import { VaultContract } from '../valio/vault.contract';
import { ActiveVaultStore } from './active-vault.store';
import { VaultModel } from './vault.model';
import { Vaults } from './vaults';

export type VaultLike = {
  slug: VaultUUID;
};

export type VaultUUID = string;

@injectable()
export class VaultStore extends Disposable {
  @observable
  valueCap = new BigNumber(0);

  @observable.ref
  activeVault?: ActiveVaultStore;

  @observable
  private cpitPerRiskProfileMap = new Map<RiskProfile, number>();

  @observable
  private vaultsMap = new Map<VaultUUID, VaultFragment>();

  readonly vaultCreated$: Observable<VaultFragment>;

  readonly vaultUpdated$: Observable<VaultFragment>;

  readonly vaultDeleted$: Observable<VaultFragment>;

  readonly vault$: Observable<ActiveVaultStore | undefined>;

  private readonly vault$$ = new ReplaySubject<ActiveVaultStore | undefined>(1);

  constructor(
    private readonly apiService: ApiService,
    private readonly vaultContract: VaultContract,
    private readonly walletStore: WalletStore,
    private readonly chainService: ChainService,
    private readonly networkStore: NetworkStore,
    private readonly transactionSnackbarStore: TransactionSnackbarStore,
    private readonly snackbarsStore: SnackbarStore,
    private readonly assetsStore: AssetsStore,
    private readonly modalsStore: ModalsStore,
    private readonly networkService: NetworkService
  ) {
    super();
    makeObservable(this);

    this.vaultCreated$ = this.apiService.subscription(VaultCreated).pipe(map((result) => result.vaultCreated));

    this.vaultUpdated$ = this.apiService.subscription(VaultUpdated).pipe(map((result) => result.vaultUpdated));

    this.vaultDeleted$ = this.apiService.subscription(VaultDeleted).pipe(map((result) => result.vaultDeleted));

    this.vault$ = this.vault$$.asObservable();

    const valueCap$ = polling(Vaults.StatsPollingInterval).pipe(
      switchMap(() => this.vault$$),
      filter(isSomething),
      switchMap(async (vault) => {
        const registry = this.networkStore.networks[ChainID.Arbitrum].get(ValioRegistry).registry;

        const customValue = await registry.customVaultValueCap(vault.parentAddress);

        return customValue.isZero() ? await registry.vaultValueCap() : customValue;
      }),
      map((value) => new BigNumber(value.toString()).div(10 ** Valio.VaultPrecision)),
      catchError(() => {
        logger.error('Failed to fetch value cap');

        return [new BigNumber(0)];
      })
    );

    this.autoDispose(valueCap$.subscribe(this.setValueCap));
    this.autoDispose(merge(this.vaultCreated$, this.vaultUpdated$, this.vaultDeleted$).subscribe(this.setVault));

    this.initCpit();
  }

  @computed
  get vault(): VaultFragment | undefined {
    return this.activeVault?.vault;
  }

  @action
  private setValueCap = (valueCap: BigNumber): void => {
    this.valueCap = valueCap;
  };

  @action
  private setRiskProfileCpit = (cpitMap: Map<RiskProfile, number>): void => {
    this.cpitPerRiskProfileMap = cpitMap;
  };

  @action
  private setVault = (vault: VaultFragment): void => {
    this.vaultsMap.set(vault.slug, vault);

    logger.debug('Updated vault', vault.slug);
  };

  @action
  private setActiveVault = (vault?: ActiveVaultStore): void => {
    this.activeVault = vault;
  };

  selectVault = async (slug?: VaultUUID): Promise<void> => {
    if (!slug) {
      this.activeVault?.dispose();
      this.vault$$.next(undefined);
      this.setActiveVault(undefined);

      return;
    }

    if (this.activeVault?.vault?.slug === slug) return;

    let vault = this.vaultsMap.get(slug);

    if (!vault) {
      const result = await this.apiService.query(GetVault, { slug });

      this.setVault(result.vaultBySlug);
      vault = result.vaultBySlug;
    }

    const activeVault = new ActiveVaultStore(
      vault,
      this,
      this.apiService,
      this.walletStore,
      this.vaultContract,
      this.chainService,
      this.networkStore,
      this.transactionSnackbarStore,
      this.assetsStore,
      this.modalsStore,
      this.snackbarsStore,
      this.networkService
    );

    await activeVault.init();

    this.activeVault?.dispose();

    this.vault$$.next(activeVault);

    this.setActiveVault(activeVault);

    logger.debug('Selected vault', vault);
  };

  getRiskProfileCpit = (riskProfile: RiskProfile): number => {
    return this.cpitPerRiskProfileMap.get(riskProfile) ?? 0;
  };

  updateVault = async (data: Values.FromModel<VaultModel>): Promise<void> => {
    const vault = this.vault;

    invariant(vault, 'Vault is not selected');

    const input: UpdateVaultInput = {
      uid: vault.uid,
      shortDescription: data.shortDescription,
      fullDescription: data.fullDescription,
      managerFocusTime: data.managerFocusTime,
      managerParticipation: data.managerParticipation,
      managerSentiment: data.managerSentiment,
      marketCapSegment: data.marketcapSegment,
      managerStrategy: data.managerStrategy,
      assetWatchlist: data.assetWatchlist.map((item) => item.address),
      tags: data.tags,
    };

    await this.apiService.query(UpdateVault, { input });
  };

  updateImage = async (entityId: string, entityType: EntityType, file?: File): Promise<void> => {
    if (!file) return;

    const data = {
      input: {
        entityId,
        entityType,
      },
      file,
    };

    await this.apiService.upload(UPLOAD_IMAGE, data);
  };

  readonly getUnitPrice = async (
    vault: VaultFragment
  ): Promise<{ minUnitPrice: Units; maxUnitPrice: Units }> => {
    const vaultChains = mapVaultChainsFromApi(vault.chains);

    const parentChain = vaultChains.find((chain) => chain.isParent);

    invariant(parentChain, 'Parent chain is not defined');

    const totalShares = await this.networkStore.networks[MainChainID].get(VaultContract)
      .getVaultParentContract(parentChain.address)
      .totalShares();

    if (totalShares.isZero()) {
      return {
        minUnitPrice: Asset.toUnits(new BigNumber(1), Decimal.Precision.USD),
        maxUnitPrice: Asset.toUnits(new BigNumber(1), Decimal.Precision.USD),
      };
    }

    const result = await Promise.all(
      vaultChains.map((chain) => {
        const container = this.networkStore.networks[chain.chainId];

        const vaultContract = container.get(VaultContract);

        const vault = vaultContract.getVaultBaseContract(chain.address);

        return vault.getVaultValue();
      })
    );

    let totalMinValue = new BigNumber(0);
    let totalMaxValue = new BigNumber(0);

    result.forEach((value) => {
      if (!value.hasHardDeprecatedAsset) {
        totalMinValue = totalMinValue.plus(value.minValue.toString());
        totalMaxValue = totalMaxValue.plus(value.maxValue.toString());
      }
    });

    const minUnitPrice = totalMinValue.div(totalShares.toString());
    const maxUnitPrice = totalMaxValue.div(totalShares.toString());

    return {
      minUnitPrice: Asset.toUnits(minUnitPrice, Decimal.Precision.USD),
      maxUnitPrice: Asset.toUnits(maxUnitPrice, Decimal.Precision.USD),
    };
  };

  private initCpit = async (): Promise<void> => {
    const riskProfiles = [RiskProfile.Low, RiskProfile.Medium, RiskProfile.High];

    const result = await Promise.all(
      riskProfiles.map((riskProfile) =>
        this.networkStore.networks[MainChainID].get(ValioRegistry)
          .registry.maxCpitBips(riskProfileToContractMap(riskProfile))
          .then((cpit) => [riskProfile, cpit.toNumber() / 100] as const)
      )
    );

    this.setRiskProfileCpit(new Map(result.map(([riskProfile, cpit]) => [riskProfile, cpit])));
  };
}
