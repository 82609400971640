import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgSmallGraph = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 6 11" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0a1 1 0 0 0-1 1v9a1 1 0 1 0 2 0V1a1 1 0 0 0-1-1ZM1 3a1 1 0 0 0-1 1v6a1 1 0 1 0 2 0V4a1 1 0 0 0-1-1Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgSmallGraph;
