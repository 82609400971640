import {
  ComponentProps,
  FocusEventHandler,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  forwardRef,
  memo,
} from 'react';
import { twMerge } from 'tailwind-merge';

import { TextFieldSize } from '.';

export type TextFieldBaseProps = PropsWithChildren<{
  fullWidth?: boolean;
  minWidth?: string;
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  active?: boolean;
  hoverEffect?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  onFocus?: FocusEventHandler<HTMLDivElement>;
  size?: TextFieldSize;
  error?: boolean;
  disabled?: boolean;
}>;

export const TextFieldBase = memo(
  forwardRef<HTMLDivElement, TextFieldBaseProps>((props, ref) => {
    const { endIcon, startIcon, children, size, error, active, hoverEffect = true, className, ...rest } = props;

    return (
      <div
        className={twMerge(
          'text-field-base-root relative flex min-h-[41px] border border-outline rounded-[10px] box-border transition-colors bg-transparent',
          rest.disabled && 'pointer-events-none',
          rest.fullWidth && 'w-full',
          !error && active && 'border-brand-600',
          hoverEffect && !error && 'hover:border-brand-600 hover:[.text-field_icon-wrap>svg]:color-brand-600',
          size === TextFieldSize.Large && 'h-[55px]',
          error && 'border-negative bg-negative/10 hover:border-negative hover:bg-negative/10',
          className
        )}
        {...rest}
        ref={ref}
      >
        {startIcon && (
          <IconWrap className={twMerge('pl-[14px]', !error && active && '[&_svg]:text-brand-600')}>
            {startIcon}
          </IconWrap>
        )}
        {children}
        {endIcon && (
          <IconWrap
            className={twMerge(
              'text-field_end-icon pr-[14px] [&_.chip-root]:-mr-2.5',
              !error && active && '[&_svg]:text-brand-600',
              size === TextFieldSize.Large && '[&_.chip-root]:-mr-1.5'
            )}
          >
            {endIcon}
          </IconWrap>
        )}
      </div>
    );
  })
);

const IconWrap = ({ className, ...props }: ComponentProps<'div'>) => (
  <div className={twMerge('text-field_icon-wrap flex items-center text-sm text-secondary', className)} {...props} />
);
