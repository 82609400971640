import { ExternalProvider, JsonRpcSigner, Network, Web3Provider } from '@ethersproject/providers';
import { defineReadOnly } from 'ethers/lib/utils';
import { interfaces } from 'inversify';
import { BehaviorSubject, Observable } from 'rxjs';
import invariant from 'tiny-invariant';
import { PublicClient, createPublicClient, webSocket } from 'viem';

import { Alchemy } from 'core/alchemy/alchemy';
import { ChainID, chains } from 'core/chain';
import { ConfigService } from 'core/config/config.service';
import { logger } from 'core/logger';

import { BaseNetworkProvider } from './base-network.provider';
import { NetworkProvider } from './network.provider';

class StaticWeb3Provider extends Web3Provider {
  async detectNetwork(): Promise<Network> {
    let network = this.network;
    if (network == null) {
      network = await super.detectNetwork();

      // If still not set, set it
      if (this._network == null) {
        // A static network does not support "any"
        defineReadOnly(this, '_network', network);

        this.emit('network', network, null);
      }
    }

    return network;
  }
}

export class StaticNetworkProvider implements BaseNetworkProvider {
  chain$: Observable<ChainID>;

  private readonly provider: Web3Provider;

  private publicClient: PublicClient;

  constructor(public readonly chainId: ChainID, readonly configService: ConfigService) {
    this.chain$ = new BehaviorSubject(chainId);

    const connectionUrl = Alchemy.urls[chainId].replace('<apiKey>', this.configService.alchemyApiKey[chainId]);

    logger.debug('Initializing static network provider', { chainId, connectionUrl });

    this.publicClient = createPublicClient({
      transport: webSocket(connectionUrl),
    });

    this.provider = new StaticWeb3Provider(this.publicClient as ExternalProvider, {
      chainId: this.chainId,
      name: chains[this.chainId].name,
    });
  }

  static bind = (chainId: ChainID, container: interfaces.Container): void => {
    container
      .bind<BaseNetworkProvider>(NetworkProvider)
      .toConstantValue(new StaticNetworkProvider(chainId, container.get(ConfigService)));
  };

  getSigner = (): JsonRpcSigner => this.provider.getSigner();

  getProvider = (): Web3Provider => this.provider;

  disconnect = (): never => {
    invariant(false, 'Static network provider does not support disconnecting');
  };
}
