import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgKwenta = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 89 85" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#kwenta_svg__a)">
      <path
        clipRule="evenodd"
        d="M17 33.392 44.155 17 72 33.392v18.381L44.155 68 17 51.773v-18.38Zm42.844 9.238-15.69-9.237-15.3 9.237 15.3 9.143 15.69-9.143Z"
        stroke="#F7FAFF"
        strokeWidth={2}
      />
    </g>
    <defs>
      <filter
        id="kwenta_svg__a"
        x={0.233}
        y={0.069}
        width={88.533}
        height={84.859}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={7.883} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_7948_23882" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={4.3} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_7948_23882" result="effect2_dropShadow_7948_23882" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.433} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_7948_23882" result="effect3_dropShadow_7948_23882" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_7948_23882" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgKwenta;
