import { ExecutorIntegration } from 'core/api/schema';
import { VaultActionInfo } from 'core/vault/vault-action.info';

import { GMXAction } from './gmx-action';
import { KwentaAction } from './kwenta-action';
import { SwapAction } from './swap-action';

type ActionIntegrationProps = {
  data: VaultActionInfo;
};

export const ActionIntegration = ({ data }: ActionIntegrationProps) => {
  switch (data.integration) {
    case ExecutorIntegration.ZeroX:
      return <SwapAction data={data} />;

    case ExecutorIntegration.Gmx:
      return <GMXAction data={data} />;

    case ExecutorIntegration.Kwenta:
      return <KwentaAction data={data} />;

    default:
      return null;
  }
};
