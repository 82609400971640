/* eslint-disable @typescript-eslint/no-unused-vars */
import { observer } from 'mobx-react-lite';
import { Key, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

import { VaultLoader } from 'components/loading';

import { TableLabel } from './infinite-table/table-label';
import { TableProps, TableSortDirection } from './table';

const Table = <T,>(props: TableProps<T>) => {
  const {
    items,
    columns,
    children,
    onRowClick,
    onSortHandler,
    orderBy,
    order,
    rowKey = 'id',
    emptyText = 'No data',
    loading,
    loaded,
    className,
  } = props;

  const createRowClick = useCallback((item: T) => (onRowClick ? () => onRowClick(item) : undefined), [onRowClick]);

  return (
    <div className={twMerge('overflow-hidden rounded-2xl border border-outline', className)}>
      <table className="table">
        <thead>
          <tr>
            {columns.map(({ renderer, comparator, label, minWidth, sortable, id, ...rest }, index) => (
              <td
                key={index}
                width={minWidth}
                onClick={() => onSortHandler && id && comparator && onSortHandler(comparator, id)}
                {...rest}
              >
                {sortable ? (
                  <TableLabel label={label} order={orderBy === id ? order : TableSortDirection.ASC} />
                ) : (
                  label
                )}
              </td>
            ))}
          </tr>
        </thead>

        <tbody>
          {items.map((item, index) => {
            const handleRowClick = createRowClick(item);

            return (
              <tr
                key={(item[rowKey as keyof T] as Key) || index}
                className={twMerge(handleRowClick && 'cursor-pointer')}
                onClick={handleRowClick}
              >
                {columns.map(({ renderer, label, ...rest }, index) =>
                  renderer ? (
                    <td key={index} {...rest}>
                      {renderer(item)}
                    </td>
                  ) : null
                )}
              </tr>
            );
          })}
          {items.length === 0 && (
            <tr>
              <td className="empty" colSpan={columns.length}>
                {loaded && emptyText}
                {loading && (
                  <div className="loader">
                    <VaultLoader width={68} height={28} />
                  </div>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {children && <div className="px-5">{children}</div>}
    </div>
  );
};

export const SimpleTable = observer(Table) as typeof Table;
