import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgMetaverse = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 19 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.733 7.533c2.843-.763 4.818-1.614 6.033-2.276A6.42 6.42 0 0 0 7.95.882a6.42 6.42 0 0 0-4.58 7.693c1.697-.034 3.991-.407 6.362-1.042ZM4.54 11.03a6.427 6.427 0 0 0 11.434-3.032c-1.936.91-4.183 1.586-5.59 1.963-2.078.557-4.128.928-5.844 1.07Zm11.349-8.078c1.857 0 2.864.385 3.076 1.177.55 2.054-5.251 4.196-8.765 5.138-2.635.705-5.217 1.111-7.085 1.111C1.256 10.378.25 9.992.038 9.2c-.23-.855.58-1.765 2.472-2.778-.039.41-.045.826-.01 1.246-.947.56-1.435 1.023-1.42 1.257v.001c.018.02.327.375 2.033.375 1.778 0 4.258-.392 6.806-1.075 5.24-1.404 8.035-3.261 8.003-3.822-.023-.024-.32-.348-1.855-.372a7.058 7.058 0 0 0-.627-1.069l.092-.003c.12-.004.242-.008.356-.008Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgMetaverse;
