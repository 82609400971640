import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import { FC, ReactNode, createElement } from 'react';
import { Observable, Subject, filter, from, map, merge, switchMap } from 'rxjs';

import { ErrorStore } from 'core/app/error.store';

import { Disposable } from 'utils/disposable';

export enum Widget {
  Pulse = 'pulse',
  Discord = 'discord',
  SocketSwap = 'socketSwap',
  Telegram = 'telegram',
}

type LazyWidgetModule = {
  lazy: () => Promise<{ default: FC }>;
  type: Widget;
  backdrop: boolean;
};

type WidgetModule = {
  node: ReactNode;
  type: Widget;
  backdrop: boolean;
};

@injectable()
export class WidgetsStore extends Disposable {
  @observable.ref
  activeWidget?: WidgetModule;

  @observable
  isOpen = false;

  readonly widget$: Observable<WidgetModule | undefined>;

  private readonly widget$$ = new Subject<LazyWidgetModule | WidgetModule | undefined>();

  constructor(private readonly errorStore: ErrorStore) {
    super();
    makeObservable(this);

    const lazyWidget$: Observable<WidgetModule> = this.widget$$.pipe(
      filter((widget): widget is LazyWidgetModule => widget !== undefined && 'lazy' in widget),
      switchMap((widget) =>
        from(widget.lazy()).pipe(
          map((resolved) => ({
            ...widget,
            node: createElement(resolved.default),
          }))
        )
      )
    );

    const widget$: Observable<WidgetModule | undefined> = this.widget$$.pipe(
      filter((widget): widget is WidgetModule | undefined => widget === undefined || 'node' in widget)
    );

    this.widget$ = merge(lazyWidget$, widget$);

    this.autoDispose(this.widget$.subscribe({ next: this.updateWidget, error: this.errorStore.notifyError }));
  }

  @action
  private updateWidget = (widget: WidgetModule | undefined): void => {
    if (widget) {
      this.activeWidget = widget;
      this.isOpen = true;
    } else {
      this.isOpen = false;
      this.activeWidget = undefined;
    }
  };

  openWidget = (widget: LazyWidgetModule | WidgetModule): void => {
    this.widget$$.next(widget);
  };

  closeWidget = (): void => {
    this.widget$$.next(undefined);
  };
}
