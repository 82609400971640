import { field } from '@mobx-form-state/react';
import { z } from 'zod';

import { ExecutorAction, ExecutorIntegration } from 'core/api/schema';

import { Validators } from 'utils/validators';

export class TradeMemoModel {
  @field({ validate: Validators.fromZod(z.string().min(1).max(360)) })
  message!: string;

  @field()
  integration!: ExecutorIntegration;

  @field()
  action!: ExecutorAction;

  @field()
  blockNumber!: number;
}
