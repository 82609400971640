import { ComponentPropsWithoutRef, PropsWithChildren, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { Secondary } from 'components/typography';

type FormControlProps = ComponentPropsWithoutRef<'div'> &
  PropsWithChildren<{
    label: ReactNode;
    helper?: ReactNode;
  }>;

export const FormControl = (props: FormControlProps) => {
  const { label, helper, children, className, ...rest } = props;

  return (
    <div className={twMerge('flex flex-col w-full mb-6', className)} {...rest}>
      <div className="flex justify-between items-center mb-3">
        <label className="typo-primary">{label}</label>
        {helper && <Secondary>{helper}</Secondary>}
      </div>
      {children}
    </div>
  );
};
