import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgCrystal = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.29 4.427a.678.678 0 0 0-.044-.151c-.005-.011-.005-.022-.01-.033l-.001-.002c0-.001 0-.002-.002-.003L14.431.411c-.004-.008-.012-.016-.017-.025-.006-.011-.008-.024-.015-.035-.014-.023-.032-.041-.049-.062-.016-.021-.031-.044-.05-.063a.704.704 0 0 0-.088-.076c-.02-.015-.038-.032-.06-.045a.691.691 0 0 0-.116-.052c-.02-.008-.04-.02-.06-.025A.71.71 0 0 0 13.782 0H3.161a.724.724 0 0 0-.184.024c-.021.006-.04.017-.06.024-.037.013-.074.025-.109.045-.021.013-.04.03-.06.043-.029.02-.06.04-.085.066-.019.018-.033.04-.05.06-.019.022-.041.043-.057.068L.108 4.157c-.009.015-.013.032-.022.048-.007.013-.018.023-.024.037-.007.015-.007.03-.013.043a.819.819 0 0 0-.046.192.741.741 0 0 0-.002.108c.003.03.005.058.01.087.006.035.017.068.028.102.01.029.02.058.033.085l.01.03c.014.024.033.042.048.064.013.019.02.038.035.055l7.398 8.738c.127.15.319.258.51.252l.006.001.031.001h.002a.723.723 0 0 0 .368-.107l.042-.025a.676.676 0 0 0 .134-.12l7.473-8.738c.01-.011.015-.025.024-.037.012-.015.027-.027.037-.045.01-.016.016-.035.025-.053a.561.561 0 0 0 .066-.182c.005-.026.011-.05.014-.074a.65.65 0 0 0 0-.123c-.002-.024-.001-.047-.005-.069Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgCrystal;
