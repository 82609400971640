import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';

import { Address } from 'core/address';
import { ReferrerProvider } from 'core/api/schema';
import { GasPrice } from 'core/gas-price/gas-price';
import { logger } from 'core/logger';

import { stringToEnum } from 'utils/string-to-enum';

import { Amount } from 'types';

import { LocalStorage } from './local-storage';
import { Storage } from './local-storage.enum';
import { LocalStorageInfo } from './local-storage.info';

@injectable()
export class LocalStorageService {
  @observable
  private data = {} as LocalStorageInfo.Data;

  constructor() {
    makeObservable(this);

    const storageKeys = Object.values(Storage);

    LocalStorage.getKeys().forEach((key) => {
      if (storageKeys.some((item) => key.startsWith(item))) {
        this.data[key as Storage] = this.parse(localStorage.getItem(key));
      }
    });

    logger.debug('LocalStorageService: storage keys', storageKeys);
    logger.debug('LocalStorageService: parsed data', this.data);
  }

  @action
  setReferrerCode = (code: string, provider: ReferrerProvider): void => {
    localStorage.setItem(Storage.ReferrerCode, JSON.stringify({ code, provider }));
    this.data.referrerCode = { code, provider };
  };

  @action
  setTermsConditions = (address: Address, value: boolean): void => {
    const accountTermsKey = LocalStorage.getAccountTermsKey(address);
    this.data[accountTermsKey as Storage.TermsConditions] = value;

    localStorage.setItem(accountTermsKey, String(value));
  };

  @action
  setVaultTermsConditions = (value: boolean): void => {
    this.data.vaultTermsConditions = value;
    localStorage.setItem(Storage.VaultTermsConditions, String(value));
  };

  @action
  setTheme = (value: string): void => {
    localStorage.setItem(Storage.Theme, value);
    this.data.theme = value;
  };

  @action
  setAccessToken = (value: string): void => {
    localStorage.setItem(Storage.AccessToken, value);
    this.data.accessToken = value;
  };

  @action
  removeAccessToken = (): void => {
    localStorage.removeItem(Storage.AccessToken);
    this.data.accessToken = null;
  };

  getReferrerCode = (): LocalStorageInfo.ReferrerType | null => {
    return this.data[Storage.ReferrerCode];
  };

  getAccessToken = (): string | null => {
    return this.data[Storage.AccessToken];
  };

  getTermsConditions = (address: Address): boolean => {
    const termsKey = LocalStorage.getAccountTermsKey(address);

    return Boolean(this.data[termsKey as Storage.TermsConditions]);
  };

  getVaultTermsConditions = (): boolean => {
    return Boolean(this.data[Storage.VaultTermsConditions]);
  };

  getSlippagePercentage = (): Amount | null => {
    if (!this.data[Storage.SlippagePercentage] || Number.isNaN(this.data[Storage.SlippagePercentage])) return null;

    return this.data[Storage.SlippagePercentage] as Amount;
  };

  setSlippagePercentage = (percentage: Amount): void => {
    localStorage.setItem(Storage.SlippagePercentage, percentage.toString());
  };

  setVereficationVault = (address: Address): void => {
    localStorage.setItem(Storage.VereficationVault, address);
  };

  getGasPrice = (): GasPrice.Labels | undefined => {
    return stringToEnum(this.data.gasPrice, GasPrice.Labels);
  };

  setGasPrice = (type: GasPrice.Labels): void => {
    localStorage.setItem(Storage.GasPrice, type);
  };

  getTheme = (): string | null => {
    return this.data[Storage.Theme];
  };

  getVereficationVault = (): string | null => {
    return this.data[Storage.VereficationVault];
  };

  private parse = <T>(raw: string | null): T | null => {
    try {
      if (raw === null) return raw;

      return JSON.parse(raw);
    } catch (e) {
      return raw as T | null;
    }
  };
}
