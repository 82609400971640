import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgEye = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C3 0 0 7 0 7s3 7 10 7 10-7 10-7-3-7-10-7Zm0 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm2-4a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgEye;
