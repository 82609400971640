import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgFavorite = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m7.68.492 1.412 3.94c.031.088.104.144.194.148l4.019.187a.72.72 0 0 1 .658.523.769.769 0 0 1-.238.83L10.578 8.74a.232.232 0 0 0-.074.24l1.072 4.056a.764.764 0 0 1-.273.815.686.686 0 0 1-.826.019l-3.357-2.32a.208.208 0 0 0-.24 0l-3.357 2.32a.686.686 0 0 1-.826-.02.765.765 0 0 1-.273-.814L3.496 8.98a.229.229 0 0 0-.074-.238L.275 6.12a.767.767 0 0 1-.238-.828.72.72 0 0 1 .658-.524l4.02-.188a.211.211 0 0 0 .193-.146L6.32.492A.716.716 0 0 1 7 0c.308 0 .57.19.68.492Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgFavorite;
