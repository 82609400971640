/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { VaultFragmentDoc } from './vault.fragment.generated';

export type UpdateVaultMutationVariables = Types.Exact<{
  input: Types.UpdateVaultInput;
}>;

export type UpdateVaultMutation = {
  __typename?: 'Mutation';
  updateVault: {
    __typename?: 'VaultType';
    uid: string;
    name: string;
    symbol: string;
    slug: string;
    shortDescription?: string | null;
    fullDescription?: string | null;
    cover?: string | null;
    bridgeLock: boolean;
    avatar?: string | null;
    managerPerformanceFee: BigNumber;
    managerStreamingFee: BigNumber;
    managerSentiment?: Types.ManagerSentiment | null;
    managerFocusTime?: Types.ManagerFocusTime | null;
    managerParticipation?: Types.ManagerParticipation | null;
    managerStrategy?: Types.ManagerStrategy | null;
    tags?: Array<Types.VaultTag> | null;
    marketCapSegment?: Array<number> | null;
    riskProfile: Types.RiskProfile;
    featured: boolean;
    closed: boolean;
    pulseUpdatedAt: string;
    createdAt: string;
    updatedAt: string;
    chains: Array<{
      __typename?: 'VaultChainType';
      chainId: number;
      name: string;
      address: string;
      isParent: boolean;
      aum: BigNumber;
    }>;
    manager: {
      __typename?: 'ManagerType';
      account: {
        __typename?: 'AccountType';
        address: string;
        chain: string;
        avatar?: string | null;
        name?: string | null;
        twitter?: string | null;
        bio?: string | null;
        createdAt: string;
        slug: string;
        referrerCode: string;
        referrerProvider?: Types.ReferrerProvider | null;
        referralCode?: string | null;
        experience: number;
        experienceSpins: number;
        rewards: Array<{
          __typename?: 'RewardType';
          rewardType?: Types.RewardEnumType | null;
          createdAt: string;
          revealedAt?: string | null;
        }>;
      };
      deposit?: {
        __typename?: 'DepositType';
        createdAt: string;
        depositor: string;
        entryPrice: BigNumber;
        pAndL: BigNumber;
        roi: BigNumber;
        shares: BigNumber;
        sharesValue: BigNumber;
        stake: BigNumber;
        depositedAmount: BigNumber;
      } | null;
    };
    assets: Array<{
      __typename?: 'VaultAssetType';
      value: BigNumber;
      amount: BigNumber;
      allocation: BigNumber;
      pAndL: BigNumber;
      roi: BigNumber;
      asset: {
        __typename?: 'AssetType';
        id: string;
        name: string;
        address: string;
        symbol: string;
        decimals?: number | null;
        price?: BigNumber | null;
        chain: string;
        chainId: number;
        priceChangeAmount?: BigNumber | null;
        priceChangePercentage?: BigNumber | null;
        marketCap?: string | null;
        totalSupply?: string | null;
        type: Types.AssetTypeEnum;
        deprecated: boolean;
        hardDeprecated: boolean;
        createdAt: string;
      };
    }>;
    assetAllocation: Array<{ __typename?: 'AssetAllocation'; name: string; allocation: BigNumber }>;
    assetWatchList: Array<{
      __typename?: 'AssetType';
      id: string;
      name: string;
      address: string;
      symbol: string;
      decimals?: number | null;
      price?: BigNumber | null;
      chain: string;
      chainId: number;
      priceChangeAmount?: BigNumber | null;
      priceChangePercentage?: BigNumber | null;
      marketCap?: string | null;
      totalSupply?: string | null;
      type: Types.AssetTypeEnum;
      deprecated: boolean;
      hardDeprecated: boolean;
      createdAt: string;
    }>;
    stats: {
      __typename?: 'VaultStatsType';
      aum: BigNumber;
      shareMinPrice: BigNumber;
      shareMaxPrice: BigNumber;
      depositors: BigNumber;
      newDeposits: number;
      roiDay?: BigNumber | null;
      roiWeek?: BigNumber | null;
      roiMonth?: BigNumber | null;
      roiTotal?: BigNumber | null;
    };
  };
};

export const UpdateVaultDocument = gql`
  mutation UpdateVault($input: UpdateVaultInput!) {
    updateVault(input: $input) {
      ...Vault
    }
  }
  ${VaultFragmentDoc}
`;

export function UpdateVault<R extends UpdateVaultMutation, V extends UpdateVaultMutationVariables>(
  request: Request<R, V>,
  variables: V,
  headers?: HeadersInit
): Promise<R> {
  return request(UpdateVaultDocument, variables, headers);
}
