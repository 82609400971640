// import { NotificationFragment } from 'core/api/gql/notifications/notification.fragment.generated';

export type NotificationFragment = {
  vault: {
    name: string;
  };
  createdAt: string;
  message: string;
};

export const MockedNotifications: NotificationFragment[] = [
  {
    message: 'Vault name performed an action x for 14%',
    createdAt: '1675704760',
    vault: {
      name: 'Vault',
    },
  },
  {
    message: 'Vault name performed an action x for 14%',
    createdAt: '1675677293',
    vault: {
      name: 'Vault',
    },
  },
  {
    message: 'Vault name performed an action x for 14%',
    createdAt: '1675667287',
    vault: {
      name: 'Vault',
    },
  },
];
