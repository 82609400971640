import BigNumber from 'bignumber.js';

import { Opaque } from 'types';

export type Percentage = Opaque<'Percentage', number>;

export namespace Percentage {
  export const fromNumber = (num: number): Percentage => {
    return num as Percentage;
  };

  /**
   * Formats percentage to string with '%' sign.
   * @param num - number to format.
   * @param decimals - number of decimal places to round to.
   */
  export const format = (num: number, decimals?: number): string => {
    const value = decimals ? `${num.toFixed(decimals)}` : num;

    return `${value}%`;
  };

  export const isIt = (x: unknown): x is Percentage => {
    return typeof x === 'number' && Math.abs(x) <= 100;
  };

  /**
   * Calculates percentage `(part/total * 100)`
   * @param part
   * @param total
   * @param fractionDigits - digits after decimal place (2 by default).
   * The number is rounded if necessary. If negative - no rounding then.
   */
  export const calculate = (part: number | BigNumber, total: number | BigNumber, fractionDigits = 2): Percentage => {
    const parsedPart = BigNumber.isBigNumber(part) ? part.toNumber() : part;
    const parsedTotal = BigNumber.isBigNumber(total) ? total.toNumber() : total;

    if (parsedTotal <= 0) {
      throw new Error('Dividing by 0 is not allowed!');
    }

    const percentage = (parsedPart / parsedTotal) * 100;

    return fractionDigits >= 0 ? (+percentage.toFixed(fractionDigits) as Percentage) : (percentage as Percentage);
  };
}
