import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import { Subject, bufferTime, filter, from, map, mergeMap, switchMap } from 'rxjs';

import { ApiService } from 'core/api/api.service';
import { GetVaultRoi } from 'core/api/gql/vault/get-vault-roi.gql.generated';
import { ChartDatasetRange, RoiDataset } from 'core/api/schema';

import { Disposable } from 'utils/disposable';

import { Milliseconds } from 'types';

type UID = string;

type WrapWithUID<T> = { uid: UID; dataset: T };

@injectable()
export abstract class VaultDatasetStore extends Disposable {
  @observable
  private roiDatasetMap = new Map<UID, RoiDataset[]>();

  private roiDatasetRequestMap = new Map<UID, Milliseconds>();

  private roiDatasetRequest$ = new Subject<[UID, ChartDatasetRange]>();

  constructor(private readonly apiService: ApiService) {
    super();

    makeObservable(this);

    this.autoDispose(
      this.roiDatasetRequest$
        .pipe(
          bufferTime(100),
          filter((value) => !!value.length),
          switchMap((value) => from(value)),
          mergeMap(([uid, dateRange]) =>
            from(this.apiService.query(GetVaultRoi, { uid, dateRange })).pipe(
              map((result) => ({
                uid,
                dataset: result.roiDataset,
              }))
            )
          )
        )

        .subscribe(this.setRoiDataset)
    );
  }

  @action
  private setRoiDataset = (result: WrapWithUID<RoiDataset[]>): void => {
    this.roiDatasetMap.set(result.uid, result.dataset);
  };

  getRoiDataset = (uid: UID, dateRange?: ChartDatasetRange): RoiDataset[] => {
    const roiDataset = this.roiDatasetMap.get(uid);

    if (!roiDataset && !this.roiDatasetRequestMap.has(uid)) {
      this.roiDatasetRequestMap.set(uid, Date.now());
      this.roiDatasetRequest$.next([uid, dateRange || ChartDatasetRange.All]);
    }

    return roiDataset || [];
  };
}
