import BigNumber from 'bignumber.js';
import { BigNumberish } from 'ethers';

import { DASH } from 'utils/dash';

import { Amount, Units } from 'types';

import { Asset } from './asset';

export namespace MoneyFormatter {
  export type FormatOptions = {
    decimalPlaces: number;
    asset?: Asset | null;
    precise?: boolean;
    decimals?: number;
  };

  // TODO: is it possible to BigNumber.js to format numbers?
  const formatter = new Intl.NumberFormat('en', { notation: 'compact', compactDisplay: 'short' });

  export const mcapFormat = (value: number) => formatter.format(value);

  export const suggestedDecimals = (value: BigNumber): number => {
    value = value.abs();

    if (value.gte(100000)) return 0;
    if (value.gte(100) || value.isEqualTo(0)) return 2;
    if (value.gte(10)) return 3;
    if (value.gte(0.1)) return 4;
    if (value.gte(0.01)) return 5;
    if (value.gte(0.001)) return 6;
    if (value.gte(0.0001)) return 7;
    if (value.gte(0.00001)) return 8;

    return 11;
  };

  type ParseValueOptions = {
    forcePrecision?: boolean;
    precision?: number;
  };

  const parseValue = (value: Amount | Units | BigNumberish | BigNumber, options: ParseValueOptions): BigNumber => {
    const isBigNumber = value instanceof BigNumber;

    const parsedValue = isBigNumber ? value : new BigNumber(value.toString());

    if ((isBigNumber && !options.forcePrecision) || !options.precision) {
      return parsedValue;
    }

    return parsedValue.div(10 ** options.precision);
  };

  type FormattedValue = {
    toString: () => string;
    value?: BigNumber;
  };

  export const format = (
    value?: BigNumber | Amount | Units | BigNumberish | null,
    format?: Partial<FormatOptions>
  ): FormattedValue => {
    const { asset, decimals } = format || {};

    if (typeof value === 'undefined' || value === '' || value === null) {
      return DASH;
    }

    const parsedValue = parseValue(value, {
      precision: asset?.decimals ?? decimals,
      forcePrecision: format?.precise,
    });

    if (parsedValue.isNaN()) {
      return DASH;
    }

    const decimalPlaces = format?.decimalPlaces || suggestedDecimals(parsedValue);

    return {
      toString: () => parsedValue.decimalPlaces(decimalPlaces, BigNumber.ROUND_FLOOR).toFormat(),
      value: parsedValue,
    };
  };
}
