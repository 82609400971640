import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgCup = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.299 7.06h.362c.572 1.174 1.632 2.063 2.921 2.45v1.883h-1.17a.482.482 0 0 0-.25.079.453.453 0 0 0-.166.195l-1.342 2.776h-.52a.82.82 0 0 0-.704.389.752.752 0 0 0 0 .779.82.82 0 0 0 .704.389h9.732a.82.82 0 0 0 .704-.39.752.752 0 0 0 0-.778.82.82 0 0 0-.704-.39h-.522l-1.342-2.775a.455.455 0 0 0-.166-.195.482.482 0 0 0-.25-.079H9.418V9.504c1.289-.386 2.349-1.275 2.92-2.448h.363a3.379 3.379 0 0 0 2.33-.928A3.106 3.106 0 0 0 16 3.893V2.018a.565.565 0 0 0-.175-.405.613.613 0 0 0-.42-.17h-2.617V.576c0-.153-.063-.3-.176-.407A.614.614 0 0 0 12.187 0H3.813c-.16 0-.312.06-.425.169a.564.564 0 0 0-.176.407v.867H.596a.613.613 0 0 0-.421.17.564.564 0 0 0-.175.405v1.875c0 .84.349 1.644.967 2.238a3.378 3.378 0 0 0 2.332.93Zm9.49-1.939V3.162h1.415v.723c0 .369-.148.723-.412.99a1.533 1.533 0 0 1-1.009.449c.002-.062.005-.133.005-.203Zm-6.636-.939a.28.28 0 0 1 .23-.186l.941-.13.42-.817A.285.285 0 0 1 8 2.897c.108 0 .208.059.255.152l.42.817.942.13a.281.281 0 0 1 .23.185.265.265 0 0 1-.072.28l-.68.637.16.9a.266.266 0 0 1-.06.226.295.295 0 0 1-.353.069l-.842-.431-.84.424a.295.295 0 0 1-.301-.02A.268.268 0 0 1 6.745 6l.162-.9-.68-.636a.264.264 0 0 1-.074-.28ZM1.796 3.168h1.416v2.17a1.533 1.533 0 0 1-1.009-.448 1.413 1.413 0 0 1-.413-.99l.006-.732Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgCup;
