import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgPlay = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 13 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.61 15c-.293 0-.561-.075-.805-.2A1.678 1.678 0 0 1 0 13.35V1.675C0 1.1.317.5.805.225c.488-.3 1.146-.3 1.634 0l9.756 5.825c.488.3.805.825.805 1.45s-.317 1.125-.805 1.45L2.44 14.775c-.268.15-.537.225-.83.225Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgPlay;
