import { Form } from '@mobx-form-state/react';
import { startOfDay, startOfHour, subDays, subMonths, subWeeks } from 'date-fns';
import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';

import { ApiService } from 'core/api/api.service';
import { AddTradeMemo } from 'core/api/gql/vault/add-trade-memo.gql.generated';
import { GetTradingMemo } from 'core/api/gql/vault/get-trading-memo.gq.generated';
import { ChartDatasetRange } from 'core/api/schema';
import { AssetsStore } from 'core/asset/assets.store';
import { ChainService } from 'core/chain/chain.service';
import { logger } from 'core/logger';
import { SnackbarStore } from 'core/snackbars/snackbar.store';
import { TradeMemoModel } from 'core/vault/trades-memo/trade-memo.model';

import { ModalsStore } from 'lib/modals/modals.store';

import { TradeMemoModal } from 'components/modals/trade-memo';

import { Disposable } from 'utils/disposable';

import { ActiveVaultStore } from '../active-vault.store';
import { TradeMemoInfo } from './trade-memo.info';
import { mapTradesMemoFromApi } from './trade-memo.map';

@injectable()
export class TradeMemoStore extends Disposable {
  @observable
  private tradeMemoMap = new Map<number, TradeMemoInfo[]>();

  readonly form = new Form(TradeMemoModel, {
    onSubmit: (): Promise<void> => this.submit(),
  });

  constructor(
    private readonly activeVaultStore: ActiveVaultStore,
    private readonly apiService: ApiService,
    private readonly modalsStore: ModalsStore,
    private readonly snackbarStore: SnackbarStore,
    private readonly chainService: ChainService,
    private readonly assetStore: AssetsStore
  ) {
    super();
    makeObservable(this);

    this.init(this.activeVaultStore.dateRange);

    this.autoDispose(this.activeVaultStore.dataRange$.subscribe(this.clearTradeMemos));

    this.autoDispose(this.activeVaultStore.dataRange$.subscribe(this.init));
  }

  @action
  private setTradeMemos = (items: TradeMemoInfo[]): void => {
    const { dateRange } = this.activeVaultStore;

    items.forEach((item) => {
      const createdAt = new Date(item.createdAt);
      let roundedDate;

      if (dateRange === ChartDatasetRange.OneDay) {
        roundedDate = startOfHour(createdAt);
      } else {
        roundedDate = startOfDay(createdAt);
      }

      const key = roundedDate.getTime();

      if (!this.tradeMemoMap.has(key)) {
        this.tradeMemoMap.set(key, []);
      }
      this.tradeMemoMap.get(key)?.push(item);
    });
  };

  @action
  private clearTradeMemos = (): void => {
    this.tradeMemoMap.clear();
  };

  getTradeMemosByDate = (targetDate: Date, rounded?: boolean): TradeMemoInfo[] => {
    if (rounded) {
      this.activeVaultStore.dateRange === ChartDatasetRange.OneDay
        ? (targetDate = startOfHour(targetDate))
        : (targetDate = startOfDay(targetDate));
    }

    const key = targetDate.getTime();

    return this.tradeMemoMap.get(key) ?? [];
  };

  getTradeMemoById = (id: string): TradeMemoInfo | null => {
    return (
      Array.from(this.tradeMemoMap.values())
        .flat()
        .find((item) => {
          if ('action' in item && item.action && 'id' in item.action) {
            return item.action.id === id;
          }

          return false;
        }) ?? null
    );
  };

  init = async (dateRange: ChartDatasetRange): Promise<void> => {
    const { vault } = this.activeVaultStore;
    const now = new Date();

    const date = {
      [ChartDatasetRange.All]: {
        fromDate: new Date(vault.createdAt),
        toDate: now,
      },
      [ChartDatasetRange.OneDay]: {
        fromDate: subDays(now, 1),
        toDate: now,
      },
      [ChartDatasetRange.OneMonth]: {
        fromDate: subMonths(now, 1),
        toDate: now,
      },
      [ChartDatasetRange.OneWeek]: {
        fromDate: subWeeks(now, 1),
        toDate: now,
      },
    };

    const input = {
      vaultUid: vault.uid,
      fromDate: date[dateRange].fromDate,
      toDate: date[dateRange].toDate,
    };

    const { tradingMemo } = await this.apiService.query(GetTradingMemo, {
      input: input,
    });

    const mapTradeMemo = mapTradesMemoFromApi(tradingMemo, this.assetStore.getAssetBySymbol);

    this.setTradeMemos(mapTradeMemo);
  };

  private submit = async (): Promise<void> => {
    const { message, integration, action, blockNumber } = this.form.values;
    const { vault } = this.activeVaultStore;

    const chain = this.chainService.chain;

    const input = {
      vaultUid: vault.uid,
      chain: chain.name,
      blockNumber,
      integration,
      message,
      action,
    };

    try {
      await this.apiService.query(AddTradeMemo, { input });

      this.snackbarStore.openSuccess('Memo added');
      this.reset();
    } catch (e) {
      this.snackbarStore.openFailed('Failed to add memo', e);
      logger.error(e);
    }
  };

  private reset = (): void => {
    this.modalsStore.close(TradeMemoModal.displayName);
    this.form.reset();
  };
}
