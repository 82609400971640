import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgL2 = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h9a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3Zm2 9.76V10h5V8.734H7.913l.87-.803c.695-.637 1.174-1.25 1.174-2.06C9.957 4.777 9.147 4 7.627 4c-1.505 0-2.444.753-2.505 2.234h1.617c.078-.703.357-.96.8-.96.444 0 .661.265.661.663 0 .496-.409 1-1.026 1.63L5 9.76Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgL2;
