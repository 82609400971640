import { useService } from '@redtea/react-inversify';
import { Container } from 'inversify';

import { ConfigService } from 'core/config/config.service';

import * as Themes from './themes';

export const useTheme = () => {
  const { theme } = useService(ConfigService);

  return Themes[theme];
};

export const getTheme = (container: Container) => {
  const { theme } = container.get(ConfigService);

  return Themes[theme];
};
