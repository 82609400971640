import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgGear = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99 14.027a.925.925 0 0 1-1.29-.013l-.714-.715a.924.924 0 0 1-.012-1.29l.03-.031c.345-.36.471-1.023.28-1.488l-.087-.209c-.192-.461-.748-.844-1.25-.854H.904A.925.925 0 0 1 0 8.505v-1.01c0-.5.401-.911.903-.922h.043c.5-.01 1.058-.39 1.25-.855l.087-.208c.191-.462.068-1.126-.28-1.488l-.03-.031a.925.925 0 0 1 .013-1.29l.715-.715a.924.924 0 0 1 1.29-.012l.031.03c.36.345 1.023.471 1.488.28l.209-.087c.461-.192.844-.748.854-1.25V.904A.925.925 0 0 1 7.495 0h1.01c.5 0 .911.401.922.903v.043c.01.5.39 1.058.854 1.25l.21.087c.461.191 1.125.068 1.487-.28l.031-.03a.925.925 0 0 1 1.29.013l.715.715c.353.353.36.928.012 1.29l-.03.031c-.345.36-.472 1.023-.28 1.488l.087.209c.191.461.748.844 1.25.854h.043c.5.01.904.422.904.922v1.01a.923.923 0 0 1-.903.922h-.043c-.5.01-1.058.39-1.25.854l-.087.21c-.191.461-.068 1.125.28 1.487l.03.031c.346.36.34.937-.013 1.29l-.715.715a.924.924 0 0 1-1.29.012l-.031-.03c-.36-.345-1.023-.472-1.488-.28l-.209.087c-.461.191-.844.748-.854 1.25v.043a.925.925 0 0 1-.922.904h-1.01a.923.923 0 0 1-.922-.903v-.043c-.01-.5-.39-1.058-.855-1.25l-.208-.087c-.462-.191-1.126-.068-1.488.28l-.031.03Zm2.514-2.414a3.91 3.91 0 1 0 2.992-7.226 3.91 3.91 0 0 0-2.992 7.226Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgGear;
