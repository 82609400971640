import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgPositiveLong = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={8.5} cy={8.5} r={8.5} fill="#000" />
    <circle cx={8.5} cy={8.5} r={8} stroke="#fff" strokeOpacity={0.2} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 14.45a5.95 5.95 0 1 0 0-11.9 5.95 5.95 0 0 0 0 11.9Zm2.543-6.762L8.747 5.3a.339.339 0 0 0-.494 0L5.957 7.688a.367.367 0 0 0-.102.255c0 .097.036.188.102.256l.207.217a.336.336 0 0 0 .489 0l1.35-1.409v4.441c0 .2.165.357.357.357h.295a.347.347 0 0 0 .342-.357V7.023l1.342 1.393a.345.345 0 0 0 .495 0l.209-.217a.367.367 0 0 0 .101-.256.366.366 0 0 0-.101-.255Z"
      fill="#00C696"
    />
  </svg>
));
export default SvgPositiveLong;
