import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgLeverage = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 19 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.28.625a1.167 1.167 0 0 0 0 2.333h6.222a1.167 1.167 0 1 0 0-2.333H11.28Zm-1.167 5.056c0-.644.523-1.166 1.167-1.166h6.222a1.167 1.167 0 0 1 0 2.333H11.28a1.167 1.167 0 0 1-1.167-1.167Zm1.167 2.722a1.167 1.167 0 0 0 0 2.334h6.222a1.167 1.167 0 1 0 0-2.334H11.28Zm-10.113 0a1.167 1.167 0 1 0 0 2.334h6.222a1.167 1.167 0 1 0 0-2.334H1.167Zm0 3.889a1.167 1.167 0 1 0 0 2.333h6.222a1.167 1.167 0 0 0 0-2.333H1.167Zm8.946 1.167c0-.645.523-1.167 1.167-1.167h6.222a1.167 1.167 0 0 1 0 2.333H11.28a1.167 1.167 0 0 1-1.167-1.166Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgLeverage;
