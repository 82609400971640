import { ComponentPropsWithoutRef, FC, SVGProps } from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * Smallest - 10px
 *
 * Smaller - 12px
 *
 * Small - 14px
 *
 * Medium - 16px
 *
 * Large - 18px
 *
 * Bigger - 21px
 *
 * Largest - 24px
 */
export enum IconSize {
  Smallest = 'smaller-10px',
  Smaller = 'smaller-12px',
  Small = 'small-14px',
  Medium = 'medium-16px',
  Large = 'large-18px',
  Bigger = 'bigger-21px',
  Largest = 'largest-24px',
}

export type SvgIconProps = ComponentPropsWithoutRef<'svg'> & IconProps;

export type IconProps = {
  size?: IconSize;
};

export const svgIcon = (SvgIcon: FC<SVGProps<SVGSVGElement>>) => (props: SvgIconProps) => {
  const { className, size, ...rest } = props;

  return (
    <SvgIcon
      className={twMerge(
        'svg-icon-root inline-block select-none shrink-0 w-[1em] h-[1em] transition-all duration-200 text-icon-secondary',
        size === IconSize.Smallest && 'text-[10px]',
        size === IconSize.Smaller && 'text-[12px]',
        size === IconSize.Small && 'text-[14px]',
        size === IconSize.Medium && 'text-[16px]',
        size === IconSize.Large && 'text-[18px]',
        size === IconSize.Bigger && 'text-[21px]',
        size === IconSize.Largest && 'text-[24px]',

        className
      )}
      {...rest}
    />
  );
};
