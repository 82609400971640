import { ChainID } from 'core/chain';

export namespace Alchemy {
  type Urls = Record<ChainID, string>;

  export const urls = {
    [ChainID.Ethereum]: 'wss://eth-mainnet.g.alchemy.com/v2/<apiKey>',
    [ChainID.Arbitrum]: 'wss://arb-mainnet.g.alchemy.com/v2/<apiKey>',
    [ChainID.Optimism]: 'wss://opt-mainnet.g.alchemy.com/v2/<apiKey>',
  } as const satisfies Urls;
}
