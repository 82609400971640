import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgEth = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 12 19" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M5.833 7.025 0 9.677l5.833 3.446 5.83-3.446-5.83-2.652Z" fill="currentColor" />
      <path d="m0 9.678 5.833 3.446V0L0 9.678Z" fill="currentColor" />
      <path d="M5.833 0v13.124l5.83-3.446L5.833 0Z" fill="currentColor" />
      <path d="M0 10.783 5.833 19V14.23L0 10.783Z" fill="currentColor" />
      <path d="M5.833 14.229V19l5.835-8.217-5.835 3.446Z" fill="currentColor" />
    </g>
  </svg>
));
export default SvgEth;
