import BigNumber from 'bignumber.js';

import { ChainID } from 'core/chain';

export enum VaultTransferActionType {
  Deposit = 'Deposit',
  Withdraw = 'Withdraw',
}

export type VaultTransferInfo = {
  action: VaultTransferActionType;
  amount: BigNumber;
  account: string;
  transactionHash: string;
  isManager: boolean;
  createdAt: Date;
  chainId: ChainID;
};
