import { useCallback, useEffect } from 'react';

export function useClickOutside(origin: Element | null, popper: Element | null, close?: () => void) {
  const handleClick = useCallback(
    (e: Event) => {
      if (e.target && popper?.contains(e.target as Node)) return;
      if (e.target && origin?.contains(e.target as Node)) return;

      close && close();
    },
    [close, origin, popper]
  );

  useEffect(() => {
    if (origin) {
      window.addEventListener('click', handleClick);
    }

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [handleClick, origin]);
}
