import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgBox = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 21" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.955 7.462a.38.38 0 0 1 .022.308.376.376 0 0 1-.218.219l-10.098 3.759a.366.366 0 0 1-.437-.156l-1.817-3.027-1.662 3.142a.361.361 0 0 1-.462.163L.218 7.881a.383.383 0 0 1-.196-.211.39.39 0 0 1 .014-.294l1.662-3.454.004-.007c.01-.02.023-.035.037-.05l.014-.017.01-.017c.007-.009.012-.017.019-.024a.34.34 0 0 1 .12-.074h.003l9.95-3.71c.08-.03.17-.03.25 0l9.752 3.71h.004c.04.014.08.04.113.074.009.007.015.015.02.023l.012.014.013.015c.011.014.023.028.031.045l1.905 3.558ZM3.058 4.092l8.349 3.333 9.261-3.354-8.69-3.302-8.92 3.324Z"
      fill="currentColor"
    />
    <path
      d="M9.425 12.643c-.16 0-.309-.033-.443-.097L2.007 9.48v7.48c0 .149.087.286.226.342l8.796 3.688V10.842l-.644 1.218a1.09 1.09 0 0 1-.96.583ZM21.832 16.959V9.497l-7.923 2.95a1.08 1.08 0 0 1-.375.066c-.375 0-.72-.197-.924-.524l-.854-1.422V21l9.836-3.692a.371.371 0 0 0 .24-.349Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgBox;
