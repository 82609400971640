import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';

import { VaultStore } from 'core/vault/vault.store';

import { ChangeChip } from 'components/change-chip';
import { Secondary } from 'components/typography';

export const ShareCardVault = observer(() => {
  const { activeVault } = useService(VaultStore);

  if (!activeVault) return null;

  const value = activeVault.vault.stats.roiTotal ? Number(activeVault.vault.stats.roiTotal?.toFixed(0)) : 0;

  return (
    <div className="flex flex-col justify-between">
      <Secondary>Vault Returns</Secondary>
      <div className="w-[137px]">
        <ChangeChip
          primary
          value={value}
          outlined
          showSymbol
          showZero
          className="font-stratos leading-[71.617px] text-[115px] not-italic font-medium h-[134px]"
        />
      </div>
    </div>
  );
});
