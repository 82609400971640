import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgSentimentalCursorBlue = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 44 208" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#sentimental-cursor-blue_svg__a)">
      <path
        d="M12.625 14.54c-.722-.723-.024-1.936.964-1.674l8.069 2.14a.999.999 0 0 0 .512 0l8.07-2.14c.987-.262 1.686.95.963 1.673l-8.582 8.582a1 1 0 0 1-1.414 0l-8.582-8.582Z"
        fill="#F7FAFF"
      />
    </g>
    <g filter="url(#sentimental-cursor-blue_svg__b)">
      <path stroke="#fff" strokeWidth={1.7} d="M22.15 189V23" />
    </g>
    <g filter="url(#sentimental-cursor-blue_svg__c)">
      <circle cx={22} cy={191} r={4} fill="#F7FAFF" />
    </g>
    <defs>
      <filter
        id="sentimental-cursor-blue_svg__a"
        x={0.065}
        y={0.566}
        width={43.699}
        height={35.111}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={6.131} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_13175_3441" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={3.344} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_13175_3441" result="effect2_dropShadow_13175_3441" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.115} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_13175_3441" result="effect3_dropShadow_13175_3441" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_13175_3441" result="shape" />
      </filter>
      <filter
        id="sentimental-cursor-blue_svg__b"
        x={9.037}
        y={10.737}
        width={26.226}
        height={190.526}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={6.131} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_13175_3441" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={3.344} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_13175_3441" result="effect2_dropShadow_13175_3441" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.115} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_13175_3441" result="effect3_dropShadow_13175_3441" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_13175_3441" result="shape" />
      </filter>
      <filter
        id="sentimental-cursor-blue_svg__c"
        x={5.737}
        y={174.737}
        width={32.526}
        height={32.526}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={6.131} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_13175_3441" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={3.344} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_13175_3441" result="effect2_dropShadow_13175_3441" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.115} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_13175_3441" result="effect3_dropShadow_13175_3441" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_13175_3441" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgSentimentalCursorBlue;
