import { injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  Subject,
  bufferWhen,
  combineLatest,
  delay,
  filter,
  fromEvent,
  map,
  retry,
  share,
  skipWhile,
  switchMap,
  take,
} from 'rxjs';

import { AccountStore } from 'core/account/account.store';
import { ReferrerProvider } from 'core/api/schema';
import { AssetsStore } from 'core/asset/assets.store';
import { LocalStorageService } from 'core/local-storage/local-storage.service';
import { NetworkProvider } from 'core/network/network.provider';
import { SnackbarStore } from 'core/snackbars/snackbar.store';
import { WalletStore } from 'core/wallet/wallet.store';

import { ModalsStore } from 'lib/modals/modals.store';

import { TermsConditionsModal } from 'components/modals/terms-conditions';

import { Disposable } from 'utils/disposable';
import { isSomething } from 'utils/is-something';
import { polling } from 'utils/polling';
import { RetryStrategy } from 'utils/retry-strategy';

import { isDev } from '../../../.webpack/parts/is-dev';
import { App } from './app';

declare const AppInfo: App.AppInfo;

@injectable()
export class AppStore extends Disposable {
  @observable
  godMode = false;

  private godMode$ = new Subject<boolean>();

  constructor(
    private readonly modalsStore: ModalsStore,
    private readonly localStorageService: LocalStorageService,
    private readonly walletStore: WalletStore,
    private readonly networkProvider: NetworkProvider,
    private readonly assetsStore: AssetsStore,
    private readonly snackbarStore: SnackbarStore,
    private readonly accountStore: AccountStore
  ) {
    super();
    makeObservable(this);

    this.extractReferralFromURL();

    const keydown$ = fromEvent<KeyboardEvent>(window, 'keydown').pipe(
      map((e) => e.key),
      share()
    );

    const buildStale$ = polling(App.AppInfoPollingInterval).pipe(
      skipWhile(() => isDev()),
      switchMap(() => fetch(App.AppInfoUrl)),
      switchMap((response) => response.json()),
      filter(App.isAppInfo),
      filter((loaded) => loaded.hash !== AppInfo.hash),
      retry(
        RetryStrategy({
          label: 'Load app info failed',
        })
      )
    );

    this.autoDispose(
      buildStale$.subscribe(() => {
        window.location.reload();
      })
    );

    this.autoDispose(
      keydown$
        .pipe(
          filter((key) => key === '/'),
          switchMap(() =>
            keydown$.pipe(
              bufferWhen(() => keydown$.pipe(filter((key) => key === 'Enter'))),
              take(1)
            )
          )
        )
        .subscribe(this.tryEnableGodMode)
    );

    this.autoDispose(
      this.godMode$.pipe(delay(2000)).subscribe(() => {
        this.snackbarStore.removeSnackbar('god-mode');
      })
    );

    this.autoDispose(this.godMode$.subscribe(this.setGodMode));

    this.autoDispose(
      combineLatest([
        this.walletStore.address$.pipe(filter(isSomething)),
        this.networkProvider.chain$.pipe(filter(isSomething)),
      ]).subscribe(this.onWalletConnected)
    );

    this.autoDispose(
      this.modalsStore.closeModal$
        .pipe(filter((modal) => modal.component.displayName === TermsConditionsModal.displayName))
        .subscribe(this.onModalClosed)
    );
  }

  @computed
  private get isTermsAccepted(): boolean {
    if (!this.walletStore.address) return false;

    return this.localStorageService.getTermsConditions(this.walletStore.address);
  }

  @action
  private setGodMode = (value: boolean): void => {
    this.godMode = value;

    if (this.godMode) {
      this.snackbarStore.openSuccess('God mode enabled');
    } else {
      this.snackbarStore.openFailed('God mode disabled');
    }
  };

  readonly init = async (): Promise<null> => {
    await Promise.all([this.accountStore.wait(), this.assetsStore.wait()]);

    return null;
  };

  private tryEnableGodMode = (keys: string[]): void => {
    const activationPhrases = [App.AdvancedModeActivationPhrase, App.AdvancedModeActivationPhraseSecond];
    const concatenatedKeys = keys.join('');

    if (activationPhrases.includes(concatenatedKeys)) {
      this.godMode$.next(!this.godMode);
    }
  };

  private onWalletConnected = async (): Promise<void> => {
    if (!this.isTermsAccepted) this.modalsStore.open(TermsConditionsModal, { meta: { permanent: true } });
  };

  private onModalClosed = async (): Promise<void> => {
    if (!this.isTermsAccepted) this.networkProvider.disconnect();

    if (!this.walletStore.address) return;
  };

  private extractReferralFromURL = (): void => {
    const urlParams = new URLSearchParams(window.location.search);
    const referral = urlParams.get('referral');
    const impactReferral = urlParams.get('irclickid');

    if (this.localStorageService.getReferrerCode()) return;

    if (referral) {
      this.localStorageService.setReferrerCode(referral, ReferrerProvider.Valio);
    } else if (impactReferral) {
      this.localStorageService.setReferrerCode(impactReferral, ReferrerProvider.Impact);
    }
  };
}
