import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';
import { Link, Outlet, useMatch } from 'react-router-dom';
import { useTheme } from 'styles/themes/use-theme.hook';
import { twMerge } from 'tailwind-merge';

import Banner from 'components/banner';
import { Button } from 'components/button';
import { Szn0LogoIcon } from 'components/icons';
import { Loading } from 'components/loading';
import { IconSize } from 'components/svg-icon';
import { H3, Primary } from 'components/typography';

import { PageProgress } from 'common/page-progress';

import { Footer } from 'pages/layout/footer/footer';
import { Header } from 'pages/layout/header/header';

import { isSomething } from 'utils/is-something';

import { PageBacklight } from './page-backlight';
import { ScrollToTop } from './scroll-to-top';
import { useMeta } from './use-meta';

export const Layout = observer(() => {
  const isMainPage = isSomething(useMatch('/'));
  const { features } = useTheme();

  useMeta();

  return (
    <div className="flex flex-col flex-1 w-full">
      {isMainPage && features?.szn1 && (
        <div className="fixed w-full z-[3]">
          <Banner
            startIcon={
              <div className="flex items-center gap-1.5">
                <div>
                  <Szn0LogoIcon className="text-[50px] h-[40px] text-white" size={IconSize.Large} />
                </div>
                <Primary className="font-medium text-[16px] text-white">is live!</Primary>
              </div>
            }
            text={<H3 className="text-white/70">Earn rewards by backing other managers or by managing vaults</H3>}
            endIcon={
              <Link to="/szn0">
                <Button slim>Learn more</Button>
              </Link>
            }
          />
        </div>
      )}

      <PageProgress />
      <Header />
      <PageBacklight />
      <div
        className={twMerge(
          'flex-1 mt-[50px] mb-[35px] relative',
          isMainPage && features?.szn1 && 'mt-[100px] mb-[75px]'
        )}
      >
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
});
