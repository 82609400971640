import { IntrospectionQuery } from 'graphql';
import { injectable } from 'inversify';

import { AuthService } from 'core/auth/auth.service';
import { ConfigService } from 'core/config/config.service';
import { OrganizationsService } from 'core/organizations/organizations.service';

import schema from '../../../schema.json';
import { BaseApiService } from './base-api.service';
import { createScalarExchange } from './custom-scalar.exchange';
import { GraphQLClientService } from './gql.client';
import { scalars } from './scalars';

@injectable()
export class ApiService extends BaseApiService {
  constructor(
    graphQLClientService: GraphQLClientService,
    configService: ConfigService,
    authService: AuthService,
    organizationsService: OrganizationsService
  ) {
    super(graphQLClientService, {
      scalarExchange: createScalarExchange({ scalars, schema: schema as unknown as IntrospectionQuery }),
      url: configService.graphqlUrl,
      wsUrl: configService.graphqlWSUrl,
      wsTeardown$: authService.authData$,
      getAuthHeaders: async () => {
        const [org, auth] = await Promise.all([organizationsService.getOrgHeaders(), authService.getAuthHeaders()]);

        return {
          ...org,
          ...auth,
        };
      },
    });
  }
}
