import {
  Children,
  ComponentPropsWithoutRef,
  PropsWithChildren,
  cloneElement,
  forwardRef,
  isValidElement,
  memo,
  useMemo,
  useRef,
} from 'react';
import { twMerge } from 'tailwind-merge';

import { useClickOutside } from 'components/menu/use-click-outside.hook';
import { Popover, PopoverProps } from 'components/popover';

import { MenuItem, MenuItemProps } from '.';

export enum MenuVariant {
  Default = 'list',
  Select = 'select',
}

export type MenuProps = PropsWithChildren<ComponentPropsWithoutRef<'ul'>> & {
  selectable?: boolean;
  variant?: MenuVariant;
  anchorEl: Element | null;
  isOpen?: boolean;
  onClose?: () => void;
  popoverProps?: Partial<PopoverProps>;
  menuRef?: React.Ref<HTMLUListElement>;
  zIndex?: number;
};

export const Menu = memo<MenuProps>((props) => {
  const {
    children,
    selectable,
    variant = MenuVariant.Default,
    anchorEl,
    isOpen,
    onClose,
    popoverProps,
    menuRef,
    zIndex,
    ...rest
  } = props;

  const popperRef = useRef<HTMLDivElement>(null);
  useClickOutside(anchorEl, popperRef.current, onClose);

  const menu = useMemo(
    () => (
      <MenuRoot variant={variant} ref={menuRef} {...rest}>
        {Children.map(children, (child) => {
          const isValid = isValidElement<MenuItemProps>(child);

          if (isValid && child.type === MenuItem) {
            return cloneElement(child, {
              ...child.props,
              selectable: child.props.selectable || selectable,
            });
          }

          return child;
        })}
      </MenuRoot>
    ),
    [children, menuRef, rest, selectable, variant]
  );

  return (
    <Popover
      anchorEl={anchorEl}
      isOpen={isOpen}
      popperEl={popperRef}
      zIndex={zIndex}
      indent={getIndent(variant)}
      {...popoverProps}
    >
      {() => {
        switch (variant) {
          case MenuVariant.Select:
            return menu;

          case MenuVariant.Default:
          default:
            return <div className="tooltip">{menu}</div>;
        }
      }}
    </Popover>
  );
});

type MenuRootProps = PropsWithChildren<ComponentPropsWithoutRef<'ul'>> & {
  variant?: MenuVariant;
};

export const MenuRoot = forwardRef<HTMLUListElement, MenuRootProps>((props, ref) => {
  const { variant, className, ...rest } = props;

  return (
    <ul
      className={twMerge(
        'm-0 list-type-none box-border max-h-[80vh] overflow-auto',
        variant === MenuVariant.Select &&
          'rounded-[10px] border border-outline bg-background *:pt-[11px] *:px-3.5 *:pb-[11px]  [:not(:last-child)]:*:border-b [:not(:last-child)]:*:border-outline',
        variant === MenuVariant.Default && 'rounded-[15px] py-[7px] backdrop-blur-[4px]',
        className
      )}
      ref={ref}
      {...rest}
    />
  );
});

const getIndent = (variant: MenuVariant): number => {
  switch (variant) {
    case MenuVariant.Select:
      return 5;

    case MenuVariant.Default:
    default:
      return 8;
  }
};
