import { Router, To } from '@remix-run/router';
import { injectable } from 'inversify';
import { distinctUntilChanged, merge, skip, skipUntil, timer } from 'rxjs';

import { NetworkService } from 'core/network/network.service';

import { Disposable } from 'utils/disposable';

@injectable()
export class AppRouter extends Disposable {
  router!: Router;

  private forceRevalidate = false;

  constructor(private readonly networkService: NetworkService) {
    super();

    const revalidate$ = merge(
      this.networkService.disconnect$,
      this.networkService.address$.pipe(distinctUntilChanged(), skip(1))
    ).pipe(skipUntil(timer(1000)));

    this.autoDispose(
      revalidate$.subscribe(() => {
        this.forceRevalidate = true;
        this.router?.revalidate();
        this.forceRevalidate = false;
      })
    );
  }

  readonly inject = (router: Router): void => {
    this.router = router;
  };

  readonly navigate = (to: To): void => {
    this.router.navigate(to);
  };

  readonly shouldRevalidate = (): boolean => {
    return this.forceRevalidate;
  };
}
