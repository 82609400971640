/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';

export type AssetUpdatedSubscriptionVariables = Types.Exact<{ [key: string]: never }>;

export type AssetUpdatedSubscription = {
  __typename?: 'SubscriptionRoot';
  assetUpdated: {
    __typename?: 'AssetType';
    chainId: number;
    symbol: string;
    price?: BigNumber | null;
    priceChangePercentage?: BigNumber | null;
  };
};

export const AssetUpdatedDocument = gql`
  subscription AssetUpdated {
    assetUpdated {
      chainId
      symbol
      price
      priceChangePercentage
    }
  }
`;

export function AssetUpdated<R extends AssetUpdatedSubscription, V extends AssetUpdatedSubscriptionVariables>(
  subscription: GetSubscription<R, V>,
  variables?: V
): Observable<R> {
  return subscription(AssetUpdatedDocument, variables);
}
