import BigNumber from 'bignumber.js';
import { action, makeObservable, observable, onBecomeObserved, onBecomeUnobserved } from 'mobx';
import {
  NEVER,
  Observable,
  Subject,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  pairwise,
  retry,
  share,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';

import { Address } from 'core/address';
import { Asset } from 'core/asset/asset';
import { AssetsStore } from 'core/asset/assets.store';
import { chains } from 'core/chain';
import { ContractStore } from 'core/contract/contract.store';
import { Decimal } from 'core/decimal';
import { Logger } from 'core/logger';
import { NetworkProvider } from 'core/network/network.provider';

import { Disposable } from 'utils/disposable';
import { isSomething } from 'utils/is-something';
import { polling } from 'utils/polling';
import { RetryStrategy } from 'utils/retry-strategy';

import { Units } from 'types';

import { WalletStore } from './wallet.store';

const logger = new Logger('BalanceService');

/**
 * Wallet balance service
 * @description
 * This service is responsible for tracking the balance of the wallet.
 * It tracks only those assets that are currently displayed in the interface.
 * So it uses onBecomeObserved and onBecomeUnobserved to track visibility.
 **/

export abstract class BaseBalanceService extends Disposable {
  @observable
  private balanceMap = new Map<Asset, Units>();

  readonly balanceUpdated$: Observable<readonly [Asset, BigNumber]>;

  private balanceReceived$: Observable<readonly [Asset, Units]>;

  private observedAssets = new Set<Asset>();

  private observedAsset$ = new Subject<Asset>();

  private unobservedAsset$ = new Subject<Asset>();

  private trackers: (() => void)[] = [];

  constructor(
    private readonly contractStore: ContractStore,
    private readonly walletStore: WalletStore,
    private readonly assetsStore: AssetsStore,
    private readonly networkProvider: NetworkProvider,
    address$: Observable<Address | undefined>,
    refreshInterval = 4000
  ) {
    super();

    makeObservable(this);

    this.trackBalance(this.assetsStore.getAssetsByChain(this.networkProvider.chainId, true), []);

    this.autoDispose(this.walletStore.disconnect$.subscribe(this.cleanup));

    this.autoDispose(
      this.networkProvider.chain$
        .pipe(
          switchMap((chainId) =>
            this.assetsStore.assets$.pipe(map(() => this.assetsStore.getAssetsByChain(chainId, true)))
          ),
          startWith([] as Asset[]),
          pairwise()
        )
        .subscribe(([prevAssets, assets]) => {
          this.trackBalance(assets, prevAssets);
        })
    );

    this.autoDispose(
      this.observedAsset$.subscribe((value) => {
        this.observedAssets.add(value);
      })
    );

    this.autoDispose(
      this.unobservedAsset$.subscribe((value) => {
        this.observedAssets.delete(value);
      })
    );

    this.balanceReceived$ = address$.pipe(
      switchMap((address) =>
        address
          ? this.observedAsset$.pipe(
              startWith(...Array.from(this.observedAssets.values())),
              mergeMap((asset) =>
                polling(refreshInterval).pipe(
                  switchMap(() =>
                    asset.isNative
                      ? this.networkProvider.getProvider().getBalance(address)
                      : this.contractStore.getERC20Contract(asset.address).balanceOf(address)
                  ),
                  filter(isSomething),
                  map((balance) => [asset, balance.toString() as Units] as const),
                  retry(
                    RetryStrategy({
                      label: `Fetch ${asset.address} balance failed for ${address} ${this.networkProvider.chainId}`,
                    })
                  ),
                  takeUntil(this.unobservedAsset$.pipe(filter((value) => value === asset)))
                )
              )
            )
          : NEVER
      ),
      share()
    );

    this.balanceUpdated$ = this.balanceReceived$.pipe(
      distinctUntilChanged(([, b]) => b === b),
      map(([asset, balance]) => [asset, asset.toBigNumber(balance)] as const)
    );

    this.autoDispose(this.balanceReceived$.subscribe(this.updateBalance));
  }

  @action
  private trackBalance = (assets: Asset[], prevAssets: Asset[]): void => {
    if (this.balanceMap.size > 0) {
      this.balanceMap.clear();
      this.trackers.forEach((disposer) => disposer());
      prevAssets.forEach((value) => this.unobservedAsset$.next(value));
    }

    assets.forEach((value) => {
      this.balanceMap.set(value, '0' as Units);
      this.trackers.push(onBecomeObserved(this.balanceMap, value, () => this.observedAsset$.next(value)));
      this.trackers.push(onBecomeUnobserved(this.balanceMap, value, () => this.unobservedAsset$.next(value)));
    });
  };

  @action
  private updateBalance = ([asset, balance]: readonly [Asset, Units]): void => {
    const prevBalance = this.balanceMap.get(asset);

    this.balanceMap.set(asset, balance);

    if (prevBalance !== balance) {
      logger.debug(
        `Balance of ${asset.address} (${asset.symbol}) updated to ${balance.toString()}`,
        this.networkProvider.chainId && chains[this.networkProvider.chainId].name
      );
    }
  };

  @action
  private cleanup = (): void => {
    this.balanceMap.clear();
    this.trackers.forEach((disposer) => disposer());
  };

  getBalanceOf = (asset: Asset): BigNumber => {
    return asset.toBigNumber(this.balanceMap.get(asset)) ?? Decimal.empty();
  };
}
