import { field } from '@mobx-form-state/react';
import { injectable } from 'inversify';

import { ManagerFocusTime, ManagerSentiment, RiskProfile, VaultTag } from 'core/api/schema';
import { ChainID } from 'core/chain';

@injectable()
export class VaultFiltersModel {
  @field()
  searchTerm = '';

  @field()
  riskProfile: RiskProfile[] = [];

  @field()
  marketCapSegment: number[] = [1000000, 5000000000];

  @field()
  managerSentiment: ManagerSentiment[] = [];

  @field()
  managerFocusTime: ManagerFocusTime[] = [];

  @field()
  tags: VaultTag[] = [];

  @field()
  chains: ChainID[] = [];
}
