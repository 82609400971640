import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgSecondLogo = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 67 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M29.007 16.928h2.882L35.75 6.241h-2.432l-2.759 8.153-2.8-8.153h-2.656l3.903 10.687ZM39.263 17.132c1.635 0 2.575-.592 3.27-1.512v1.308h2.431V9.919c0-2.922-1.88-3.903-4.27-3.903-2.392 0-4.374 1.042-4.578 3.535h2.39c.123-1.062.757-1.675 2.044-1.675 1.451 0 1.942.715 1.942 2.145v.552h-1.329c-3.065 0-5.476.899-5.476 3.392 0 2.227 1.614 3.167 3.576 3.167Zm.593-1.777c-1.227 0-1.717-.593-1.717-1.492 0-1.308 1.206-1.737 3.086-1.737h1.267v1.063c0 1.369-1.124 2.166-2.636 2.166ZM46.94 16.928h2.472V1.296H46.94v15.632ZM51.568 16.928h2.473V6.241h-2.473v10.687Zm1.206-12.445c.817 0 1.47-.613 1.47-1.41 0-.817-.653-1.43-1.47-1.43-.818 0-1.472.613-1.472 1.43 0 .797.654 1.41 1.472 1.41ZM60.916 15.211c-1.88 0-2.963-1.369-2.963-3.555v-.164c0-2.206 1.124-3.535 2.963-3.535 1.84 0 2.943 1.349 2.943 3.556v.143c0 2.186-1.104 3.555-2.943 3.555Zm-.02 1.921c3.147 0 5.497-2.166 5.497-5.497v-.163c0-3.27-2.35-5.456-5.477-5.456-3.146 0-5.496 2.207-5.496 5.517v.164c0 3.249 2.33 5.435 5.476 5.435Z"
      fill="#fff"
    />
    <path
      d="M9.052 5.414a.566.566 0 0 0 .534 0L18.381.742a.17.17 0 0 1 .169.008.177.177 0 0 1 .082.152v3.756a.61.61 0 0 1-.085.31.586.586 0 0 1-.23.22L9.576 9.846a.17.17 0 0 1-.233-.07.183.183 0 0 1-.022-.09V8.137a.61.61 0 0 0-.084-.314.586.586 0 0 0-.23-.221L.314 2.986a.596.596 0 0 1-.23-.222A.62.62 0 0 1 0 2.45V.901A.183.183 0 0 1 .083.748.17.17 0 0 1 .255.742l8.797 4.695v-.023Zm0 5.827L.255 6.545a.17.17 0 0 0-.233.071.183.183 0 0 0-.022.089v1.554c0 .111.028.22.084.316a.596.596 0 0 0 .23.224l8.665 4.616a.585.585 0 0 1 .248.223.61.61 0 0 1 .09.326v1.55a.183.183 0 0 0 .083.154.17.17 0 0 0 .172.005l8.746-4.648a.586.586 0 0 0 .233-.22.609.609 0 0 0 .086-.31V6.737a.182.182 0 0 0-.083-.155.17.17 0 0 0-.172-.005l-8.806 4.64a.565.565 0 0 1-.533 0l.009.023Zm-.01 5.784L.256 12.35a.173.173 0 0 0-.17.008.181.181 0 0 0-.085.151v1.555c0 .11.029.217.084.31.056.094.136.17.23.22l8.665 4.62a.58.58 0 0 1 .23.22.605.605 0 0 1 .085.31v1.555a.188.188 0 0 0 .012.107.181.181 0 0 0 .07.082.174.174 0 0 0 .2-.01l8.747-4.649a.591.591 0 0 0 .233-.221.615.615 0 0 0 .086-.314v-3.756a.187.187 0 0 0-.085-.152.173.173 0 0 0-.17-.008l-8.81 4.648a.566.566 0 0 1-.534 0"
      fill="#9340F4"
    />
  </svg>
));
export default SvgSecondLogo;
