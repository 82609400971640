import { Context } from '@redtea/react-inversify';
import { RouterProvider } from 'react-router-dom';

import { appContainer } from 'core/app.container';
import { AppRouter } from 'core/app/app.router';
import { ConfigService } from 'core/config/config.service';
import { PrivyConnector } from 'core/privy/privy-connector';

import { Loading } from 'components/loading';
import { MobileNotAvailable } from 'components/mobile-not-available';

import { router } from 'pages';

import { ErrorBoundary } from './error-boundary';

appContainer.get(AppRouter).inject(router);

const { theme } = appContainer.get(ConfigService);

const htmlTag = document.getElementsByTagName('html')[0];

htmlTag.className = htmlTag.className + theme;

export const App = () => (
  <Context.Provider value={appContainer}>
    <PrivyConnector>
      <ErrorBoundary>
        <RouterProvider router={router} fallbackElement={<Loading />} />
      </ErrorBoundary>

      <MobileNotAvailable />
    </PrivyConnector>
  </Context.Provider>
);
