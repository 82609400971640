import { MouseEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export type MenuHook = {
  isOpen: boolean;
  onClose: () => void;
  anchorEl: Element | null;
};

export function useMenu(): {
  handleClick: (e: MouseEvent<Element>) => void;
  state: MenuHook;
} {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const location = useLocation();

  useEffect(() => {
    setAnchorEl(null);
  }, [location.pathname]);

  const handleClick = (e: MouseEvent<Element>) => setAnchorEl(!anchorEl ? e.currentTarget : null);

  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  return { handleClick, state: { isOpen: open, onClose: handleClose, anchorEl } };
}
