import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { ModalsStore } from 'lib/modals/modals.store';

import { Button, ButtonVariant } from 'components/button';
import { H1 } from 'components/typography';

import BoxOne from 'assets/img/box-1.png';

export const RewardSZN0ReceivedModal = observer(() => {
  const { closeAll } = useService(ModalsStore);

  const handleClose = () => closeAll();

  return (
    <div className="flex flex-col items-center justify-center gap-8 w-[557px] p-[55px]">
      <H1>You received a lootbox!</H1>
      <div className="relative">
        <H1 className="text-[57px] absolute -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4">?</H1>
        <img className="w-[283px] mix-blend-screen blur-sm" src={BoxOne} />
        <div className='flex  items-center justify-center absolute w-[75px] h-[75px] backdrop-blur-[3.5px] rounded-full right-5 top-5 border border-white/15 bg-white/15 after:content-[""] after:absolute after:w-[65px] after:h-[65px] after:-translate-x-2/4 after:-translate-y-2/4 after:rounded-full after:left-2/4 after:top-2/4 after:border-white/15 after:bg-white/15'>
          <H1>1x</H1>
        </div>
      </div>

      <div className="flex w-full gap-2.5">
        <Button fullWidth onClick={handleClose}>
          Later
        </Button>
        <Link to="/szn0/reveal" className="w-full">
          <Button fullWidth variant={ButtonVariant.Contained} onClick={handleClose}>
            Reveal now
          </Button>
        </Link>
      </div>
    </div>
  );
});

RewardSZN0ReceivedModal.displayName = 'RewardSZN0ReceivedModal';
