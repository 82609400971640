import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgCycleArrows = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.797 4.85c-1.141-1.094-2.74-1.498-4.232-1.212l.245.236a.869.869 0 0 1 0 1.246.962.962 0 0 1-1.3 0L2.632 3.301a.869.869 0 0 1 0-1.246L4.511.253a.962.962 0 0 1 1.3 0 .869.869 0 0 1 0 1.246l-.352.337c1.985-.303 4.11.27 5.638 1.751a6.078 6.078 0 0 1 1.528 6.518c-.229.606-1.036.775-1.51.32a.866.866 0 0 1-.211-.926A4.389 4.389 0 0 0 9.797 4.85ZM.383 5.878c.229-.606 1.037-.775 1.51-.32.247.252.335.606.212.926-.58 1.583-.211 3.402 1.088 4.665 1.142 1.095 2.74 1.5 4.233 1.213l-.246-.236a.869.869 0 0 1 0-1.246.962.962 0 0 1 1.3 0l1.88 1.819a.869.869 0 0 1 0 1.246l-1.88 1.802a.962.962 0 0 1-1.3 0 .869.869 0 0 1 0-1.246l.351-.337c-1.984.303-4.11-.27-5.637-1.751C.067 10.644-.442 8.084.384 5.878Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgCycleArrows;
