import { field } from '@mobx-form-state/react';
import { z } from 'zod';

import type { Address } from 'core/address';

import { Validators } from 'utils/validators';

export class GodModeModel {
  @field({
    validate: Validators.fromZod(z.string().startsWith('0x').min(42).max(42)),
  })
  forceAddress?: Address;
}
