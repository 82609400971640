import BigNumber from 'bignumber.js';

import { VaultAssetFragment } from 'core/api/gql/vault/vault-asset.fragment.generated';
import { GetAssetBySymbol } from 'core/asset/assets.store';
import { ChainID, chains } from 'core/chain';
import { Decimal } from 'core/decimal';

import { isSomething } from 'utils/is-something';

import { VaultAssetInfo } from './vault-asset.info';

export const mapVaultAssetFromApi = (vaultAssets: VaultAssetFragment[], getAsset: GetAssetBySymbol): VaultAssetInfo[] =>
  vaultAssets
    .map((item) => {
      const asset = getAsset(item.asset.symbol, item.asset.chainId, item.asset.type);
      const chain = chains[item.asset.chainId as ChainID];

      if (!asset || !chain) return null;

      return {
        asset,
        chainId: chain.chainId,
        amount: item.amount,
        value: Decimal.precise(item.value),
        allocation: item.allocation.toNumber(),
        priceChangePercentage: item.asset.priceChangePercentage?.toNumber(),
        priceChangeAmount: Decimal.precise(item.asset.priceChangeAmount),
        type: asset.type,
        pAndL: new BigNumber(item.pAndL),
        roi: item.roi.toNumber(),
      };
    })
    .filter(isSomething);
