import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgMediumTerm = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle opacity={0.2} cx={8.708} cy={8.708} r={8.708} fill="#9F44FF" />
    <path
      d="M8.708 16.076c0 .74.603 1.35 1.334 1.236a8.708 8.708 0 0 0 0-17.21C9.311-.01 8.708.6 8.708 1.34v14.736Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgMediumTerm;
