import { useService } from '@redtea/react-inversify';
import Lottie from 'react-lottie-player';
import { OneOfTheme } from 'styles/themes';
import anodosLottie from 'styles/themes/anodos/anodos-loader.json';

import { ConfigService } from 'core/config/config.service';

import logoLottieWhite from './logo-lottie-white.json';
import logoLottie from './logo-lottie.json';

type VaultLoaderProps = {
  width: number;
  height: number;
  white?: boolean;
};

export const VaultLoader = ({ width, height, white }: VaultLoaderProps) => {
  const { theme } = useService(ConfigService);

  const loader = getLoaderByTheme(theme, { width, height, white });

  return loader;
};

export const Loading = () => (
  <div className="flex items-center justify-center flex-1 h-full">
    <VaultLoader width={136} height={56} />
  </div>
);

const getLoaderByTheme = (theme: OneOfTheme, props: VaultLoaderProps) => {
  switch (theme) {
    case 'anodos':
      return (
        <Lottie
          style={{ width: props.width, height: props.height, margin: 0 }}
          loop
          play
          animationData={anodosLottie}
          rendererSettings={{ preserveAspectRatio: 'xMidYMid' }}
        />
      );
    default:
      return (
        <Lottie
          style={{ width: props.width, height: props.height, margin: 0 }}
          loop
          play
          animationData={props.white ? logoLottieWhite : logoLottie}
          rendererSettings={{ preserveAspectRatio: 'xMidYMid' }}
        />
      );
  }
};
