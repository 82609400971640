import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgController = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 15 10" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m14.884 6.822-1.64-5.15A2.367 2.367 0 0 0 10.994 0H4.005a2.367 2.367 0 0 0-2.25 1.673l-1.64 5.15A2.483 2.483 0 0 0 .341 8.83 2.353 2.353 0 0 0 2.083 9.98a2.326 2.326 0 0 0 1.961-.69l.659-.675c.241-.247.573-.39.916-.39h3.762c.343 0 .675.143.916.39l.659.674a2.342 2.342 0 0 0 1.961.691A2.353 2.353 0 0 0 14.66 8.83c.353-.604.439-1.333.225-2.008Zm-4.705-4.628c.295 0 .536.246.536.548a.544.544 0 0 1-.536.548.544.544 0 0 1-.536-.548c0-.302.241-.548.536-.548ZM5.893 4.387h-.536v.549a.544.544 0 0 1-.536.548.544.544 0 0 1-.536-.548v-.549H3.75a.544.544 0 0 1-.536-.548c0-.302.242-.549.536-.549h.536v-.548c0-.302.241-.548.536-.548.295 0 .536.246.536.548v.548h.536c.294 0 .535.247.535.549a.544.544 0 0 1-.535.548Zm3.214 0a.544.544 0 0 1-.535-.548c0-.302.24-.549.535-.549.295 0 .536.247.536.549a.544.544 0 0 1-.536.548Zm1.072 1.097a.544.544 0 0 1-.536-.548c0-.302.241-.549.536-.549.295 0 .536.247.536.549a.544.544 0 0 1-.536.548Zm1.072-1.097a.544.544 0 0 1-.536-.548c0-.302.241-.549.536-.549.295 0 .536.247.536.549a.544.544 0 0 1-.536.548Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgController;
