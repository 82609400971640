import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgAaveLending = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 17c0 9.389-7.611 17-17 17S0 26.389 0 17 7.611 0 17 0s17 7.611 17 17ZM18.691 9.876l5.748 13.898a.919.919 0 0 1 .07.39c0 .324-.096.578-.28.775-.184.197-.438.294-.762.294a1.03 1.03 0 0 1-.592-.197c-.184-.128-.31-.312-.412-.522l-5.468-13.6-2.006 5h.972a1.04 1.04 0 0 1 1.03 1.029v.026a1.037 1.037 0 0 1-1.03 1.03H14.14l-2.63 6.52c-.1.21-.227.394-.411.52a1.02 1.02 0 0 1-.591.198c-.325 0-.583-.096-.763-.293-.18-.198-.28-.452-.28-.776 0-.136.026-.267.07-.394l2.388-5.77h-1.017a1.04 1.04 0 0 1-1.03-1.03v-.013a1.037 1.037 0 0 1 1.03-1.03h1.893L15.3 9.876c.324-.719.806-1.07 1.441-1.07h.509c.635 0 1.117.351 1.441 1.07Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgAaveLending;
