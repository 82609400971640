/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';

export type GetVaultRoiQueryVariables = Types.Exact<{
  uid: Types.Scalars['String']['input'];
  dateRange: Types.ChartDatasetRange;
}>;

export type GetVaultRoiQuery = {
  __typename?: 'Query';
  roiDataset: Array<{ __typename?: 'RoiDataset'; key: string; value: string }>;
};

export const GetVaultRoiDocument = gql`
  query GetVaultRoi($uid: String!, $dateRange: ChartDatasetRange!) {
    roiDataset(uid: $uid, dateRange: $dateRange) {
      key
      value
    }
  }
`;

export function GetVaultRoi<R extends GetVaultRoiQuery, V extends GetVaultRoiQueryVariables>(
  request: Request<R, V>,
  variables: V,
  headers?: HeadersInit
): Promise<R> {
  return request(GetVaultRoiDocument, variables, headers);
}
