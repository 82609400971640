import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgSzn0Logo = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 57 26" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0a8 8 0 0 0-8 8v10a8 8 0 0 0 8 8h41a8 8 0 0 0 8-8V8a8 8 0 0 0-8-8H8Zm34.237 5.33A4.465 4.465 0 0 1 43.89 5c.567 0 1.124.11 1.654.321a4.309 4.309 0 0 1 2.648 3.984v1.423c0 .45-.093.9-.27 1.322a3.484 3.484 0 0 1-1.886 1.873 3.549 3.549 0 0 1-1.338.266V8.663a.741.741 0 0 0-.214-.524.728.728 0 0 0-.241-.156.801.801 0 0 0-.288-.055h-.13a.765.765 0 0 0-.53.211.74.74 0 0 0-.214.523v2.066h-3.484V9.305c-.01-.56.102-1.12.316-1.643a4.318 4.318 0 0 1 2.323-2.332Zm1.703 11.838h-.129a.784.784 0 0 1-.286-.056.834.834 0 0 1-.24-.158.762.762 0 0 1-.212-.53v-5.601a3.472 3.472 0 0 0-3.207 2.168 3.537 3.537 0 0 0-.268 1.34v1.423a4.3 4.3 0 0 0 .314 1.665c.212.53.526 1.014.924 1.414.397.41.868.726 1.386.949a4.162 4.162 0 0 0 1.644.335c.564 0 1.128-.112 1.645-.335.517-.223.998-.54 1.386-.95.397-.408.712-.883.924-1.413.213-.53.314-1.098.314-1.665V14.33H44.67v2.093a.763.763 0 0 1-.212.53.724.724 0 0 1-.24.159.672.672 0 0 1-.278.056ZM9.502 7.243c-.203.5-.305 1.076-.305 1.733a4.6 4.6 0 0 0 .325 1.751c.223.532.495.975.816 1.329.329.348.735.696 1.22 1.043l1.713 1.23c.505.361.893.742 1.162 1.142.275.394.413.853.413 1.378 0 .499-.154.892-.463 1.18-.308.29-.725.434-1.25.434-.518 0-.934-.174-1.25-.522-.308-.354-.462-.83-.462-1.427v-1.072H9v1.21c0 1.253.39 2.238 1.171 2.953.788.715 1.775 1.072 2.963 1.072.774 0 1.466-.147 2.076-.442.617-.296 1.112-.758 1.486-1.388.38-.637.571-1.401.571-2.293 0-.893-.21-1.66-.63-2.303-.42-.643-1.004-1.234-1.752-1.772l-1.771-1.27c-.486-.347-.856-.691-1.112-1.033a1.945 1.945 0 0 1-.384-1.2c0-.506.134-.896.403-1.172.276-.282.64-.423 1.093-.423.485 0 .86.154 1.122.463.269.308.403.777.403 1.407v.955h2.421V9.192c0-.88-.18-1.627-.541-2.244-.354-.617-.823-1.066-1.407-1.348-.584-.289-1.25-.433-1.998-.433a5.07 5.07 0 0 0-1.526.216c-.459.145-.872.368-1.24.67-.36.295-.643.692-.846 1.19ZM25.6 20.48v-2.214h-5.56l5.363-10.078V5.364h-7.559v2.214h5.275l-5.383 10.078v2.825H25.6ZM35.654 5.364V20.48h-3.966L28.805 7.214V20.48H26.58V5.364h4.015l2.844 13.08V5.363h2.215Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgSzn0Logo;
