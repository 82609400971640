import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgRemove = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m8.874 7.5 5.841-5.84A.97.97 0 1 0 13.341.284l-5.84 5.84L1.658.286A.97.97 0 1 0 .285 1.659L6.125 7.5l-5.84 5.841a.97.97 0 1 0 1.374 1.374L7.5 8.875l5.841 5.84a.97.97 0 0 0 1.374 0 .97.97 0 0 0 0-1.374l-5.84-5.84Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgRemove;
