import { FC } from 'react';

export type ChainInfo = {
  name: string;
  chainId: ChainID;
  symbol: string;
  symbolAlias: string; // points to the asset that is used as the native asset of the chain (e.g. WETH for Ethereum)
  blockExplorerUrl: string;
  Icon: FC;
};

export type AnyChain = number;

export enum ChainID {
  Ethereum = 1,
  Arbitrum = 42161,
  Optimism = 10,
}

export const MainChainID = ChainID.Arbitrum;
