import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgChat = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.5 0C3.368 0 0 3.362 0 7.5c0 1.436.4 2.81 1.157 4.004L.048 14.158A.612.612 0 0 0 .606 15a.517.517 0 0 0 .14-.018l3.392-.782c1.036.527 2.193.8 3.362.8 4.137 0 7.5-3.362 7.5-7.5S11.638 0 7.5 0ZM5.355 5.458H7.5c.333 0 .606.273.606.606a.608.608 0 0 1-.606.606H5.355a.604.604 0 0 1-.605-.606c0-.333.266-.606.605-.606Zm4.29 4.083h-4.29a.604.604 0 0 1-.605-.606c0-.333.266-.605.605-.605h4.29c.339 0 .606.272.606.605a.604.604 0 0 1-.606.606Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgChat;
