import BigNumber from 'bignumber.js';
import { BigNumberish } from 'ethers';

import { Address } from 'core/address';
import { ChainID } from 'core/chain';

import { Market } from './market';

export namespace Kwenta {
  export const MainChainID = ChainID.Optimism;

  export const MarginAssetCode = 'sUSD';

  export const MinMargin = 50;

  export const AcceptablePriceSlippage = 1;

  export type Asset = string;

  export enum Side {
    Long = 'Long',
    Short = 'Short',
  }

  export enum OrderType {
    Market = 'Market',
    Limit = 'Limit',
  }

  export type MarketFeeRates = {
    makerFee: BigNumberish;
    makerFeeDelayedOrder: BigNumberish;
    makerFeeOffchainDelayedOrder: BigNumberish;
    takerFee: BigNumberish;
    takerFeeDelayedOrder: BigNumberish;
    takerFeeOffchainDelayedOrder: BigNumberish;
  };

  export type MarketInfo = {
    key: string;
    asset: string;
    baseAsset: string;
    quoteAsset: string;
    currentFundingRate: BigNumberish;
    currentFundingVelocity: BigNumberish;
    feeRates: MarketFeeRates;
    market: Address;
    marketDebt: BigNumberish;
    marketSize: BigNumberish;
    marketSkew: BigNumberish;
    maxLeverage: number;
    price: BigNumber;
  };

  export type Position = {
    id: BigNumberish;
    lastFundingIndex: BigNumberish;
    lastPrice: BigNumber;
    margin: BigNumber;
    size: BigNumber;
  };

  export type PositionDetails = {
    accessibleMargin: BigNumber;
    accruedFunding: BigNumberish;
    canLiquidatePosition: boolean;
    liquidationPrice: BigNumber;
    notionalValue: BigNumber;
    position: Position;
    profitLoss: BigNumber;
    remainingMargin: BigNumber;
  };

  export type MarketPositionDetails = {
    market: Market;
    details: PositionDetails;
  };

  export const getPositionDetailsDefault = (): PositionDetails => ({
    accessibleMargin: new BigNumber(0),
    accruedFunding: 0,
    canLiquidatePosition: false,
    liquidationPrice: new BigNumber(0),
    notionalValue: new BigNumber(0),
    position: {
      id: 0,
      lastFundingIndex: 0,
      lastPrice: new BigNumber(0),
      margin: new BigNumber(0),
      size: new BigNumber(0),
    },
    profitLoss: new BigNumber(0),
    remainingMargin: new BigNumber(0),
  });
}
