import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgParachute = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 13 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 5.649C0 2.814 2.808.526 6.273.526s6.272 2.288 6.286 5.123a.27.27 0 0 1-.082.191l-4.109 4.37a.866.866 0 0 0-.383-.384l3.958-4.219c-.069-.273-.699-.657-1.685-.657-1.054 0-1.63.356-1.726.699L7.286 9.66h-.575l1.219-3.93c.014-.082.014-.165-.028-.233-.178-.26-.767-.548-1.63-.548-.903 0-1.506.301-1.657.562a.266.266 0 0 0 0 .205l1.22 3.93h-.59L4 5.636c-.082-.315-.671-.699-1.712-.699-.986 0-1.616.384-1.684.658l3.971 4.232a.866.866 0 0 0-.383.383L.082 5.84A.27.27 0 0 1 0 5.65Zm5.15 4.56h2.246c.328 0 .589.26.589.59v2.15c0 .315-.26.575-.576.575H5.15a.579.579 0 0 1-.575-.575v-2.164c0-.315.26-.575.576-.575Zm1.191 2.493.726-.726a.496.496 0 0 0-.123-.78.484.484 0 0 0-.562.109l-.096.096-.11-.11a.49.49 0 0 0-.78.137c-.096.178-.04.411.11.562l.712.712c.027.027.082.027.123 0Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgParachute;
