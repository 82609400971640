import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgGas = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.383 2.647 12.366.133a.571.571 0 1 0-.732.878l2.517 2.097c-.264.21-.437.53-.437.892 0 .63.513 1.143 1.143 1.143v5.143a1.144 1.144 0 0 1-2.286 0V7.429c0-.946-.768-1.715-1.714-1.715h-.571V2.286A2.288 2.288 0 0 0 8 0H3.429a2.288 2.288 0 0 0-2.286 2.286V13.54l-1.047 1.57A.571.571 0 0 0 .571 16h10.286a.572.572 0 0 0 .476-.888l-1.047-1.57V6.856h.571c.315 0 .572.256.572.571v2.858a2.288 2.288 0 0 0 2.285 2.285A2.288 2.288 0 0 0 16 10.286V3.964c0-.51-.225-.99-.617-1.317ZM8.286 5.714H3.143a.286.286 0 0 1-.286-.285V2.57c0-.157.128-.285.286-.285h5.143c.158 0 .285.128.285.285V5.43a.286.286 0 0 1-.285.285Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgGas;
