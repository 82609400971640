import { BytesLike, ContractTransaction, PayableOverrides } from 'ethers';
import { injectable } from 'inversify';
import invariant from 'tiny-invariant';

import { AssetTypeEnum } from 'core/api/schema';
import { ChainService } from 'core/chain/chain.service';
import { VaultStore } from 'core/vault/vault.store';

import { isSomething } from 'utils/is-something';

import { Valio } from './valio';
import { VaultContract } from './vault.contract';

@injectable()
export class VaultExecutor {
  constructor(
    private readonly vaultStore: VaultStore,
    private readonly vaultContract: VaultContract,
    private readonly chainService: ChainService
  ) {}

  readonly execute = async (
    integration: Valio.ExecutorIntegration,
    payload: BytesLike,
    overrides?: PayableOverrides & { from?: string }
  ): Promise<ContractTransaction> => {
    const cleanParams = this.getCleanParams(integration);

    invariant(this.vaultStore.activeVault, 'Active vault is not defined');

    const vaultAddress = this.vaultStore.activeVault.address;

    invariant(vaultAddress, 'Vault address is not defined');

    const vault = this.vaultContract.getVaultBaseContract(vaultAddress, true);

    return vault.executeAndClean(integration, payload, cleanParams, { ...overrides });
  };

  readonly estimateGas = async (
    integration: Valio.ExecutorIntegration,
    payload: BytesLike,
    overrides?: PayableOverrides & { from?: string }
  ) => {
    const cleanParams = this.getCleanParams(integration);

    invariant(this.vaultStore.activeVault, 'Active vault is not defined');

    const vaultAddress = this.vaultStore.activeVault.parentAddress;

    const vault = this.vaultContract.getVaultParentContract(vaultAddress, true);

    return vault.estimateGas.executeAndClean(integration, payload, cleanParams, { ...overrides });
  };

  private getCleanParams = (integration: Valio.ExecutorIntegration): Valio.ExecutorIntegration[] => {
    const hasGmx = isSomething(
      this.vaultStore.activeVault?.assets.find(
        (asset) => asset.type === AssetTypeEnum.Gmx && asset.chainId === this.chainService.chain.chainId
      )
    );

    const cleanParams = [];

    if (hasGmx && integration !== Valio.ExecutorIntegration.GMX) {
      cleanParams.push(Valio.ExecutorIntegration.GMX);
    }

    return cleanParams;
  };
}
