import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgArrowRight = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 5.496a1.901 1.901 0 0 0-.506-1.285L7.817.267A.83.83 0 0 0 7.213 0a.83.83 0 0 0-.604.267.924.924 0 0 0-.188.298.973.973 0 0 0 .188 1.004l2.82 3.01H.857a.83.83 0 0 0-.606.268.951.951 0 0 0-.251.649c0 .243.09.476.251.648a.83.83 0 0 0 .606.269H9.43L6.609 9.43a.951.951 0 0 0-.254.648c0 .243.089.477.25.65A.83.83 0 0 0 7.21 11a.83.83 0 0 0 .607-.267l3.677-3.944c.322-.343.504-.808.506-1.293Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgArrowRight;
