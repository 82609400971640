import { useEffect } from 'react';
import { useTheme } from 'styles/themes/use-theme.hook';

export const useMeta = () => {
  const { meta } = useTheme();

  useEffect(() => {
    if (meta) {
      document.title = meta.title;
      document.querySelector('meta[name="description"]')?.setAttribute('content', meta.description);
      document.querySelector('link[rel="icon"]')?.setAttribute('href', meta.icon);
    }
  }, [meta]);
};
