import { ComponentProps, ReactNode, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { CallToAction } from 'components/button/call-to-action';

export enum IconButtonSize {
  Medium = 'Medium',
  Large = 'Large',
}

export type IconButtonProps = ComponentProps<'button'> & {
  icon: ReactNode | string;
  size?: IconButtonSize;
  active?: boolean;
  animation?: boolean;
  pulseAnimation?: boolean;
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const { icon, type, active, animation, size, className, pulseAnimation, ...rest } = props;

  return (
    <button
      ref={ref}
      className={twMerge(
        'icon-button group/icon-btn',
        active && 'icon-button-active',
        IconButtonSize.Medium === size && 'icon-button-medium',
        IconButtonSize.Large === size && 'icon-button-large',
        pulseAnimation && 'icon-button-pulse',
        className
      )}
      type={type || 'button'}
      {...rest}
    >
      <div className="relative w-[18px] h-[18px] flex justify-center items-center">
        {typeof icon === 'string' ? <img src={icon} alt="icon-button-icon" /> : icon}
      </div>
      {animation && <CallToAction />}
    </button>
  );
});
