import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgCalendarWithArrow = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path
        d="M16.15 1.7h-1.7V.85a.85.85 0 1 0-1.7 0v.85h-8.5V.85a.85.85 0 1 0-1.7 0v.85H.85a.85.85 0 0 0-.85.85v13.6a.85.85 0 0 0 .85.85h15.3a.85.85 0 0 0 .85-.85V2.55a.85.85 0 0 0-.85-.85Zm-.85 13.6H1.7V6.8h13.6v8.5Zm0-10.2H1.7V3.4h13.6v1.7Z"
        fill="currentColor"
      />
      <path
        d="M5.95 11.9h3.052l-.255.246a.85.85 0 0 0 0 1.207.85.85 0 0 0 1.207 0l1.7-1.7a.723.723 0 0 0 .161-.255.722.722 0 0 0 .085-.348v-.085a.848.848 0 0 0-.042-.213.257.257 0 0 0 0-.085.853.853 0 0 0-.162-.255l-1.7-1.7A.853.853 0 1 0 8.79 9.92l.255.247H5.95a.85.85 0 1 0 0 1.7v.034Z"
        fill="currentColor"
      />
    </g>
  </svg>
));
export default SvgCalendarWithArrow;
