import {
  ChangeEvent,
  ComponentProps,
  ComponentPropsWithoutRef,
  FocusEvent,
  HTMLInputTypeAttribute,
  ReactNode,
  WheelEvent,
  forwardRef,
  memo,
  useContext,
  useEffect,
} from 'react';
import { twMerge } from 'tailwind-merge';

import { TextFieldBase, TextFieldBaseProps } from 'components/text-field-base';

import { TextFieldSize } from '.';
import { TextFieldContext, useTextField } from './text-field.context';

export type TextFieldProps = Omit<TextFieldBaseProps, 'onClick'> & {
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  name?: string;
  disabled?: boolean;
  multiline?: boolean;
  placeholder?: string;
  value?: string | number;
  rows?: number;
  showCharCount?: boolean;
  maxLength?: number;
  subValue?: ReactNode;
  leftPadding?: number;
  inputType?: HTMLInputTypeAttribute;
  autoFocus?: boolean;
};

export const TextField = memo<TextFieldProps>((props) => {
  const {
    fullWidth,
    endIcon,
    startIcon,
    className,
    onFocus,
    onBlur,
    onChange,
    showCharCount,
    maxLength,
    size = TextFieldSize.Default,
    subValue,
    error,
    minWidth,
    inputType,
    disabled,
    ...rest
  } = props;
  const context = useContext(TextFieldContext);
  const textField = useTextField();

  const { active, setActive, setError } = context || textField;

  useEffect(() => {
    setError(Boolean(error));
  }, [error, setError]);

  const handleBlur = (e: FocusEvent<HTMLElement>) => {
    setActive(false);
    onBlur && onBlur(e as FocusEvent<HTMLTextAreaElement | HTMLInputElement>);
  };

  const handleFocus = (e: FocusEvent<HTMLElement>) => {
    setActive(true);
    onFocus && onFocus(e as FocusEvent<HTMLTextAreaElement | HTMLInputElement>);
  };

  const handleChange = (e: ChangeEvent<HTMLElement>) => {
    onChange && onChange(e as ChangeEvent<HTMLTextAreaElement | HTMLInputElement>);
  };

  const handleWheel = (e: WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  };

  const charCount = rest.value?.toString().length;
  const hasSubValue = typeof subValue !== 'undefined';

  const input = props.multiline ? (
    <TextAreaField
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleChange}
      size={size}
      hasSubValue={hasSubValue}
      error={error}
      disabled={disabled}
      maxLength={maxLength}
      {...rest}
    />
  ) : (
    <InputField
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleChange}
      onWheel={handleWheel}
      size={size}
      hasSubValue={hasSubValue}
      type={inputType}
      error={error}
      disabled={disabled}
      maxLength={maxLength}
      {...rest}
    />
  );

  return (
    <TextFieldBase {...{ fullWidth, endIcon, startIcon, className, active, size, error, minWidth, disabled }}>
      {hasSubValue ? (
        <div className="flex flex-col flex-1">
          {input}
          <span className="absolute pointer-events-none text-sm text-secondary font-medium left-3.5 bottom-2.5">
            {subValue}
          </span>
        </div>
      ) : (
        input
      )}

      {showCharCount && (
        <div className="absolute right-0 bottom-0 my-2.5 mx-[13px] text-icon-secondary pointer-events-none select-none text-sm ">
          {charCount}
          {maxLength ? `/${maxLength}` : ''}
        </div>
      )}
    </TextFieldBase>
  );
});

type InputFieldClassNamesParams = {
  size: TextFieldSize;
  hasSubValue?: boolean;
  error?: boolean;
  className?: string;
};

const inputFieldClassNames = (params: InputFieldClassNamesParams) =>
  twMerge(
    'flex-1 w-full overflow-hidden font-graphik text-sm font-medium text-primary bg-transparent border-none py-2.5 px-3.5',
    params.size === TextFieldSize.Large && 'text-lg',
    params.hasSubValue && 'pt-[9px] pb-[26px]',
    params.error && 'text-negative hover:text-negative',
    'placeholder:text-sm placeholder:text-secondary',
    params.className
  );

type InputFieldProps = ComponentProps<'input'> & InputFieldClassNamesParams;

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  ({ className, size, hasSubValue, error, ...props }, ref) => (
    <input ref={ref} className={inputFieldClassNames({ size, hasSubValue, error, className })} {...props} />
  )
);
type TextAreaFieldProps = ComponentPropsWithoutRef<'textarea'> & InputFieldClassNamesParams;

export const TextAreaField = ({ className, size, hasSubValue, error, ...props }: TextAreaFieldProps) => (
  <textarea className={inputFieldClassNames({ size, hasSubValue, error, className })} {...props} />
);
