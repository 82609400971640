import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgFlame = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 11 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.603 4.434C7.905 3.93 7.496 3.331 7.39 2.653c-.183-1.15.558-2.177.565-2.186a.294.294 0 0 0-.002-.352.288.288 0 0 0-.335-.095c-4.307 1.7-4.508 5.438-4.47 6.542-.832-.046-1.2-1.067-1.204-1.077a.29.29 0 0 0-.207-.188.287.287 0 0 0-.27.076C-.141 6.963-.006 9.249.01 9.47c.007 3.048 2.465 5.525 5.487 5.53h.007C8.534 15 11 12.514 11 9.458c.014-3.612-2.138-4.884-2.397-5.024Zm-3.101 9.982h-.005a1.657 1.657 0 0 1-1.645-1.663c0-.71 1.03-2.695 1.649-3.795.617 1.1 1.648 3.084 1.648 3.795 0 .917-.739 1.662-1.647 1.663Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgFlame;
