import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgSuccessfulNeon = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#successful-neon_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33 50c9.389 0 17-7.611 17-17s-7.611-17-17-17-17 7.611-17 17 7.611 17 17 17Zm6-20.715c0-.34-.129-.667-.358-.909a1.195 1.195 0 0 0-.866-.376c-.324 0-.636.135-.865.377l-5.254 5.52-1.583-1.663a1.193 1.193 0 0 0-.86-.361c-.322.003-.629.138-.855.376a1.32 1.32 0 0 0-.359.898c-.003.338.12.662.344.905l2.447 2.572c.23.24.541.376.866.376.324 0 .635-.135.865-.377l6.12-6.428a1.32 1.32 0 0 0 .358-.91Z"
        fill="#F7FAFF"
      />
    </g>
    <defs>
      <filter
        id="successful-neon_svg__a"
        x={0.233}
        y={0.233}
        width={65.533}
        height={65.533}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={7.883} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_7558_23262" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={4.3} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_7558_23262" result="effect2_dropShadow_7558_23262" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.433} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_7558_23262" result="effect3_dropShadow_7558_23262" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_7558_23262" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgSuccessfulNeon;
