/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { AssetFragmentDoc } from '../asset/asset.fragment.generated';

export type GmxActionFragment = {
  __typename: 'GmxActionType';
  id: string;
  action: Types.ExecutorAction;
  inputTokenAmount: string;
  outputTokenAmount: string;
  transaction: string;
  chainId: number;
  chain: string;
  memo?: string | null;
  unitPrice: string;
  createdAt: string;
  inputToken: {
    __typename?: 'AssetType';
    id: string;
    name: string;
    address: string;
    symbol: string;
    decimals?: number | null;
    price?: BigNumber | null;
    chain: string;
    chainId: number;
    priceChangeAmount?: BigNumber | null;
    priceChangePercentage?: BigNumber | null;
    marketCap?: string | null;
    totalSupply?: string | null;
    type: Types.AssetTypeEnum;
    deprecated: boolean;
    hardDeprecated: boolean;
    createdAt: string;
  };
  outputToken: {
    __typename?: 'AssetType';
    id: string;
    name: string;
    address: string;
    symbol: string;
    decimals?: number | null;
    price?: BigNumber | null;
    chain: string;
    chainId: number;
    priceChangeAmount?: BigNumber | null;
    priceChangePercentage?: BigNumber | null;
    marketCap?: string | null;
    totalSupply?: string | null;
    type: Types.AssetTypeEnum;
    deprecated: boolean;
    hardDeprecated: boolean;
    createdAt: string;
  };
};

export const GmxActionFragmentDoc = gql`
  fragment GmxAction on GmxActionType {
    __typename
    id
    action
    inputToken {
      ...Asset
    }
    inputTokenAmount
    outputToken {
      ...Asset
    }
    outputTokenAmount
    transaction
    chainId
    chain
    memo
    unitPrice
    createdAt
  }
  ${AssetFragmentDoc}
`;
