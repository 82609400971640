import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgClose = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m11.832 10.012 7.788-7.788A1.294 1.294 0 1 0 17.788.392L10 8.18 2.212.392A1.294 1.294 0 1 0 .38 2.224l7.788 7.788L.38 17.8a1.294 1.294 0 0 0 .916 2.212c.332 0 .664-.127.916-.38L10 11.844l7.788 7.788a1.292 1.292 0 0 0 1.832 0 1.294 1.294 0 0 0 0-1.832l-7.788-7.788Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgClose;
