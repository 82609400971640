import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgError = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="error_svg__a" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 21C16.299 21 21 16.299 21 10.5S16.299 0 10.5 0 0 4.701 0 10.5 4.701 21 10.5 21ZM8.314 7l2.277 2.262L12.868 7l1.315 1.28-2.29 2.276 2.29 2.273-1.299 1.298-2.293-2.278-2.293 2.278L7 12.83l2.289-2.273-2.29-2.275L8.314 7Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 21C16.299 21 21 16.299 21 10.5S16.299 0 10.5 0 0 4.701 0 10.5 4.701 21 10.5 21ZM8.314 7l2.277 2.262L12.868 7l1.315 1.28-2.29 2.276 2.29 2.273-1.299 1.298-2.293-2.278-2.293 2.278L7 12.83l2.289-2.273-2.29-2.275L8.314 7Z"
      fill="#FF406E"
    />
    <path
      d="m10.591 9.262-.705.71.705.7.705-.7-.705-.71ZM8.314 7l.705-.71-.698-.693-.704.686.697.717Zm4.554 0 .698-.717-.705-.686-.698.693.705.71Zm1.315 1.28.705.71.722-.716-.729-.71-.698.717Zm-2.29 2.276-.704-.71-.714.71.714.709.705-.71Zm2.29 2.273.706.707.71-.71-.712-.706-.705.71Zm-1.299 1.298-.705.71.707.702.705-.705-.707-.707Zm-2.293-2.278.705-.71-.705-.7-.705.7.705.71Zm-2.293 2.278-.707.707.705.705.707-.702-.705-.71ZM7 12.83l-.705-.71-.712.708.71.71.707-.708Zm2.289-2.273.704.709.715-.71-.715-.709-.704.71ZM6.999 8.28 6.3 7.564l-.728.71.721.716L7 8.28ZM20 10.5a9.5 9.5 0 0 1-9.5 9.5v2C16.851 22 22 16.851 22 10.5h-2ZM10.5 1a9.5 9.5 0 0 1 9.5 9.5h2C22 4.149 16.851-1 10.5-1v2ZM1 10.5A9.5 9.5 0 0 1 10.5 1v-2C4.149-1-1 4.149-1 10.5h2Zm9.5 9.5A9.5 9.5 0 0 1 1 10.5h-2C-1 16.851 4.149 22 10.5 22v-2Zm.796-11.448L9.019 6.29l-1.41 1.42L9.886 9.97l1.41-1.419Zm.867-2.262L9.886 8.552l1.41 1.42 2.277-2.263-1.41-1.419Zm2.718 1.274-1.315-1.28-1.396 1.432 1.316 1.281 1.395-1.433Zm-2.283 3.701 2.29-2.275-1.41-1.419-2.29 2.275 1.41 1.419Zm2.289.855-2.289-2.274-1.41 1.419 2.29 2.274 1.409-1.42Zm-1.296 2.714 1.298-1.298-1.414-1.414-1.298 1.298 1.414 1.414ZM9.886 12.56l2.293 2.278 1.41-1.42-2.293-2.277-1.41 1.419Zm-.883 2.278 2.293-2.278-1.41-1.42-2.293 2.279 1.41 1.419Zm-2.71-1.3 1.298 1.297 1.414-1.414-1.298-1.298-1.414 1.414Zm2.29-3.69L6.296 12.12l1.41 1.419 2.288-2.274-1.41-1.419ZM6.295 8.99l2.29 2.275 1.41-1.419-2.29-2.275-1.41 1.42Zm1.323-2.707L6.3 7.564l1.396 1.433 1.315-1.28-1.395-1.434Z"
      fill="#fff"
      fillOpacity={0.35}
      mask="url(#error_svg__a)"
    />
  </svg>
));
export default SvgError;
