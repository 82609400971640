import BigNumber from 'bignumber.js';
import { BigNumberish } from 'ethers';
import { action, makeObservable, observable } from 'mobx';
import { Observable, distinctUntilChanged, filter, map, shareReplay } from 'rxjs';

import { Address, Addresses } from 'core/address';
import { AssetFragment } from 'core/api/gql/asset/asset.fragment.generated';
import { AssetTypeEnum } from 'core/api/schema';
import { ChainID, ChainInfo, chains } from 'core/chain';
import { Decimal } from 'core/decimal';

import { getIcon } from 'components/assets/asset.component';
import { SvgIconProps } from 'components/svg-icon';

import { Disposable } from 'utils/disposable';
import { Percentage } from 'utils/percentage';

import { Amount, Units } from 'types';

import { AssetUpdated } from './assets.store';
import { MoneyFormatter } from './money-formatter';

// eslint-disable-next-line @typescript-eslint/naming-convention
BigNumber.config({ EXPONENTIAL_AT: 1e9, DECIMAL_PLACES: 30, ROUNDING_MODE: BigNumber.ROUND_FLOOR });

type AssetOptions = {
  isNative?: boolean;
};

export class Asset extends Disposable {
  @observable.ref
  price: BigNumber;

  @observable
  price24hChange: Percentage;

  readonly name: string;

  readonly symbol: string;

  readonly address: Address;

  readonly decimals: number;

  readonly marketCap: BigNumber;

  readonly totalSupply: BigNumber;

  readonly Icon: React.FC<SvgIconProps>;

  readonly isNative: boolean;

  readonly assetUpdated$: Observable<AssetUpdated>;

  readonly decimalPlaces: number;

  readonly chain: ChainInfo;

  readonly type: AssetTypeEnum;

  readonly deprecated: boolean;

  readonly hardDeprecated: boolean;

  constructor(asset: AssetFragment, assetUpdated$: Observable<AssetUpdated>, options?: AssetOptions) {
    super();
    makeObservable(this);

    this.name = asset.name;
    this.symbol = asset.symbol;
    this.address = asset.address.toLowerCase();
    this.decimals = asset.decimals ?? 0;
    this.marketCap = new BigNumber(asset.marketCap ?? 0);
    this.totalSupply = new BigNumber(asset.totalSupply ?? 0);
    this.price = Decimal.precise(asset.price, Decimal.Precision.USD10);
    this.price24hChange = Percentage.fromNumber(asset.priceChangePercentage?.toNumber() ?? 0);
    this.chain = chains[asset.chainId as ChainID];
    this.type = asset.type;
    this.deprecated = asset.deprecated;
    this.hardDeprecated = asset.hardDeprecated;

    /*
     * in case of stable coin we want to show 2 decimal places
     * in case of other tokens we want to show 4 decimal places
     */
    this.decimalPlaces = this.decimals === 6 ? 2 : 4;

    this.Icon = getIcon(this.symbol);

    this.isNative = options?.isNative ?? false;

    this.assetUpdated$ = assetUpdated$.pipe(
      filter((assetUpdated) => assetUpdated.chainId === this.chain.chainId && assetUpdated.symbol === this.symbol),
      map((assetUpdated) => ({
        ...assetUpdated,
        price: Decimal.precise(assetUpdated.price, Decimal.Precision.USD10),
      })),
      distinctUntilChanged((a, b) => a.price.eq(b.price)),
      shareReplay(1)
    );

    this.autoDispose(this.assetUpdated$.subscribe(this.updateAsset));
  }

  static toUnits = (value: BigNumber, decimals = 0): Units => {
    return value
      .multipliedBy(10 ** decimals)
      .decimalPlaces(0)
      .toString() as Units;
  };

  static toBigNumber = (value: Units | BigNumberish, decimals = 0): BigNumber => {
    return new BigNumber(value.toString()).div(10 ** decimals);
  };

  static toAmount = (value: Units | BigNumberish | BigNumber, decimals = 0): Amount => {
    const _value = value instanceof BigNumber ? value : Asset.toBigNumber(value, decimals);

    return _value.decimalPlaces(decimals, BigNumber.ROUND_FLOOR).toString() as Amount;
  };

  static areEqual = (a?: Asset, b?: Asset): boolean => {
    if (!a || !b) return false;

    return Addresses.areEqual(a?.address, b?.address);
  };

  @action
  private updateAsset = (assetUpdated: AssetUpdated): void => {
    this.price = assetUpdated.price;
    this.price24hChange = Percentage.fromNumber(assetUpdated.priceChangePercentage.toNumber());
  };

  toUSD(value: Units | BigNumber): BigNumber {
    const parsedValue = BigNumber.isBigNumber(value) ? value : new BigNumber(value).div(10 ** this.decimals);

    return parsedValue.multipliedBy(this.price);
  }

  fromUSD(value: BigNumber): BigNumber {
    return value.div(this.price);
  }

  toUnits(value: Amount | string | BigNumber | number): Units {
    const _value = value instanceof BigNumber ? value : new BigNumber(value);

    return Asset.toUnits(_value, this.decimals);
  }

  toBigNumber(value?: Units | BigNumberish): BigNumber {
    if (!value) return new BigNumber('');

    return Asset.toBigNumber(value, this.decimals);
  }

  formatUnits(value: Units | BigNumberish): Amount {
    const parsedValue = this.toBigNumber(value);

    return parsedValue.toString() as Amount;
  }

  format(value?: Amount | Units | BigNumberish | BigNumber, format?: MoneyFormatter.FormatOptions): string {
    const formatOptions: Partial<MoneyFormatter.FormatOptions> = { ...format, asset: this };

    return MoneyFormatter.format(value, formatOptions).toString();
  }

  toAmount(value: Units | BigNumberish | BigNumber): Amount {
    return Asset.toAmount(value, this.decimals);
  }

  toString() {
    return this.address || this.symbol;
  }
}
