import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgNegativeShort = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={8.5} cy={8.5} r={8.5} fill="#000" />
    <circle cx={8.5} cy={8.5} r={8} stroke="#fff" strokeOpacity={0.2} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 14.45a5.95 5.95 0 1 0 0-11.9 5.95 5.95 0 0 0 0 11.9ZM5.957 9.312 8.253 11.7a.339.339 0 0 0 .494 0l2.296-2.388a.367.367 0 0 0 .101-.256.366.366 0 0 0-.1-.255l-.209-.217a.337.337 0 0 0-.488 0l-1.35 1.408v-4.44a.36.36 0 0 0-.357-.358h-.295a.347.347 0 0 0-.342.357v4.426L6.66 8.584a.345.345 0 0 0-.495 0l-.208.217a.367.367 0 0 0-.102.255c0 .097.036.188.102.256Z"
      fill="#FF406E"
    />
  </svg>
));
export default SvgNegativeShort;
