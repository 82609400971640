import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgCheck = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 12 9" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.868.366a1.25 1.25 0 0 1 0 1.767l-6.25 6.25a1.25 1.25 0 0 1-1.767 0l-2.5-2.5a1.25 1.25 0 0 1 1.767-1.767l1.617 1.616L9.1.366a1.25 1.25 0 0 1 1.767 0Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgCheck;
