import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { VaultLoader } from 'components/loading';

import { CallToAction } from './call-to-action';

export enum ButtonVariant {
  Text,
  Contained,
  Outlined,
}

export type ButtonProps = ComponentPropsWithoutRef<'button'> & {
  variant?: ButtonVariant;
  fullWidth?: boolean;
  slim?: boolean;
  bold?: boolean;
  selected?: boolean;
  selectable?: boolean;
  loading?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
};

export const Button = (props: ButtonProps) => {
  const {
    children,
    variant = ButtonVariant.Outlined,
    fullWidth,
    slim,
    bold,
    selected,
    selectable,
    loading,
    disabled,
    startIcon,
    endIcon,
    className,
    ...rest
  } = props;

  return (
    <button
      disabled={disabled || loading}
      className={twMerge(
        'btn group/btn',
        variant !== ButtonVariant.Text && 'btn-shape',
        variant === ButtonVariant.Contained && 'btn-contained',
        variant === ButtonVariant.Outlined && 'btn-outlined',
        variant === ButtonVariant.Text && 'btn-text',
        selectable && 'btn-selectable',
        selected && 'btn-selected',
        bold && 'btn-bold',
        slim && 'btn-slim',
        fullWidth && 'w-full', // TODO: use w-full instead
        className
      )}
      {...rest}
    >
      {loading && <VaultLoader width={13} height={15} white />}
      {startIcon && <span className="[display:inherit] [&_svg]:[display:inherit]">{startIcon}</span>}
      {children}
      {endIcon && <span className="[display:inherit] [&_svg]:[display:inherit]">{endIcon}</span>}
      {(variant === ButtonVariant.Contained || variant === ButtonVariant.Outlined) &&
        !disabled &&
        !loading &&
        !props.selectable && <CallToAction />}
    </button>
  );
};
