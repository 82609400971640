import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgStar = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m6.582.422 1.211 3.377c.027.075.09.123.166.127l3.445.16a.617.617 0 0 1 .564.449.66.66 0 0 1-.204.71L9.067 7.492a.2.2 0 0 0-.064.205l.92 3.477a.655.655 0 0 1-.235.7.588.588 0 0 1-.708.015L6.103 9.902a.178.178 0 0 0-.206 0L3.02 11.89a.588.588 0 0 1-.708-.017.655.655 0 0 1-.235-.699l.92-3.477a.196.196 0 0 0-.064-.204L.236 5.245a.658.658 0 0 1-.204-.71.617.617 0 0 1 .564-.449l3.445-.16a.181.181 0 0 0 .166-.126L5.417.422A.614.614 0 0 1 6 0c.264 0 .49.163.582.422Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgStar;
