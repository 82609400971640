import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgEqual = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 15 7" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 1a1 1 0 0 1 1-1h13a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1ZM0 6a1 1 0 0 1 1-1h13a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgEqual;
