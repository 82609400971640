import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgSentimentalCursor = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 64 226" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#sentimental-cursor_svg__a)">
      <path
        d="M22.625 23.54c-.722-.723-.024-1.936.964-1.674l8.069 2.14a.999.999 0 0 0 .512 0l8.07-2.14c.987-.262 1.685.951.963 1.674l-8.582 8.581a1 1 0 0 1-1.414 0l-8.582-8.582Z"
        fill="#F7FAFF"
      />
    </g>
    <g filter="url(#sentimental-cursor_svg__b)">
      <path stroke="#E3C8FF" strokeWidth={2} d="M32 198V32" />
    </g>
    <g filter="url(#sentimental-cursor_svg__c)">
      <circle cx={32} cy={200} r={4} fill="#F7FAFF" />
    </g>
    <defs>
      <filter
        id="sentimental-cursor_svg__a"
        x={0.668}
        y={0.17}
        width={62.492}
        height={53.903}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={10.83} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9504_6820" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={5.907} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_9504_6820" result="effect2_dropShadow_9504_6820" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.969} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_9504_6820" result="effect3_dropShadow_9504_6820" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_9504_6820" result="shape" />
      </filter>
      <filter
        id="sentimental-cursor_svg__b"
        x={19}
        y={20}
        width={26}
        height={190}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={6} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9504_6820" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_9504_6820" result="shape" />
      </filter>
      <filter
        id="sentimental-cursor_svg__c"
        x={6.341}
        y={174.341}
        width={51.319}
        height={51.319}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={10.83} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9504_6820" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={5.907} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_9504_6820" result="effect2_dropShadow_9504_6820" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.969} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_9504_6820" result="effect3_dropShadow_9504_6820" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_9504_6820" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgSentimentalCursor;
