/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { NotificationFragmentDoc } from './notifications.fragment.gql.generated';

export type NotificationReceivedSubscriptionVariables = Types.Exact<{ [key: string]: never }>;

export type NotificationReceivedSubscription = {
  __typename?: 'SubscriptionRoot';
  notification: {
    __typename?: 'NotificationType';
    type: Types.NotificationTypeEnum;
    status: Types.NotificationStatusEnum;
    context?: string | null;
    message: string;
    createdAt: string;
  };
};

export const NotificationReceivedDocument = gql`
  subscription NotificationReceived {
    notification {
      ...Notification
    }
  }
  ${NotificationFragmentDoc}
`;

export function NotificationReceived<
  R extends NotificationReceivedSubscription,
  V extends NotificationReceivedSubscriptionVariables
>(subscription: GetSubscription<R, V>, variables?: V): Observable<R> {
  return subscription(NotificationReceivedDocument, variables);
}
