import useResizeObserver from '@react-hook/resize-observer';
import { RefObject, useLayoutEffect, useMemo, useState } from 'react';

import { TableColumn } from '../table';

export const useColumns = <T>(target: RefObject<HTMLElement>, columns: TableColumn<T>[]) => {
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    if (target.current) {
      setWidth(target.current.getBoundingClientRect().width);
    }
  }, [target]);

  useResizeObserver(target, (entry) => setWidth(entry.contentRect.width));

  const minColumnsWidth = useMemo(
    () => columns.reduce((acc, { minWidth }) => (minWidth ? acc + minWidth : acc), 0),
    [columns]
  );

  const hiddenColumns = useMemo(
    () =>
      columns.reduceRight((acc, curr, index, arr) => {
        const collapsedValue = Array.from(acc.keys())
          .map((item) => arr[item])
          .reduce((a, c) => (c.minWidth ? a + c.minWidth : a), 0);

        if (minColumnsWidth - collapsedValue > width && curr.autoHide && curr.minWidth) {
          acc.add(index);
        }

        return acc;
      }, new Set<number>()),
    [columns, minColumnsWidth, width]
  );

  return useMemo(() => columns.filter((_, index) => !hiddenColumns.has(index)), [columns, hiddenColumns]);
};
