import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgUpload = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 23 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.5 0c1.71 0 3.425.667 4.73 2.005a6.899 6.899 0 0 1 1.914 4.053C20.869 6.31 23 8.657 23 11.516 23 14.545 20.605 17 17.651 17H4.546C2.028 17 0 14.921 0 12.339c0-2.583 2.028-4.661 4.546-4.661h.318a6.961 6.961 0 0 1 1.914-5.673A6.567 6.567 0 0 1 11.5 0Zm0 5.758a.46.46 0 0 0-.36.137L8.198 8.637c-.209.193-.247.568-.041.78.198.204.553.228.76.034l2.048-1.911v5.895c0 .303.24.548.535.548a.542.542 0 0 0 .535-.548V7.54l2.047 1.91a.55.55 0 0 0 .76-.034.574.574 0 0 0-.041-.78l-2.942-2.741a.574.574 0 0 0-.36-.137Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgUpload;
