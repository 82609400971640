import { injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { TableSortDirection } from 'components/table';

@injectable()
export class SortedResource<T> {
  @observable.ref
  comparator?: (a: T, b: T) => number;

  @observable
  order: TableSortDirection = TableSortDirection.ASC;

  @observable
  orderBy?: keyof T;

  constructor(private readonly getItems: () => T[]) {
    makeObservable(this);
  }

  @computed
  get items(): T[] {
    return this.getItems()
      .slice()
      .sort((a, b) =>
        this.order === TableSortDirection.ASC
          ? (this.comparator && this.comparator(a, b)) || 0
          : (this.comparator && -this.comparator(a, b)) || 0
      );
  }

  @action
  private setOrder = (order: TableSortDirection): void => {
    this.order = order;
  };

  @action
  private setOrderBy = (orderBy: keyof T): void => {
    this.orderBy = orderBy;
  };

  @action
  private setComparator = (comparator: (a: T, b: T) => number): void => {
    this.comparator = comparator;
  };

  onSort = (comparator: (a: T, b: T) => number, orderBy: keyof T): void => {
    this.setOrder(this.order === TableSortDirection.ASC ? TableSortDirection.DESC : TableSortDirection.ASC);
    this.setComparator(comparator);
    this.setOrderBy(orderBy);
  };
}
