import { injectable } from 'inversify';
import { Subject, filter, map, mergeMap, switchMap, timer } from 'rxjs';

import { Addresses } from 'core/address';
import { ApiService } from 'core/api/api.service';
import { DepositReceived } from 'core/api/gql/deposit/deposit-received.gql.generated';
import { NetworkStore } from 'core/network/network.store';
import { VaultUUID } from 'core/vault/vault.store';
import { WalletStore } from 'core/wallet/wallet.store';

import { Disposable } from 'utils/disposable';
import { visibility$ } from 'utils/polling';

import { Snackbar } from '../snackbar';
import { SnackbarStore } from '../snackbar.store';
import { DepositSnackbarStatus } from './deposit';

@injectable()
export class DepositSnackbarStore extends Disposable {
  private deposit$ = new Subject<Snackbar.Deposit>();

  constructor(
    private readonly snackbarStore: SnackbarStore,
    private readonly apiService: ApiService,
    private readonly walletStore: WalletStore,
    private readonly networkStore: NetworkStore
  ) {
    super();

    const pendingDeposit$ = this.deposit$.pipe(filter(({ status }) => status === DepositSnackbarStatus.Pending));

    const depositSuccess$ = pendingDeposit$.pipe(
      mergeMap((deposit) => {
        return this.apiService.subscription(DepositReceived, { uid: deposit.uid }).pipe(
          filter(
            ({
              deposit: {
                deposit: { depositor },
              },
            }) => Addresses.areEqual(depositor, this.walletStore.address)
          ),
          map(() => deposit)
        );
      })
    );

    const depositExpired$ = pendingDeposit$.pipe(
      mergeMap((deposit) => {
        const { chainTime } = this.networkStore;

        const timeDifference = deposit.expiry * 1000 - chainTime;

        return timer(timeDifference).pipe(map(() => deposit));
      })
    );
    const closeSuccess$ = depositSuccess$.pipe(
      mergeMap((item) =>
        visibility$.pipe(
          filter((isPageVisible) => isPageVisible),
          switchMap(() => timer(Snackbar.SuccessCloseDelay).pipe(map(() => item.id)))
        )
      )
    );

    const closeExpired$ = depositExpired$.pipe(
      mergeMap((item) =>
        visibility$.pipe(
          filter((isPageVisible) => isPageVisible),
          switchMap(() => timer(Snackbar.SuccessCloseDelay).pipe(map(() => item.id)))
        )
      )
    );

    this.autoDispose(closeExpired$.subscribe(this.snackbarStore.removeSnackbar));
    this.autoDispose(closeSuccess$.subscribe(this.snackbarStore.removeSnackbar));
    this.autoDispose(pendingDeposit$.subscribe(this.snackbarStore.addSnackbar));
    this.autoDispose(depositExpired$.subscribe(this.updateDepositExpired));
    this.autoDispose(depositSuccess$.subscribe(this.updateDepositSuccess));
  }

  trackDeposit = async (uid: VaultUUID, expiry: number, txId: string): Promise<void> => {
    const deposit: Snackbar.Deposit = {
      id: uid,
      uid,
      status: DepositSnackbarStatus.Pending,
      type: Snackbar.Type.Deposit,
      expiry,
      txId,
    };

    this.deposit$.next(deposit);
  };

  cancelDeposit = (uid: VaultUUID): void => {
    this.snackbarStore.removeSnackbar(uid);
  };

  private updateDepositSuccess = (item: Snackbar.Deposit): void => {
    this.snackbarStore.updateSnackbar({ ...item, status: DepositSnackbarStatus.Success });
  };

  private updateDepositExpired = (item: Snackbar.Deposit): void => {
    this.snackbarStore.updateSnackbar({ ...item, status: DepositSnackbarStatus.Expired });
  };
}
