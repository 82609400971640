import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgRepeat = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 19 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19 8.459v2.848a3.797 3.797 0 0 1-3.8 3.799H4.75v.95a.948.948 0 0 1-1.473.787l-2.85-1.899-.114-.095a.949.949 0 0 1-.18-.256v-.066a.845.845 0 0 1 0-.656v-.066a.947.947 0 0 1 .18-.257l.114-.095 2.85-1.899a.95.95 0 0 1 1.473.703v.95H15.2a1.901 1.901 0 0 0 1.9-1.9V8.46a.95.95 0 0 1 1.9 0Zm-18.05.95a.95.95 0 0 0 .95-.95v-2.85a1.9 1.9 0 0 1 1.9-1.898h10.45v.95a.948.948 0 0 0 1.473.788l2.85-1.9.114-.095a.95.95 0 0 0 .17-.256v-.066a.844.844 0 0 0 0-.656V2.41a.948.948 0 0 0-.17-.257l-.114-.095L15.723.16a.95.95 0 0 0-1.473.703v.95H3.8A3.8 3.8 0 0 0 0 5.61v2.849a.95.95 0 0 0 .95.95Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgRepeat;
