import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgYearnLpYield = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#yearn-lp-yield_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 17c0 9.389-7.611 17-17 17S0 26.389 0 17 7.611 0 17 0s17 7.611 17 17ZM8.567 8.06l1.341-1.364c.738-.75 1.35-1.364 1.361-1.364.011 0 1.298 1.299 2.86 2.886 1.561 1.588 2.856 2.887 2.878 2.887.021 0 1.321-1.304 2.889-2.896l2.849-2.896 1.351 1.373 1.352 1.373-3.27 3.324-3.271 3.325v6.19h-3.8V14.707l-3.27-3.324-3.27-3.324Zm-1.099 9.914c.12-1.19.56-2.682 1.035-3.52l.076-.132 1.445 1.467c1.126 1.143 1.44 1.484 1.427 1.546-.173.77-.194.937-.196 1.592-.002.705.047 1.082.216 1.672.521 1.817 1.968 3.332 3.743 3.919.653.215.97.26 1.813.259.828-.002 1.188-.057 1.83-.28 1.679-.583 3.085-2.026 3.616-3.71.165-.525.23-.865.272-1.418.04-.526 0-1.157-.103-1.623-.035-.16-.072-.343-.083-.406-.018-.105.087-.223 1.405-1.562.783-.797 1.44-1.449 1.457-1.449.044 0 .421.82.568 1.234.427 1.21.575 2.086.573 3.404 0 1.218-.12 1.971-.485 3.085-1.11 3.386-3.968 5.877-7.443 6.49-5.271.932-10.28-2.732-11.106-8.122-.088-.576-.12-1.858-.06-2.446Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="yearn-lp-yield_svg__a">
        <path d="M0 0h34v34H0z" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
));
export default SvgYearnLpYield;
