import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import { filter, map, retry, switchMap } from 'rxjs';

import { ApiService } from 'core/api/api.service';
import { GetNotifications } from 'core/api/gql/notification/get-notifications.gql.generated';
import { NotificationReceived } from 'core/api/gql/notification/notification.gql.generated';
import { NotificationStatusEnum, NotificationTypeEnum } from 'core/api/schema';
import { WalletStore } from 'core/wallet/wallet.store';

import { Disposable } from 'utils/disposable';
import { isSomething } from 'utils/is-something';
import { RetryStrategy } from 'utils/retry-strategy';

import { MockedNotifications, NotificationFragment } from './notifications.mock';

const totalDepositsInput = {
  input: {
    filter: {
      type: NotificationTypeEnum.Deposit,
      status: NotificationStatusEnum.Pending,
    },
  },
};

@injectable()
export class NotificationsStore extends Disposable {
  @observable
  isLoading = false;

  @observable
  totalDeposits = 0;

  @observable
  notifications: NotificationFragment[] = MockedNotifications;

  constructor(private readonly apiService: ApiService, private readonly walletStore: WalletStore) {
    super();

    makeObservable(this);

    const notification$ = this.walletStore.address$.pipe(
      filter(isSomething),
      switchMap(() => this.apiService.subscription(NotificationReceived)),
      map((item) => item.notification),
      filter((item) => item.type === NotificationTypeEnum.Deposit)
    );

    const totalDeposits$ = this.walletStore.address$.pipe(
      filter(isSomething),
      switchMap(() => this.apiService.query(GetNotifications, totalDepositsInput)),
      retry(
        RetryStrategy({
          label: 'Fetch total new deposits failed',
        })
      ),
      map((item) => item.notifications.numItems)
    );

    this.autoDispose(this.walletStore.disconnect$.subscribe(this.reset));

    this.autoDispose(totalDeposits$.subscribe(this.setTotalDeposits));

    this.autoDispose(
      notification$
        .pipe(filter((item) => item.status === NotificationStatusEnum.Pending))
        .subscribe(this.incrementTotalDeposits)
    );

    this.autoDispose(
      notification$
        .pipe(filter((item) => item.status === NotificationStatusEnum.Seen))
        .subscribe(this.decrementTotalDeposits)
    );
  }

  @action
  private setTotalDeposits = (value: number): void => {
    this.totalDeposits = value;
  };

  @action
  private incrementTotalDeposits = (): void => {
    this.totalDeposits++;
  };

  @action
  private decrementTotalDeposits = (): void => {
    this.totalDeposits--;
  };

  @action
  private reset = (): void => {
    this.notifications = [];
    this.totalDeposits = 0;
  };
}
