import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgChevronCarousel = svgIcon((props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#chevron-carousel_svg__a)">
        <path
          className="fill-amber-50"
          d="M11.237 37.773c.14.144.33.226.53.227.2.002.39-.08.53-.227l12.482-12.725a.766.766 0 0 0 0-1.079L12.296 11.242a.742.742 0 0 0-1.077-.018.77.77 0 0 0 .018 1.097L23.191 24.51 11.236 36.694a.768.768 0 0 0 0 1.079Z"
        />
      </g>
      <defs>
        <filter
          id="chevron-carousel_svg__a"
          x={0}
          y={0}
          width={36}
          height={49}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation={5.5} />
          <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_63_8927" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation={3} />
          <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
          <feBlend in2="effect1_dropShadow_63_8927" result="effect2_dropShadow_63_8927" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
          <feBlend in2="effect2_dropShadow_63_8927" result="effect3_dropShadow_63_8927" />
          <feBlend in="SourceGraphic" in2="effect3_dropShadow_63_8927" result="shape" />
        </filter>
      </defs>
    </svg>
  );
});
export default SvgChevronCarousel;
