import { VaultActionInfoKwenta } from 'core/vault/vault-action.info';

import { AssetIcon } from 'components/asset-icon';
import { AssetIconSize } from 'components/asset-icon/asset-icon';
import { FormatMoney } from 'components/format-money/format-money';
import { Secondary } from 'components/typography';

type KwentaActionProps = {
  data: VaultActionInfoKwenta;
};

export const KwentaAction = (props: KwentaActionProps) => {
  const { data } = props;

  return (
    <div className="flex flex-col justify-center">
      <div className="flex gap-x-2 items-center">
        <AssetIcon asset={data.market} size={AssetIconSize.Small} />

        <div className="flex gap-1.5">
          <FormatMoney value={data.positionDelta.abs()} asset={data.market} showSuffix className="text-primary" />
        </div>
      </div>
      <div className="flex gap-x-1.5 items-center">
        <Secondary>
          Price: <FormatMoney unstyled value={data.desiredFillPrice} dollarSign />
        </Secondary>
      </div>
    </div>
  );
};
