import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgClock = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.5 0a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13Zm2.187 8.687a.465.465 0 0 1-.655 0L6.175 6.83a.464.464 0 0 1-.14-.33V3.714a.464.464 0 1 1 .93 0V6.31l1.722 1.722a.464.464 0 0 1 0 .655Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgClock;
