/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { AssetFragmentDoc } from './asset.fragment.generated';

export type GetAssetsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetAssetsQuery = {
  __typename?: 'Query';
  assets: Array<{
    __typename?: 'AssetType';
    id: string;
    name: string;
    address: string;
    symbol: string;
    decimals?: number | null;
    price?: BigNumber | null;
    chain: string;
    chainId: number;
    priceChangeAmount?: BigNumber | null;
    priceChangePercentage?: BigNumber | null;
    marketCap?: string | null;
    totalSupply?: string | null;
    type: Types.AssetTypeEnum;
    deprecated: boolean;
    hardDeprecated: boolean;
    createdAt: string;
  }>;
};

export const GetAssetsDocument = gql`
  query GetAssets {
    assets {
      ...Asset
    }
  }
  ${AssetFragmentDoc}
`;

export function GetAssets<R extends GetAssetsQuery, V extends GetAssetsQueryVariables>(
  request: Request<R, V>,
  variables?: V,
  headers?: HeadersInit
): Promise<R> {
  return request(GetAssetsDocument, variables, headers);
}
