import { HTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type ModalComponentProps = HTMLAttributes<HTMLDivElement> & {
  children?: ReactNode;
};

export const ModalHeader = (props: ModalComponentProps) => {
  const { children, className, ...rest } = props;

  return (
    <div
      className={twMerge('pt-[36px] pr-[41px] pb-[21px] pl-[41px] box-border border-b border-outline', className)}
      {...rest}
    >
      {children}
    </div>
  );
};

export const ModalContent = (props: ModalComponentProps) => {
  const { children, className, ...rest } = props;

  return (
    <div className={twMerge('px-[33px] py-[23px]', className)} {...rest}>
      {children}
    </div>
  );
};

export const ModalFooter = (props: ModalComponentProps) => {
  const { children, className, ...rest } = props;

  return (
    <div className={twMerge('px-8 py-6 border-t border-outline', className)} {...rest}>
      {children}
    </div>
  );
};
