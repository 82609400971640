import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgEmail = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.071 0H1.93C.868 0 0 .878 0 1.952v.566L1.286 3v.006l.51.183.415.155 6.57 2.388a.67.67 0 0 0 .438 0l6.325-2.303L18 2.55v-.6C18 .879 17.132.001 16.071.001Z"
      fill="currentColor"
    />
    <path
      d="M16.714 4.385 9.65 6.955c-.205.079-.43.111-.649.111-.219 0-.444-.032-.65-.11L1.71 4.541l-.424-.157v.011L0 3.956v7.092C0 12.122.868 13 1.929 13H16.07C17.132 13 18 12.122 18 11.048V3.93l-1.286.461v-.006Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgEmail;
