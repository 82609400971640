import { Field, Validator, ValidatorResult } from '@mobx-form-state/core';
import { keccak256 } from 'ethers/lib/utils';
import { ZodType, z } from 'zod';

import { Collection } from './collection';

export namespace Validators {
  export const fromZod =
    (validator: ZodType): Validator =>
    async (field: Field): Promise<ValidatorResult> => {
      const result = await validator.safeParseAsync(field.value);

      return !result.success && Collection.takeFirst(result.error.format()._errors);
    };

  export const nameValidator = z
    .string()
    .nonempty('Required')
    .regex(/^[a-zA-Z0-9\s]+$/, 'Should contain only letters and numbers');

  export const isCorrectName =
    (): Validator =>
    ({ value }) =>
      !/^[a-zA-Z0-9\s]+$/.test(value) ? 'Incorrect email' : '';

  export const addressValidator = z.string().refine(
    (value) => {
      if (!/^0x[0-9a-fA-F]{40}$/.test(value)) {
        return false;
      }

      if (/^0x[0-9a-f]{40}$/.test(value) || /^0x?[0-9A-F]{40}$/.test(value)) {
        return true;
      }

      return verifyChecksum(value);
    },
    {
      message: 'Incorrect  address',
    }
  );
}

const verifyChecksum = (address: string): boolean => {
  address = address.replace('0x', '');

  const addressHash = keccak256(address.toLowerCase());

  for (let i = 0; i < 40; i++) {
    if (
      (parseInt(addressHash[i], 16) > 7 && address[i].toUpperCase() !== address[i]) ||
      (parseInt(addressHash[i], 16) <= 7 && address[i].toLowerCase() !== address[i])
    ) {
      return false;
    }
  }

  return true;
};
