import { PrivyInterface, WalletWithMetadata } from '@privy-io/react-auth';
import type { ConnectedWallet } from '@privy-io/react-auth';
import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import { Observable, Subject, combineLatest, firstValueFrom, share, switchMap } from 'rxjs';
import invariant from 'tiny-invariant';

import { AnyChain, caipToNumber } from 'core/chain';

import { Collection } from 'utils/collection';
import { Disposable } from 'utils/disposable';
import { isSomething } from 'utils/is-something';

import { Privy } from './privy';

@injectable()
export class PrivyClient extends Disposable {
  @observable.ref
  connectedWallet?: ConnectedWallet;

  readonly privyState$: Observable<Privy.State>;

  private privy?: PrivyInterface;

  private readonly privy$ = new Subject<PrivyInterface>();

  private readonly wallets$ = new Subject<ConnectedWallet[]>();

  constructor() {
    super();
    makeObservable(this);

    this.privyState$ = combineLatest([this.privy$, this.wallets$]).pipe(
      switchMap(async ([privy, wallets]) => {
        const linkedAccounts = privy.user?.linkedAccounts ?? [];

        const userWallets = linkedAccounts.filter((account) => account.type === 'wallet') as WalletWithMetadata[];

        const linkedAndConnectedWallets = userWallets
          .map((account) => wallets.find((wallet) => wallet.address === account.address))
          .filter(isSomething);

        const defaultWallet = Collection.takeFirst(linkedAndConnectedWallets);

        const networkState: Privy.NetworkState | null = defaultWallet
          ? {
              address: defaultWallet.address,
              chainId: caipToNumber(defaultWallet.chainId),
              provider: await defaultWallet.getEthersProvider(),
            }
          : null;

        return {
          user: privy.user,
          wallets: linkedAndConnectedWallets,
          connectedWallet: defaultWallet,
          networkState,
        };
      }),
      share()
    );

    this.autoDispose(this.privy$.subscribe(this.setPrivy));
    this.autoDispose(this.privyState$.subscribe(this.setConnectedWallet));
  }

  get isConnected(): boolean {
    if (!this.privy) return false;

    return this.privy.ready && this.privy.authenticated && !!this.connectedWallet;
  }

  @action
  private setConnectedWallet = (privyState: Privy.State) => {
    this.connectedWallet = privyState.connectedWallet;
  };

  nextWallets = (wallets: ConnectedWallet[]) => {
    this.wallets$.next(wallets);
  };

  nextPrivy = (privy: PrivyInterface) => {
    this.privy$.next(privy);
  };

  connect = async (): Promise<void> => {
    invariant(this.privy, 'Privy is not initialized');

    if (this.privy.ready && this.privy.authenticated && this.connectedWallet === undefined) {
      await this.privy.logout();
      const privy = await firstValueFrom(this.privy$);

      privy.login();
    } else {
      this.privy.login();
    }
  };

  disconnect = (): void => {
    invariant(this.privy, 'Privy is not initialized');

    this.privy?.logout();
  };

  switchChain = (chainId: AnyChain): Promise<void> => {
    invariant(this.connectedWallet, 'connectedWallet is not initialized');

    return this.connectedWallet?.switchChain(chainId);
  };

  private setPrivy = (privy: PrivyInterface) => {
    this.privy = privy;
  };
}
