import BigNumber from 'bignumber.js';
import { action, computed, makeObservable, observable } from 'mobx';
import { Observable, Subject, concatMap, filter, lastValueFrom, map, retry, skip, switchMap, takeWhile } from 'rxjs';
import invariant from 'tiny-invariant';

import { type Address, Addresses } from 'core/address';
import { ApiService } from 'core/api/api.service';
import type { DepositFragment } from 'core/api/gql/deposit/deposit.fragment.generated';
import { GetVaultDeposits, GetVaultDepositsQuery } from 'core/api/gql/deposit/get-vault-deposits.gql.generated';
import { UpdateNotificationsStatus } from 'core/api/gql/notification/update-notification.gql.generated';
import { GetVaultActions, GetVaultActionsQuery } from 'core/api/gql/vault-actions/get-vault-actions.gql.generated';
import { VaultAction } from 'core/api/gql/vault-actions/vault-action.gql.generated';
import { GetVaultAum } from 'core/api/gql/vault/get-vault-aum.gql.generated';
import { GetVaultDeposit } from 'core/api/gql/vault/get-vault-deposit.gql.generated';
import { GetVaultDepositors } from 'core/api/gql/vault/get-vault-depositors.gql.generated';
import { GetVaultRoi } from 'core/api/gql/vault/get-vault-roi.gql.generated';
import { GetVaultTransfers, GetVaultTransfersQuery } from 'core/api/gql/vault/get-vault-transfers.gql.generated';
import type { VaultFragment } from 'core/api/gql/vault/vault.fragment.generated';
import { AumDataset, ChartDatasetRange, DepositorsDataset, RoiDataset } from 'core/api/schema';
import { Asset } from 'core/asset';
import { AssetsStore } from 'core/asset/assets.store';
import { ChainID } from 'core/chain';
import { ChainService } from 'core/chain/chain.service';
import { VaultChainInfo } from 'core/chain/vault-chain';
import { mapVaultChainsFromApi } from 'core/chain/vault-chain.map';
import { Logger } from 'core/logger';
import { NetworkService } from 'core/network/network.service';
import { NetworkStore } from 'core/network/network.store';
import { PaginatedResource, PaginatedResult } from 'core/paginated-resource';
import { SnackbarStore } from 'core/snackbars/snackbar.store';
import { TransactionSnackbarStore } from 'core/snackbars/transactions/transaction-snackbar.store';
import { Valio } from 'core/valio/valio';
import { VaultContract } from 'core/valio/vault.contract';
import { WalletStore } from 'core/wallet/wallet.store';

import { ModalsStore } from 'lib/modals/modals.store';

import { Disposable } from 'utils/disposable';
import { isSomething } from 'utils/is-something';
import { polling } from 'utils/polling';
import { RetryStrategy } from 'utils/retry-strategy';

import { AssetAllocation } from './asset-allocation.enum';
import { TradeMemoStore } from './trades-memo/trade-memo.store';
import { VaultActionInfo } from './vault-action.info';
import { mapVaultActionFromApi, mapVaultActionsFromApi } from './vault-actions.map';
import { VaultAssetInfo } from './vault-asset.info';
import { mapVaultAssetFromApi } from './vault-assets.map';
import { VaultDepositInfo } from './vault-deposit.info';
import { mapVaultDepositsFromApi } from './vault-deposits.map';
import { VaultTransferInfo } from './vault-transfer.info';
import { mapVaultTransfersFromApi } from './vault-transfer.map';
import { VaultStore } from './vault.store';
import { Vaults } from './vaults';

const logger = new Logger('ActiveVaultStore');

export class ActiveVaultStore extends Disposable {
  @observable.ref
  totalShares = new BigNumber(0);

  @observable.ref
  aum = new BigNumber(0);

  @observable.ref
  myDeposit?: DepositFragment | null;

  @observable.ref
  depositorsDataset: DepositorsDataset[] = [];

  @observable.ref
  aumDataset: AumDataset[] = [];

  @observable.ref
  vault: VaultFragment;

  @observable.ref
  roiDataset: RoiDataset[] = [];

  @observable
  cpit = 0;

  @observable
  dateRange: ChartDatasetRange = ChartDatasetRange.All;

  @observable
  bridgeLock = false;

  tradesMemo: TradeMemoStore;

  vaultActions: PaginatedResource<VaultActionInfo>;

  vaultHolders: PaginatedResource<VaultDepositInfo>;

  vaultTransfers: PaginatedResource<VaultTransferInfo>;

  dataRange$ = new Subject<ChartDatasetRange>();

  vaultAction$: Observable<VaultActionInfo>;

  readonly vaultUpdated$: Observable<VaultFragment>;

  constructor(
    vault: VaultFragment,
    private readonly vaultStore: VaultStore,
    private readonly apiService: ApiService,
    private readonly walletStore: WalletStore,
    private readonly vaultContract: VaultContract,
    private readonly chainService: ChainService,
    private readonly networkStore: NetworkStore,
    private readonly transactionSnackbarStore: TransactionSnackbarStore,
    private readonly assetsStore: AssetsStore,
    private readonly modalsStore: ModalsStore,
    private readonly snackbarStore: SnackbarStore,
    private readonly networkService: NetworkService
  ) {
    super();
    makeObservable(this);

    this.vault = vault;

    logger.debug('vault', vault.chains);

    this.tradesMemo = new TradeMemoStore(
      this,
      this.apiService,
      this.modalsStore,
      this.snackbarStore,
      this.chainService,
      this.assetsStore
    );

    this.vaultAction$ = this.apiService.subscription(VaultAction, { vault: this.vault.uid }).pipe(
      map((result) => mapVaultActionFromApi(result.vaultAction, this.assetsStore.getAssetBySymbol)),
      filter(isSomething)
    );

    this.vaultTransfers = new PaginatedResource({
      pageSize: 5,
      fetch: (options): Promise<GetVaultTransfersQuery> =>
        this.apiService.query(GetVaultTransfers, {
          input: {
            vault: this.vault.uid,
            page: {
              ...options,
            },
          },
        }),
      map: (result): PaginatedResult<VaultTransferInfo> => ({
        ...result.vaultTransfers,
        items: mapVaultTransfersFromApi(result.vaultTransfers.items, this.isManager, this.parentChainId),
      }),
    });

    this.vaultHolders = new PaginatedResource({
      pageSize: 5,
      fetch: (options): Promise<GetVaultDepositsQuery> =>
        this.apiService.query(GetVaultDeposits, {
          uid: this.vault.uid,
          ...options,
        }),
      map: (result): PaginatedResult<VaultDepositInfo> => ({
        ...result.deposits,
        items: mapVaultDepositsFromApi(result.deposits.items, this.isManager, this.parentChainId),
      }),
    });

    this.vaultActions = new PaginatedResource({
      pageSize: 5,
      fetch: (page): Promise<GetVaultActionsQuery> =>
        this.apiService.query(GetVaultActions, {
          input: { vault: this.vault.uid, page },
        }),
      map: (result): PaginatedResult<VaultActionInfo> => {
        return {
          ...result.vaultActions,
          items: mapVaultActionsFromApi(result.vaultActions.items, this.assetsStore.getAssetBySymbol),
        };
      },
    });

    const bridgeLock$ = polling(10000).pipe(
      concatMap(() =>
        this.networkStore.networks[this.parentChainId]
          .get(VaultContract)
          .getVaultParentContract(this.parentAddress)
          .bridgeInProgress()
      )
    );

    const vaultDeleted$ = this.vaultStore.vaultDeleted$.pipe(
      filter((deletedVault) => deletedVault.slug === vault.slug),
      filter(isSomething)
    );

    this.vaultUpdated$ = this.vaultStore.vaultUpdated$.pipe(
      filter((updatedVault) => updatedVault.slug === vault.slug),
      filter(isSomething)
    );

    this.autoDispose(this.vaultAction$.subscribe(this.vaultActions.refresh));

    this.autoDispose(bridgeLock$.subscribe(this.setBridgeLock));

    this.autoDispose(this.vaultUpdated$.subscribe(this.updateActiveVault));

    this.autoDispose(vaultDeleted$.subscribe(this.updateActiveVault));

    this.autoDispose(
      this.dataRange$.subscribe(() => {
        this.fetchDepositorsDataset();
        this.fetchAumDataset();
        this.fetchRoiDataset();
      })
    );

    this.autoDispose(this.walletStore.address$.pipe(skip(1)).subscribe(this.init));

    this.autoDispose(
      polling(Vaults.StatsPollingInterval)
        .pipe(
          concatMap(this.fetchStats),
          retry(
            RetryStrategy({
              label: 'Fetch vault stats failed',
            })
          )
        )
        .subscribe(this.setStats)
    );

    this.autoDispose(() => this.tradesMemo.dispose());

    this.autoDispose(
      this.networkService.chain$
        .pipe(
          filter(isSomething),
          switchMap((chainID) =>
            polling(10000).pipe(
              concatMap(async () => {
                const vaultChain = this.getVaultChain(chainID) || this.getVaultChain(this.parentChainId);

                if (vaultChain) {
                  const contract = this.networkStore.networks[chainID].get(VaultContract);

                  const vaultContract = vaultChain.isParent
                    ? contract.getVaultParentContract(vaultChain.address)
                    : contract.getChildVaultContract(vaultChain.address);

                  const cpit = await vaultContract.getCurrentCpit();

                  return cpit.toNumber() / 100;
                }

                return 0;
              }),
              retry(
                RetryStrategy({
                  label: 'Get vault CPIT failed',
                })
              )
            )
          )
        )
        .subscribe(this.setCpit)
    );
  }

  @computed
  get remainingDepositAmount(): BigNumber {
    const value = this.vaultStore.valueCap.minus(this.aum);

    return value.isNegative() ? new BigNumber(0) : value;
  }

  @computed
  get kwentaAllocation(): number {
    return this.vault.assetAllocation.find((item) => item.name === AssetAllocation.Kwenta)?.allocation.toNumber() ?? 0;
  }

  @computed
  get gmxAllocation(): number {
    return this.vault.assetAllocation.find((item) => item.name === AssetAllocation.GMX)?.allocation.toNumber() ?? 0;
  }

  @computed
  get spotAllocation(): number {
    return this.vault.assetAllocation.find((item) => item.name === AssetAllocation.Spot)?.allocation.toNumber() ?? 0;
  }

  @computed
  get assets(): VaultAssetInfo[] {
    return mapVaultAssetFromApi(this.vault.assets, this.assetsStore.getAssetBySymbol);
  }

  @computed
  get hardDeprecatedAssets(): VaultAssetInfo[] {
    return this.assets.filter(({ asset }) => asset.hardDeprecated);
  }

  @computed
  get assetWatchList(): Asset[] {
    return this.vault.assetWatchList
      .map(({ symbol, chainId }) => this.assetsStore.getAssetBySymbol(symbol, chainId))
      .filter(isSomething);
  }

  @computed
  get isWithdrawAvailable(): boolean {
    return this.isManaged || new BigNumber(this.myDeposit?.shares || 0).gt(0);
  }

  @computed
  get chains(): VaultChainInfo[] {
    return mapVaultChainsFromApi(this.vault?.chains ?? []);
  }

  @computed
  get minUnitPrice(): BigNumber {
    return this.vault.stats.shareMinPrice;
  }

  @computed
  get maxUnitPrice(): BigNumber {
    return this.vault.stats.shareMaxPrice;
  }

  @computed
  get hasChildren(): boolean {
    return this.chains.length > 1;
  }

  @computed
  get address(): Address | undefined {
    return this.getVaultChain(this.chainService.chain.chainId)?.address;
  }

  @computed
  get parentAddress(): Address {
    const result = this.getVaultChain(this.parentChainId)?.address;

    invariant(result, 'Parent chain not found');

    return result;
  }

  @computed
  get isParentChain(): boolean {
    return this.parentChainId === this.chainService.chain.chainId;
  }

  @computed
  get isManaged(): boolean {
    return Addresses.areEqual(this.vault.manager.account.address, this.walletStore.address);
  }

  @computed
  get isCpitMax(): boolean {
    return this.cpit >= this.vaultStore.getRiskProfileCpit(this.vault.riskProfile);
  }

  @computed
  get parentChainId(): ChainID {
    const result = this.chains.find((chain) => chain.isParent)?.chainId;

    invariant(result, 'Parent chain not found');

    return result;
  }

  @computed
  get hasEthereumDeploy(): boolean {
    return isSomething(this.getVaultChain(ChainID.Ethereum));
  }

  @action
  updateDataRange = (range: ChartDatasetRange): void => {
    this.dateRange = range;
    this.dataRange$.next(range);
  };

  @action
  private setCpit = (value: number): void => {
    this.cpit = value;
  };

  @action
  private setDepositorsDataset = (dataset: DepositorsDataset[]): void => {
    this.depositorsDataset = dataset;
  };

  @action
  private setAumDataset = (dataset: AumDataset[]): void => {
    this.aumDataset = dataset;
  };

  @action
  private setRoiDataset = (dataset: RoiDataset[]): void => {
    this.roiDataset = dataset;
  };

  @action
  private setStats = (stats: Vaults.Stats): void => {
    this.aum = stats.aum;
    this.totalShares = stats.totalShares;
  };

  @action
  private setBridgeLock = (bridgeLock: boolean): void => {
    this.bridgeLock = bridgeLock;
  };

  @action
  private updateActiveVault = (vault: VaultFragment): void => {
    this.vault = vault;

    logger.debug('Active vault updated', vault);

    this.init();

    if (this.vaultHolders.loaded) {
      this.vaultHolders.refresh();
    }
  };

  @action
  private setFetchedData = (data: { myDeposit?: DepositFragment | null }): void => {
    this.myDeposit = data.myDeposit;
  };

  readonly getAssetBySymbol = (symbol: string): VaultAssetInfo | undefined =>
    this.assets.find((asset) => asset.asset.symbol === symbol);

  readonly resetNewDepositors = (): void => {
    const currentDateUtc = new Date().toUTCString();

    this.apiService.query(UpdateNotificationsStatus, {
      input: {
        beforeDate: new Date(currentDateUtc),
        vault: this.vault.uid,
      },
    });
  };

  readonly isSync = async (): Promise<boolean> => {
    const parentVault = this.networkStore.networks[this.parentChainId]
      .get(VaultContract)
      .getVaultParentContract(this.parentAddress);

    return parentVault.inSync();
  };

  readonly syncVault = async (): Promise<void> => {
    const { fees, totalSendFee } = await this.vaultContract.getLzFeesMultiChain(
      this.parentAddress,
      'requestTotalValueUpdateMultiChain(uint256[])'
    );

    const parentVault = this.vaultContract.getVaultParentContract(this.parentAddress, true);

    const tx = await parentVault.requestTotalValueUpdateMultiChain(fees, {
      value: totalSendFee,
    });

    this.transactionSnackbarStore.trackTx(tx.hash, tx.wait(1));

    await lastValueFrom(
      polling(1000).pipe(
        switchMap(() => parentVault.inSync()),
        takeWhile((isSync) => !isSync)
      )
    );
  };

  readonly getVaultChain = (chainId?: ChainID): VaultChainInfo | undefined =>
    this.chains.find((chain) => chain.chainId === chainId);

  init = async (): Promise<null> => {
    const myDeposit = this.walletStore.address
      ? this.apiService
          .query(GetVaultDeposit, {
            uid: this.vault.uid,
            depositor: this.walletStore.address,
          })
          .catch((e) => {
            logger.error('Fetching my deposit failed', e);

            return undefined;
          })
      : undefined;

    const [myDepositResult] = await Promise.all([myDeposit]);

    this.setFetchedData({ myDeposit: myDepositResult?.deposit });

    await Promise.all([this.fetchDepositorsDataset(), this.fetchAumDataset(), this.fetchRoiDataset()]);

    return null;
  };

  private fetchDepositorsDataset = async (): Promise<void> => {
    const result = await this.apiService.query(GetVaultDepositors, {
      uid: this.vault.uid,
      dateRange: this.dateRange,
    });

    this.setDepositorsDataset(result.depositorsDataset);
  };

  private fetchAumDataset = async (): Promise<void> => {
    const result = await this.apiService.query(GetVaultAum, { uid: this.vault.uid, dateRange: this.dateRange });

    this.setAumDataset(result.aumDataset);
  };

  private fetchRoiDataset = async (): Promise<void> => {
    const result = await this.apiService.query(GetVaultRoi, { uid: this.vault.uid, dateRange: this.dateRange });

    this.setRoiDataset(result.roiDataset);
  };

  private isManager = (address: Address): boolean => Addresses.areEqual(this.vault.manager.account.address, address);

  private fetchStats = async (): Promise<Vaults.Stats> => {
    const parentNetworkContainer = this.networkStore.networks[this.parentChainId];

    const parentVaultContract = parentNetworkContainer.get(VaultContract);

    const parentVault = parentVaultContract.getVaultParentContract(this.parentAddress);

    const totalShares = await parentVault.totalShares();

    const aum = await Promise.all(
      this.chains.map(async ({ chainId, address, isParent }) => {
        const networkContainer = this.networkStore.networks[chainId];

        const vaultContract = networkContainer.get(VaultContract);

        const vault = isParent
          ? vaultContract.getVaultParentContract(address)
          : vaultContract.getChildVaultContract(address);

        const vaultValue = await vault.getVaultValue();

        return new BigNumber(vaultValue.minValue.toString()).div(10 ** Valio.VaultPrecision);
      })
    );

    return {
      totalShares: new BigNumber(totalShares.toString()),
      aum: aum.reduce((acc, value) => acc.plus(value), new BigNumber(0)),
    };
  };
}
