import { ChainID } from 'core/chain';

export namespace Valio {
  export type SupportedChains = (typeof supportedChains)[number];

  export const supportedChains = [ChainID.Arbitrum, ChainID.Optimism, ChainID.Ethereum] as const;

  export const isSupportedChain = (chainId: ChainID): chainId is SupportedChains =>
    supportedChains.includes(chainId as SupportedChains);

  export type Contracts = {
    Registry: string;
    Transport: string;
  };

  export const VaultPrecision = 8;

  export type DepositChains = (typeof depositChains)[number];

  export const depositChains = [ChainID.Arbitrum] as const;

  export const isDepositChain = (chainId: ChainID): chainId is DepositChains =>
    depositChains.includes(chainId as DepositChains);

  export type MaxActiveAssets = {
    [key in DepositChains]: number;
  };

  export type DepositSymbols = {
    [key in DepositChains]: string[];
  };

  export const depositSymbols = {
    [ChainID.Arbitrum]: ['USDC.e', 'USDC'],
  } as DepositSymbols;

  export const VLTTokenAddress = '0x5FbDB2315678afecb367f032d93F642f64180aa3';

  export const ManagerTokenId = 0;

  export const PerformanceFeeDecimals = 2;

  export const StreamingFeeDecimals = 2;

  export const MaxStreamingFeePercent = 5;

  export const MaxPerformanceFeePercent = 50;

  export const streamingFeeDefault = 2;

  export const performanceFeeDefault = 20;

  export type ContractAddresses = {
    [keyof in SupportedChains]: Contracts;
  };

  export enum GasFunctionType {
    Standard,
    CreateChild,
    Withdraw,
  }

  export enum ExecutorIntegration {
    ZeroX,
    GMX,
    SnxPerpsV2,
    GMXOrderBook,
  }
}
