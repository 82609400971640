import { formatDate } from 'date-fns';

import { Seconds } from 'types';

export namespace DateUtils {
  export const roundTime = Math.floor;

  export type Milliseconds = number;

  export type Type = number | Date | string;

  export const DateFormats = {
    short: 'dd.MM.yy',
    day: 'd MMMM',
    time: 'HH:mm',
    date: 'MMM d, yyyy',
    shortDay: 'd MMM',
    shortDayWithYear: 'd MMM yyyy',
  };

  export const format = (date: Type, format?: keyof typeof DateFormats): string => {
    return formatDate(new Date(date), format ? DateFormats[format] : DateFormats.short);
  };

  export const toSeconds = (ms: Milliseconds): Seconds => roundTime(ms / 1000);

  export const toMilliseconds = (s: Seconds): Milliseconds => s * 1000;

  export const fromSeconds = (s: Seconds): Date => new Date(toMilliseconds(s));

  export const toDeadline = (expiration: number): number => {
    return Math.floor((Date.now() + expiration) / 1000);
  };
}
