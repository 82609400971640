import { ComponentCarousel } from 'components/carousel/component-carousel';

import { SlideOne } from './slides/slide-one';
import { SlideTwo } from './slides/slide-two';

const slides = [SlideOne, SlideTwo];

export const ProfileTutorialModal = () => {
  return (
    <div className="items-center justify-center flex-1 w-[566px] relative h-[493px] rounded-[20px] overflow-hidden -z-[1]">
      <ComponentCarousel
        slides={slides}
        navigation
        className="[&_.chevron-right>svg]:mt-[150px] [&_.chevron-right>svg]:-z-[1] [&_.chevron-left>svg]:mt-[150px] [&_.chevron-left>svg]:-z-[1]"
      />
    </div>
  );
};

ProfileTutorialModal.displayName = 'ProfileTutorialModal';
