/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';

export type NotificationFragment = {
  __typename?: 'NotificationType';
  type: Types.NotificationTypeEnum;
  status: Types.NotificationStatusEnum;
  context?: string | null;
  message: string;
  createdAt: string;
};

export const NotificationFragmentDoc = gql`
  fragment Notification on NotificationType {
    type
    status
    context
    message
    createdAt
  }
`;
