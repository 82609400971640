import { Address } from 'core/address';
import { DepositFragment } from 'core/api/gql/deposit/deposit.fragment.generated';
import { ChainID } from 'core/chain';
import { Decimal } from 'core/decimal';

import { VaultDepositInfo } from './vault-deposit.info';

export const mapVaultDepositsFromApi = (
  vaultDeposits: DepositFragment[],
  isManager: (address: Address) => boolean,
  chainId: ChainID
): VaultDepositInfo[] =>
  vaultDeposits.map((item) => ({
    depositor: item.depositor,
    isManager: isManager(item.depositor),
    depositedAmount: Decimal.precise(item.depositedAmount),
    stake: item.stake.toNumber(),
    createdAt: new Date(item.createdAt),
    chainId: chainId,
  }));
