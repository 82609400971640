import { OptimismIcon } from 'components/icons';

import { ChainID, ChainInfo } from './chain.info';

export const optimismChain = {
  name: 'Optimism',
  chainId: ChainID.Optimism,
  symbol: 'ETH',
  symbolAlias: 'WETH',
  blockExplorerUrl: 'https://optimistic.etherscan.io',
  Icon: OptimismIcon,
} as const satisfies ChainInfo;
