import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';

import { WalletStore } from 'core/wallet/wallet.store';

import { TermsConditionsForm } from './terms-conditions-form';

export const TermsConditionsModal = observer(() => {
  const { address } = useService(WalletStore);

  if (!address) return null;

  return <TermsConditionsForm address={address} key={address} />;
});

TermsConditionsModal.displayName = 'TermsConditionsModal';
