import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgLidoStaking = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 10 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.37 5.423 4.904 0 1.44 5.423l3.464 2.02 3.465-2.02Zm-3.465-4.02L2.5 5.168l2.403 1.401L7.31 5.167 4.905 1.404ZM.88 6.294 4.9 8.634l4.02-2.343.11.172a4.9 4.9 0 1 1-8.259 0l.11-.172Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgLidoStaking;
