import { VaultActionInfoZeroX } from 'core/vault/vault-action.info';

import { AssetIcon } from 'components/asset-icon';
import { AssetIconSize } from 'components/asset-icon/asset-icon';
import { FormatMoney } from 'components/format-money/format-money';
import { ArrowRightIcon } from 'components/icons';
import { Primary, Secondary } from 'components/typography';

type SwapActionProps = {
  data: VaultActionInfoZeroX;
};

export const SwapAction = (props: SwapActionProps) => {
  const { data } = props;

  return (
    <div className="flex items-center gap-x-6">
      <div className="flex gap-x-2 items-centerw-2/5 justify-start">
        <AssetIcon asset={data.inputToken} chain size={AssetIconSize.Medium} />
        <div className="flex flex-col">
          <Primary>
            <FormatMoney value={data.inputTokenAmount} asset={data.inputToken} precise showSuffix unstyled />
          </Primary>
          <Secondary>
            <FormatMoney value={data.inputToken.price} dollarSign />
          </Secondary>
        </div>
      </div>
      <ArrowRightIcon />
      <div className="flex gap-x-2 items-center w-2/5 justify-start">
        <AssetIcon asset={data.outputToken} chain size={AssetIconSize.Medium} />
        <div className="flex flex-col">
          <Primary>
            <FormatMoney value={data.outputTokenAmount} asset={data.outputToken} precise showSuffix unstyled />
          </Primary>
          <Secondary>
            <FormatMoney value={data.outputToken.price} dollarSign />
          </Secondary>
        </div>
      </div>
    </div>
  );
};
