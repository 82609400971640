import { LogoWhiteLabelIcon } from 'components/icons';
import { IconSize } from 'components/svg-icon';

import type { DefaultTheme } from '../default';
import metisLogoSrc from './metis-logo.png';
import './metis.css';

export const MetisTheme: DefaultTheme = {
  name: 'metis',
  appName: 'Metis',
  connectButtonText: 'Sign in',
  connectButtonEmptyText: 'Sign in',
  logo: (
    <div className="relative">
      <LogoWhiteLabelIcon className="self-center w-[22px] h-[22px] text-primary" size={IconSize.Largest} />
      <img
        src={metisLogoSrc}
        alt="Metis"
        className="absolute bottom-0 right-0 w-4 h-4 translate-x-2.5 translate-y-1.5"
      />
    </div>
  ),
  meta: {
    title:
      'Valio.xyz - Bet on your favourite traders and let them make money for you. Talent discovery marketplace, natively omnichain.',
    description:
      'Deposit safely in dynamic vaults managed by the best web3 traders and investors. Built on Layer0 and secured by CPIT®',
    icon: '',
  },
  features: {
    szn0: false,
    szn1: false,
    manage: false,
    shareCard: false,
    socialLinks: false,
    allocationVideo: false,
    walletBalanceImg: false,
    dayPerformingVaults: true,
    footerLinks: false,
  },
};
