import { RouteObject } from 'react-router-dom';

import { NotFound } from 'components/not-found';

export const myPositionRoutes: RouteObject[] = [
  {
    index: true,
    lazy: () => import('./portfolio'),
  },
  {
    path: ':id',
    lazy: () => import('./details'),
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
