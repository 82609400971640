import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgRandom = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 15 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.371 4.714V3.608c-1.216.052-2.369.605-3.221 1.554L5.528 8.167c-1.2 1.382-2.906 2.176-4.644 2.176-.49 0-.884-.432-.884-.967 0-.535.395-.967.884-.967 1.264 0 2.496-.57 3.364-1.571L6.87 3.833c1.169-1.33 2.812-2.124 4.501-2.159V.552c0-.449.458-.708.79-.449l2.638 2.09a.582.582 0 0 1 0 .88l-2.638 2.09c-.332.259-.79 0-.79-.45ZM.884 3.608c1.264 0 2.496.57 3.364 1.572l.127.138 1.232-1.399-.063-.069C4.344 2.47 2.638 1.675.9 1.675c-.49 0-.884.431-.884.967-.016.535.379.966.868.966Z"
      fill="currentColor"
    />
    <path
      d="m14.799 8.944-2.638-2.09c-.332-.258-.79 0-.79.45v1.105c-1.216-.035-2.369-.604-3.206-1.554l-.126-.156-1.232 1.4.063.068c1.169 1.347 2.796 2.124 4.501 2.159v1.122c0 .449.458.708.79.449l2.638-2.09a.563.563 0 0 0 0-.863Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgRandom;
