import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgStaking = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 30 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.994 16.7c.624.305 1.31.463 2.003.46A4.54 4.54 0 0 0 17 16.7v.001l10.862-5.46a2.969 2.969 0 0 0 0-5.313L17 .468a4.526 4.526 0 0 0-4.006 0L2.132 5.927a2.968 2.968 0 0 0 0 5.313l10.862 5.46Zm0 7.42c.623.305 1.309.462 2.003.46A4.541 4.541 0 0 0 17 24.12l12.183-6.128a1.485 1.485 0 0 0 .653-1.988 1.484 1.484 0 0 0-1.989-.653l-12.183 6.113c-.42.212-.915.212-1.335 0L2.146 15.351a1.483 1.483 0 0 0-1.988.653 1.484 1.484 0 0 0 .653 1.988l12.182 6.128ZM14.996 32a4.544 4.544 0 0 1-2.004-.46L.811 25.412a1.484 1.484 0 0 1-.653-1.989 1.484 1.484 0 0 1 1.988-.653l12.183 6.114c.42.212.915.212 1.335 0l12.183-6.114a1.485 1.485 0 0 1 1.989.653 1.484 1.484 0 0 1-.653 1.989L17 31.54a4.544 4.544 0 0 1-2.003.46Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgStaking;
