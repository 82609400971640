/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { AssetFragmentDoc } from '../asset/asset.fragment.generated';

export type VaultAssetFragment = {
  __typename?: 'VaultAssetType';
  value: BigNumber;
  amount: BigNumber;
  allocation: BigNumber;
  pAndL: BigNumber;
  roi: BigNumber;
  asset: {
    __typename?: 'AssetType';
    id: string;
    name: string;
    address: string;
    symbol: string;
    decimals?: number | null;
    price?: BigNumber | null;
    chain: string;
    chainId: number;
    priceChangeAmount?: BigNumber | null;
    priceChangePercentage?: BigNumber | null;
    marketCap?: string | null;
    totalSupply?: string | null;
    type: Types.AssetTypeEnum;
    deprecated: boolean;
    hardDeprecated: boolean;
    createdAt: string;
  };
};

export const VaultAssetFragmentDoc = gql`
  fragment VaultAsset on VaultAssetType {
    asset {
      ...Asset
    }
    value
    amount
    allocation
    pAndL
    roi
  }
  ${AssetFragmentDoc}
`;
