import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgPremia = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#premia_svg__a)">
      <path
        clipRule="evenodd"
        d="M35.765 32.581h31.47c1.79 0 2.823 2.102 1.771 3.6L54.03 57.497c-1.053 1.498-.019 3.599 1.77 3.599H69c.7 0 1.358-.347 1.77-.934l14.812-21.08a2.332 2.332 0 0 0 0-2.667l-12.986-18.48a2.166 2.166 0 0 0-1.77-.935h-38.65c-.7 0-1.358.347-1.77.934l-12.986 18.48a2.332 2.332 0 0 0 0 2.667l32.31 45.986a2.147 2.147 0 0 0 3.542 0l6.599-9.392a2.331 2.331 0 0 0 0-2.667L33.994 36.181c-1.052-1.498-.018-3.6 1.771-3.6Z"
        stroke="#F7FAFF"
        strokeWidth={2}
      />
    </g>
    <defs>
      <filter
        id="premia_svg__a"
        x={0.233}
        y={0.233}
        width={102.533}
        height={102.533}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={7.883} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3319_18913" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={4.3} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_3319_18913" result="effect2_dropShadow_3319_18913" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.433} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_3319_18913" result="effect3_dropShadow_3319_18913" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_3319_18913" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgPremia;
