import { PathPattern } from 'react-router';
import { matchPath, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

const leftSide: PathPattern[] = [{ path: '/', end: true }];

export const PageBacklight = () => {
  const location = useLocation();

  const isLeftSide = leftSide.map((path) => matchPath(path, location.pathname)).some(Boolean);

  return (
    <div
      className={twMerge(
        'fixed w-[800px] h-[800px] -top-[600px] bg-brand-500 opacity-40 filter blur-[385px]',
        isLeftSide ? '-left-[600px]' : '-right-[600px]'
      )}
    />
  );
};
