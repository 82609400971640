import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';
import { Link, useMatch } from 'react-router-dom';
import { useTheme } from 'styles/themes/use-theme.hook';
import { twMerge } from 'tailwind-merge';

import { AppStore } from 'core/app/app.store';
import { WalletStore } from 'core/wallet/wallet.store';

import { ModalsStore } from 'lib/modals/modals.store';

import { Chip } from 'components/chip';
import { GodModeModal } from 'components/modals/god-mode';

import { isSomething } from 'utils/is-something';

import { ConnectButton } from './connect-button';
import { NavMenu } from './nav-menu/nav-menu';
import { Search } from './search/search';
import { UserMenu } from './user-menu/user-menu';

export const Header = observer(() => {
  const { address } = useService(WalletStore);
  const { godMode } = useService(AppStore);
  const modalsStore = useService(ModalsStore);
  const { logo, features } = useTheme();

  const isMainPage = isSomething(useMatch('/'));

  return (
    <div
      className={twMerge(
        'fixed top-0 left-0 right-0 flex flex-row h-[50px] max-h-[50px] px-[19px] py-[8px] border-b border-outline backdrop-filter backdrop-blur-sm box-border bg-background/70 shadow-none z-[6]',
        isMainPage && features?.szn1 && 'top-[50px]'
      )}
    >
      <div className="flex flex-row flex-auto gap-x-9">
        <Link to="/" className="flex items-center">
          <div className="relative flex items-center gap-1">{logo}</div>
        </Link>
        <NavMenu />
      </div>
      <div className="flex flex-row flex-auto justify-end items-center gap-x-[7px]">
        {godMode && <Chip label="God mode menu" onClick={() => modalsStore.open(GodModeModal)} />}
        <Search />
        {address && <UserMenu />}
        <div className="flex flex-row gap-x-[7px]" data-testid="connect-wallet-button">
          {!address && <ConnectButton slim />}
        </div>
      </div>
    </div>
  );
});
