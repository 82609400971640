import { ComponentPropsWithoutRef, PropsWithChildren, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { CheckIcon } from 'components/icons';
import { IconSize } from 'components/svg-icon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MenuItemValue = number | string | object | undefined | null | any;

export type MenuItemProps = PropsWithChildren<
  Omit<ComponentPropsWithoutRef<'li'>, 'value'> & {
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    text: ReactNode;
    value?: MenuItemValue;
    selected?: boolean;
    selectable?: boolean;
    disabled?: boolean;
  }
>;

export const MenuItem = ({ startIcon, endIcon, text, selected, selectable, disabled, ...props }: MenuItemProps) => (
  <li
    className={twMerge(
      'flex items-center cursor-pointer pointer-events-all py-[7px] px-3.5 text-secondary box-border select-none',
      selected && 'text-primary',
      'hover:text-primary aria-selected:text-primary',
      disabled && 'pointer-events-none text-secondary'
    )}
    {...props}
  >
    {startIcon && <MenuItemIcon classname="mr-2.5">{startIcon}</MenuItemIcon>}
    <MenuItemText>{text}</MenuItemText>
    {endIcon && <MenuItemIcon classname="ml-2.5">{endIcon}</MenuItemIcon>}
    {selectable && (
      <MenuItemIcon classname={twMerge('ml-2.5 text-transparent', selected && 'text-brand-600')}>
        <CheckIcon size={IconSize.Smaller} />
      </MenuItemIcon>
    )}
  </li>
);

export const MenuItemText = ({ classname, ...props }: PropsWithChildren<{ classname?: string }>) => (
  <div
    className={twMerge('text-sm', 'transition duration-200 flex-1 pointer-events-none truncate', classname)}
    {...props}
  />
);

export const MenuItemIcon = ({ classname, ...props }: PropsWithChildren<{ classname?: string }>) => (
  <div
    className={twMerge(
      'menu-item-icon-root pointer-events-none [&_svg]:block [&_img]:block',
      '[&>.svg-icon-root]:text-current',
      classname
    )}
    {...props}
  />
);
