import { useService } from '@redtea/react-inversify';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import ConfettiExplosion from 'react-confetti-explosion';

import { AppRouter } from 'core/app/app.router';

import { ModalsStore } from 'lib/modals/modals.store';

import { Button } from 'components/button';
import { H1, Primary, Secondary } from 'components/typography';

import { RevealSZN0Store } from '../reveal-szn0.store';

const confettiColors = ['#9F44FF', '#5E11AF', '#3D0677', '#260845'];

export const RevealModal = observer(() => {
  const { inventoryHistory, availableBoxes } = useService(RevealSZN0Store);
  const { navigate } = useService(AppRouter);
  const modalsStore = useService(ModalsStore);

  const handleClose = () => {
    modalsStore.close(RevealModal.displayName);
    navigate('/szn0');
  };

  return (
    <div className="flex flex-col w-full min-w-[550px] px-10 py-[25px] gap-[50px]">
      {!availableBoxes && (
        <div className="absolute top-0 -translate-x-2/4 left-2/4">
          <AnimatePresence>
            <motion.div
              variants={contentAnimation}
              transition={{ duration: 0.2 }}
              exit="hide"
              animate="show"
              initial="hide"
            >
              <ConfettiExplosion
                className="absolute top-0 -translate-x-2/4 -translate-y-2/4 left-2/4"
                width={1600}
                zIndex={4}
                duration={3000}
                colors={confettiColors}
              />
            </motion.div>
          </AnimatePresence>
        </div>
      )}

      <div className="flex flex-col items-center justify-center">
        <H1>Reward chests received</H1>
        <Secondary>Congratulations! Here are reward chests that you revealed</Secondary>
      </div>
      <div className="flex items-center justify-center">
        {inventoryHistory.map((item, key) => (
          <div key={key} className="relative flex flex-col items-center justify-center">
            <img className="w-[230px]" src={item.src} />
            <Primary className="absolute bottom-[25px] first-letter:uppercase">{item.type.toLowerCase()}</Primary>
            <div className='flex items-center justify-center absolute w-[75px] h-[75px] backdrop-blur-[3.5px] rounded-[50%] right-5 top-5 bottom-2.5 border border-white/15 bg-white/15 after:content-[""] after:absolute after:w-[57px] after:h-[57px] after:-translate-x-2/4 after:-translate-y-2/4 after:rounded-full after:left-2/4 after:top-2/4 after:bg-white/11 after:border after:border-white/13'>
              {<H1>{item.count}x</H1>}
            </div>
          </div>
        ))}
      </div>

      <div className="flex gap-2.5">
        <Button fullWidth onClick={handleClose}>
          Back to my SZN0
        </Button>
      </div>
    </div>
  );
});

RevealModal.displayName = 'RevealModal';

const contentAnimation: Variants = {
  show: {
    opacity: 1,
  },
  hide: {
    opacity: 0,
  },
};
