import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgLines = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.5A1.5 1.5 0 0 1 1.5 0h12a1.5 1.5 0 0 1 0 3h-12A1.5 1.5 0 0 1 0 1.5Zm0 5.25a1.5 1.5 0 0 1 1.5-1.5h12a1.5 1.5 0 0 1 0 3h-12A1.5 1.5 0 0 1 0 6.75Zm1.5 3.75a1.5 1.5 0 0 0 0 3h12a1.5 1.5 0 0 0 0-3h-12Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgLines;
