import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';

import { NetworkStore } from 'core/network/network.store';

import { Button } from 'components/button';
import { H1, Secondary } from 'components/typography';

import { chains } from '../chain';
import { MainChainID } from '../chain.info';

export const ChainSwitcherModal = observer(() => {
  const { requiredChainId, switchNetwork, isNetworkSwitching, isWrongChain } = useService(NetworkStore);

  const header = requiredChainId ? `Switch to ${chains[requiredChainId].name}` : 'Please switch chain';
  const description = isWrongChain
    ? 'This chain is not supported'
    : `In order to perform this action please switch to ${chains[requiredChainId ?? MainChainID].name}`;

  const items = requiredChainId ? [chains[requiredChainId]] : Object.values(chains).reverse();

  return (
    <div className="flex flex-col gap-5 w-[489px] box-border p-[30px]" data-testid="chain-switcher-modal">
      <div className="flex items-center justify-center">
        <div className="flex flex-col items-center">
          <H1>{header}</H1>
          <Secondary>{description}</Secondary>
        </div>
      </div>
      <div className="flex flex-col gap-[12px]">
        {items.map((chain) => (
          <Button
            key={chain.chainId}
            startIcon={<chain.Icon />}
            onClick={() => switchNetwork(chain.chainId)}
            loading={isNetworkSwitching}
            fullWidth
            data-testid="chain-switcher-button"
          >
            {isNetworkSwitching ? 'Switching' : 'Switch'} to {chain.name}
          </Button>
        ))}
      </div>
    </div>
  );
});

ChainSwitcherModal.displayName = 'ChainSwitcherModal';
