import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgMetamask = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 37 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m10.412 29.878 4.769 1.266v-1.655l.39-.39h2.724v3.31h-2.92l-3.6-1.557-1.363-.974Z" fill="#CDBDB2" />
    <path d="m25.984 29.878-4.671 1.266v-1.655l-.39-.39h-2.725v3.31h2.92l3.601-1.557 1.265-.974Z" fill="#CDBDB2" />
    <path d="m15.573 26.277-.39 3.211.487-.389h5.061l.584.39-.39-3.212-.778-.487-3.893.097-.681.39Z" fill="#393939" />
    <path d="m12.943 4.573 2.336 5.45 1.07 15.864h3.796l1.168-15.864 2.141-5.45h-10.51Z" fill="#F89C35" />
    <path d="M2.725 16.546 0 24.43l6.813-.39h4.38v-3.406l-.195-7.007-.974.778-7.299 2.141Z" fill="#F89D35" />
    <path d="m7.883 17.422 7.98.195-.875 4.087-3.796-.973-3.31-3.309Z" fill="#D87C30" />
    <path d="m7.883 17.518 3.309 3.114v3.115l-3.31-6.23Z" fill="#EA8D3A" />
    <path d="m11.191 20.727 3.893.974 1.266 4.185-.876.486-4.283-2.53v-3.115Z" fill="#F89D35" />
    <path d="m11.19 23.846-.778 6.034 5.158-3.601-4.38-2.433Z" fill="#EB8F35" />
    <path d="m15.864 17.616.487 8.272-1.46-4.233.973-4.04Z" fill="#EA8E3A" />
    <path d="m6.715 23.943 4.477-.097-.779 6.034-3.698-5.937Z" fill="#D87C30" />
    <path d="m2.141 32.504 8.273-2.628-3.699-5.937L0 24.426l2.141 8.078Z" fill="#EB8F35" />
    <path d="m15.28 10.023-4.185 3.504-3.212 3.893 7.98.291-.583-7.688Z" fill="#E8821E" />
    <path
      d="m10.412 29.879 5.158-3.601-.389 3.114v1.752l-3.504-.681-1.265-.584ZM25.984 29.879l-5.06-3.601.389 3.114v1.752l3.504-.681 1.167-.584Z"
      fill="#DFCEC3"
    />
    <path d="m13.722 19.369 1.071 2.238-3.796-.973 2.725-1.265Z" fill="#393939" />
    <path d="m2.043 0 13.236 10.024-2.238-5.45L2.043 0Z" fill="#E88F35" />
    <path
      d="M2.043 0 .29 5.353l.973 5.84-.681.389.973.875-.778.682 1.07.973-.681.584 1.557 1.946 7.3-2.238c3.568-2.855 5.32-4.315 5.255-4.38C15.214 9.96 10.802 6.618 2.043 0Z"
      fill="#8E5A30"
    />
    <path d="M33.675 16.546 36.4 24.43l-6.812-.39h-4.38v-3.406l.195-7.007.973.778 7.3 2.141Z" fill="#F89D35" />
    <path d="m28.518 17.422-7.981.195.876 4.087 3.796-.973 3.309-3.309Z" fill="#D87C30" />
    <path d="m28.518 17.518-3.31 3.114v3.115l3.31-6.23Z" fill="#EA8D3A" />
    <path d="m25.209 20.727-3.893.974-1.265 4.185.876.486 4.282-2.53v-3.115Z" fill="#F89D35" />
    <path d="m25.21 23.846.778 6.034-5.06-3.504 4.282-2.53Z" fill="#EB8F35" />
    <path d="m20.536 17.616-.486 8.272 1.46-4.233-.974-4.04Z" fill="#EA8E3A" />
    <path d="m29.686 23.943-4.477-.097.778 6.034 3.699-5.937Z" fill="#D87C30" />
    <path d="m34.26 32.504-8.273-2.628 3.698-5.937 6.715.487-2.14 8.078Z" fill="#EB8F35" />
    <path d="m21.12 10.023 4.186 3.504 3.212 3.893-7.981.291.584-7.688Z" fill="#E8821E" />
    <path d="m22.678 19.369-1.07 2.238 3.795-.973-2.725-1.265Z" fill="#393939" />
    <path d="M34.357 0 21.121 10.024l2.239-5.45L34.357 0Z" fill="#E88F35" />
    <path
      d="m34.358 0 1.751 5.353-.973 5.84.681.389-.973.875.779.682-1.07.973.68.584-1.557 1.946-7.3-2.238c-3.568-2.855-5.32-4.315-5.255-4.38C21.186 9.96 25.598 6.618 34.358 0Z"
      fill="#8E5A30"
    />
  </svg>
));
export default SvgMetamask;
