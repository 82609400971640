import { VaultActionGmxInfo } from 'core/vault/vault-action.info';

import { AssetIcon } from 'components/asset-icon';
import { AssetIconSize } from 'components/asset-icon/asset-icon';
import { FormatMoney } from 'components/format-money/format-money';
import { Primary, Secondary } from 'components/typography';

type GMXActionProps = {
  data: VaultActionGmxInfo;
};

export const GMXAction = (props: GMXActionProps) => {
  const { data } = props;

  return (
    <div className="flex flex-col">
      <div className="flex gap-x-[7px] items-center">
        <Primary>Request {data.increase ? 'increase' : 'decrease'}</Primary>
        <AssetIcon asset={data.indexToken} size={AssetIconSize.Small} />
        <Primary>
          {data.isLong ? 'Long' : 'Short'}{' '}
          <FormatMoney
            value={data.increase ? data.positionDelta : data.positionDelta.multipliedBy(-1)}
            prependSign
            dollarSign
            unstyled
          />
        </Primary>
      </div>
      <div className="flex gap-x-1.5 items-center">
        <AssetIcon asset={data.indexToken} size={AssetIconSize.Small} />
        <Secondary>
          Price: <FormatMoney unstyled value={data.unitPrice} dollarSign />
        </Secondary>
      </div>
    </div>
  );
};
