import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';

import { ChainService } from 'core/chain/chain.service';
import { Snackbar } from 'core/snackbars/snackbar';

import { ArrowUpRightIcon, ErrorIcon, SuccessfulIcon } from 'components/icons';
import { VaultLoader } from 'components/loading';
import { IconSize } from 'components/svg-icon';
import { Primary, Secondary } from 'components/typography';

type TransactionsProps = {
  data: Snackbar.Transaction;
};

export const Transactions = observer((props: TransactionsProps) => {
  const { status, from, to, txId } = props.data;

  const { chain } = useService(ChainService);

  return (
    <>
      <div className="flex items-center gap-2.5" data-testid="transaction-notification">
        {getTransactionsData(status).icon}
        <div className="flex flex-col">
          <Primary data-testid="status">Transaction {getTransactionsData(status).text}</Primary>

          {from && to && (
            <Secondary data-testid="swap-info">
              Swap from {from.symbol} to {to.symbol}
            </Secondary>
          )}

          <Secondary>
            <a
              className="no-underline flex items-center gap-[2px]"
              href={`${chain.blockExplorerUrl}/tx/${txId}`}
              target="_blank"
              rel="noreferrer"
            >
              TX <ArrowUpRightIcon />
            </a>
          </Secondary>
        </div>
      </div>
    </>
  );
});

export enum TransactionsTypes {
  Pending = 'pending',
  Failed = 'failed',
  Successful = 'successful',
}

const getTransactionsData = (status: TransactionsTypes) => {
  switch (status) {
    case TransactionsTypes.Failed:
      return {
        icon: <ErrorIcon className="[filter:drop-shadow(0px_0px_20px_#ff406e)]" size={IconSize.Bigger} />,
        text: TransactionsTypes.Failed,
      };
    case TransactionsTypes.Pending:
      return { icon: <VaultLoader width={21} height={21} />, text: TransactionsTypes.Pending };
    case TransactionsTypes.Successful:
      return {
        icon: (
          <SuccessfulIcon
            className="[filter:drop-shadow(0px_0px_20px_rgba(0,_255,_200,_0.5))]"
            size={IconSize.Bigger}
          />
        ),
        text: TransactionsTypes.Successful,
      };
  }
};
