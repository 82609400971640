import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgBridge = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 22 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.688 3.409c.48 0 .94-.058 1.375-.151v3.56h1.374V2.82a8.93 8.93 0 0 0 1.376-.737v4.736h1.375V1.93c.386.22.858.464 1.386.69a.658.658 0 0 0-.011.107v4.091h1.375V3.105c.443.124.905.216 1.374.264v3.45h1.376V3.38a9.116 9.116 0 0 0 1.374-.207v3.644h1.376V2.743c.54-.219.999-.464 1.374-.697v4.772h1.376V2.081c.381.25.844.512 1.375.738v4h1.375V3.257c.434.093.894.151 1.375.151.379 0 .687-.305.687-.682a.685.685 0 0 0-.688-.682c-2.42 0-4.308-1.828-4.326-1.845a.691.691 0 0 0-.972 0C15.995.218 14.117 2.045 11 2.045c-2.482 0-5.062-1.89-5.088-1.91A.693.693 0 0 0 5.014.2C4.995.218 3.124 2.045.687 2.045A.685.685 0 0 0 0 2.727c0 .377.308.682.688.682ZM21.313 7.5H.688A.685.685 0 0 0 0 8.182v1.363c0 .377.308.682.688.682h3.437v4.091c0 .377.308.682.688.682h1.375c.379 0 .687-.306.687-.682v-4.09h8.25v4.09c0 .377.308.682.688.682h1.374c.38 0 .688-.306.688-.682v-4.09h3.438c.379 0 .687-.306.687-.683V8.182a.685.685 0 0 0-.688-.682Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgBridge;
