import { NEVER, Observable, ReplaySubject, fromEvent, map, share, startWith, switchMap, timer } from 'rxjs';

import { Milliseconds } from 'types';

const isVisible = (): boolean => !document.hidden;

export const visibility$ = fromEvent(document, 'visibilitychange').pipe(
  startWith(isVisible()),
  map(isVisible),
  share({
    connector: () => new ReplaySubject(1),
  })
);

export const polling = (interval: Milliseconds): Observable<number> =>
  visibility$.pipe(switchMap((visible) => (visible ? timer(0, interval) : NEVER)));
