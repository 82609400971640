import { Container, ContainerModule } from 'inversify';

import { AccountStore } from 'core/account/account.store';
import { AssetsStore } from 'core/asset/assets.store';
import { AuthService } from 'core/auth/auth.service';
import { ChainService } from 'core/chain/chain.service';
import { ConfigService } from 'core/config/config.service';
import { ContractStore } from 'core/contract/contract.store';
import { GasPriceStore } from 'core/gas-price/gas-price.store';
import { $Container } from 'core/identifiers';
import { LocalStorageService } from 'core/local-storage/local-storage.service';
import { NetworkProvider } from 'core/network/network.provider';
import { NetworkService } from 'core/network/network.service';
import { NetworkStore } from 'core/network/network.store';
import { NotificationsStore } from 'core/notifications/notifications.store';
import { RevealStore } from 'core/reveal/reveal.store';
import { SnackbarStore } from 'core/snackbars/snackbar.store';
import { ValioRegistry } from 'core/valio/valio.registry';
import { VaultContract } from 'core/valio/vault.contract';
import { ZeroXExecutor } from 'core/valio/zero-x.executor';
import { ExploreVaultsStore } from 'core/vault/explore/explore-vaults.store';
import { VaultSearchStore } from 'core/vault/vault-search.store';
import { VaultStore } from 'core/vault/vault.store';
import { VaultBalanceService } from 'core/wallet/vault-balance.service';
import { WalletBalanceService } from 'core/wallet/wallet-balance.service';
import { WalletStore } from 'core/wallet/wallet.store';

import { ModalsStore } from 'lib/modals/modals.store';

import { RevealSZN0Store } from 'pages/season-zero/reveal/reveal-szn0.store';

import { createIOC } from 'utils/use-ioc';

import { AmplitudeService } from './amplitude/amplitude.service';
import { AppRouter } from './app/app.router';
import { AppStore } from './app/app.store';
import { ErrorStore } from './app/error.store';
import { ContractCache } from './contract/contract.cache';
import { PrivyClient } from './network/privy.client';
import { OrganizationsService } from './organizations/organizations.service';
import { ReferralStore } from './referral/referral.store';
import { DepositSnackbarStore } from './snackbars/depoposit/deposit-snackbar.store';
import { TransactionSnackbarStore } from './snackbars/transactions/transaction-snackbar.store';
import { WithdrawSnackbarStore } from './snackbars/withdraw/withdraw-snackbar.store';
import { VaultExecutor } from './valio/vault.executor';
import { VaultDatasetStore } from './vault/vault-dataset.store';
import { BalanceService } from './wallet/balance.service';
import { LazyWalletBalanceService } from './wallet/lazy-wallet-balance.service';

export const bootstrapModule = createIOC({
  module: new ContainerModule((bind) => {
    bind(ConfigService).toSelf();
    bind($Container).toDynamicValue(({ container }) => container as Container);
  }),
  autoInstantiate: [ConfigService],
});

export const coreModule = createIOC({
  module: new ContainerModule((bind) => {
    bind(AppStore).toSelf();
    bind(ErrorStore).toSelf();
    bind(AppRouter).toSelf();
    bind(NetworkProvider).toSelf();
    bind(NetworkService).toSelf();
    bind(NetworkStore).toDynamicValue(
      ({ container }) =>
        new NetworkStore(
          container.get(NetworkService),
          container.get(ModalsStore),
          container.get(NetworkProvider),
          container.get(SnackbarStore),
          container.get($Container),
          [coreMultiModule]
        )
    );
    bind(PrivyClient).toSelf();
    bind(LocalStorageService).toSelf();
    bind(ZeroXExecutor).toSelf();
    bind(VaultStore).toSelf();
    bind(VaultExecutor).toSelf();
    bind(VaultDatasetStore).toSelf();
    bind(VaultSearchStore).toSelf();
    bind(ExploreVaultsStore).toSelf();
    bind(NotificationsStore).toSelf();
    bind(ReferralStore).toSelf();
    bind(AccountStore).toSelf();
    bind(SnackbarStore).toSelf();
    bind(TransactionSnackbarStore).toSelf();
    bind(DepositSnackbarStore).toSelf();
    bind(WithdrawSnackbarStore).toSelf();
    bind(AssetsStore).toSelf();
    bind(ChainService).toSelf();
    bind(GasPriceStore).toSelf();
    bind(RevealStore).toSelf();
    bind(RevealSZN0Store).toSelf();
    bind(AuthService).toSelf();
    bind(OrganizationsService).toSelf();
    bind(WalletStore).toSelf();
    bind(BalanceService).toSelf();
    bind(ValioRegistry).toSelf();
    bind(VaultContract).toSelf();
    bind(ContractStore).toSelf();
    bind(ContractCache).toSelf();
    bind(AmplitudeService).toSelf();
  }),
  autoInstantiate: [AppStore, AuthService, NetworkStore, DepositSnackbarStore, VaultExecutor],
});

/**
 * These services that are used in cross-chain interactions
 * will be instantiated for each chain container {readonly} - no signer.
 */
export const coreMultiModule = createIOC({
  module: new ContainerModule((bind) => {
    bind(ValioRegistry).toSelf();
    bind(VaultContract).toSelf();
    bind(ContractStore).toSelf();

    // available only in multichain containers
    bind(WalletBalanceService).toSelf();
    bind(VaultBalanceService).toSelf();
    bind(LazyWalletBalanceService).toSelf();
  }),
  autoInstantiate: [WalletBalanceService, VaultBalanceService, LazyWalletBalanceService],
});
