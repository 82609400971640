import { Web3Provider } from '@ethersproject/providers';
import { ConnectedWallet, User } from '@privy-io/react-auth';

import { Address } from 'core/address';
import { AnyChain } from 'core/chain';

export namespace Privy {
  export enum ConnectionMethod {
    Wallet = 'wallet',
    Twitter = 'twitter',
    Google = 'google',
    Discord = 'discord',
    Email = 'email',
  }

  export type Connection = {
    method: ConnectionMethod;
    username: string | null;
  };

  export type NetworkState<TChain = AnyChain> = {
    address: Address;
    chainId: TChain;
    provider: Web3Provider;
  };

  export type State = {
    user: User | null;
    wallets: ConnectedWallet[];
    connectedWallet?: ConnectedWallet;
    networkState: NetworkState | null;
  };

  export const getConnection = (user?: User | null): Connection | undefined => {
    if (!user) {
      return undefined;
    }

    if (user.twitter) {
      return { method: ConnectionMethod.Twitter, username: user.twitter.username };
    }

    if (user.discord) {
      return { method: ConnectionMethod.Discord, username: user.discord.username };
    }

    if (user.google) {
      return { method: ConnectionMethod.Google, username: user.google.email };
    }

    if (user.email) {
      return { method: ConnectionMethod.Email, username: user.email.address };
    }

    return { method: ConnectionMethod.Wallet, username: null };
  };
}
