import { JsonRpcSigner, TransactionRequest, Web3Provider } from '@ethersproject/providers';
import { captureException } from '@sentry/react';
import { BigNumber } from 'ethers';

import { GraphQLClientService } from 'core/api/gql.client';
import { SimulateTransaction } from 'core/api/gql/transactions/simulate-transaction.gql.generated';

import { isDev } from '../../../.webpack/parts/is-dev';

export const createProxyProvider = (provider: Web3Provider, gqlClientService: GraphQLClientService) => {
  const signer = getProxySigner(provider, gqlClientService);

  return new Proxy(provider, {
    get(target, property) {
      switch (property) {
        case 'getSigner':
          return () => signer;
        case 'estimateGas':
        case 'call':
          return customHandler({ method: target[property].bind(target), provider, gqlClientService });
      }

      return target[property as keyof Web3Provider];
    },
  });
};

const getProxySigner = (provider: Web3Provider, gqlClientService: GraphQLClientService) => {
  const signer = provider.getSigner();

  return new Proxy(signer, {
    get(target, property: keyof JsonRpcSigner) {
      if (property === 'sendTransaction') {
        return customHandler({ method: target.sendTransaction.bind(target), provider, gqlClientService });
      }

      return target[property];
    },
  });
};

type CustomHandlerParams = {
  method: (transaction: TransactionRequest) => Promise<unknown>;
  provider: Web3Provider;
  gqlClientService: GraphQLClientService;
};

const customHandler = (params: CustomHandlerParams) => {
  const { provider, gqlClientService, method } = params;

  return async (transaction: TransactionRequest) => {
    try {
      transaction.gasLimit = transaction.gasLimit || (await provider.estimateGas(transaction));

      const gasLimitWithBuffer = BigNumber.from(transaction.gasLimit).mul(120).div(100);

      return await method({ ...transaction, gasLimit: gasLimitWithBuffer });
    } catch (error) {
      if (isDev()) throw error;

      if (!(error && typeof error === 'object' && 'code' in error && error.code === 'ACTION_REJECTED')) {
        SimulateTransaction(gqlClientService.client.request.bind(gqlClientService.client), {
          input: {
            to: transaction.to || '',
            from: transaction.from || '',
            input: transaction.data?.toString() || '',
            gas: Number(transaction.gasLimit || 0),
            value: transaction.value?.toString() || '0',
            networkId: (await provider.getNetwork()).chainId,
            gasPrice: Number(transaction.gasPrice || 0),
          },
        }).catch((e) => captureException(e));
      }

      throw error;
    }
  };
};
