/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { NotificationFragmentDoc } from './notifications.fragment.gql.generated';

export type GetNotificationsQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.NotificationsInput>;
}>;

export type GetNotificationsQuery = {
  __typename?: 'Query';
  notifications: {
    __typename?: 'NotificationsType';
    numPages: number;
    numItems: number;
    items: Array<{
      __typename?: 'NotificationType';
      type: Types.NotificationTypeEnum;
      status: Types.NotificationStatusEnum;
      context?: string | null;
      message: string;
      createdAt: string;
    }>;
  };
};

export const GetNotificationsDocument = gql`
  query GetNotifications($input: NotificationsInput) {
    notifications(input: $input) {
      numPages
      numItems
      items {
        ...Notification
      }
    }
  }
  ${NotificationFragmentDoc}
`;

export function GetNotifications<R extends GetNotificationsQuery, V extends GetNotificationsQueryVariables>(
  request: Request<R, V>,
  variables?: V,
  headers?: HeadersInit
): Promise<R> {
  return request(GetNotificationsDocument, variables, headers);
}
