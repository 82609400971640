import { Address } from 'core/address';

export namespace LocalStorage {
  export const getAccountTermsKey = (address: Address): string => {
    return `termsConditions-${address}`;
  };

  export const getKeys = (): string[] => {
    const keys: string[] = [];

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key === null) continue;
      keys.push(key);
    }

    return keys;
  };
}
