import { RouteObject } from 'react-router-dom';

import { NotFound } from 'components/not-found';

export const manageRoutes: RouteObject[] = [
  {
    index: true,
    lazy: () => import('./'),
  },
  {
    path: 'create',
    lazy: () => import('./create-vault'),
  },
  {
    path: 'vault/:id/:tool?',
    lazy: () => import('./vault'),
    children: [
      {
        index: true,
        lazy: () => import('./vault/preview'),
      },
      {
        path: 'spot',
        lazy: () => import('./vault/spot-trade'),
      },
      {
        path: 'stake',
        lazy: () => import('./vault/lido'),
      },
      {
        path: 'premia',
        lazy: () => import('./vault/premia'),
      },
      {
        path: 'yearn',
        lazy: () => import('./vault/yearn'),
      },
      {
        path: 'kwenta/:asset?',
        lazy: () => import('./vault/kwenta'),
      },
      {
        path: 'gmx',
        lazy: () => import('./vault/gmx'),
      },
      {
        path: 'settings',
        lazy: () => import('./vault/settings'),
      },
      {
        path: 'bridge',
        lazy: () => import('./vault/swap-bridge'),
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
