import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgGraph = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.786.429a1 1 0 0 0-1 1V13.57a1 1 0 0 0 1 1H13a1 1 0 0 0 1-1V1.43a1 1 0 0 0-1-1h-1.214ZM5.643 5.286a1 1 0 0 1 1-1h1.214a1 1 0 0 1 1 1v8.285a1 1 0 0 1-1 1H6.643a1 1 0 0 1-1-1V5.286ZM.5 9.143a1 1 0 0 1 1-1h1.214a1 1 0 0 1 1 1v4.428a1 1 0 0 1-1 1H1.5a1 1 0 0 1-1-1V9.143Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgGraph;
