import { Values } from '@mobx-form-state/core';
import { Transport } from '@valioxyz/valio-contracts';
import BigNumber from 'bignumber.js';

import { Asset } from 'core/asset';
import { VaultModel } from 'core/vault/vault.model';

import { Units } from 'types';

import { riskProfileToContractMap } from './risk-profile.map';
import { Valio } from './valio';

export type CreateVaultParams = Pick<
  Values.FromModel<VaultModel>,
  'name' | 'streamingFee' | 'performanceFee' | 'riskProfile'
>;

type CreateParentVaultParams = Parameters<Transport['createParentVault']>;

export const createVaultParamsToContractMap = (
  params: CreateVaultParams,
  address: string,
  value: Units
): CreateParentVaultParams => {
  const { name } = params;

  const createVaultParams = [
    name,
    name,
    address,
    Asset.toUnits(new BigNumber(params.streamingFee), Valio.StreamingFeeDecimals),
    Asset.toUnits(new BigNumber(params.performanceFee), Valio.PerformanceFeeDecimals),
    riskProfileToContractMap(params.riskProfile),
    {
      value,
    },
  ] satisfies CreateParentVaultParams;

  return createVaultParams;
};
