import { OneOfTheme } from '.';
import * as themes from './themes';

export const themeFromHost = (url: string): OneOfTheme => {
  for (const theme in themes) {
    // if (url.includes('localhost')) {
    //   return 'anodos';
    // }

    if (url.includes(theme)) {
      return theme as OneOfTheme;
    }
  }

  return 'default';
};
