import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgChevronLeft = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.356 4.683 5.836.75C6.017.59 6.265.5 6.522.5c.258 0 .506.09.688.25a.86.86 0 0 1 .214.278.768.768 0 0 1 0 .66.86.86 0 0 1-.214.278L2.722 5.892a.86.86 0 0 0-.213.278.768.768 0 0 0 0 .66c.05.104.122.199.213.279l4.49 3.925a.81.81 0 0 1 .288.605.807.807 0 0 1-.284.608c-.182.161-.43.252-.69.253a1.05 1.05 0 0 1-.69-.249l-4.48-3.934C.808 7.835.5 7.181.5 6.5s.308-1.335.856-1.817Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgChevronLeft;
