import { memo, useCallback } from 'react';

import { Button, ButtonVariant } from 'components/button';
import { ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from 'components/icons';
import { Menu, MenuItem } from 'components/menu';
import { useMenu } from 'components/menu/use-menu.hook';
import { IconSize } from 'components/svg-icon';
import { Tab, Tabs } from 'components/tabs';

import { usePagination } from './use-pagination';

export const rowsOptionsDefault = [5, 10, 25, 50, 100];

export type PaginationProps = {
  pageSize: number;
  numPages: number;
  currentPage: number;
  rowsOptions?: number[];
  onPageSizeChange: (value: number) => void;
  onChange: (page: number) => void;
};

export const Pagination = memo<PaginationProps>((props) => {
  const { pageSize, numPages, currentPage, rowsOptions = rowsOptionsDefault, onPageSizeChange, onChange } = props;

  const { next, previous, range, setPage } = usePagination({
    onChange: (page) => onChange(page - 1),
    page: currentPage + 1,
    total: numPages,
  });

  const { state, handleClick } = useMenu();

  const makeOnRowsHandler = useCallback(
    (value: number) => () => {
      onPageSizeChange(value);
      state.onClose();
    },
    [onPageSizeChange, state]
  );

  const isPrevActive = currentPage > 0;
  const isNextActive = currentPage < numPages - 1;

  return (
    <div
      data-testid="table-pagination"
      className='relative flex w-full justify-end after:content-[""] after:absolute after:w-[110%] after:h-px after:top-0 after:-left-5 after:border-t after:border-outline'
    >
      <Button variant={ButtonVariant.Text} endIcon={<ChevronUpIcon size={IconSize.Smaller} />} onClick={handleClick}>
        Rows per page: {pageSize}
      </Button>
      <Menu {...state} popoverProps={{ placement: 'top', indent: 8 }}>
        {rowsOptions.map((item) => (
          <MenuItem
            key={item}
            value={item}
            text={item}
            onClick={makeOnRowsHandler(item)}
            selected={item === pageSize}
          />
        ))}
      </Menu>
      <div className="ml-9 flex gap-x-1.5">
        <Button
          variant={ButtonVariant.Text}
          startIcon={<ChevronLeftIcon />}
          onClick={previous}
          disabled={!isPrevActive}
        />
        <Tabs value={currentPage + 1} onChange={(page) => typeof page !== 'undefined' && setPage(page)}>
          {range.map((page, index) =>
            typeof page === 'number' ? (
              <Tab className="[&_tab-label]:p-[5px] [&_tab-label]:w-6" key={page} value={page} label={page} />
            ) : (
              <Tab className="[&_tab-label]:p-[5px] [&_tab-label]:w-6" key={page + index} label="..." disabled />
            )
          )}
        </Tabs>
        <Button variant={ButtonVariant.Text} endIcon={<ChevronRightIcon />} onClick={next} disabled={!isNextActive} />
      </div>
    </div>
  );
});
