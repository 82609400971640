import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgSignRequest = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 188 125" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#sign-request_svg__a)" fill="#F7FAFF">
      <path d="M92.007 61.798c.51-.5 1.328-.49 1.826.022 3.367 3.46 5.85 7.604 7.326 12.41C107.509 94.9 94.219 109 62.5 109c-31.719 0-45.008-14.099-38.66-34.77 1.45-4.72 3.872-8.803 7.15-12.227a1.288 1.288 0 0 1 1.826-.036 1.3 1.3 0 0 1 .036 1.833c-3 3.134-5.213 6.864-6.543 11.193-5.785 18.837 6.07 31.414 36.191 31.414 30.12 0 41.976-12.577 36.19-31.414-1.353-4.407-3.622-8.193-6.705-11.362a1.3 1.3 0 0 1 .022-1.833Z" />
      <path d="M107.548 81.788a1.297 1.297 0 0 1 .469 2.545l-.149.027-10.333 1.297a1.296 1.296 0 0 1-.469-2.545l.149-.028 10.333-1.296ZM96.133 89.2a1.292 1.292 0 0 1 1.45-.924l.147.033 9.041 2.593c.686.196 1.083.914.888 1.602a1.292 1.292 0 0 1-1.45.924l-.147-.033-9.042-2.593a1.297 1.297 0 0 1-.887-1.603ZM17.457 81.788a1.296 1.296 0 0 0-.48 2.543l.15.029 9.987 1.296a1.296 1.296 0 0 0 .48-2.543l-.148-.028-9.989-1.296ZM28.173 89.186a1.292 1.292 0 0 0-1.459-.908l-.147.035-8.696 2.593a1.297 1.297 0 0 0 .588 2.52l.147-.035 8.697-2.593a1.297 1.297 0 0 0 .87-1.612ZM88.148 39.013a1.29 1.29 0 0 1 1.23.52c4.534 6.25 5.876 15.226 4.107 26.884a1.292 1.292 0 1 1-2.554-.39c1.603-10.563.537-18.623-3.126-24.22l-.066-.096-.12.023c-5.2 1.041-9.62 4.474-13.292 10.385l-.313.512a1.29 1.29 0 0 1-1.387.597c-3.785-.844-7.162-1.265-10.127-1.265-2.965 0-6.342.42-10.127 1.265a1.29 1.29 0 0 1-1.387-.597c-3.626-6.047-7.999-9.618-13.149-10.799l-.577-.121-.065.096c-3.58 5.47-4.68 13.292-3.231 23.504l.105.716a1.292 1.292 0 1 1-2.554.39c-1.77-11.658-.427-20.634 4.108-26.884a1.29 1.29 0 0 1 1.229-.52c6.25.908 11.498 4.69 15.708 11.25l.15.24.775-.157c3.021-.596 5.797-.919 8.33-.969l.685-.007c2.715 0 5.72.326 9.015.976l.773.156.152-.239c4.103-6.392 9.189-10.146 15.23-11.174l.478-.076ZM40.463 83.52a1.29 1.29 0 0 1-1.657.771 1.298 1.298 0 0 1-.769-1.663c1.042-2.853 4.777-4.74 9.018-4.74.957 0 1.896.097 2.79.283a1.296 1.296 0 0 1-.524 2.538 11.15 11.15 0 0 0-2.266-.228c-3.266 0-5.983 1.373-6.592 3.04ZM75.679 80.71a1.296 1.296 0 0 1-.525-2.539 13.722 13.722 0 0 1 2.791-.282c4.24 0 7.976 1.886 9.018 4.739a1.298 1.298 0 0 1-.768 1.663 1.29 1.29 0 0 1-1.658-.77c-.609-1.667-3.326-3.04-6.592-3.04-.782 0-1.544.078-2.266.229ZM57.309 89.304a1.292 1.292 0 1 0-2.534.504c.71 3.601 3.945 6.23 7.725 6.23s7.014-2.629 7.725-6.23a1.292 1.292 0 1 0-2.534-.504c-.47 2.38-2.637 4.14-5.19 4.14-2.555 0-4.722-1.76-5.192-4.14Z" />
    </g>
    <g filter="url(#sign-request_svg__b)">
      <path d="M167 18c-2.667 16.333-16.8 49.9-52 53.5" stroke="#fff" strokeWidth={2} />
    </g>
    <g filter="url(#sign-request_svg__c)">
      <path d="m161 20.782 6.243-3.32 3.32 6.244" stroke="#fff" strokeWidth={2} strokeLinecap="round" />
    </g>
    <defs>
      <filter
        id="sign-request_svg__a"
        x={0.233}
        y={23.233}
        width={124.533}
        height={101.533}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={7.883} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9444_1516" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={4.3} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_9444_1516" result="effect2_dropShadow_9444_1516" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.433} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_9444_1516" result="effect3_dropShadow_9444_1516" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_9444_1516" result="shape" />
      </filter>
      <filter
        id="sign-request_svg__b"
        x={99.132}
        y={2.072}
        width={84.622}
        height={86.189}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={7.883} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9444_1516" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={4.3} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_9444_1516" result="effect2_dropShadow_9444_1516" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.433} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_9444_1516" result="effect3_dropShadow_9444_1516" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_9444_1516" result="shape" />
      </filter>
      <filter
        id="sign-request_svg__c"
        x={144.233}
        y={0.343}
        width={43.097}
        height={40.129}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={7.883} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9444_1516" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={4.3} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_9444_1516" result="effect2_dropShadow_9444_1516" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.433} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_9444_1516" result="effect3_dropShadow_9444_1516" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_9444_1516" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgSignRequest;
