/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';

export type DepositFragment = {
  __typename?: 'DepositType';
  createdAt: string;
  depositor: string;
  entryPrice: BigNumber;
  pAndL: BigNumber;
  roi: BigNumber;
  shares: BigNumber;
  sharesValue: BigNumber;
  stake: BigNumber;
  depositedAmount: BigNumber;
};

export const DepositFragmentDoc = gql`
  fragment Deposit on DepositType {
    createdAt
    depositor
    entryPrice
    pAndL
    roi
    shares
    sharesValue
    stake
    createdAt
    depositedAmount
  }
`;
