import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';

import { ChainService } from 'core/chain/chain.service';

import { ArrowUpRightIcon, ErrorIcon, SuccessfulIcon } from 'components/icons';
import { VaultLoader } from 'components/loading';
import { IconSize } from 'components/svg-icon';
import { Primary, Secondary } from 'components/typography';

import { Snackbar } from '../snackbar';

type DepositSnackbarProps = {
  data: Snackbar.Deposit;
};

export const DepositSnackbar = observer((props: DepositSnackbarProps) => {
  const { status, txId } = props.data;
  const { chain } = useService(ChainService);

  return (
    <div className="flex items-center gap-2.5">
      <div>{getDepositSnackbarData(status)?.icon}</div>
      <div className="flex flex-col">
        <Primary>{getDepositSnackbarData(status)?.text}</Primary>
        <Secondary>
          <a
            className="no-underline flex items-center gap-[2px]"
            href={`${chain.blockExplorerUrl}/tx/${txId}`}
            target="_blank"
            rel="noreferrer"
          >
            Arbiscan <ArrowUpRightIcon />
          </a>
        </Secondary>
      </div>
    </div>
  );
});

export enum DepositSnackbarStatus {
  Pending = 'pending',
  Success = 'success',
  Expired = 'expired',
  Cancelled = 'cancelled',
}

const getDepositSnackbarData = (status: DepositSnackbarStatus) => {
  switch (status) {
    case DepositSnackbarStatus.Pending:
      return { icon: <VaultLoader width={21} height={21} />, text: 'Your deposit is processing' };
    case DepositSnackbarStatus.Success:
      return {
        icon: (
          <SuccessfulIcon
            className="[filter:drop-shadow(0px_0px_20px_rgba(0,_255,_200,_0.5))]"
            size={IconSize.Bigger}
          />
        ),
        text: 'Deposit successful',
      };
    case DepositSnackbarStatus.Expired:
      return {
        icon: <ErrorIcon className="[filter:drop-shadow(0px_0px_20px_#ff406e)]" size={IconSize.Bigger} />,
        text: 'Your deposit has expired.',
      };
  }
};
