import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgChevronCaruselBlue = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 40 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#chevron-carusel-blue_svg__a)">
      <path
        d="M26.763 39.773a.745.745 0 0 1-.53.227.719.719 0 0 1-.53-.227L13.222 27.048a.766.766 0 0 1 0-1.079l12.483-12.727a.742.742 0 0 1 1.077-.018.77.77 0 0 1-.018 1.097L14.809 26.51l11.954 12.185a.768.768 0 0 1 0 1.08Z"
        fill="#fff"
      />
    </g>
    <defs>
      <filter
        id="chevron-carusel-blue_svg__a"
        x={0.737}
        y={0.737}
        width={38.526}
        height={51.526}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={6.131} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_13190_24613" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={3.344} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_13190_24613" result="effect2_dropShadow_13190_24613" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.115} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_13190_24613" result="effect3_dropShadow_13190_24613" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_13190_24613" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgChevronCaruselBlue;
