import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgChevronRight = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m6.644 8.317-4.48 3.934c-.182.16-.43.249-.687.249a1.05 1.05 0 0 1-.688-.25.86.86 0 0 1-.214-.278.768.768 0 0 1 0-.66.86.86 0 0 1 .214-.278l4.489-3.926a.86.86 0 0 0 .213-.278.768.768 0 0 0 0-.66.86.86 0 0 0-.213-.279L.788 1.966A.81.81 0 0 1 .5 1.361c0-.228.101-.446.284-.608.182-.161.43-.252.69-.253.258 0 .507.089.69.249l4.48 3.934c.548.482.856 1.136.856 1.817s-.308 1.335-.856 1.817Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgChevronRight;
