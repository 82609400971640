import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgHand = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 12 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 3.695v8.299A6.005 6.005 0 0 1 6 18c-3.312 0-6-2.69-6-6.006V7.276c0-.53.454-.948.984-.948.55 0 1.003.417 1.003.948v3.562c0 .152.114.265.265.265a.259.259 0 0 0 .265-.265V1.952c0-.531.455-.948 1.004-.948.548 0 .984.436.984.948v6.442c0 .151.113.265.265.265a.259.259 0 0 0 .265-.265V.947c0-.53.454-.947 1.003-.947.549 0 .984.436.984.947v7.447c0 .151.114.265.265.265a.259.259 0 0 0 .265-.265V1.952c0-.531.454-.948 1.003-.948.55 0 1.003.436 1.003.948v6.442c0 .151.114.265.246.265a.259.259 0 0 0 .265-.265v-4.7c0-.51.455-.909 1.004-.909.548 0 .927.417.927.91Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgHand;
