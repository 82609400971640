import * as amplitude from '@amplitude/analytics-browser';
import { injectable } from 'inversify';
import { Primitive } from 'zod';

import { Amplitude } from 'core/amplitude/amplitude';
import { ConfigService } from 'core/config/config.service';

import { Address, Addresses } from '../address';
import { ChainID, chains } from '../chain';

@injectable()
export class AmplitudeService {
  constructor(private readonly configService: ConfigService) {
    amplitude.init(this.configService.amplitudeApiKey, {
      defaultTracking: true,
    });
  }

  trackBridgeAssets = (data: Amplitude.BridgeAssetsParams): void => {
    this.track(Amplitude.Event.Bridge, data);
  };

  trackSignIn = (data: Amplitude.SignInParams): void => {
    // Used to track authorization
    this.track(Amplitude.Event.SignIn, data);
    this.setUserId(data.address);
  };

  trackExplorerFilter = (data: Amplitude.ExplorerFilterParams): void => {
    if (data.filter === Amplitude.ExplorerFilter.Chain && data.value !== Amplitude.SelectAll) {
      return this.track(Amplitude.Event.ExplorerFilter, { ...data, value: chains[data.value as ChainID].name });
    }
    // Used to track the filters on the explore page
    this.track(Amplitude.Event.ExplorerFilter, data);
  };

  trackShare = (): void => {
    // Used to track the share buttons
    this.track(Amplitude.Event.Share);
  };

  trackRangeSwitch = (): void => {
    // Used to track chart date change clicks (vault-view)
    this.track(Amplitude.Event.RangeSwitch);
  };

  trackChartSwitch = (data: Amplitude.ChartSwitchParams): void => {
    // Used to track chart type change clicks (vault-view)
    this.track(Amplitude.Event.ChartsSwitch, data);
  };

  trackShareCard = (data: Amplitude.ShareCardParams): void => {
    // Used to track share card clicks (vault-view/spot)
    this.track(Amplitude.Event.ShareCard, data);
  };

  trackVaultCreation = (data: Amplitude.VaultCreationParams): void => {
    // Used to track leaving create vault process
    this.track(Amplitude.Event.VaultCreation, data);
  };

  trackVault = (data: Amplitude.ClickVaultCardParams): void => {
    // Used to track featured vault clicks (vault-view)
    this.track(Amplitude.Event.VaultCard, data);
  };

  trackOpenTelegram = (): void => {
    // Used to track  telegram  open
    this.track(Amplitude.Event.OpenTelegram);
  };

  private track = <T extends Record<string, Primitive>>(event: Amplitude.Event, data?: T): void => {
    amplitude.track(event, data);
  };

  private setUserId = (address: Address): void => {
    const amplitudeUserId = amplitude.getUserId();
    // Set the user ID in case it's not set yet
    if (!amplitudeUserId) {
      return amplitude.setUserId(address);
    }

    // Set the user ID in case the user previously had an address and connected to a new one.
    if (!Addresses.areEqual(address, amplitudeUserId)) {
      return amplitude.setUserId(address);
    }
  };
}
