import { CSSProperties } from 'react';

import {
  CatIcon,
  CrosedClockNeonIcon,
  DiscordIcon,
  GitbookIcon,
  MirrorIcon,
  PulseNeonIcon,
  SecondLogoIcon,
  SentimentalCursorIcon,
  UserWithCursorNeonIcon,
  XIcon,
} from 'components/icons';
import { IconSize } from 'components/svg-icon';

import slideOne from 'assets/img/profile-tutorial-one.png';
import slideTwo from 'assets/img/profile-tutorial-two.png';
import shareCardSrc from 'assets/img/share-card-bg.png';
import valioAvatar from 'assets/img/valio-avatar.png';
import valioFavicon from 'assets/img/valio-favicon.png';
import brandLogoSrc from 'assets/logo.svg';

export const Theme = {
  name: 'default',
  appName: 'Valio',
  connectButtonText: 'Connect',
  connectButtonEmptyText: 'Connect wallet',
  logo: (
    <>
      <SecondLogoIcon className="self-center w-[67px] h-[22px]" size={IconSize.Largest} />
      <div className="flex items-center justify-center w-[24px] h-[10px] rounded-[30px] text-[6px] absolute -right-[10px] -bottom-[7px] bg-brand-600 text-white">
        Beta
      </div>
    </>
  ),
  brandLogoSrc: brandLogoSrc,
  shareCardSrc: shareCardSrc,
  walletLogoSrc: valioAvatar,
  sentimentalCursorIcon: (style: CSSProperties) => (
    <SentimentalCursorIcon
      className="h-[189px] w-[54px] absolute bottom-[32px] left-[0] -m-[27px] text-brand-600"
      style={style}
    />
  ),
  meta: {
    title:
      'Valio.xyz - Bet on your favourite traders and let them make money for you. Talent discovery marketplace, natively omnichain.',
    description:
      'Deposit safely in dynamic vaults managed by the best web3 traders and investors. Built on Layer0 and secured by CPIT®',
    icon: valioFavicon,
  },
  features: {
    szn0: true,
    szn1: true,
    manage: true,
    shareCard: true,
    allocationVideo: true,
    walletBalanceImg: true,
    dayPerformingVaults: true,
    socialLinks: true,
    footerLinks: true,
  },
  errorPage: {
    icon: <CatIcon className="w-[125px] h-[102px]" />,
    title: 'Cats misbehaving and breaking stuff',
    subtitle: 'Reload the page, and let us know if problems still persist',
    contactIcon: <DiscordIcon />,
    contactUrl: 'https://discord.gg/valio',
    contactText: 'Go to Discord',
  },
  tutorial: {
    slideOneSrc: slideOne,
    slideOneText:
      'This is your self-custodial Valio wallet on the Ethereum blockchain. This is your public wallet address - you will need it to top up your balance.',
    slideTwoSrc: slideTwo,
  },
  myPositionsNullState: {
    title: 'Back any trader in 3 easy steps',
    subtitle: 'Valio is non-custodial. Withdraw any time - no lockups',
    items: [
      {
        icon: <UserWithCursorNeonIcon className="text-[90px]" />,
        title: 'Select manager and fund',
      },
      {
        icon: <PulseNeonIcon className="text-[90px]" />,
        title: 'Deposit and monitor',
      },
      {
        icon: <CrosedClockNeonIcon className="text-[90px]" />,
        title: 'Withdraw any time. No lockups',
      },
    ],
  },
  editProfile: {
    subtitle: 'Your profile info will be seen throughout Valio app',
    placeholder: 'Tell the backers on Valio about yourself',
  },
  aumNaming: 'AUM',
  socials: [
    {
      url: 'https://twitter.com/valio_xyz',
      icon: <XIcon className="hover:text-primary text-white/30" />,
    },
    {
      url: 'https://discord.gg/valio',
      icon: <DiscordIcon className="hover:text-primary text-white/30" />,
    },
    {
      url: 'https://mirror.xyz/valioxyz.eth',
      icon: <MirrorIcon className="hover:text-primary text-white/30" />,
    },
    {
      url: 'http://docs.valio.xyz/',
      icon: <GitbookIcon size={IconSize.Large} className="hover:text-primary text-white/30" />,
    },
  ],
};

export type DefaultTheme = Partial<Omit<typeof Theme, 'name'>> & { name: string };
