import { VaultActionInfoZeroX } from 'core/vault/vault-action.info';

import { FormatMoney } from 'components/format-money';
import { getHeaderText } from 'components/modals/trade-memo/trade-memo';
import { Primary, Secondary } from 'components/typography';

type ShareCardSpotProps = {
  data: VaultActionInfoZeroX;
};

export const ShareCardSpot = (props: ShareCardSpotProps) => {
  const { data } = props;

  return (
    <>
      <div className="flex items-center gap-3.5">
        <div className='relative w-[98px] h-[98px] rounded-full flex items-center justify-center border border-secondary  after:content-[""] after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:absolute after:w-[88px] after:h-[88px] after:rounded-full after:bg-black after:z-0'>
          <data.outputToken.Icon className="w-[58px] h-[58px] z-1" />
        </div>
        <div className="flex flex-col">
          <Secondary>Position {getHeaderText(data.action)}</Secondary>
          <Primary className="font-stratos text-[71.617px] font-medium leading-[71.617px]">
            {data.outputToken.symbol}
          </Primary>
        </div>
      </div>
      <div className="flex">
        <div className="flex flex-col gap-1.5">
          <Secondary>Entry price</Secondary>
          <Primary className="font-stratos text-[29.948px] font-medium leading-[29.948px]">
            <FormatMoney value={data.outputToken.price} dollarSign unstyled />
          </Primary>
        </div>
      </div>
    </>
  );
};
