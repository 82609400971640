import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgHighCap = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 10 21" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.743.075c-.013 0-.027 0-.04.015l-.081.09-2.46 2.73a.626.626 0 0 0 0 .84c.216.24.554.24.77 0L4.46 2.055V8.4c0 .33.244.6.541.6.297 0 .54-.27.54-.6V2.055L7.068 3.75c.216.24.554.24.77 0a.645.645 0 0 0 0-.855L5.392.18a.37.37 0 0 0-.04-.038.37.37 0 0 1-.041-.037C5.304.105 5.3.1 5.297.097 5.294.094 5.291.09 5.284.09A.289.289 0 0 1 5.25.067C5.24.06 5.23.052 5.216.045c-.007 0-.013-.004-.02-.008a.045.045 0 0 0-.02-.007c-.006 0-.011-.003-.018-.006-.01-.004-.02-.009-.036-.009C5.095 0 5.068 0 5.04 0h-.055c-.013 0-.027.004-.04.007-.014.004-.027.008-.04.008-.007 0-.017.004-.028.007-.01.004-.02.008-.027.008-.015 0-.027.005-.036.01a.045.045 0 0 1-.018.005c-.013 0-.04.015-.054.03Zm.26 14.548h.492c.232 0 .459-.026.679-.05l.034-.005c.153-.017.304-.037.453-.06.149-.023.293-.049.432-.078a7.74 7.74 0 0 0 .98-.267c.12-.043.234-.087.344-.134.204-.087.401-.19.59-.307.122-.078.24-.166.348-.262.065-.058.127-.12.184-.186.052-.06.1-.126.141-.194.039-.064.071-.132.097-.202a.92.92 0 0 0 0-.629 1.145 1.145 0 0 0-.097-.202 1.444 1.444 0 0 0-.141-.194 1.935 1.935 0 0 0-.184-.186 2.694 2.694 0 0 0-.348-.262c-.19-.12-.39-.226-.598-.314a5.572 5.572 0 0 0-.343-.134 7.152 7.152 0 0 0-.98-.267 14.362 14.362 0 0 0-.433-.079 10.283 10.283 0 0 0-1.166-.115h-.492c-2.666 0-4.828.925-4.828 2.068 0 1.142 2.17 2.06 4.835 2.06Zm0 2.068c2.664 0 4.826-.925 4.826-2.067v-.674c-.34.295-.725.531-1.142.7a9.633 9.633 0 0 1-3.685.663 9.63 9.63 0 0 1-3.684-.663 4.065 4.065 0 0 1-1.143-.7v.674c0 1.142 2.162 2.067 4.827 2.067Zm4.826 0c0 1.143-2.162 2.068-4.827 2.068S.175 17.834.175 16.69v-.673c.34.294.726.53 1.143.7a9.627 9.627 0 0 0 3.684.662 9.628 9.628 0 0 0 3.685-.663 4.06 4.06 0 0 0 1.142-.7v.674ZM5.002 20.83c2.665 0 4.827-.925 4.827-2.067v-.674a4.06 4.06 0 0 1-1.142.7 9.628 9.628 0 0 1-3.685.663 9.627 9.627 0 0 1-3.684-.663 4.06 4.06 0 0 1-1.143-.7v.674c0 1.14 2.162 2.067 4.827 2.067Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgHighCap;
