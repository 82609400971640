import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgTiles = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.875 0H3A2.5 2.5 0 0 0 .5 2.5v1.875a2.5 2.5 0 0 0 2.5 2.5h1.875a2.5 2.5 0 0 0 2.5-2.5V2.5a2.5 2.5 0 0 0-2.5-2.5ZM13 0h-1.875a2.5 2.5 0 0 0-2.5 2.5v1.875a2.5 2.5 0 0 0 2.5 2.5H13a2.5 2.5 0 0 0 2.5-2.5V2.5A2.5 2.5 0 0 0 13 0ZM3 8.125h1.875a2.5 2.5 0 0 1 2.5 2.5V12.5a2.5 2.5 0 0 1-2.5 2.5H3a2.5 2.5 0 0 1-2.5-2.5v-1.875a2.5 2.5 0 0 1 2.5-2.5Zm10 0h-1.875a2.5 2.5 0 0 0-2.5 2.5V12.5a2.5 2.5 0 0 0 2.5 2.5H13a2.5 2.5 0 0 0 2.5-2.5v-1.875a2.5 2.5 0 0 0-2.5-2.5Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgTiles;
