import BoxOne from 'assets/img/season-one/50-points.png';
import BoxTwo from 'assets/img/season-one/250-points.png';
import BoxThree from 'assets/img/season-one/500-points.png';
import BoxFour from 'assets/img/season-one/2000-points.png';

export namespace Reveal {
  export const STEP = 19;
  export const IMG_WIDTH = 200;
  export const ANIMATION_DURATION = 4;

  export enum RewardType {
    FIFTY = 50,
    TWOHUNDREDFIFTY = 250,
    FIVEHUNDRED = 500,
    TWOTHUSAND = 2000,
  }

  export type Box = {
    src: string;
    type: RewardType;
  };

  export const initialBoxes: Box[] = [
    { src: BoxOne, type: RewardType.FIFTY },
    { src: BoxFour, type: RewardType.FIFTY },
    { src: BoxThree, type: RewardType.TWOHUNDREDFIFTY },
    { src: BoxTwo, type: RewardType.TWOTHUSAND },
    { src: BoxTwo, type: RewardType.FIFTY },
    { src: BoxOne, type: RewardType.TWOHUNDREDFIFTY },
    { src: BoxThree, type: RewardType.TWOTHUSAND },
    { src: BoxFour, type: RewardType.TWOTHUSAND },
    { src: BoxFour, type: RewardType.FIVEHUNDRED },
  ];

  const shuffleArray = <T>(array: T[]): T[] => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }

    return shuffled;
  };

  export const getBoxByType = (type: RewardType): Box => {
    switch (type) {
      case RewardType.FIFTY:
        return { src: BoxOne, type };
      case RewardType.TWOHUNDREDFIFTY:
        return { src: BoxTwo, type };
      case RewardType.FIVEHUNDRED:
        return { src: BoxThree, type };
      case RewardType.TWOTHUSAND:
        return { src: BoxFour, type };
      default:
        return { src: BoxOne, type };
    }
  };

  export const generateNewBoxes = (length: number): Box[] => {
    const shuffledBoxes = shuffleArray<Box>(initialBoxes);

    const newBoxes = Array.from({ length }, (_, i) => {
      const randomItem = shuffledBoxes[i % shuffledBoxes.length];

      return { src: randomItem.src, type: randomItem.type };
    });

    return newBoxes;
  };
}
