import { useForm } from '@mobx-form-state/react';
import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styles/themes/use-theme.hook';

import { Address } from 'core/address';
import { LocalStorageService } from 'core/local-storage/local-storage.service';
import { NetworkStore } from 'core/network/network.store';
import { Privy } from 'core/network/privy';

import { ModalsStore } from 'lib/modals/modals.store';

import { Button, ButtonVariant } from 'components/button';
import { Checkbox } from 'components/checkbox';
import { Divider } from 'components/divider';
import { H1, H3, Primary } from 'components/typography';

import { ProfileTutorialModal } from '../profile-tutorial';
import { TermsConditionsModal } from './terms-conditions';
import { TermsConditionsModel } from './terms-conditions.model';

type TermsConditionsFormProps = {
  address: Address;
};

export const TermsConditionsForm = observer((props: TermsConditionsFormProps) => {
  const { address } = props;

  const { getTermsConditions, setTermsConditions } = useService(LocalStorageService);
  const { connection } = useService(NetworkStore);
  const { open, close } = useService(ModalsStore);
  const { appName, features } = useTheme();

  const navigate = useNavigate();

  const navigateToProfile = () => {
    if (connection?.method !== Privy.ConnectionMethod.Wallet) {
      navigate('/profile');
      open(ProfileTutorialModal);
    }
  };

  const form = useForm(TermsConditionsModel, {
    initialValues: {
      termsAndConditions: getTermsConditions(address),
    },
    onSubmit: ({ termsAndConditions }) => {
      setTermsConditions(address, termsAndConditions);
      close(TermsConditionsModal.displayName);
    },
  });

  return (
    <>
      <form
        onSubmit={form.handleSubmit}
        className="flex flex-col absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-[480px] rounded-[20px] z-[99] [box-shadow:0px_4px_9px_0px_rgba(0,_0,_0,_0.54)] border border-outline bg-background"
      >
        <div className="flex items-center justify-center flex-col w-full pt-[30px] pr-[37px] pb-2.5 pl-[37px] box-border">
          <H1>{features?.szn1 && 'SZN1'} Terms and conditions</H1>
          <div className="flex flex-col items-center">
            <H3>
              Read our{' '}
              <a
                className="underline"
                href="https://www.valio.xyz/Terms_of_Services.pdf"
                target="_blank"
                rel="noreferrer"
              >
                terms and conditions
              </a>
              .
            </H3>
            <H3>
              Further to our terms and conditions, &quot;By accessing or using our software platform, you represent and
              warrant that you are not a &apos;US Person&apos; as defined in Regulation S under the United States
              Securities Act of 1933, as amended (&apos;Securities Act&apos;). If you are a &apos;US Person&apos; as
              defined in Regulation S, you are not authorized to access or use our software platform, and any such
              access or use is strictly prohibited.&quot;
            </H3>
            <H3>
              Participation in the Beta version and unaudited smart contracts is voluntary. Users hereby acknowledge
              potential risks and indemnify {appName} from any related claims or liabilities.
            </H3>
          </div>
        </div>
        <Divider />
        <div className="flex flex-col items-center gap-2.5 pb-5 w-full pt-[30px] pr-[37px] pl-[37px] box-border">
          <div className="flex items-center justify-between flex-1 w-full">
            <Primary>
              I have read and accept our
              <a
                className="ml-[3px] text-primary underline"
                href="https://www.valio.xyz/Terms_of_Services.pdf"
                target="_blank"
                rel="noreferrer"
              >
                terms and conditions
              </a>
            </Primary>
            <div className="flex" data-testid="terms-conditions-checkbox">
              <Checkbox
                checked={form.values.termsAndConditions}
                onChange={(e) => form.fields.termsAndConditions.onChange(e.target.checked)}
              />
            </div>
          </div>
          <Button
            type="submit"
            fullWidth
            variant={ButtonVariant.Contained}
            onClick={navigateToProfile}
            disabled={!form.values.termsAndConditions || form.submitting}
            data-testid="terms-conditions-proceed-button"
          >
            Proceed
          </Button>
        </div>
      </form>
    </>
  );
});
