import { PrivyClientConfig, PrivyProvider } from '@privy-io/react-auth';
import { useService } from '@redtea/react-inversify';
import { PropsWithChildren, memo } from 'react';
import { useTheme } from 'styles/themes/use-theme.hook';
import { arbitrum } from 'viem/chains';

import { ConfigService } from 'core/config/config.service';

import { InjectPrivyWallet } from './inject-privy-wallet';

const defaultConfig: PrivyClientConfig = {
  defaultChain: arbitrum,
  appearance: {
    showWalletLoginFirst: false,
  },
  loginMethods: ['email', 'google', 'twitter', 'discord', 'wallet'],
  embeddedWallets: {
    createOnLogin: 'users-without-wallets',
    requireUserPasswordOnCreate: false,
  },
  mfa: {
    noPromptOnMfaRequired: false,
  },
};

type PrivyConnectorProps = PropsWithChildren<unknown>;

export const PrivyConnector = memo((props: PrivyConnectorProps) => {
  const { children } = props;
  const { privyAppId } = useService(ConfigService);
  const { brandLogoSrc } = useTheme();

  const config: PrivyClientConfig = {
    ...defaultConfig,
    appearance: {
      ...defaultConfig.appearance,
      logo: brandLogoSrc,
      theme: 'dark',
    },
  };

  return (
    <PrivyProvider appId={privyAppId} config={config}>
      {children}
      <InjectPrivyWallet />
    </PrivyProvider>
  );
});
