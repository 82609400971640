import { ReactNode } from 'react';

import { Button, ButtonVariant } from 'components/button';
import { ChevronDownIcon, ChevronUpIcon } from 'components/icons';

import { TableSortDirection } from '../table';

type TableLabelProps = {
  label: ReactNode;
  order?: TableSortDirection | null;
};

export const TableLabel = (props: TableLabelProps) => {
  const { label, order = TableSortDirection.ASC } = props;

  const endIcon = order === TableSortDirection.ASC ? <ChevronDownIcon /> : <ChevronUpIcon />;

  return (
    <Button variant={ButtonVariant.Text} endIcon={endIcon}>
      {label}
    </Button>
  );
};
