import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgSzn0 = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 21 36" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.225 0c-1.35 0-2.678.284-3.94.787a10.283 10.283 0 0 0-3.318 2.23A10.2 10.2 0 0 0 .754 6.34 9.937 9.937 0 0 0 0 10.253v3.388H8.3V8.722a1.763 1.763 0 0 1 .509-1.246 1.823 1.823 0 0 1 1.261-.503h.31c.221 0 .464.044.686.132.221.087.42.218.575.371a1.763 1.763 0 0 1 .509 1.246v13.16a8.45 8.45 0 0 0 3.187-.634 8.296 8.296 0 0 0 2.7-1.792 8.01 8.01 0 0 0 1.792-2.667 8.174 8.174 0 0 0 .642-3.148v-3.388c0-2.711-1.063-5.334-2.988-7.258a10.282 10.282 0 0 0-3.32-2.23A10.61 10.61 0 0 0 10.226 0Z"
      fill="currentColor"
    />
    <path
      d="M10.035 28.977h.308c.242 0 .462-.044.66-.133a1.72 1.72 0 0 0 .572-.377 1.816 1.816 0 0 0 .506-1.263V22.22h8.252v3.39c0 1.35-.242 2.702-.748 3.965a10.356 10.356 0 0 1-2.2 3.368c-.925.974-2.069 1.728-3.301 2.26a9.91 9.91 0 0 1-3.917.797 9.91 9.91 0 0 1-3.917-.797 10.217 10.217 0 0 1-3.3-2.26 10.127 10.127 0 0 1-2.2-3.368A10.24 10.24 0 0 1 0 25.61v-3.39c0-1.085.22-2.17.638-3.19a8.127 8.127 0 0 1 1.783-2.703 8.24 8.24 0 0 1 5.853-2.459v13.337a1.816 1.816 0 0 0 .506 1.263c.176.155.352.288.572.377.22.089.462.133.682.133Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgSzn0;
