import { VaultChainFragment } from 'core/api/gql/chain/vault-chain.fragment.generated';

import { isSomething } from 'utils/is-something';
import { stringToEnum } from 'utils/string-to-enum';

import { chains } from './chain';
import { ChainID } from './chain.info';
import { VaultChainInfo } from './vault-chain';

export const mapVaultChainsFromApi = (vaultChains: VaultChainFragment[]): VaultChainInfo[] =>
  vaultChains
    .map((item) => {
      const chainId = stringToEnum(item.chainId, ChainID);

      if (!chainId) return undefined;

      const { isParent, address, aum } = item;

      return { ...chains[chainId], isParent, address, aum };
    })
    .filter(isSomething);
