import BigNumber from 'bignumber.js';
import { BigNumberish } from 'ethers';
import { ComponentPropsWithoutRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { Asset } from 'core/asset';
import { MoneyFormatter } from 'core/asset/money-formatter';

import { Secondary } from 'components/typography';

import { isSomething } from 'utils/is-something';

import { Amount, Units } from 'types';

export type FormatMoneyProps = ComponentPropsWithoutRef<'div'> & {
  value?: number | BigNumber | Units | Amount | BigNumberish | null;
  dollarSign?: boolean;
  prefix?: string;
  showSuffix?: boolean;
  prependSign?: boolean;
  colored?: boolean;
  asset?: Asset | null;
  unstyled?: boolean;
  precise?: boolean;
  primary?: boolean;
  decimals?: number;
  decimalPlaces?: number;
};

export const FormatMoney = (props: FormatMoneyProps) => {
  const {
    value,
    dollarSign,
    showSuffix,
    prependSign,
    asset,
    unstyled,
    prefix,
    precise,
    decimals,
    decimalPlaces,
    colored,
    primary,
    className,
    ...rest
  } = props;

  const formattedValue = MoneyFormatter.format(value, { asset, precise, decimals, decimalPlaces });
  const absoluteValue = MoneyFormatter.format(formattedValue.value?.absoluteValue(), {
    asset,
    precise: false,
    decimals,
    decimalPlaces,
  });

  const isEmpty = !isSomething(formattedValue.value);

  const isPositive = formattedValue.value?.isPositive() && !formattedValue.value?.isZero();

  const isNegative = formattedValue.value?.isNegative();

  const formattedString = [
    prefix && prefix,
    isSomething(formattedValue.value) && isNegative && '-',
    prependSign && isSomething(formattedValue.value) && isPositive && '+',
    !isEmpty && dollarSign && '$',
    absoluteValue.toString(),
    !isEmpty && showSuffix && asset && ' ' + asset.symbol,
  ]
    .filter(Boolean)
    .join('');

  if (unstyled) return <>{formattedString}</>;

  return (
    <Secondary
      className={twMerge(
        'inline-block',
        primary && 'text-primary',
        colored && isPositive && 'text-positive',
        colored && isNegative && 'text-negative',
        className
      )}
      {...rest}
    >
      {formattedString}
    </Secondary>
  );
};
