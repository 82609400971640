import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgNft = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.589 4.58 5.137.334a.667.667 0 0 0-1.154 0L0 7.231h3.383a5.339 5.339 0 0 1 4.206-2.65ZM8 13.899a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm.667-9.292V1.231H16v7.334h-2.835a5.348 5.348 0 0 0-4.498-3.959Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgNft;
