import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgLido = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 80 101" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#lido_svg__a)">
      <path
        d="m40.023 17.858.842-.539L40.023 16l-.843 1.32.843.538Zm16.263 25.456.503.864.9-.524-.56-.878-.843.538Zm-32.526 0-.843-.538-.56.877.9.525.503-.864Zm16.262 9.479-.504.864.504.293.503-.293-.503-.864ZM28.738 42.11l-.842-.538-.561.878.9.524.503-.864Zm11.285-17.663.842-.539-.842-1.319-.843 1.32.843.538Zm-.001 24.24-.504.864.504.294.504-.294-.504-.864Zm11.285-6.577.504.864.9-.524-.561-.878-.843.538ZM39.997 58.39l-.503.864.504.293.503-.293-.503-.864ZM21.133 47.393l.504-.864-.83-.483-.517.809.843.538Zm37.736 0 .842-.539-.516-.809-.83.484.504.864Zm.515.806-.843.539.843-.538Zm-3.12 28.639-.707-.707.707.707Zm-32.526 0 .707-.707-.707.707ZM20.617 48.2l-.843-.539.843.538ZM39.18 18.396l16.263 25.457 1.685-1.077L40.865 17.32l-1.685 1.077ZM24.603 43.852l16.262-25.456-1.685-1.077-16.263 25.457 1.686 1.076Zm15.922 8.077L24.264 42.45l-1.008 1.728 16.262 9.479 1.007-1.728Zm15.257-9.479L39.518 51.93l1.007 1.728 16.264-9.479-1.007-1.728Zm-26.2.199 11.283-17.664-1.685-1.077-11.284 17.664 1.685 1.077Zm10.944 5.174-11.284-6.577-1.007 1.728 11.283 6.577 1.008-1.728Zm10.278-6.577-11.286 6.577 1.008 1.728 11.285-6.577-1.007-1.728ZM39.18 24.986l11.285 17.663 1.685-1.077-11.285-17.664-1.685 1.077Zm1.321 32.539L21.636 46.529l-1.007 1.728 18.865 10.996 1.007-1.728Zm17.863-10.996-18.87 10.996 1.007 1.728 18.87-10.996-1.007-1.728Zm1.862 1.132-.516-.807-1.685 1.077.515.807 1.686-1.077ZM56.97 77.545c7.965-7.963 9.319-20.393 3.256-29.884l-1.686 1.077a21.992 21.992 0 0 1-2.984 27.393l1.414 1.414Zm-33.94 0c9.372 9.37 24.568 9.37 33.94 0l-1.414-1.414c-8.591 8.59-22.52 8.59-31.112 0l-1.414 1.414Zm-3.256-29.884c-6.063 9.491-4.709 21.92 3.256 29.884l1.414-1.414a21.992 21.992 0 0 1-2.984-27.393l-1.686-1.077Zm.515-.806-.515.806 1.686 1.077.515-.806-1.686-1.077Z"
        fill="#F7FAFF"
      />
    </g>
    <defs>
      <filter
        id="lido_svg__a"
        x={0.233}
        y={0.234}
        width={79.533}
        height={100.106}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={7.883} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3319_20552" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={4.3} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_3319_20552" result="effect2_dropShadow_3319_20552" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.433} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_3319_20552" result="effect3_dropShadow_3319_20552" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_3319_20552" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgLido;
