import { ChangeEvent, ComponentPropsWithoutRef, memo } from 'react';
import { twMerge } from 'tailwind-merge';

import { CheckIcon } from 'components/icons';
import { IconSize } from 'components/svg-icon';

export type CheckboxProps = Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> & {
  className?: string;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  disabled?: boolean;
};

export const Checkbox = memo<CheckboxProps>((props) => {
  const { className, checked, onChange, readOnly, disabled, ...rest } = props;

  return (
    <div
      className={twMerge(
        'checkbox-root relative w-7 h-7 bg-transparent border border-[#979797] cursor-pointer transition-colors duration-200 rounded-lg text-transparent',
        '[&_svg]:hover:opacity-100',
        checked && 'bg-brand-600 text-black border-transparent',
        !checked && 'text-icon-secondary opacity-100',
        className
      )}
      {...rest}
    >
      <input
        className="absolute opacity-0 w-full h-full top-0 left-0 m-0 p-0 z-[1] cursor-pointer"
        type="checkbox"
        onChange={onChange}
        checked={checked}
        readOnly={readOnly}
        disabled={disabled}
      />
      {!disabled && (
        <CheckIcon
          className={twMerge(
            'absolute top-0 left-0 right-0 bottom-0 m-auto opacity-0 text-primary',
            checked && 'opacity-100'
          )}
          size={IconSize.Smaller}
        />
      )}
    </div>
  );
});
