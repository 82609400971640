import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgYearn = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 88 102" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#yearn_svg__a)">
      <path
        clipRule="evenodd"
        d="m23.975 21.965-3.787 3.908 9.235 9.528 9.234 9.527v17.744h10.727V44.93l9.234-9.529 9.235-9.528-3.816-3.936L60.221 18l-8.045 8.3c-4.425 4.566-8.095 8.302-8.156 8.302-.061 0-3.719-3.723-8.128-8.273-4.41-4.55-8.042-8.273-8.073-8.273-.031 0-1.76 1.759-3.844 3.909Zm-3.966 22.24c-1.343 2.4-2.582 6.674-2.923 10.088-.168 1.684-.079 5.359.17 7.01 2.33 15.45 16.474 25.95 31.357 23.282 9.813-1.758 17.88-8.9 21.017-18.603 1.032-3.193 1.367-5.353 1.37-8.844.003-3.778-.414-6.29-1.62-9.756-.414-1.189-1.479-3.537-1.603-3.537-.05 0-1.902 1.868-4.114 4.152-3.72 3.839-4.019 4.176-3.967 4.478.03.18.137.703.236 1.163.287 1.337.4 3.145.289 4.652-.117 1.584-.302 2.56-.769 4.064-1.499 4.829-5.47 8.965-10.21 10.636-1.811.639-2.827.796-5.166.802-2.378.005-3.275-.126-5.117-.744-5.014-1.682-9.099-6.023-10.57-11.231-.478-1.692-.615-2.772-.61-4.793.006-1.878.065-2.36.553-4.564.039-.178-.85-1.155-4.03-4.432l-4.08-4.204-.213.381Z"
        stroke="#F7FAFF"
        strokeWidth={2}
      />
    </g>
    <defs>
      <filter
        id="yearn_svg__a"
        x={0.233}
        y={0.797}
        width={87.533}
        height={100.971}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={7.883} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3319_19052" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={4.3} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_3319_19052" result="effect2_dropShadow_3319_19052" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.433} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_3319_19052" result="effect3_dropShadow_3319_19052" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_3319_19052" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgYearn;
