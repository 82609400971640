import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgLogo = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.092 5.417a.727.727 0 0 0 .654 0L22.525.025a.218.218 0 0 1 .28.084.2.2 0 0 1 .027.1v4.335c0 .127-.036.251-.104.36a.702.702 0 0 1-.281.253l-10.712 5.376a.218.218 0 0 1-.211-.005.208.208 0 0 1-.102-.18V8.56a.672.672 0 0 0-.103-.362.702.702 0 0 0-.283-.256L.386 2.615a.712.712 0 0 1-.282-.257.683.683 0 0 1-.104-.36V.207A.201.201 0 0 1 .102.03a.217.217 0 0 1 .21-.005l10.78 5.419v-.027Zm0 6.725L.312 6.723a.218.218 0 0 0-.285.082.201.201 0 0 0-.027.102v1.794c0 .128.035.254.103.364.068.11.166.2.283.26l10.617 5.327c.124.055.23.145.303.257a.673.673 0 0 1 .11.377v1.788a.2.2 0 0 0 .027.103.21.21 0 0 0 .18.106.218.218 0 0 0 .106-.025l10.718-5.365a.701.701 0 0 0 .285-.253.673.673 0 0 0 .106-.36V6.946a.201.201 0 0 0-.102-.179.218.218 0 0 0-.211-.005l-10.79 5.354a.727.727 0 0 1-.654 0l.011.027Zm-.011 6.677L.313 13.421a.223.223 0 0 0-.284.084.207.207 0 0 0-.029.1V15.4c0 .127.035.252.103.36a.696.696 0 0 0 .283.253l10.617 5.333a.697.697 0 0 1 .282.253.668.668 0 0 1 .103.359v1.794a.206.206 0 0 0 .016.124.222.222 0 0 0 .331.082l10.717-5.365a.708.708 0 0 0 .286-.256.678.678 0 0 0 .105-.362v-4.336a.207.207 0 0 0-.104-.175.224.224 0 0 0-.209-.01L11.736 18.82a.728.728 0 0 1-.654 0Z"
      fill="#9340F4"
    />
  </svg>
));
export default SvgLogo;
