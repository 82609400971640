import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgCopy = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 13.333H3.333A3.337 3.337 0 0 1 0 10V3.333A3.337 3.337 0 0 1 3.333 0H10a3.338 3.338 0 0 1 3.333 3.333V10A3.338 3.338 0 0 1 10 13.333Zm-6.667-12a2 2 0 0 0-2 2V10a2 2 0 0 0 2 2H10a2 2 0 0 0 2-2V3.333a2 2 0 0 0-2-2H3.333ZM16 12.667V4a.666.666 0 1 0-1.333 0v8.667a2 2 0 0 1-2 2H4A.667.667 0 1 0 4 16h8.667A3.337 3.337 0 0 0 16 12.667Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgCopy;
