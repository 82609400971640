import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgLedger = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M27.783 0H12.556v20.369h20.436V5.299C33 2.44 30.654 0 27.782 0ZM7.88 0H5.325C2.455 0 0 2.33 0 5.308v2.546h7.88V0ZM0 12.623h7.88v7.854H0v-7.854Zm25.12 20.369h2.555c2.87 0 5.325-2.33 5.325-5.308v-2.538h-7.88v7.846Zm-12.564-7.846h7.88V33h-7.88v-7.854ZM0 25.146v2.546C0 30.553 2.338 33 5.325 33H7.88v-7.854H0Z"
      fill="#333745"
    />
  </svg>
));
export default SvgLedger;
