import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgBell = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 15 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.094 2.15c-2.426.653-4.22 2.958-4.22 5.704v3.927c0 .362-.28.655-.623.655-.691 0-1.251.588-1.251 1.31 0 .722.56 1.308 1.253 1.308h12.494c.692 0 1.253-.587 1.253-1.309 0-.723-.562-1.309-1.25-1.309-.345 0-.625-.29-.625-.655V7.854c0-2.745-1.792-5.051-4.219-5.705v-.676C8.906.657 8.276 0 7.5 0c-.775 0-1.406.66-1.406 1.473v.676Zm-.781 13.56h4.375c0 1.264-.98 2.29-2.188 2.29-1.208 0-2.187-1.026-2.187-2.29Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgBell;
