import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgTelegram = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 15 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m5.886 8.568-.248 3.63c.355 0 .509-.16.693-.35l1.664-1.654 3.45 2.627c.632.366 1.077.173 1.248-.605l2.264-11.032C15.158.21 14.62-.17 14.003.068L.696 5.367c-.908.367-.894.893-.154 1.132l3.402 1.1 7.902-5.142c.372-.256.71-.114.432.142L5.886 8.568Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgTelegram;
