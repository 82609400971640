import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconButton, IconButtonSize } from 'components/icon-button';
import { CloseIcon } from 'components/icons';
import { Popover } from 'components/popover';

import { WidgetsStore } from './widgets.store';

export const Widgets = observer(() => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const { activeWidget, isOpen, closeWidget } = useService(WidgetsStore);

  return (
    <>
      {isOpen && (
        <IconButton
          className={twMerge(
            'fixed bottom-[50px] right-[24px] z-[11] cursor-pointer',
            !activeWidget?.backdrop && 'opacity-0 -bottom-[10px] right-[10px]'
          )}
          size={IconButtonSize.Large}
          ref={(ref) => ref && setAnchorEl(ref)}
          active
          pulseAnimation
          icon={<CloseIcon className="text-white" />}
          onClick={() => closeWidget()}
        />
      )}

      <Popover anchorEl={anchorEl} isOpen={isOpen} placement="top-end" indent={13}>
        {() => activeWidget?.node}
      </Popover>
    </>
  );
});
