import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgBulb = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 19" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.5 0c-.2 0-.425.223-.425.442v1.325c-.003.234.2.449.425.449.224 0 .428-.215.425-.449V.443A.453.453 0 0 0 8.5 0Zm5.737 2.637a.424.424 0 0 0-.299.138l-.85.884a.473.473 0 0 0-.009.631.43.43 0 0 0 .607-.003l.85-.883a.466.466 0 0 0 .106-.49.436.436 0 0 0-.405-.277Zm-11.521.007a.439.439 0 0 0-.36.296.465.465 0 0 0 .107.464l.85.884a.43.43 0 0 0 .607.002.473.473 0 0 0-.009-.63l-.85-.884a.422.422 0 0 0-.345-.132ZM8.5 3.977c-2.375 0-4.675 2.013-4.675 4.86 0 3.474 2.254 3.987 2.523 6.186h4.304c.269-2.199 2.523-2.712 2.523-6.186 0-2.847-2.3-4.86-4.675-4.86Zm6.163 5.081c0 .209.201.442.425.442h1.487c.225.003.425-.208.425-.442 0-.233-.2-.445-.425-.442h-1.487c-.245 0-.425.234-.425.442ZM0 9.058c0 .209.202.442.425.442H1.7c.225.003.431-.208.431-.442 0-.233-.206-.445-.43-.442H.424c-.244 0-.425.234-.425.442Zm3.878 4.295a.423.423 0 0 0-.292.138L2.49 14.623a.473.473 0 0 0-.01.63.43.43 0 0 0 .608-.002l1.096-1.14a.466.466 0 0 0 .096-.487.436.436 0 0 0-.402-.272Zm9.19 0a.44.44 0 0 0-.36.295.465.465 0 0 0 .108.464l1.096 1.14a.43.43 0 0 0 .607.002.473.473 0 0 0-.01-.631l-1.095-1.132a.423.423 0 0 0-.345-.139Zm-6.693 2.554v2.21c0 .507.402.883.85.883h2.55c.448 0 .85-.376.85-.884v-2.209h-4.25Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgBulb;
