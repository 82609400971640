import { Suspense, memo } from 'react';
import { Outlet } from 'react-router-dom';

import { Loading } from 'components/loading';

import { groundSrc } from 'assets/img/groundSrc';

import { RevealHeader } from './reveal-header';

export const RevealLayout = memo(() => (
  <div className="flex justify-between flex-col relative w-screen h-full bg-black">
    <div className="fixed z-[1] w-[800px] h-[800px] top-[-600px] opacity-40 blur-[385px] left-[-600px] bg-brand-600-100" />
    <div className="fixed z-[1] w-[800px] h-[800px] bottom-[-850px] -translate-x-2/4 opacity-40 blur-[385px] left-2/4 bg-brand-600-100" />
    <RevealHeader />
    <Suspense fallback={<Loading />}>
      <Outlet />
    </Suspense>
    <img src={groundSrc} className="absolute bottom-0 z-0 w-full" />
    <div />
  </div>
));
