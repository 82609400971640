import { ReactNode, memo } from 'react';
import { twMerge } from 'tailwind-merge';

import { TabValue } from '.';

export type TabProps<T extends TabValue> = {
  label?: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  active?: boolean;
  disabled?: boolean;
  value?: T;
  onClick?: (value?: T) => void;
  className?: string;
  minWidth?: number;
  readonly?: boolean;
};

const CustomTab = <T extends TabValue>(props: TabProps<T>) => {
  const { label, active = false, disabled, onClick, value, startIcon, endIcon, className, readonly, ...rest } = props;

  const handleClick = () => {
    if (disabled) return;

    onClick && onClick(value);
  };

  return (
    <div
      className={twMerge(
        'h-[40px] relative flex-1 cursor-pointer select-none',
        disabled && 'cursor-not-allowed',
        readonly && 'pointer-events-none cursor-default',
        className
      )}
      onClick={handleClick}
      {...rest}
    >
      <div
        data-testid={`tab-${label}`}
        className={twMerge(
          'tab-label [&_svg]:text-current [&_svg]:duration-0',
          active && 'tab-label-active',
          disabled && 'tab-label-disabled'
        )}
      >
        {startIcon}
        {label}
        {endIcon}
      </div>
      {active && <div className="tab-underline" />}
    </div>
  );
};

export const Tab = memo(CustomTab) as typeof CustomTab;
