import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgQuestion = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14Zm-.103-5.535.032-.872c.501-.007.942-.09 1.323-.25.382-.16.683-.403.904-.73.222-.326.332-.74.332-1.24 0-.487-.107-.9-.32-1.237a2.113 2.113 0 0 0-.883-.778c-.374-.182-.804-.273-1.29-.273-.523 0-.971.098-1.345.294a2.063 2.063 0 0 0-.855.806 2.22 2.22 0 0 0-.283 1.165H5.83c0-.34.101-.611.305-.81.203-.2.486-.3.849-.3.348 0 .628.1.838.3.21.199.316.473.316.821 0 .363-.09.639-.272.828a1.384 1.384 0 0 1-.73.38 4.842 4.842 0 0 1-1.012.099h-.501l.043 1.797h1.23Zm-.612 2.45a.84.84 0 0 0 .62-.24c.16-.16.24-.352.24-.577a.8.8 0 0 0-.24-.588.84.84 0 0 0-.62-.24.858.858 0 0 0-.626.24.79.79 0 0 0-.245.588c0 .225.082.417.245.577.163.16.372.24.626.24Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgQuestion;
