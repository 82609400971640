import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgMirror = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 12 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 6a6 6 0 1 1 12 0v10H0V6Z" fill="currentColor" />
  </svg>
));
export default SvgMirror;
