import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgBtc = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.342 16.484A8.366 8.366 0 0 0 10.389.25 8.364 8.364 0 0 0 .251 6.343a8.365 8.365 0 0 0 6.09 10.14Zm3.873-11.42c1.16.399 2.007.997 1.84 2.11v.001c-.12.815-.571 1.21-1.171 1.348.823.429 1.242 1.087.843 2.227-.495 1.416-1.672 1.535-3.238 1.239l-.38 1.523-.918-.23.375-1.501a34.2 34.2 0 0 1-.732-.19l-.376 1.51-.917-.23.38-1.525a53.396 53.396 0 0 1-.267-.07l-.388-.1-1.195-.297.456-1.051s.677.18.667.167c.26.064.376-.106.421-.219l.6-2.407.07.018.028.006a.747.747 0 0 0-.096-.03l.428-1.719c.012-.195-.056-.44-.427-.534.014-.01-.667-.165-.667-.165l.244-.981 1.266.316v.005c.19.047.386.092.585.138l.377-1.508.917.229-.369 1.478c.247.056.495.113.736.173l.366-1.468.918.228-.376 1.509Zm-2.904 5.468c.75.198 2.387.63 2.647-.415.267-1.07-1.321-1.426-2.096-1.6l-.226-.052-.504 2.02.179.047Zm.706-2.953c.625.167 1.988.53 2.225-.42.242-.973-1.082-1.266-1.729-1.41-.072-.015-.137-.03-.189-.043L7.867 7.54l.15.04Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgBtc;
