import { Container } from 'inversify';

import { apiModule } from 'core/api/api.module';
import { bootstrapModule, coreModule } from 'core/core.module';

import { libModule } from 'lib/lib.module';

import { initIOC } from 'utils/init-ioc';

const appContainer = new Container({ defaultScope: 'Singleton', skipBaseClassChecks: true });

initIOC(appContainer, [libModule, bootstrapModule, apiModule, coreModule]);

export { appContainer };
