/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';

export type ReferralFragment = {
  __typename?: 'ReferralType';
  points: number;
  referral: { __typename?: 'AccountType'; address: string; createdAt: string; chain: string };
};

export const ReferralFragmentDoc = gql`
  fragment Referral on ReferralType {
    points
    referral {
      address
      createdAt
      chain
    }
  }
`;
