import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgCalendar = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4 .72a.8.8 0 0 1 .8.8v.8h6.4v-.8a.8.8 0 0 1 1.6 0v.8h.8a2.4 2.4 0 0 1 2.4 2.4v.8H0v-.8a2.4 2.4 0 0 1 2.4-2.4h.8v-.8a.8.8 0 0 1 .8-.8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 14.32v-7.2h16v7.2a2.4 2.4 0 0 1-2.4 2.4H2.4a2.4 2.4 0 0 1-2.4-2.4Zm5.6-3.2a.8.8 0 1 1-1.6 0 .8.8 0 0 1 1.6 0Zm2.4.8a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Zm4-.8a.8.8 0 1 1-1.6 0 .8.8 0 0 1 1.6 0Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgCalendar;
