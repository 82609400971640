import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgInfinity = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 22 10" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.395 6.607 8.479 8.535a4.944 4.944 0 0 1-7.024 0 5.023 5.023 0 0 1 0-7.07 4.944 4.944 0 0 1 7.024 0l.958.964L8.16 3.714l-.958-.964a3.149 3.149 0 0 0-4.47 0 3.199 3.199 0 0 0 0 4.5 3.149 3.149 0 0 0 4.47 0L9.118 5.32l3.831-3.856a4.944 4.944 0 0 1 7.025 0 5.023 5.023 0 0 1 0 7.07 4.944 4.944 0 0 1-7.025 0l-.958-.964 1.278-1.285.957.964a3.148 3.148 0 0 0 4.47 0 3.199 3.199 0 0 0 0-4.5 3.148 3.148 0 0 0-4.47 0L12.47 4.518l-2.075 2.089Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgInfinity;
