import { User } from '@privy-io/react-auth';
import * as Sentry from '@sentry/react';
import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import { Observable, Subject, distinctUntilChanged, filter, map, merge, share, shareReplay, timeout } from 'rxjs';

import type { Address } from 'core/address';
import { AmplitudeService } from 'core/amplitude/amplitude.service';
import { Logger } from 'core/logger';
import { NetworkService } from 'core/network/network.service';

import { didChange } from 'utils/did-change';
import { Disposable } from 'utils/disposable';
import { isSomething } from 'utils/is-something';

const logger = new Logger('WalletStore');

@injectable()
export class WalletStore extends Disposable {
  @observable
  address?: Address;

  @observable.ref
  user: User | null = null;

  readonly address$: Observable<Address | undefined>;

  readonly disconnect$ = this.networkService.disconnect$;

  readonly connect$: Observable<void> = this.networkService.connector.privyState$.pipe(
    map((state) => (state.networkState ? state.networkState : undefined)),
    distinctUntilChanged((prev, next) => typeof prev === typeof next),
    filter(isSomething),
    map(() => undefined),
    share()
  );

  private addressOverride?: Address;

  private forceAddress$ = new Subject<undefined>();

  constructor(private readonly networkService: NetworkService, amplitudeService: AmplitudeService) {
    super();
    makeObservable(this);

    this.address$ = merge(this.forceAddress$, this.networkService.address$).pipe(
      map((address) => this.addressOverride ?? address),
      distinctUntilChanged(),
      shareReplay(1)
    );

    this.autoDispose(
      this.address$.pipe(filter(isSomething)).subscribe((address) => {
        amplitudeService.trackSignIn({ address });
        Sentry.setUser({ username: address });
      })
    );

    this.autoDispose(this.address$.subscribe(this.setAddress));
    this.autoDispose(
      this.networkService.connector.privyState$.pipe(map((state) => state.user)).subscribe(this.setUser)
    );
  }

  @action
  private setAddress = (address?: Address): void => {
    this.address = address;

    logger.debug('address', address);
  };

  @action
  private setUser = (user: User | null): void => {
    this.user = user;
  };

  forceAddress = (address?: Address): void => {
    this.addressOverride = address;
    this.forceAddress$.next(undefined);
  };

  /**
   * @returns Promise<boolean>
   * @description Wait until wallet connected and return true or false by timeout 1000ms
   */
  isConnected = (): Promise<boolean> =>
    new Promise<boolean>((resolve) => {
      if (this.address) {
        resolve(true);
      } else {
        const subscription = didChange(() => this.address)
          .pipe(timeout(1000))
          .subscribe({
            next: () => {
              resolve(true);
              subscription.unsubscribe();
            },
            error: () => {
              resolve(false);
              subscription.unsubscribe();
            },
          });
      }
    });
}
