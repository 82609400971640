/* eslint-disable react-hooks/exhaustive-deps */
import { Context } from '@redtea/react-inversify';
import { Container, ContainerModule, interfaces } from 'inversify';
import { useContext, useEffect } from 'react';

import { isSomething } from './is-something';

export type IOCDescriptor = {
  autoInstantiate?: readonly interfaces.ServiceIdentifier<unknown>[];
  module: ContainerModule;
  identifier: symbol;
};

const IOCBag: symbol[] = [];

const IOCTimers = new Map<symbol, NodeJS.Timeout>();

export const loadDescriptors = (descriptors: IOCDescriptor[], container: Container) => {
  for (const descriptor of descriptors) {
    if (!IOCBag.includes(descriptor.identifier)) {
      IOCBag.push(descriptor.identifier);
      container.load(descriptor.module);
      descriptor.autoInstantiate?.filter(isSomething).forEach((identifier) => {
        container.get(identifier);
      });
    }
  }
};

const unloadDescriptors = (descriptors: IOCDescriptor[], container: Container) => {
  descriptors.forEach((descriptor) => {
    const timer = setTimeout(() => {
      unloadDescriptor(descriptor, container);
    }, 500);

    IOCTimers.set(
      descriptor.identifier,
      // delayed module unmount, to smooth out remount cases
      timer
    );
  });
};

const unloadDescriptor = (descriptor: IOCDescriptor, container: Container) => {
  IOCTimers.delete(descriptor.identifier);
  if (IOCBag.includes(descriptor.identifier)) {
    container.unload(descriptor.module);
    const idx = IOCBag.findIndex((bag) => bag === descriptor.identifier);
    if (idx !== -1) {
      IOCBag.splice(idx, 1);
    }
  }
};

export const useIOC = (...descriptors: IOCDescriptor[]): void => {
  const appContainer = useContext(Context);

  loadDescriptors(descriptors, appContainer);

  useEffect(() => {
    descriptors.forEach(({ identifier }) => clearTimeout(IOCTimers.get(identifier)));

    return () => {
      unloadDescriptors(descriptors, appContainer);
    };
  }, []);
};

export const createIOC = ({ module, autoInstantiate }: Omit<IOCDescriptor, 'identifier'>): IOCDescriptor => {
  const identifier = Symbol('descriptor');

  return { module, autoInstantiate, identifier };
};
