import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgChevronDown = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 12 7" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.183 6.144.25 1.664C.09 1.483 0 1.235 0 .978 0 .719.09.47.25.289A.86.86 0 0 1 .527.075a.768.768 0 0 1 .66 0c.104.05.199.122.278.214l3.926 4.489a.86.86 0 0 0 .278.213.768.768 0 0 0 .66 0 .86.86 0 0 0 .279-.213l3.925-4.49A.81.81 0 0 1 11.139 0c.228 0 .446.101.608.284.161.182.252.43.253.69 0 .258-.089.507-.249.69l-3.934 4.48C7.335 6.692 6.681 7 6 7s-1.335-.308-1.817-.856Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgChevronDown;
