import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgArrowUpRight = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 11 10" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.371.886a1.9 1.9 0 0 0-1.266-.55L2.716.145a.83.83 0 0 0-.616.24.83.83 0 0 0-.238.615c.003.117.03.234.078.344a.973.973 0 0 0 .843.577l4.121.134-6.06 6.06a.83.83 0 0 0-.24.62c.009.234.11.463.282.635s.4.273.636.281a.83.83 0 0 0 .619-.238L8.2 3.353l.14 4.128a.951.951 0 0 0 .28.637c.171.173.4.275.635.284a.83.83 0 0 0 .62-.237.83.83 0 0 0 .241-.617l-.189-5.39A1.901 1.901 0 0 0 9.371.886Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgArrowUpRight;
