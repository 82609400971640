import {
  DepositAutomator__factory,
  Transport__factory,
  VaultBaseExternal,
  VaultBaseExternal__factory,
  VaultChild,
  VaultChild__factory,
  VaultParent,
  VaultParent__factory,
  WithdrawAutomator__factory,
} from '@valioxyz/valio-contracts';
import { BigNumber, BigNumberish, ContractTransaction } from 'ethers';
import { injectable } from 'inversify';

import { Address } from 'core/address';
import { ChainID } from 'core/chain';
import { ContractCache } from 'core/contract/contract.cache';
import { ContractAddress } from 'core/contract/contract.store';
import { LayerZero } from 'core/layer-zero';
import { Logger } from 'core/logger';
import { NetworkProvider } from 'core/network/network.provider';

import { Units } from 'types';

import { CreateVaultParams, createVaultParamsToContractMap } from './create-vault-params.map';
import { ValioRegistry } from './valio.registry';

type CreateVaultResult = {
  tx: ContractTransaction;
  vaultAddress: Address;
};

const logger = new Logger('VaultContract');

export type DepositQueue = {
  index: BigNumberish;
  expiryTime: BigNumber;
  depositAsset: Address;
};

export type WithdrawQueue = {
  index: BigNumberish;
  expiryTime: BigNumber;
};

@injectable()
export class VaultContract {
  constructor(
    private readonly networkProvider: NetworkProvider,
    private readonly valioRegistry: ValioRegistry,
    private readonly contractCache: ContractCache
  ) {}

  readonly getTransport = (useSigner?: boolean) => {
    return Transport__factory.connect(
      this.valioRegistry.contracts.Transport,
      useSigner ? this.networkProvider.getSigner() : this.networkProvider.getProvider()
    );
  };

  createVault = async (data: CreateVaultParams, value: Units): Promise<CreateVaultResult> => {
    const signer = this.networkProvider.getSigner();

    const signerAdress = await signer.getAddress();

    const transport = Transport__factory.connect(this.valioRegistry.contracts.Transport, signer);

    const createVaultParams = createVaultParamsToContractMap(data, signerAdress, value);

    logger.debug('createVaultParams', createVaultParams);

    const vaultAddress = await transport.callStatic.createParentVault(...createVaultParams);

    const tx = await transport.createParentVault(...createVaultParams);

    logger.debug('tx', tx.hash);

    logger.debug('vaultAddress', vaultAddress);

    return { tx, vaultAddress };
  };

  estimateCreateVaultGas = async (data: CreateVaultParams, address: string, value: Units): Promise<BigNumberish> => {
    const transport = this.getTransport();

    const gas = await transport.estimateGas.createParentVault(...createVaultParamsToContractMap(data, address, value));

    return gas;
  };

  getVaultCreationFee = async (): Promise<BigNumberish> => {
    const transport = Transport__factory.connect(
      this.valioRegistry.contracts.Transport,
      this.networkProvider.getProvider()
    );

    const fee = await transport.CREATE_VAULT_FEE();

    return fee;
  };

  deployVault = async (address: Address, chainId: ChainID): Promise<ContractTransaction> => {
    const parentVault = this.getVaultParentContract(address, true);

    const internalChainId = LayerZero.getInternalChainId(chainId);
    const sighash = parentVault.interface.getSighash('requestCreateChild(uint16,uint256)');

    const fee = await parentVault.getLzFee(sighash, internalChainId);

    const tx = await parentVault.requestCreateChild(internalChainId, fee, {
      value: fee,
    });

    return tx;
  };

  getWithdrawAutomator = async (useSigner?: boolean) =>
    WithdrawAutomator__factory.connect(
      await this.valioRegistry.getWithdrawAutomatorAddress(),
      useSigner ? this.networkProvider.getSigner() : this.networkProvider.getProvider()
    );

  getDepositAutomator = async (useSigner?: boolean) =>
    DepositAutomator__factory.connect(
      await this.valioRegistry.getDepositAutomatorAddress(),
      useSigner ? this.networkProvider.getSigner() : this.networkProvider.getProvider()
    );

  getVaultParentContract = (address: ContractAddress, useSigner?: boolean): VaultParent =>
    VaultParent__factory.connect(
      address,
      useSigner ? this.networkProvider.getSigner() : this.networkProvider.getProvider()
    );

  getVaultBaseContract = (address: ContractAddress, useSigner?: boolean): VaultBaseExternal =>
    VaultBaseExternal__factory.connect(
      address,
      useSigner ? this.networkProvider.getSigner() : this.networkProvider.getProvider()
    );

  getChildVaultContract = (address: ContractAddress, useSigner?: boolean): VaultChild =>
    VaultChild__factory.connect(
      address,
      useSigner ? this.networkProvider.getSigner() : this.networkProvider.getProvider()
    );

  getToken = async (account: string, contract: VaultParent): Promise<number | null> => {
    const balance = await contract.balanceOf(account);

    const isSystemToken = (tokenId: number): boolean => {
      const MANAGER_TOKEN_ID = 0;
      const PROTOCOL_TOKEN_ID = 1;

      return tokenId === PROTOCOL_TOKEN_ID || tokenId === MANAGER_TOKEN_ID;
    };

    for (let i = 0; i < balance.toNumber(); i++) {
      const tokenId = await contract.tokenOfOwnerByIndex(account, i);

      if (!isSystemToken(tokenId.toNumber())) {
        if (i === balance.toNumber() - 1) {
          return tokenId.toNumber();
        }
      }
    }

    return null;
  };

  getLzFeesMultiChain = async (
    address: Address,
    func: keyof VaultParent['interface']['functions']
  ): Promise<{ fees: BigNumberish[]; totalSendFee: BigNumberish }> => {
    const parentVault = this.getVaultParentContract(address);

    const sighash = parentVault.interface.getSighash(func);
    const [fees, totalSendFee] = await parentVault.getLzFeesMultiChain(sighash);

    return { fees, totalSendFee };
  };

  getQueuedDepositByUser = async (vault: Address, user: Address): Promise<DepositQueue[]> => {
    const automator = await this.getDepositAutomator();

    const indexes = await automator.queuedDepositIndexesByVaultByDepositor(vault, user);

    logger.debug('getQueuedDepositByUser indexes', indexes);

    const queuedDeposits = await Promise.all(
      indexes.map((index) =>
        automator
          .queuedDepositByVaultByIndex(vault, index)
          .then((item) => ({ index, expiryTime: item.expiryTime, depositAsset: item.depositAsset }))
      )
    );

    return queuedDeposits;
  };

  getQueuedWithdrawByUser = async (vault: Address, user: Address): Promise<WithdrawQueue[]> => {
    const automator = await this.getWithdrawAutomator();

    const parentVault = this.getVaultParentContract(vault);

    const tokenId = await this.getToken(user, parentVault);

    if (!tokenId) {
      logger.info('getQueuedWithdrawByUser: no token id');

      return [];
    }

    logger.debug('load indexes for', vault, tokenId);

    const indexes = await automator.queuedWithdrawsByVault(vault);

    const indexesByToken = indexes.filter((index) => index.tokenId.eq(tokenId));

    logger.debug('indexes', indexesByToken);

    const queuedWithdraws = await Promise.all(
      indexesByToken.map((_, index) =>
        automator.queuedWithdrawByVaultByIndex(vault, index).then((item) => ({ index, expiryTime: item.expiryTime }))
      )
    );

    return queuedWithdraws;
  };
}
