import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgCrosedClockNeonBlue = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#crosed-clock-neon-blue_svg__a)">
      <path
        d="M56.07 17.737a1.277 1.277 0 1 0-1.806-1.807l-2.938 2.938C47.254 15.222 41.882 13 36 13c-12.682 0-23 10.318-23 23 0 5.882 2.222 11.254 5.868 15.325l-2.938 2.938a1.277 1.277 0 1 0 1.807 1.807l2.938-2.938C24.746 56.778 30.118 59 36 59c12.682 0 23-10.318 23-23 0-5.882-2.222-11.254-5.868-15.325l2.938-2.938ZM15.556 36c0-11.273 9.17-20.444 20.444-20.444 5.178 0 9.908 1.938 13.515 5.122L37.278 32.915v-10.97a1.278 1.278 0 1 0-2.556 0V35.47L20.678 49.515A20.358 20.358 0 0 1 15.556 36Zm40.888 0c0 11.273-9.17 20.444-20.444 20.444a20.358 20.358 0 0 1-13.515-5.122L36.21 37.597l6.897 3.982a1.277 1.277 0 1 0 1.278-2.213l-6.304-3.64 13.24-13.24A20.358 20.358 0 0 1 56.445 36Z"
        fill="#fff"
      />
    </g>
    <defs>
      <filter
        id="crosed-clock-neon-blue_svg__a"
        x={0.737}
        y={0.737}
        width={70.526}
        height={70.526}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={6.131} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_13174_2137" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={3.344} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_13174_2137" result="effect2_dropShadow_13174_2137" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.115} />
        <feColorMatrix values="0 0 0 0 0.329412 0 0 0 0 0.572549 0 0 0 0 0.792157 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_13174_2137" result="effect3_dropShadow_13174_2137" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_13174_2137" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgCrosedClockNeonBlue;
