import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgGift = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.065 3.543h2.094a.84.84 0 0 1 .84.841v1.682a.84.84 0 0 1-.84.841H.84A.84.84 0 0 1 0 6.067V4.383a.84.84 0 0 1 .841-.84h2.112a2.395 2.395 0 0 1-.287-1.128C2.666 1.092 3.758 0 5.118 0 6.943 0 7.784 1.163 8.5 2.416 9.216 1.163 10.057 0 11.9 0c1.342 0 2.451 1.092 2.451 2.416 0 .412-.107.787-.286 1.127ZM11.9 1.306c-1.235 0-1.718.752-2.505 2.184v.035H11.9c.626 0 1.145-.5 1.145-1.11 0-.608-.519-1.109-1.145-1.109ZM7.605 3.49c-.787-1.432-1.27-2.184-2.487-2.184-.644 0-1.145.484-1.145 1.092 0 .608.518 1.11 1.145 1.11h2.505l-.018-.018ZM1.933 17a.84.84 0 0 1-.841-.84V8.106h14.834v8.052a.84.84 0 0 1-.84.841H1.932Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgGift;
