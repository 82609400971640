import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';

import { VaultStore } from 'core/vault/vault.store';

import { Avatar, AvatarSize } from 'components/avatar';
import { Primary, Secondary } from 'components/typography';

import { formatShortAddress } from 'utils/web3';

export const ShareCardFooter = observer(() => {
  const { activeVault } = useService(VaultStore);

  if (!activeVault) return null;

  return (
    <div className="flex items-center gap-3.5 w-full mt-3">
      <div className="flex">
        <Avatar size={AvatarSize.Vault} src={activeVault.vault.avatar} />
      </div>
      <div className="flex flex-col justify-between">
        <div className="flex gap-2.5">
          <Primary className="text-lg font-stratos -mb-1.5 pb-1.5 inline-block whitespace-nowrap">
            {activeVault.vault.name}
          </Primary>
          <div className="flex gap-1.5">
            <Avatar size={AvatarSize.Small} src={activeVault.vault.manager.account.avatar} />
            <Secondary className="whitespace-nowrap">
              {activeVault.vault.manager.account.name || formatShortAddress(activeVault.vault.manager.account.address)}
            </Secondary>
          </div>
        </div>
        <Secondary className="w-[500px] overflow-hidden">{activeVault.vault.shortDescription}</Secondary>
      </div>
    </div>
  );
});
