import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type BannerProps = {
  text: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  className?: string;
};

export const Banner = (props: BannerProps) => {
  const { text, startIcon, endIcon, className } = props;

  return (
    <div
      className={twMerge(
        'flex items-center justify-center w-full min-h-[50px] bg-brand-600',
        '[&_a]:text-white',
        className
      )}
    >
      {startIcon && <div className="flex mr-[5px] text-white/30">{startIcon}</div>}

      {text}

      {endIcon && <div className="flex ml-[5px] text-white/30">{endIcon}</div>}
    </div>
  );
};
