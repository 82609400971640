import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgOxtrading = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m2.729 8.216 1.01-1.044-1.256-1.695-1.597-2.26A6.445 6.445 0 0 0 0 6.5c0 1.984.886 3.757 2.286 4.95l2.027-1.434a4.173 4.173 0 0 1-1.584-1.8ZM4.782 2.73l1.045 1.01L7.52 2.483 9.78.887A6.44 6.44 0 0 0 6.498 0a6.482 6.482 0 0 0-4.95 2.287l1.435 2.027a4.14 4.14 0 0 1 1.8-1.584Zm4.48 3.099 1.255 1.694 1.597 2.26c.583-.996.89-2.129.886-3.283a6.486 6.486 0 0 0-2.286-4.95L8.687 2.983a4.142 4.142 0 0 1 1.584 1.8l-1.01 1.045Zm2.19 4.884-1.435-2.027a4.141 4.141 0 0 1-1.8 1.584L7.174 9.26 5.48 10.517l-2.26 1.597c.996.584 2.128.891 3.282.887a6.489 6.489 0 0 0 4.95-2.287Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgOxtrading;
