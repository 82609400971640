import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgVaultChart = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#vault-chart_svg__a)">
      <mask id="vault-chart_svg__b" fill="#fff">
        <path d="M24.796.664c0-.367.297-.665.664-.653a20.796 20.796 0 0 1 18.12 11.861.648.648 0 0 1-.334.87.682.682 0 0 1-.885-.338A19.467 19.467 0 0 0 25.46 1.34a.682.682 0 0 1-.665-.676Z" />
      </mask>
      <path
        d="M24.796.664c0-.367.297-.665.664-.653a20.796 20.796 0 0 1 18.12 11.861.648.648 0 0 1-.334.87.682.682 0 0 1-.885-.338A19.467 19.467 0 0 0 25.46 1.34a.682.682 0 0 1-.665-.676Z"
        fill="#9F44FF"
        stroke="#fff"
        strokeOpacity={0.28}
        mask="url(#vault-chart_svg__b)"
      />
      <mask id="vault-chart_svg__c" fill="#fff">
        <path d="M43.602 13.614c.343-.131.728.04.848.387a20.795 20.795 0 0 1-2.209 18.113.648.648 0 0 1-.916.172.683.683 0 0 1-.175-.931 19.468 19.468 0 0 0 2.058-16.88.682.682 0 0 1 .394-.861Z" />
      </mask>
      <path
        d="M43.602 13.614c.343-.131.728.04.848.387a20.795 20.795 0 0 1-2.209 18.113.648.648 0 0 1-.916.172.683.683 0 0 1-.175-.931 19.468 19.468 0 0 0 2.058-16.88.682.682 0 0 1 .394-.861Z"
        fill="#69B7FF"
        stroke="#fff"
        strokeOpacity={0.14}
        mask="url(#vault-chart_svg__c)"
      />
      <mask id="vault-chart_svg__d" fill="#fff">
        <path d="M40.618 33.242a.648.648 0 0 1 .103.926 20.796 20.796 0 0 1-21.358 6.7.648.648 0 0 1-.445-.819.683.683 0 0 1 .833-.452 19.466 19.466 0 0 0 19.925-6.25.683.683 0 0 1 .942-.105Z" />
      </mask>
      <path
        d="M40.618 33.242a.648.648 0 0 1 .103.926 20.796 20.796 0 0 1-21.358 6.7.648.648 0 0 1-.445-.819.683.683 0 0 1 .833-.452 19.466 19.466 0 0 0 19.925-6.25.683.683 0 0 1 .942-.105Z"
        fill="#CC38C4"
        stroke="#fff"
        strokeOpacity={0.28}
        mask="url(#vault-chart_svg__d)"
      />
      <mask id="vault-chart_svg__e" fill="#fff">
        <path d="M18.058 39.766a.648.648 0 0 1-.845.393 20.796 20.796 0 0 1-10.419-8.952.648.648 0 0 1 .262-.895.683.683 0 0 1 .91.266 19.467 19.467 0 0 0 9.692 8.328.683.683 0 0 1 .4.86Z" />
      </mask>
      <path
        d="M18.058 39.766a.648.648 0 0 1-.845.393 20.796 20.796 0 0 1-10.419-8.952.648.648 0 0 1 .262-.895.683.683 0 0 1 .91.266 19.467 19.467 0 0 0 9.692 8.328.683.683 0 0 1 .4.86Z"
        fill="#CC6D38"
        stroke="#fff"
        strokeOpacity={0.28}
        mask="url(#vault-chart_svg__e)"
      />
      <path
        d="M6.613 29.437a.648.648 0 0 1-.875-.32A20.796 20.796 0 0 1 23.167.064a.648.648 0 0 1 .693.622.682.682 0 0 1-.632.706 19.467 19.467 0 0 0-16.29 27.155.682.682 0 0 1-.325.89Z"
        fill="#F7FAFF"
      />
    </g>
    <defs>
      <filter
        id="vault-chart_svg__a"
        x={0}
        y={0.01}
        width={49.591}
        height={49.581}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_726_14442" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_726_14442" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgVaultChart;
