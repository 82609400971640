/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { AccountFragmentDoc } from './account.fragment.generated';

export type UpdateAccountMutationVariables = Types.Exact<{
  input: Types.UpdateAccountInput;
}>;

export type UpdateAccountMutation = {
  __typename?: 'Mutation';
  updateAccount: {
    __typename?: 'AccountType';
    address: string;
    chain: string;
    avatar?: string | null;
    name?: string | null;
    twitter?: string | null;
    bio?: string | null;
    createdAt: string;
    slug: string;
    referrerCode: string;
    referrerProvider?: Types.ReferrerProvider | null;
    referralCode?: string | null;
    experience: number;
    experienceSpins: number;
    rewards: Array<{
      __typename?: 'RewardType';
      rewardType?: Types.RewardEnumType | null;
      createdAt: string;
      revealedAt?: string | null;
    }>;
  };
};

export const UpdateAccountDocument = gql`
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      ...Account
    }
  }
  ${AccountFragmentDoc}
`;

export function UpdateAccount<R extends UpdateAccountMutation, V extends UpdateAccountMutationVariables>(
  request: Request<R, V>,
  variables: V,
  headers?: HeadersInit
): Promise<R> {
  return request(UpdateAccountDocument, variables, headers);
}
