import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgOpera = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.794.591C9.636.591.59 9.637.59 20.796c0 10.835 8.53 19.679 19.242 20.18a20.132 20.132 0 0 0 14.428-5.118c-2.369 1.57-5.138 2.475-8.097 2.475-4.811 0-9.12-2.387-12.019-6.151-2.234-2.638-3.68-6.536-3.78-10.91v-.952c.1-4.375 1.546-8.274 3.78-10.91 2.898-3.765 7.207-6.152 12.019-6.152 2.96 0 5.729.904 8.097 2.476A20.128 20.128 0 0 0 20.87.59h-.076Z"
      fill="url(#opera_svg__a)"
    />
    <path
      d="M14.145 9.41c1.854-2.188 4.248-3.508 6.864-3.508 5.882 0 10.65 6.669 10.65 14.895 0 8.226-4.768 14.895-10.65 14.895-2.616 0-5.01-1.32-6.864-3.509 2.898 3.764 7.207 6.151 12.019 6.151 2.96 0 5.728-.903 8.096-2.474 4.136-3.7 6.74-9.077 6.74-15.063a20.155 20.155 0 0 0-6.738-15.062c-2.37-1.571-5.139-2.475-8.098-2.475-4.812 0-9.12 2.387-12.019 6.15"
      fill="url(#opera_svg__b)"
    />
    <defs>
      <linearGradient id="opera_svg__a" x1={17.426} y1={1.25} x2={17.426} y2={40.413} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF1B2D" />
        <stop offset={0.3} stopColor="#FF1B2D" />
        <stop offset={0.614} stopColor="#FF1B2D" />
        <stop offset={1} stopColor="#A70014" />
      </linearGradient>
      <linearGradient id="opera_svg__b" x1={27.572} y1={3.559} x2={27.572} y2={38.196} gradientUnits="userSpaceOnUse">
        <stop stopColor="#9C0000" />
        <stop offset={0.7} stopColor="#FF4B4B" />
        <stop offset={1} stopColor="#FF4B4B" />
      </linearGradient>
    </defs>
  </svg>
));
export default SvgOpera;
