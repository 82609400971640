import { MouseEvent, useEffect, useRef, useState } from 'react';

export const useSearchMenu = <T>(results: T[]) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [active, setActive] = useState(false);
  const prevAnchorEl = useRef<Element | null>(null);

  const handleClick = (e: MouseEvent<Element>) => {
    prevAnchorEl.current = e.currentTarget;
    setAnchorEl((prevState) => {
      if (!prevState && results.length) {
        setActive(true);

        return e.currentTarget;
      } else {
        return prevState;
      }
    });
  };

  const handleClose = () => {
    setActive(false);
    setAnchorEl(null);
  };

  const handleFocus = () => {
    setActive(true);
  };

  const handleBlur = () => {
    setActive(false);
  };

  const open = active && Boolean(anchorEl);

  useEffect(() => {
    active && results.length ? setAnchorEl(prevAnchorEl.current) : setAnchorEl(null);
  }, [active, results]);

  return {
    handleClick,
    state: { isOpen: open, onClose: handleClose, onBlur: handleBlur, onFocus: handleFocus, anchorEl },
  };
};
