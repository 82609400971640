import { ContainerModule } from 'inversify';

import { createIOC } from 'utils/use-ioc';

import { BackdropStore } from './backdrop/backdrop.store';
import { ModalsStore } from './modals/modals.store';
import { WidgetsStore } from './widgets/widgets.store';

export const libModule = createIOC({
  module: new ContainerModule((bind) => {
    bind(ModalsStore).toSelf();
    bind(BackdropStore).toSelf();
    bind(WidgetsStore).toSelf();
  }),
  autoInstantiate: [BackdropStore],
});
