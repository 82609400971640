import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgGovernance = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 13 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.856 8.35A.146.146 0 0 0 13 8.202v-.767a.129.129 0 0 0-.101-.133c-.375-.088-1.616-.413-2.41-.604-.36-1.8-1.846-3.157-3.664-3.305V2.124h1.659a.23.23 0 0 0 .23-.236V.693a.23.23 0 0 0-.23-.236H6.796V.31A.308.308 0 0 0 6.493 0a.318.318 0 0 0-.303.31v3.083a4.083 4.083 0 0 0-3.665 3.305c-.808.191-2.049.501-2.424.59C.043 7.302 0 7.36 0 7.435v.767c0 .074.058.148.144.148h.91v4.544h-.91A.146.146 0 0 0 0 13.04v.797c0 .088.072.162.144.162h12.697a.162.162 0 0 0 .159-.162v-.797a.162.162 0 0 0-.159-.162h-.894v-4.53h.909Zm-5.67 0h.995v4.543h-.996V8.35ZM5.8 12.893h-.995V8.35H5.8v4.543ZM2.424 8.35h.996v4.543h-.996V8.35Zm8.138 4.543h-.996V8.35h.996v4.543Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgGovernance;
