import { injectable } from 'inversify';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { Subject, delay, filter, map, repeat, switchMap } from 'rxjs';

import { AccountStore } from 'core/account/account.store';
import { ApiService } from 'core/api/api.service';
import { RevealReward } from 'core/api/gql/account/season-zero/reveal-reward.gql.generated';
import { RewardFragment } from 'core/api/gql/account/season-zero/reward.fragment.generated';
import { Reward } from 'core/api/gql/account/season-zero/reward.gql.generated';
import { RewardEnumType } from 'core/api/schema';
import { WalletStore } from 'core/wallet/wallet.store';

import { ModalsStore } from 'lib/modals/modals.store';

import { Disposable } from 'utils/disposable';
import { isSomething } from 'utils/is-something';

import { RevealModal } from './modals/reveal-modal';
import { RewardSZN0ReceivedModal } from './modals/reward-received-modal';
import { RevealSZN0 } from './reveal-szn0';

@injectable()
export class RevealSZN0Store extends Disposable {
  @observable
  boxes: RevealSZN0.Box[] = RevealSZN0.initialBoxes;

  @observable
  inventory: RewardFragment[] = [];

  @observable
  inventoryHistory: RevealSZN0.Inventory[] = [];

  @observable
  currentPosition = 5;

  @observable
  isRevealing = false;

  @observable
  isOpening = false;

  private openBox$ = new Subject<RevealSZN0.Box>();

  constructor(
    private readonly modalsStore: ModalsStore,
    private readonly accountStore: AccountStore,
    private readonly apiService: ApiService,
    private readonly modalsManager: ModalsStore,
    private readonly walletStore: WalletStore
  ) {
    super();

    makeObservable(this);

    this.init();

    const rewardReceived$ = this.walletStore.address$.pipe(
      switchMap(() => this.apiService.subscription(Reward)),
      map((item) => item.reward),
      filter(isSomething)
    );

    this.autoDispose(rewardReceived$.subscribe(() => this.modalsManager.open(RewardSZN0ReceivedModal)));

    this.autoDispose(rewardReceived$.subscribe(this.addBoxToInventory));

    this.autoDispose(
      this.openBox$.pipe(delay(RevealSZN0.ANIMATION_DURATION * 1000)).subscribe(() => {
        this.setIsOpening(false);
        this.setIsRevealing(false);
      })
    );

    this.autoDispose(this.openBox$.subscribe(this.updateInventoryHistory));
    this.autoDispose(this.openBox$.subscribe(this.updateInventory));

    this.autoDispose(
      this.openBox$
        .pipe(
          filter(() => !this.availableBoxes),
          delay(RevealSZN0.ANIMATION_DURATION * 1000),
          map(() => this.modalsStore),
          repeat()
        )
        .subscribe(() => this.modalsManager.open(RevealModal))
    );

    reaction(
      () => this.accountStore.account,
      () => this.init()
    );
  }

  @computed
  get openedBoxes(): RevealSZN0.Inventory[] {
    const countByType = this.inventory.reduce((countMap, item) => {
      if (isSomething(item.rewardType)) {
        countMap[item.rewardType] = (countMap[item.rewardType] || 0) + 1;
      }

      return countMap;
    }, {} as Record<RewardEnumType, number>);

    return RevealSZN0.initialInventory.map((inventoryItem) => ({
      ...inventoryItem,
      count: countByType[inventoryItem.type] || 0,
    }));
  }

  @computed
  get availableBoxes(): number {
    return this.inventory.filter((item) => !item.rewardType).length;
  }

  @action
  clear = (): void => {
    this.boxes = RevealSZN0.initialBoxes;
    this.currentPosition = 5;
  };

  @action
  private init = (): void => {
    if (!this.accountStore.account) return;

    this.inventory = this.accountStore.account.rewards ?? [];
  };

  @action
  private updateInventoryHistory = (box: RevealSZN0.Box): void => {
    const inventoryItem = this.inventoryHistory.find((item) => item.type === box.type);

    if (inventoryItem) {
      inventoryItem.count++;
    } else {
      this.inventoryHistory.push({ ...box, count: 1 });
    }
  };

  @action
  private setIsOpening = (value: boolean): void => {
    this.isOpening = value;
  };

  @action
  private setIsRevealing = (value: boolean): void => {
    this.isRevealing = value;
  };

  @action
  private updateCurrentPosition = (): void => {
    this.currentPosition = this.currentPosition + RevealSZN0.STEP;
  };

  @action
  private updateBoxes = (boxes: RevealSZN0.Box[]): void => {
    this.boxes.push(...boxes);
  };

  @action
  private updateInventory = (box: RevealSZN0.Box): void => {
    const itemToUpdate = this.inventory.find((item) => !item.rewardType);

    if (itemToUpdate) {
      itemToUpdate.rewardType = box.type;
    }
  };

  @action
  private addBoxToInventory = (box: RewardFragment): void => {
    this.inventory.push(box);
  };

  selectWinnerBox = async (): Promise<void> => {
    const beforeWinnerBoxes = RevealSZN0.generateNewBoxes(14);
    const afterWinnerBoxes = RevealSZN0.generateNewBoxes(4);
    this.setIsOpening(true);

    const { revealReward } = await this.apiService.query(RevealReward);

    if (revealReward) {
      const winnerBox = RevealSZN0.getBoxByType(revealReward.rewardType ?? RewardEnumType.Common);
      this.setIsRevealing(true);

      this.updateCurrentPosition();
      this.updateBoxes([...beforeWinnerBoxes, winnerBox, ...afterWinnerBoxes]);

      this.openBox$.next(winnerBox);
    }
  };
}
