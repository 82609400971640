import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgArrowUp = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.505 0c.57.006 1.116.248 1.518.674l4.662 4.903c.202.214.315.504.315.806 0 .302-.113.591-.315.806-.101.107-.22.192-.353.25a1.037 1.037 0 0 1-1.187-.25L7.59 3.429v11.428c0 .303-.114.594-.317.808-.203.215-.48.335-.767.335-.287 0-.563-.12-.766-.335a1.175 1.175 0 0 1-.318-.808V3.43l-3.567 3.76a1.058 1.058 0 0 1-.765.337 1.057 1.057 0 0 1-.769-.332A1.175 1.175 0 0 1 0 6.387a1.176 1.176 0 0 1 .315-.81L4.977.674C5.38.245 5.93.003 6.505 0Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgArrowUp;
