/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { DepositFragmentDoc } from '../deposit/deposit.fragment.generated';

export type GetVaultDepositQueryVariables = Types.Exact<{
  uid: Types.Scalars['String']['input'];
  depositor: Types.Scalars['String']['input'];
}>;

export type GetVaultDepositQuery = {
  __typename?: 'Query';
  deposit?: {
    __typename?: 'DepositType';
    createdAt: string;
    depositor: string;
    entryPrice: BigNumber;
    pAndL: BigNumber;
    roi: BigNumber;
    shares: BigNumber;
    sharesValue: BigNumber;
    stake: BigNumber;
    depositedAmount: BigNumber;
  } | null;
};

export const GetVaultDepositDocument = gql`
  query GetVaultDeposit($uid: String!, $depositor: String!) {
    deposit(vault: $uid, depositor: $depositor) {
      ...Deposit
    }
  }
  ${DepositFragmentDoc}
`;

export function GetVaultDeposit<R extends GetVaultDepositQuery, V extends GetVaultDepositQueryVariables>(
  request: Request<R, V>,
  variables: V,
  headers?: HeadersInit
): Promise<R> {
  return request(GetVaultDepositDocument, variables, headers);
}
