import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgArb = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m9.398 8.372 1.237-2.099 3.333 5.192.002.997-.011-6.857a.517.517 0 0 0-.239-.411L7.718 1.74a.529.529 0 0 0-.512.034l-.02.013L1.36 5.164l-.023.01a.515.515 0 0 0-.301.438l.01 5.588L4.15 6.387c.391-.638 1.243-.844 2.034-.832l.928.024-5.468 8.77.644.37 5.534-9.13 2.446-.01-5.52 9.362 2.3 1.323.275.158c.116.047.253.05.37.007l6.087-3.527-1.164.674-3.218-5.204Zm.472 6.796-2.323-3.646 1.418-2.406 3.05 4.808-2.145 1.244Z"
      fill="#2D374B"
    />
    <path
      d="m7.547 11.522 2.323 3.646 2.146-1.244-3.05-4.809-1.419 2.407ZM13.97 12.461l-.002-.996-3.334-5.192-1.237 2.098 3.219 5.205 1.163-.674a.516.516 0 0 0 .19-.376v-.065Z"
      fill="#28A0F0"
    />
    <path
      d="m0 13.401 1.643.947L7.111 5.58l-.928-.024c-.79-.012-1.642.194-2.033.832L1.045 11.2 0 12.805v.596ZM10.266 5.58l-2.445.008-5.534 9.131 1.934 1.114.526-.892 5.52-9.362Z"
      fill="#fff"
    />
    <path
      d="M15 5.566a1.558 1.558 0 0 0-.731-1.252L8.189.817a1.582 1.582 0 0 0-1.396 0C6.743.843.88 4.243.88 4.243A1.546 1.546 0 0 0 0 5.563v7.242L1.045 11.2l-.01-5.588a.516.516 0 0 1 .215-.388c.028-.02 5.99-3.471 6.01-3.48a.529.529 0 0 1 .458-.003l6.002 3.453c.141.09.23.243.238.41v6.923a.505.505 0 0 1-.18.375l-1.163.674-.6.348-2.146 1.244-2.176 1.26a.528.528 0 0 1-.37-.006L4.747 14.94l-.526.892 2.314 1.332a57.394 57.394 0 0 0 .367.205c.164.08.4.126.614.126.196 0 .386-.036.567-.107l6.32-3.66c.363-.281.577-.705.596-1.164V5.566Z"
      fill="#96BEDC"
    />
  </svg>
));
export default SvgArb;
