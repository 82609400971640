import { useService } from '@redtea/react-inversify';
import { format } from 'date-fns';
import { toBlob, toPng } from 'html-to-image';
import { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styles/themes/use-theme.hook';

import { Amplitude } from 'core/amplitude/amplitude';
import { AmplitudeService } from 'core/amplitude/amplitude.service';
import { logger } from 'core/logger';
import { SnackbarStore } from 'core/snackbars/snackbar.store';
import { VaultActionInfo } from 'core/vault/vault-action.info';

import { Button } from 'components/button';
import { CopyIcon, DownloadIcon } from 'components/icons';
import { VaultLoader } from 'components/loading';
import { H2, Secondary } from 'components/typography';

import ShareCard from 'common/share-card';

type ActionsShareProps = {
  data?: VaultActionInfo;
};

const options = {
  cacheBust: true,
};

export const StatsShare = (props: ActionsShareProps) => {
  const [shareImg, setShareImg] = useState<string | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);
  const shareCardRef = useRef<HTMLDivElement | null>(null);
  const { openFailed } = useService(SnackbarStore);
  const amplitudeService = useService(AmplitudeService);
  const { appName } = useTheme();

  const { data } = props;

  useEffect(() => {
    if (shareCardRef.current) {
      toPng(shareCardRef.current, options)
        .then((dataUrl) => {
          setShareImg(dataUrl);
        })
        .catch((error) => {
          setHasError(true);
          logger.error('StatsShare: toPng', error);
        });
    }
  }, []);

  const handleDownloadImage = () => {
    const currentDate = format(new Date(), 'yy.MM.dd_HH:mm');

    if (shareImg) {
      const downloadLink = document.createElement('a');
      downloadLink.href = shareImg;
      downloadLink.download = `${appName}_${currentDate}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      const integration = data?.integration;
      amplitudeService.trackShareCard({
        action: Amplitude.ShareCardEventAction.Download,
        integration: integration ?? Amplitude.ShareCardIntegration.VaultReturns,
      });
    }
  };

  const handleCopyImage = async () => {
    if (shareCardRef.current) {
      toBlob(shareCardRef.current, options)
        .then((blob) => {
          if (blob) {
            navigator.clipboard.write([
              new ClipboardItem({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'image/png': blob,
              }),
            ]);

            const integration = data?.integration;
            amplitudeService.trackShareCard({
              action: Amplitude.ShareCardEventAction.Copy,
              integration: integration ?? Amplitude.ShareCardIntegration.VaultReturns,
            });
          }
        })
        .catch((err) => {
          openFailed('Failed to copy image', err);
        });
    }
  };

  return (
    <div className="flex flex-col gap-2.5 w-full h-full relative">
      <div className="flex flex-col gap-2.5">
        <div className="flex flex-col items-center justify-center">
          <H2 className="m-0">Download your P&L shareable image!</H2>
          <Secondary>Share with your subscribers or depositors</Secondary>
        </div>

        <div className="flex gap-2.5">
          <Button
            data-testid="stats-share-download-button"
            fullWidth
            disabled={!shareImg}
            startIcon={<DownloadIcon />}
            onClick={handleDownloadImage}
          >
            Download
          </Button>
          <Button
            data-testid="stats-share-copy-button"
            disabled={!shareImg}
            fullWidth
            startIcon={<CopyIcon />}
            onClick={handleCopyImage}
          >
            Copy
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-center w-full min-h-[200px]">
        {shareImg ? (
          <img className="w-full rounded-2.5 border border-outline" src={shareImg} />
        ) : (
          !hasError && <VaultLoader width={20} height={20} />
        )}
        {hasError && <Secondary>Failed to load image</Secondary>}
      </div>

      <div className="absolute -top-[10000px]">
        <div ref={shareCardRef}>
          <ShareCard data={data} />
        </div>
      </div>
    </div>
  );
};
