/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { DepositFragmentDoc } from './deposit.fragment.generated';

export type GetVaultDepositsQueryVariables = Types.Exact<{
  uid: Types.Scalars['String']['input'];
  page: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
}>;

export type GetVaultDepositsQuery = {
  __typename?: 'Query';
  deposits: {
    __typename?: 'Deposits';
    numPages: number;
    numItems: number;
    items: Array<{
      __typename?: 'DepositType';
      createdAt: string;
      depositor: string;
      entryPrice: BigNumber;
      pAndL: BigNumber;
      roi: BigNumber;
      shares: BigNumber;
      sharesValue: BigNumber;
      stake: BigNumber;
      depositedAmount: BigNumber;
    }>;
  };
};

export const GetVaultDepositsDocument = gql`
  query GetVaultDeposits($uid: String!, $page: Int!, $pageSize: Int!) {
    deposits(vault: $uid, page: $page, pageSize: $pageSize) {
      numPages
      numItems
      items {
        ...Deposit
      }
    }
  }
  ${DepositFragmentDoc}
`;

export function GetVaultDeposits<R extends GetVaultDepositsQuery, V extends GetVaultDepositsQueryVariables>(
  request: Request<R, V>,
  variables: V,
  headers?: HeadersInit
): Promise<R> {
  return request(GetVaultDepositsDocument, variables, headers);
}
