import { captureException } from '@sentry/react';
import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';

import { Errors } from 'core/app/errors';
import { logger } from 'core/logger';

@injectable()
export class ErrorStore {
  @observable
  reloadNeeded = false;

  constructor() {
    makeObservable(this);
  }

  @action
  readonly notifyError = (error: unknown): void => {
    if (Errors.isChunkLoadError(error)) {
      window.location.reload();

      return;
    }

    logger.error(error);

    captureException(error);

    this.reloadNeeded = true;
  };
}
