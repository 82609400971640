import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgCase = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9 4H19a5.006 5.006 0 0 1 5 5v3H0V9a5.006 5.006 0 0 1 5-5h1.1A5.009 5.009 0 0 1 11 0h2a5.01 5.01 0 0 1 4.9 4ZM9.275 2.554A3 3 0 0 0 8.184 4h7.632A3 3 0 0 0 13 2h-2a3 3 0 0 0-1.725.554Zm3.432 13.153A1 1 0 0 0 13 15v-1h11v5a5.006 5.006 0 0 1-5 5H5a5.006 5.006 0 0 1-5-5v-5h11v1a1 1 0 0 0 1.707.707Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgCase;
