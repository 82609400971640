import { Control, useForm } from '@mobx-form-state/react';
import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';

import { SnackbarStore } from 'core/snackbars/snackbar.store';
import { WalletStore } from 'core/wallet/wallet.store';

import { Button, ButtonVariant } from 'components/button';
import { FormControl } from 'components/form-control';
import { ModalContent, ModalFooter, ModalHeader } from 'components/modals/styled';
import { TextField } from 'components/text-field';
import { H1 } from 'components/typography';

import { GodModeModel } from './god-mode.model';

export const GodModeModal = observer(() => {
  const { forceAddress, address } = useService(WalletStore);
  const { openSuccess, openFailed } = useService(SnackbarStore);

  const form = useForm(GodModeModel, {
    initialValues: {
      forceAddress: address,
    },
    onSubmit: (values) => {
      forceAddress(values.forceAddress);
      openSuccess('Changes applied');
    },
    onSubmitFail: (e) => openFailed('Failed to apply changes', e),
  });

  return (
    <form className="w-[480px]" onSubmit={form.handleSubmit}>
      <ModalHeader className="flex justify-center">
        <H1>God mode menu</H1>
      </ModalHeader>
      <ModalContent>
        <FormControl label="Force wallet address">
          <Control of={TextField} field={form.fields.forceAddress} />
        </FormControl>
      </ModalContent>
      <ModalFooter>
        <Button fullWidth variant={ButtonVariant.Contained}>
          Save
        </Button>
      </ModalFooter>
    </form>
  );
});

GodModeModal.displayName = 'GodModeModal';
