/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../../schema';
import { RewardFragmentDoc } from './reward.fragment.generated';

export type RewardSubscriptionVariables = Types.Exact<{ [key: string]: never }>;

export type RewardSubscription = {
  __typename?: 'SubscriptionRoot';
  reward: {
    __typename?: 'RewardType';
    rewardType?: Types.RewardEnumType | null;
    createdAt: string;
    revealedAt?: string | null;
  };
};

export const RewardDocument = gql`
  subscription Reward {
    reward {
      ...Reward
    }
  }
  ${RewardFragmentDoc}
`;

export function Reward<R extends RewardSubscription, V extends RewardSubscriptionVariables>(
  subscription: GetSubscription<R, V>,
  variables?: V
): Observable<R> {
  return subscription(RewardDocument, variables);
}
