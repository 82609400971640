import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';

import { VaultSearchStore } from 'core/vault/vault-search.store';

import { CloseIcon, SearchIcon } from 'components/icons';
import { VaultLoader } from 'components/loading';
import { Menu } from 'components/menu';
import { NavLink } from 'components/nav-link';
import { TextField } from 'components/text-field';
import { VaultItem } from 'components/vault-list';

import { formatShortAddress } from 'utils/web3';

import { useSearchMenu } from './use-search-menu.hook';

export const Search = observer(() => {
  const { term, searchResults, setSearchTerm, isLoading } = useService(VaultSearchStore);

  const { handleClick, state } = useSearchMenu(searchResults);

  return (
    <div className="w-[250px]" onClick={handleClick}>
      <TextField
        className="min-h-[34px] [&__input]:pt-0 [&__input]:pb-0"
        value={term}
        onFocus={state.onFocus}
        onBlur={state.onBlur}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search..."
        endIcon={
          isLoading ? (
            <VaultLoader width={18} height={18} />
          ) : term ? (
            <CloseIcon onClick={() => setSearchTerm('')} />
          ) : (
            <SearchIcon />
          )
        }
      />

      <Menu
        className="w-[250px] max-h-[480px] overflow-scroll"
        {...state}
        popoverProps={{ indent: 13, placement: 'bottom-end' }}
      >
        {searchResults.map((item) => (
          <NavLink
            component={VaultItem}
            onClick={() => {
              setSearchTerm('');
            }}
            to={`/vault/${item.slug}`}
            key={item.uid}
            avatar={item.avatar}
            title={item.name}
            subtitle={item.manager.account.name || formatShortAddress(item.manager.account.address)}
            className="px-[11px] py-[8px]"
            change={item.stats.roiTotal?.toNumber() || 0}
          />
        ))}
      </Menu>
    </div>
  );
});
