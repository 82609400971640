import { EthereumIcon } from 'components/icons';

import { ChainID, ChainInfo } from './chain.info';

export const ethereumChain = {
  name: 'Ethereum',
  chainId: ChainID.Ethereum,
  symbol: 'ETH',
  symbolAlias: 'WETH',
  blockExplorerUrl: 'https://etherscan.io',
  Icon: EthereumIcon,
} as const satisfies ChainInfo;
