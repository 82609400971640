import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgPencil = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 10.256V13h2.744l7.945-8.017L7.944 2.24 0 10.256Zm12.783-7.367a.698.698 0 0 0 0-1.011L11.123.217a.698.698 0 0 0-1.012 0l-1.3 1.3 2.745 2.744 1.227-1.372Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgPencil;
