import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgCrossedEye = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.684 10.686a14.028 14.028 0 0 1-2.268-2.8l-.26-.394a.827.827 0 0 1 0-.963l.26-.402C1.541 4.43 3.88.886 9 .886a9.389 9.389 0 0 1 3.212.533L9.936 3.633a3.654 3.654 0 0 0-2.313.144 3.606 3.606 0 0 0-1.168.759c-.334.325-.6.71-.78 1.135a3.415 3.415 0 0 0-.148 2.25l-2.843 2.765Zm15.16-3.194-.26.402C16.459 9.592 14.12 13.136 9 13.136a9.083 9.083 0 0 1-4.082-.918l-1.58 1.536a.913.913 0 0 1-1.262-.01.863.863 0 0 1-.263-.611.863.863 0 0 1 .252-.616l5.2-5.056a1.69 1.69 0 0 1-.065-.45 1.742 1.742 0 0 1 .529-1.236 1.817 1.817 0 0 1 1.734-.452l5.2-5.056A.9.9 0 0 1 15.301 0a.922.922 0 0 1 .644.256.874.874 0 0 1 .263.627.853.853 0 0 1-.274.621l-1.296 1.26a13.364 13.364 0 0 1 2.945 3.372l.26.393a.827.827 0 0 1 0 .963ZM12.6 7.011a3.416 3.416 0 0 0-.502-1.774l-1.37 1.331c.09.295.097.608.02.906-.078.298-.237.57-.46.788a1.768 1.768 0 0 1-.81.447c-.307.076-.629.07-.932-.018l-1.37 1.332a3.67 3.67 0 0 0 3.613.029 3.544 3.544 0 0 0 1.328-1.283 3.42 3.42 0 0 0 .483-1.758Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgCrossedEye;
