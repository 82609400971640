import { isError } from '@mobx-form-state/core/utils/errors';
import { injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { Subject, delay, map, merge } from 'rxjs';

import { Errors } from 'core/app/errors';

import { Disposable } from 'utils/disposable';

import { Snackbar } from './snackbar';

@injectable()
export class SnackbarStore extends Disposable {
  @observable
  snackbarMap = new Map<Snackbar.ID, Snackbar.WithTimestamp<Snackbar.OneOfSnackbar>>();

  private snackbars$ = new Subject<Snackbar.WithTimestamp<Snackbar.OneOfSnackbar>>();

  private success$ = new Subject<Snackbar.OneOfSnackbar>();

  private failed$ = new Subject<Snackbar.OneOfSnackbar>();

  constructor() {
    super();

    makeObservable(this);

    const closeSuccess$ = this.success$.pipe(
      delay(Snackbar.SuccessCloseDelay),
      map((item) => item.id)
    );

    const closeFailed$ = this.failed$.pipe(
      delay(Snackbar.FailedCloseDelay),
      map((item) => item.id)
    );

    this.autoDispose(merge(closeSuccess$, closeFailed$).subscribe(this.removeSnackbar));
    this.autoDispose(merge(this.success$, this.failed$).subscribe(this.addSnackbar));

    this.autoDispose(this.snackbars$.subscribe(this.pushSnackbar));
  }

  @computed
  get snackbars(): Snackbar.OneOfSnackbar[] {
    return Array.from(this.snackbarMap.values()).sort((a, b) => a.timestamp - b.timestamp);
  }

  @action
  removeSnackbar = (id: Snackbar.ID): void => {
    this.snackbarMap.delete(id);
  };

  @action
  updateSnackbar = (item: Snackbar.OneOfSnackbar): void => {
    const snackbar = this.snackbarMap.get(item.id);

    if (snackbar) {
      this.snackbarMap.set(item.id, { ...snackbar, ...item });
    }
  };

  @action
  private pushSnackbar = (item: Snackbar.WithTimestamp<Snackbar.OneOfSnackbar>): void => {
    this.snackbarMap.set(item.id, item);
  };

  addSnackbar = (item: Snackbar.OneOfSnackbar): void => {
    this.snackbars$.next({ ...item, timestamp: Date.now() });
  };

  openSuccess = (message: string): void => {
    this.success$.next({
      id: 'success' + Snackbar.getRandomId(),
      type: Snackbar.Type.Success,
      message,
    });
  };

  openFailed = (message: string, error?: unknown): void => {
    let description: string | undefined;

    if (isError(error)) {
      if (Errors.isUserRejectedAction(error)) {
        description = 'Transaction rejected';
      } else if (Errors.isInsufficientGasError(error) || Errors.isSecondInsufficientGasError(error)) {
        description = 'Not enough gas';
      } else if (Errors.isNoLiquidityPoolError(error)) {
        description = 'A token within the vault has no liquidity pool. Please contact support on discord.';
      } else if (Errors.isVaultNotInSync(error)) {
        description =
          'Your vault is out of sync with other chains. Please try again later or make a sync call manually.';
      } else if (Errors.isAlreadyLeviedThisPeriod(error)) {
        description = 'You have already levied fees in this period. Please try again later.';
      } else if (Errors.isVaultClosed(error)) {
        description = 'This vault is closed. You cannot deposit more funds.';
      } else if (Errors.isNotDepositAsset(error)) {
        description = 'This vault does not support this asset. Please deposit a supported asset.';
      } else if (Errors.isManagerAlreadyOwnsHoldings(error)) {
        description = 'Something went wrong. Please try again later.';
      } else if (Errors.isHoldsHardDeprecatedAsset(error)) {
        description = 'This vault holds deprecated assets. You cannot deposit in this vault for now.';
      } else if (Errors.isVaultWillExceedCustomCap(error) || Errors.isVaultWillExceedCap(error)) {
        description = 'This deposit will exceed the vaults cap. Please deposit less funds.';
      } else if (Errors.isMinDepositNotMet(error)) {
        description = 'The minimum deposit amount is not met. Please deposit more funds.';
      } else if (Errors.isExceedsMaxDeposit(error)) {
        description = 'This deposit exceeds the individual maximum deposit amount. Please deposit less funds.';
      } else if (Errors.hasReason(error)) {
        description = error.reason;
      } else if (Errors.isGQLError(error)) {
        description = error.response.errors[0].message;
      } else {
        description = error.message;
      }
    }

    if (typeof error === 'string') {
      description = error;
    }

    this.failed$.next({
      id: 'failed' + Snackbar.getRandomId(),
      type: Snackbar.Type.Failed,
      message,
      description,
    });
  };
}
