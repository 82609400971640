/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';

export type AssetFragment = {
  __typename?: 'AssetType';
  id: string;
  name: string;
  address: string;
  symbol: string;
  decimals?: number | null;
  price?: BigNumber | null;
  chain: string;
  chainId: number;
  priceChangeAmount?: BigNumber | null;
  priceChangePercentage?: BigNumber | null;
  marketCap?: string | null;
  totalSupply?: string | null;
  type: Types.AssetTypeEnum;
  deprecated: boolean;
  hardDeprecated: boolean;
  createdAt: string;
};

export const AssetFragmentDoc = gql`
  fragment Asset on AssetType {
    id
    name
    address
    symbol
    decimals
    price
    chain
    chainId
    priceChangeAmount
    priceChangePercentage
    marketCap
    totalSupply
    type
    deprecated
    hardDeprecated
    createdAt
  }
`;
