import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgMoon = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.66 7.978a.49.49 0 0 0-.412.09 5.247 5.247 0 0 1-1.594.934 4.97 4.97 0 0 1-1.808.324A5.253 5.253 0 0 1 6.123 7.78a5.298 5.298 0 0 1-1.54-3.74c0-.61.107-1.204.286-1.743A4.833 4.833 0 0 1 5.73.75a.46.46 0 0 0-.072-.647c-.125-.09-.268-.126-.412-.09A7.223 7.223 0 0 0 1.45 2.567 7.11 7.11 0 0 0 0 6.88c0 1.96.788 3.74 2.077 5.034A7.017 7.017 0 0 0 7.089 14a7.092 7.092 0 0 0 6.892-5.465.436.436 0 0 0-.322-.557Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgMoon;
