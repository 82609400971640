/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';

export type VaultStatsFragment = {
  __typename?: 'VaultStatsType';
  aum: BigNumber;
  shareMinPrice: BigNumber;
  shareMaxPrice: BigNumber;
  depositors: BigNumber;
  newDeposits: number;
  roiDay?: BigNumber | null;
  roiWeek?: BigNumber | null;
  roiMonth?: BigNumber | null;
  roiTotal?: BigNumber | null;
};

export const VaultStatsFragmentDoc = gql`
  fragment VaultStats on VaultStatsType {
    aum
    shareMinPrice
    shareMaxPrice
    depositors
    newDeposits
    roiDay: roi(dateRange: ONE_DAY)
    roiWeek: roi(dateRange: ONE_WEEK)
    roiMonth: roi(dateRange: ONE_MONTH)
    roiTotal: roi(dateRange: ALL)
  }
`;
