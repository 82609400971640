import BigNumber from 'bignumber.js';
import { BigNumberish } from 'ethers';

import type { Units } from 'types';

export namespace Decimal {
  export enum Precision {
    GMX = 30,
    WEI = 18,
    USD10 = 10,
    USD = 8,
  }

  export const parse = (value?: string | BigNumberish, precision: Precision = Precision.USD): BigNumber => {
    return value ? precise(new BigNumber(value.toString()), precision) : empty();
  };

  export const precise = (value?: BigNumber | null, precision: Precision = Precision.USD): BigNumber => {
    return value?.div(10 ** precision) || empty();
  };

  export const empty = (): BigNumber => {
    return new BigNumber('');
  };

  export const format = (value: BigNumber, precision: Precision = Precision.USD): Units => {
    return value
      .multipliedBy(10 ** precision)
      .decimalPlaces(0)
      .toString() as Units;
  };

  export const fromBN = (value: BigNumberish): BigNumber => {
    return new BigNumber(value.toString());
  };
}
