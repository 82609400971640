import BigNumber from 'bignumber.js';
import { BigNumberish } from 'ethers';
import { injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { AssetsStore } from 'core/asset/assets.store';
import { ChainService } from 'core/chain/chain.service';
import { Debank } from 'core/debank/debank';
import { LocalStorageService } from 'core/local-storage/local-storage.service';
import { NetworkStore } from 'core/network/network.store';

import { Disposable } from 'utils/disposable';

import { GasPrice } from './gas-price';

@injectable()
export class GasPriceStore extends Disposable {
  @observable
  selectedGasPrice: GasPrice.Labels;

  @observable.ref
  gasPriceList: GasPrice.Type[] = [];

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly chainService: ChainService,
    private readonly networkStore: NetworkStore,
    private readonly assetsStore: AssetsStore
  ) {
    super();

    makeObservable(this);

    this.selectedGasPrice = this.localStorageService.getGasPrice() || GasPrice.Labels.Recommend;

    this.autoDispose(this.chainService.chain$.subscribe(this.loadData));
  }

  @computed
  get gasPrice(): BigNumberish | null {
    const item = this.gasPriceList.find(({ name }) => name === this.selectedGasPrice);

    if (item) return item.price;

    return null;
  }

  @action
  selectGasPrice = (type: GasPrice.Labels): void => {
    this.localStorageService.setGasPrice(type);
    this.selectedGasPrice = type;
  };

  @action
  private updateGasPrices = (items: GasPrice.Type[]): void => {
    this.gasPriceList = items;
  };

  calculateGas = (estimatedGas: BigNumberish | BigNumber, chainId = this.chainService.chain.chainId): BigNumber => {
    const nativeAsset = this.assetsStore.getNativeAsset(chainId);

    return nativeAsset
      .toBigNumber(estimatedGas.toString())
      .multipliedBy(this.networkStore.getGasPrice(chainId))
      .multipliedBy(1 + GasPrice.GAS_PRICE_BUFFER_PERCENTAGE / 100);
  };

  calculateGasUSD = (estimatedGas: BigNumberish | BigNumber, chainId = this.chainService.chain.chainId): BigNumber => {
    const nativeAsset = this.assetsStore.getNativeAsset(chainId);

    return nativeAsset.toUSD(this.calculateGas(estimatedGas, chainId));
  };

  private loadData = async (): Promise<void> => {
    try {
      const chainId = this.chainService.chain.chainId;

      const result = await Debank.fetchData(chainId);

      const data = GasPrice.dataToGasPrice(result);

      this.updateGasPrices(data);
    } catch (error) {
      this.selectGasPrice(GasPrice.Labels.Recommend);
    }
  };
}
