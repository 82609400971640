import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgSuccessful = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="successful_svg__a" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 21C16.299 21 21 16.299 21 10.5S16.299 0 10.5 0 0 4.701 0 10.5 4.701 21 10.5 21Zm-2.15-7.866a1.25 1.25 0 0 0 1.757-.003l4.23-4.192a.918.918 0 1 0-1.292-1.304l-2.94 2.913a1.25 1.25 0 0 1-1.754.005l-.395-.386a.919.919 0 0 0-1.288 1.312l1.682 1.655Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 21C16.299 21 21 16.299 21 10.5S16.299 0 10.5 0 0 4.701 0 10.5 4.701 21 10.5 21Zm-2.15-7.866a1.25 1.25 0 0 0 1.757-.003l4.23-4.192a.918.918 0 1 0-1.292-1.304l-2.94 2.913a1.25 1.25 0 0 1-1.754.005l-.395-.386a.919.919 0 0 0-1.288 1.312l1.682 1.655Z"
      fill="#00C696"
    />
    <path
      d="m7.956 10.167-.7.715.7-.715Zm5.088-2.532-.704-.71.704.71Zm-2.937 5.496-.704-.71.704.71Zm-1.757.003-.701.713.701-.713Zm5.986-4.195-.704-.71.704.71ZM20 10.5a9.5 9.5 0 0 1-9.5 9.5v2C16.851 22 22 16.851 22 10.5h-2ZM10.5 1a9.5 9.5 0 0 1 9.5 9.5h2C22 4.149 16.851-1 10.5-1v2ZM1 10.5A9.5 9.5 0 0 1 10.5 1v-2C4.149-1-1 4.149-1 10.5h2Zm9.5 9.5A9.5 9.5 0 0 1 1 10.5h-2C-1 16.851 4.149 22 10.5 22v-2Zm3.132-11.771L9.403 12.42l1.408 1.42 4.23-4.192-1.409-1.42Zm-2.823 3.03 2.939-2.914-1.408-1.42L9.4 9.838l1.408 1.42Zm-3.552-.377.394.386 1.4-1.43-.395-.385-1.4 1.429Zm1.795 1.54L7.37 10.765l-1.403 1.425 1.682 1.656 1.403-1.426ZM5.974 9.448a1.919 1.919 0 0 0-.007 2.742l1.403-1.425a.081.081 0 0 1 0 .116L5.974 9.449Zm2.682.004a1.919 1.919 0 0 0-2.682-.004l1.396 1.433a.081.081 0 0 1-.113 0l1.399-1.43Zm.745.385a.25.25 0 0 1-.35 0l-1.4 1.43a2.25 2.25 0 0 0 3.158-.01l-1.408-1.42Zm5.627-2.925a1.918 1.918 0 0 0-2.688.012l1.408 1.42a.082.082 0 0 1-.115 0l1.395-1.432ZM9.403 12.42a.25.25 0 0 1-.351 0l-1.403 1.426a2.25 2.25 0 0 0 3.162-.006l-1.408-1.42Zm5.637-2.772a1.918 1.918 0 0 0-.012-2.736l-1.395 1.433a.082.082 0 0 1 0-.117l1.407 1.42Z"
      fill="#fff"
      fillOpacity={0.35}
      mask="url(#successful_svg__a)"
    />
  </svg>
));
export default SvgSuccessful;
