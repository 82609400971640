import { ComponentPropsWithoutRef } from 'react';
import { twMerge } from 'tailwind-merge';

type DividerProps = ComponentPropsWithoutRef<'hr'> & {
  vertical?: boolean;
};

export const Divider = ({ vertical, className, ...props }: DividerProps) => (
  <hr
    className={twMerge(
      'divider-root block bg-outline m-0 p-0 border-0 h-px',
      vertical && 'inline-block min-w-px h-auto',
      className
    )}
    {...props}
  />
);
