import { injectable } from 'inversify';
import { OneOfTheme } from 'styles/themes';
import { themeFromHost } from 'styles/themes/theme.map';
import { z } from 'zod';

import { ChainID } from 'core/chain';
import { Valio } from 'core/valio';

import { configSchema } from './config.schema';

@injectable()
export class ConfigService {
  private config: z.output<typeof configSchema>;

  constructor() {
    this.config = configSchema.parse(process.env);
  }

  get isSeasonOne(): boolean {
    return this.config.REACT_APP_SEASON_ONE;
  }

  get isSeasonZero(): boolean {
    return this.config.REACT_APP_SEASON_ZERO;
  }

  get graphqlUrl(): string {
    return this.config.REACT_APP_GRAPHQL_URL;
  }

  get graphqlWSUrl(): string {
    return this.config.REACT_APP_GRAPHQL_WS_URL;
  }

  get wcProjectId(): string {
    return this.config.REACT_APP_WC_PROJECT_ID;
  }

  get zeroXApiKey(): string {
    return this.config.REACT_APP_ZERO_X_API_KEY;
  }

  get s3Bucket(): string {
    return this.config.REACT_APP_S3_BUCKET;
  }

  get onrampApiKey(): string {
    return this.config.REACT_APP_ONRAMPER_API_KEY;
  }

  get alchemyApiKey(): Record<ChainID, string> {
    return {
      [ChainID.Ethereum]: this.config.REACT_APP_ALCHEMY_API_KEY_ETHEREUM,
      [ChainID.Arbitrum]: this.config.REACT_APP_ALCHEMY_API_KEY_ARBITRUM,
      [ChainID.Optimism]: this.config.REACT_APP_ALCHEMY_API_KEY_OPTIMISM,
    };
  }

  get valio(): Valio.ContractAddresses {
    return {
      [ChainID.Ethereum]: {
        Registry: this.config.REACT_APP_VALIO_REGISTRY_ETHEREUM,
        Transport: this.config.REACT_APP_VALIO_TRANSPORT_ETHEREUM,
      },
      [ChainID.Arbitrum]: {
        Registry: this.config.REACT_APP_VALIO_REGISTRY_ARBITRUM,
        Transport: this.config.REACT_APP_VALIO_TRANSPORT_ARBITRUM,
      },
      [ChainID.Optimism]: {
        Registry: this.config.REACT_APP_VALIO_REGISTRY_OPTIMISM,
        Transport: this.config.REACT_APP_VALIO_TRANSPORT_OPTIMISM,
      },
    };
  }

  get socketSwapApiKey(): string {
    return this.config.REACT_APP_SOCKET_API_KEY;
  }

  get isStageOrTestEnv(): boolean {
    return this.isTestEnv || this.isStageEnv;
  }

  get isTestEnv(): boolean {
    return this.config.REACT_APP_ENV === 'test';
  }

  get isStageEnv(): boolean {
    return this.config.REACT_APP_ENV === 'stage';
  }

  get amplitudeApiKey(): string {
    return this.config.REACT_APP_AMPLITUDE_API_KEY;
  }

  get privyAppId(): string {
    return this.config.REACT_APP_PRIVY_APP_ID;
  }

  get theme(): OneOfTheme {
    return themeFromHost(window.location.hostname);
  }
}
