import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgGridPlus = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 656.49 290" width="1em" height="1em" {...props}>
    <path
      fill="#1F4DFF"
      d="M590.89 72.05h-32.41V40.17H531.4v31.88h-32.41v25.53h32.41v31.88h27.08V97.58h32.41V72.05zm-421.3 116.08c-6.7 3.24-15.62 5.47-26.37 5.47-27 0-48.87-19.47-48.87-47.86s20.88-47.05 49.07-47.05c19.68 0 31.64 7.3 35.29 9.73l12.37-22.31c-5.27-3.44-20.28-13.58-48.87-13.58-45 0-76.87 32-76.87 73.21s31.84 73 76.26 73c29.81 0 46.65-10.75 54.35-16.63v-56.17h-26.36ZM336.4 125c0-32.76-23.8-53-59.48-53h-52.39v146.75h28.8V176.8h25.26l28.59 41.95h34l-33.6-48.42c18.17-8.56 28.82-24.42 28.82-45.33Zm-62 26.51h-21.07V97.29h21.29c19 0 31.93 10.22 31.93 27.75 0 16.07-12.73 26.51-32.14 26.51Zm91.95-79.48h28.8v146.72h-28.8z"
    />
    <path
      fill="#1F4DFF"
      d="M558.33 146.62v-4.41h-26.86v4.41s-.29 10.68-.76 14.2c-2.75 20.52-22.23 32.47-47.11 32.47h-26.51v-95.8h26.65V72h-55.45v146.75h56.56c40.64 0 70.27-22.21 73.48-57.93.32-3.53 0-14.2 0-14.2Z"
    />
  </svg>
));
export default SvgGridPlus;
