import { useService } from '@redtea/react-inversify';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { createPortal } from 'react-dom';

import { Transactions } from 'core/snackbars/transactions/transactions';

import { CloseIcon } from 'components/icons';
import { getPortalElement } from 'components/popover/use-portal-element.hook';

import { DepositSnackbar } from './depoposit/deposit';
import { Snackbar } from './snackbar';
import { SnackbarItem } from './snackbar-item';
import { SnackbarStore } from './snackbar.store';
import { WithdrawSnackbar } from './withdraw/withdraw';

export const Snackbars = observer(() => {
  const { snackbars, removeSnackbar } = useService(SnackbarStore);

  return createPortal(
    <div
      className="fixed flex justify-center items-center flex-col-reverse top-0 right-5 z-[999]"
      data-testid="snackbar"
    >
      <AnimatePresence>
        {snackbars.map((item, key) => (
          <motion.div
            className="w-[312px] min-h-[63px] flex relative flex-col justify-center px-[15px] py-[10px] box-border rounded-[10px] backdrop-filter backdrop-blur-[2px] mb-[12px] border border-outline bg-background/70"
            variants={itemtAnimation}
            transition={{ duration: 0.5 }}
            exit="hide"
            animate="show"
            initial="hide"
            key={key}
          >
            {Snackbar.isTransaction(item) && <Transactions data={item} />}
            {(Snackbar.isFailed(item) || Snackbar.isSuccess(item)) && <SnackbarItem data={item} />}
            {Snackbar.isDeposit(item) && <DepositSnackbar data={item} />}
            {Snackbar.isWithdraw(item) && <WithdrawSnackbar data={item} />}

            <div className="absolute right-[10px] top-[10px] cursor-pointer">
              <CloseIcon onClick={() => removeSnackbar(item.id)} />
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>,
    getPortalElement('snackbar')
  );
});

const itemtAnimation: Variants = {
  show: {
    y: 20,
    opacity: 1,
  },
  hide: {
    y: 0,
    opacity: 0,
  },
};
