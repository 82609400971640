'use client';

import { useService } from '@redtea/react-inversify';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { createPortal } from 'react-dom';

import { CloseIcon } from 'components/icons';

import { Collection } from 'utils/collection';
import { isServer } from 'utils/is-server';

import { Modal, ModalsStore } from './modals.store';
import { usePortalElement } from './use-portal-element';

export const Modals = () => {
  if (isServer) return null;

  return <ModalsContainer />;
};

const ModalsContainer = observer(() => {
  const { items, handleClose } = useService(ModalsStore);

  const portalEl = usePortalElement('modal');

  return createPortal(
    <AnimatePresence>
      {items.map((item) => {
        const { component: ModalComponent, props } = item;

        const content = <ModalComponent {...props} />;

        return (
          <div
            className="absolute pointer-events-none z-10 flex justify-center items-center top-0 right-0 bottom-0 left-0"
            key={item.idx}
          >
            {item.meta?.unwrapped ? (
              content
            ) : (
              <ModalContainer item={item} show={item === Collection.takeLast(items)} handleClose={handleClose}>
                {content}
              </ModalContainer>
            )}
          </div>
        );
      })}
    </AnimatePresence>,
    portalEl
  );
});

type ModalContainerProps = {
  item: Modal;
  show?: boolean;
  handleClose: () => void;
  children: React.ReactNode;
};

const ModalContainer = observer((props: ModalContainerProps) => (
  <motion.div
    className="[box-shadow:0px_50px_60px_rgba(0,_0,_0,_0.5)] rounded-[20px] relative min-w-[100px] min-h-[100px] border border-outline bg-background pointer-events-auto"
    variants={contentAnimation}
    transition={{ duration: 0.3 }}
    exit="hide"
    animate={props.show ? 'show' : 'hide'}
    initial="hide"
  >
    {!props.item.meta?.permanent && (
      <CloseIcon
        className="absolute cursor-pointer text-[20px] top-[33px] right-[25px] hover:text-primary z-50"
        data-testid="modals-close-icon"
        onClick={props.handleClose}
      />
    )}
    {props.children}
  </motion.div>
));

const contentAnimation: Variants = {
  show: {
    y: -5,
    opacity: 1,
  },
  hide: {
    y: 0,
    opacity: 0,
  },
};
