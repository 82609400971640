import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgUpDownArrow = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.305 7.75H13.4c.501 0 .781.594.472.981l-3.156 4.016a.592.592 0 0 1-.943 0L6.617 8.731c-.31-.401-.03-.982.471-.982h2.094V.61c0-.341.28-.609.605-.609h.914a.61.61 0 0 1 .604.61v7.14ZM7.383 4.269c.31.401.03.982-.471.982H4.818v7.14a.61.61 0 0 1-.604.609H3.3a.61.61 0 0 1-.605-.61V5.25H.601c-.5 0-.781-.58-.471-.981L3.285.253a.592.592 0 0 1 .943 0l3.155 4.016Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgUpDownArrow;
