import { useService } from '@redtea/react-inversify';
import { PanInfo, motion } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import { OneOfTheme } from 'styles/themes';
import { twMerge } from 'tailwind-merge';

import { ConfigService } from 'core/config/config.service';

import { ChevronCarouselIcon, ChevronCaruselBlueIcon } from 'components/icons';

type ComponentCarouselProps = {
  slides: FC[];
  navigation?: boolean;
  autoScroll?: boolean;
  className?: string;
};

enum Direction {
  Forward,
  Backward,
}

export const ComponentCarousel = (props: ComponentCarouselProps) => {
  const { slides, navigation, autoScroll, className } = props;
  const { theme } = useService(ConfigService);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(0);

  useEffect(() => {
    if (autoScroll) {
      const interval = setInterval(() => {
        const newIndex = (currentIndex + 1) % slides.length;
        paginate(newIndex);
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [currentIndex, slides, autoScroll]);

  const paginate = (value: number) => {
    setCurrentIndex(value);
    setCurrentPosition(value * 100);
  };

  const navigate = (direction: Direction) => {
    const newIndex =
      direction === Direction.Forward
        ? (currentIndex + 1) % slides.length
        : (currentIndex - 1 + slides.length) % slides.length;
    paginate(newIndex);
  };

  const dragEndHandler = (event: MouseEvent | TouchEvent, info: PanInfo) => {
    const newIndex = info.offset.x > 0 ? currentIndex - 1 : currentIndex + 1;
    const maxIndex = slides.length - 1;

    const nextIndex = newIndex < 0 ? maxIndex : newIndex > maxIndex ? 0 : newIndex;

    paginate(nextIndex);
  };

  return (
    <div className={twMerge('flex relative h-full overflow-hidden', className)}>
      {slides.map((Component, index) => (
        <motion.div
          className="relative min-w-full h-full flex items-center overflow-hidden z-0"
          animate={{ x: `-${currentPosition}%` }}
          onDragEnd={dragEndHandler}
          drag="x"
          transition={{ duration: 0.5 }}
          key={index}
        >
          {<Component />}
        </motion.div>
      ))}

      <div className="flex items-center justify-center gap-2.5 absolute left-2/4 -translate-x-1/2 bottom-[40px] transition-all duration-500 ease-in">
        {slides.length > 1 &&
          slides.map((_, index) => (
            <button
              key={index}
              onClick={() => paginate(index)}
              className={twMerge(
                'relative w-[50px] bg-icon-secondary h-[5px] transition-all duration-500 ease-linear rounded-[3px] cursor-pointer after:-inset-5 after:absolute before:-inset-5 before:absolute',
                currentIndex === index && 'bg-[#f7faff] bg-opacity-100 shadow-brand'
              )}
            />
          ))}
      </div>

      {navigation && (
        <>
          <button
            className="chevron-left absolute flex items-center justify-start z-[2] inset-y-0 right-0 cursor-pointer w-[43px]"
            onClick={() => navigate(Direction.Forward)}
          >
            <div className="rotate-180">{getChevronByTheme(theme)}</div>
          </button>
          <button
            className="chevron-right absolute flex items-center justify-start z-[2] inset-y-0 left-0 cursor-pointer w-[43px]"
            onClick={() => navigate(Direction.Forward)}
          >
            <div className="">{getChevronByTheme(theme)}</div>
          </button>
        </>
      )}
    </div>
  );
};

const getChevronByTheme = (theme: OneOfTheme) => {
  switch (theme) {
    case 'anodos':
      return <ChevronCaruselBlueIcon className="w-[36px] h-[49px]" />;

    default:
      return <ChevronCarouselIcon className="w-[36px] h-[49px]" />;
  }
};
