import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import { Subject, combineLatest, distinctUntilChanged, filter, fromEvent, map, merge, share, startWith } from 'rxjs';

import { ModalsStore } from 'lib/modals/modals.store';
import { WidgetsStore } from 'lib/widgets/widgets.store';

import { Disposable } from 'utils/disposable';
import { isSomething } from 'utils/is-something';

@injectable()
export class BackdropStore extends Disposable {
  @observable
  visible = false;

  private requestClose$ = new Subject<void>();

  constructor(private readonly modalsStore: ModalsStore, private readonly widgetsStore: WidgetsStore) {
    super();
    makeObservable(this);

    const showBackdrop$ = combineLatest([
      this.modalsStore.openModal$.pipe(startWith(false)),
      this.widgetsStore.widget$.pipe(startWith(false)),
    ]).pipe(
      filter((states) => states.some((open) => isSomething(open))),
      map(() => true)
    );

    const hideBackdrop$ = combineLatest([
      this.modalsStore.closeModal$.pipe(startWith(false)).pipe(map(() => this.modalsStore.active)),
      this.widgetsStore.widget$.pipe(startWith(false)).pipe(map(() => this.widgetsStore.activeWidget?.backdrop)),
    ]).pipe(
      filter((states) => states.every((open) => !open)),
      map(() => false)
    );

    const visibility$ = merge(showBackdrop$, hideBackdrop$).pipe(distinctUntilChanged(), share());

    const onEscape$ = fromEvent<KeyboardEvent>(document, 'keydown').pipe(
      filter(({ code }) => code === 'Escape'),
      map(() => void 0)
    );

    this.autoDispose(merge(this.requestClose$, onEscape$).subscribe(this.handleRequestClose));
    this.autoDispose(visibility$.subscribe(this.toggleStyles));
    this.autoDispose(visibility$.subscribe(this.updateVisibility));
  }

  @action
  private updateVisibility = (value: boolean): void => {
    this.visible = value;
  };

  onClick = (): void => {
    this.requestClose$.next();
  };

  private toggleStyles = (visible: boolean): void => {
    if (visible) {
      window.scrollTo(0, 0);
      document.body.style.setProperty('overflow', 'hidden');
    } else {
      document.body.style.removeProperty('overflow');
    }
  };

  private handleRequestClose = (): void => {
    if (this.modalsStore.active && !this.modalsStore.hasPermanent) {
      return this.modalsStore.handleClose();
    }

    if (this.widgetsStore.activeWidget) {
      return this.widgetsStore.closeWidget();
    }
  };
}
