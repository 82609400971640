import { captureException } from '@sentry/core';
import { useRouteError } from 'react-router';
import { useTheme } from 'styles/themes/use-theme.hook';

import { Errors } from 'core/app/errors';
import { logger } from 'core/logger';

import { Button } from 'components/button';
import { NotFound } from 'components/not-found';
import { H1, H3 } from 'components/typography';

export const ErrorPage = () => {
  const { errorPage } = useTheme();
  const error = useRouteError();

  if (Errors.isNotFoundError(error)) {
    return <NotFound />;
  }

  if (Errors.isChunkLoadError(error)) {
    window.location.reload();
  } else {
    logger.error(error);
    captureException(error);
  }

  return (
    <div className="flex justify-center items-center flex-col h-full flex-1 gap-3">
      {errorPage?.icon}

      <div className="flex items-center flex-col">
        <H1 className="mt-3">{errorPage?.title}</H1>
        <H3>{errorPage?.subtitle}</H3>
        <div className="flex mt-5">
          <a target="_blank" href={errorPage?.contactUrl} rel="noreferrer">
            <Button startIcon={errorPage?.contactIcon}>{errorPage?.contactText}</Button>
          </a>
        </div>
      </div>
    </div>
  );
};
