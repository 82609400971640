import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgOptimism = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 .655a9 9 0 1 1 0 18 9 9 0 0 1 0-18ZM7.208 7.184c-1.275 0-2.065.619-2.371 1.857-.108.455-.192.845-.25 1.169a2.431 2.431 0 0 0-.034.365c0 .473.169.837.507 1.094.342.252.78.378 1.317.378.648 0 1.166-.15 1.553-.452.388-.306.66-.768.818-1.385.094-.378.175-.768.243-1.169a2.4 2.4 0 0 0 .034-.358c0-.315-.08-.585-.237-.81-.157-.23-.373-.401-.648-.514a2.357 2.357 0 0 0-.932-.175Zm4.924.067h-1.797a.206.206 0 0 0-.129.048.193.193 0 0 0-.074.121L9.2 11.81a.161.161 0 0 0 .02.122.143.143 0 0 0 .115.048h.919a.206.206 0 0 0 .128-.048.193.193 0 0 0 .074-.121l.311-1.48h.912c.59 0 1.054-.123 1.391-.37.343-.249.57-.631.683-1.15.027-.12.04-.238.04-.35 0-.392-.153-.692-.46-.899-.3-.207-.702-.31-1.201-.31Zm-5.012.905c.428 0 .642.205.642.615 0 .1-.01.196-.027.29-.054.316-.133.685-.237 1.108-.076.307-.205.534-.385.683a.96.96 0 0 1-.642.223c-.432 0-.648-.207-.648-.622 0-.099.009-.194.027-.284.081-.436.162-.806.243-1.107.077-.307.203-.534.378-.683a.986.986 0 0 1 .649-.223Zm4.897.034c.184 0 .315.036.391.108.077.068.115.167.115.298 0 .058-.006.126-.02.202a.73.73 0 0 1-.284.446.805.805 0 0 1-.493.155h-.777l.257-1.209h.81Z"
      fill="#FF0420"
    />
  </svg>
));
export default SvgOptimism;
