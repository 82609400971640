import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgSettings = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.708 3.365h1.938a2.64 2.64 0 0 0 5.097 0h8.549a.708.708 0 0 0 0-1.417h-8.55a2.64 2.64 0 0 0-5.096 0H.708a.708.708 0 0 0 0 1.417Zm4.486-1.948a1.24 1.24 0 1 1 0 2.479 1.24 1.24 0 0 1 0-2.48Zm11.098 6.375h-1.938a2.64 2.64 0 0 0-5.096 0H.708a.708.708 0 1 0 0 1.416h8.55a2.64 2.64 0 0 0 5.096 0h1.938a.708.708 0 0 0 0-1.416ZM11.806 9.74a1.24 1.24 0 1 1 0-2.48 1.24 1.24 0 0 1 0 2.48Zm-4.063 3.895h8.549a.708.708 0 0 1 0 1.417h-8.55a2.64 2.64 0 0 1-5.096 0H.708a.708.708 0 1 1 0-1.417h1.938a2.64 2.64 0 0 1 5.097 0Zm-3.237 1.74a1.24 1.24 0 1 0 1.377-2.062 1.24 1.24 0 0 0-1.377 2.061Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgSettings;
