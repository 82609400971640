import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgBriefcase = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#briefcase_svg__a)">
      <path
        d="M11.875 2.5h-.688A3.131 3.131 0 0 0 8.126 0h-1.25a3.13 3.13 0 0 0-3.063 2.5h-.687A3.129 3.129 0 0 0 0 5.625V7.5h15V5.625A3.129 3.129 0 0 0 11.875 2.5Zm-6.76 0a1.875 1.875 0 0 1 1.76-1.25h1.25a1.875 1.875 0 0 1 1.76 1.25h-4.77ZM8.125 9.375a.625.625 0 0 1-1.25 0V8.75H0v3.125A3.129 3.129 0 0 0 3.125 15h8.75A3.129 3.129 0 0 0 15 11.875V8.75H8.125v.625Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="briefcase_svg__a">
        <path d="M0 0h15v15H0z" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
));
export default SvgBriefcase;
