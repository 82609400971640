import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

type AssetLike = {
  Icon: FC;
  chain: {
    Icon: FC;
  };
};

export type AssetIconProps = {
  asset: AssetLike;
  size?: AssetIconSize;
  chain?: boolean;
};

export enum AssetIconSize {
  Small = 'small', // 23px
  Medium = 'medium', // 29px @default
  Big = 'big', // 40px
}

export const AssetIcon = (props: AssetIconProps) => {
  const { asset, chain, size = AssetIconSize.Medium } = props;

  return (
    <div
      className={twMerge(
        'relative flex justify-center items-center box-border bg-black border border-outline rounded-full',
        '[&_img]:bg-transparent [&_img]:p-0',
        size === AssetIconSize.Small && 'h-[23px] w-[23px] [&_img]:w-4 [&_img]:h-4',
        size === AssetIconSize.Medium && 'h-[29px] w-[29px] [&_img]:w-5 [&_img]:h-5',
        size === AssetIconSize.Big && 'h-10 w-10 [&_img]:w-[31px] [&_img]:h-[31px]'
      )}
    >
      <asset.Icon />

      {chain && (
        <div
          className={twMerge(
            'absolute flex justify-center items-center bg-background border border-outline rounded-full right-[-3px] bottom-[-3px]',

            size === AssetIconSize.Small && 'h-4 w-4',
            size === AssetIconSize.Medium && 'h-4 w-4 right-[-6px] bottom-[-3px]',
            size === AssetIconSize.Big && 'h-5 w-5'
          )}
        >
          <asset.chain.Icon />
        </div>
      )}
    </div>
  );
};
