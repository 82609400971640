/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { AccountFragmentDoc } from '../account/account.fragment.generated';
import { AssetFragmentDoc } from '../asset/asset.fragment.generated';
import { VaultChainFragmentDoc } from '../chain/vault-chain.fragment.generated';
import { DepositFragmentDoc } from '../deposit/deposit.fragment.generated';
import { VaultStatsFragmentDoc } from '../stats/vault-stats.fragment.generated';
import { VaultAssetFragmentDoc } from './vault-asset.fragment.generated';

export type VaultFragment = {
  __typename?: 'VaultType';
  uid: string;
  name: string;
  symbol: string;
  slug: string;
  shortDescription?: string | null;
  fullDescription?: string | null;
  cover?: string | null;
  bridgeLock: boolean;
  avatar?: string | null;
  managerPerformanceFee: BigNumber;
  managerStreamingFee: BigNumber;
  managerSentiment?: Types.ManagerSentiment | null;
  managerFocusTime?: Types.ManagerFocusTime | null;
  managerParticipation?: Types.ManagerParticipation | null;
  managerStrategy?: Types.ManagerStrategy | null;
  tags?: Array<Types.VaultTag> | null;
  marketCapSegment?: Array<number> | null;
  riskProfile: Types.RiskProfile;
  featured: boolean;
  closed: boolean;
  pulseUpdatedAt: string;
  createdAt: string;
  updatedAt: string;
  chains: Array<{
    __typename?: 'VaultChainType';
    chainId: number;
    name: string;
    address: string;
    isParent: boolean;
    aum: BigNumber;
  }>;
  manager: {
    __typename?: 'ManagerType';
    account: {
      __typename?: 'AccountType';
      address: string;
      chain: string;
      avatar?: string | null;
      name?: string | null;
      twitter?: string | null;
      bio?: string | null;
      createdAt: string;
      slug: string;
      referrerCode: string;
      referrerProvider?: Types.ReferrerProvider | null;
      referralCode?: string | null;
      experience: number;
      experienceSpins: number;
      rewards: Array<{
        __typename?: 'RewardType';
        rewardType?: Types.RewardEnumType | null;
        createdAt: string;
        revealedAt?: string | null;
      }>;
    };
    deposit?: {
      __typename?: 'DepositType';
      createdAt: string;
      depositor: string;
      entryPrice: BigNumber;
      pAndL: BigNumber;
      roi: BigNumber;
      shares: BigNumber;
      sharesValue: BigNumber;
      stake: BigNumber;
      depositedAmount: BigNumber;
    } | null;
  };
  assets: Array<{
    __typename?: 'VaultAssetType';
    value: BigNumber;
    amount: BigNumber;
    allocation: BigNumber;
    pAndL: BigNumber;
    roi: BigNumber;
    asset: {
      __typename?: 'AssetType';
      id: string;
      name: string;
      address: string;
      symbol: string;
      decimals?: number | null;
      price?: BigNumber | null;
      chain: string;
      chainId: number;
      priceChangeAmount?: BigNumber | null;
      priceChangePercentage?: BigNumber | null;
      marketCap?: string | null;
      totalSupply?: string | null;
      type: Types.AssetTypeEnum;
      deprecated: boolean;
      hardDeprecated: boolean;
      createdAt: string;
    };
  }>;
  assetAllocation: Array<{ __typename?: 'AssetAllocation'; name: string; allocation: BigNumber }>;
  assetWatchList: Array<{
    __typename?: 'AssetType';
    id: string;
    name: string;
    address: string;
    symbol: string;
    decimals?: number | null;
    price?: BigNumber | null;
    chain: string;
    chainId: number;
    priceChangeAmount?: BigNumber | null;
    priceChangePercentage?: BigNumber | null;
    marketCap?: string | null;
    totalSupply?: string | null;
    type: Types.AssetTypeEnum;
    deprecated: boolean;
    hardDeprecated: boolean;
    createdAt: string;
  }>;
  stats: {
    __typename?: 'VaultStatsType';
    aum: BigNumber;
    shareMinPrice: BigNumber;
    shareMaxPrice: BigNumber;
    depositors: BigNumber;
    newDeposits: number;
    roiDay?: BigNumber | null;
    roiWeek?: BigNumber | null;
    roiMonth?: BigNumber | null;
    roiTotal?: BigNumber | null;
  };
};

export const VaultFragmentDoc = gql`
  fragment Vault on VaultType {
    uid
    name
    symbol
    slug
    shortDescription
    fullDescription
    cover
    bridgeLock
    chains {
      ...VaultChain
    }
    manager {
      account {
        ...Account
      }
      deposit {
        ...Deposit
      }
    }
    avatar
    assets {
      ...VaultAsset
    }
    assetAllocation {
      name
      allocation
    }
    managerPerformanceFee
    managerStreamingFee
    managerSentiment
    managerFocusTime
    managerParticipation
    managerStrategy
    tags
    assetWatchList {
      ...Asset
    }
    marketCapSegment
    riskProfile
    stats {
      ...VaultStats
    }
    featured
    closed
    pulseUpdatedAt
    createdAt
    updatedAt
  }
  ${VaultChainFragmentDoc}
  ${AccountFragmentDoc}
  ${DepositFragmentDoc}
  ${VaultAssetFragmentDoc}
  ${AssetFragmentDoc}
  ${VaultStatsFragmentDoc}
`;
