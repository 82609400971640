import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import { injectable } from 'inversify';
import { makeObservable, observable } from 'mobx';
import { Observable, filter } from 'rxjs';
import invariant from 'tiny-invariant';

import { GraphQLClientService } from 'core/api/gql.client';
import { ChainID, MainChainID } from 'core/chain';

import { Disposable } from 'utils/disposable';
import { isSomething } from 'utils/is-something';

import { BaseNetworkProvider } from './base-network.provider';
import { createProxyProvider } from './create-proxy-provider';
import { NetworkService } from './network.service';

@injectable()
export class NetworkProvider extends Disposable implements BaseNetworkProvider {
  @observable.ref
  private proxyProvider?: Web3Provider;

  @observable.ref
  private provider?: Web3Provider;

  chain$: Observable<ChainID | undefined>;

  chainId: ChainID = MainChainID;

  constructor(
    private readonly networkService: NetworkService,
    private readonly gqlClientService: GraphQLClientService
  ) {
    super();
    makeObservable(this);

    const { provider$, chain$ } = this.networkService;

    this.chain$ = chain$;

    this.autoDispose(provider$.subscribe(this.handleProviderChange));
    this.autoDispose(chain$.pipe(filter(isSomething)).subscribe(this.handleChainChange));
  }

  get initialized(): boolean {
    return isSomething(this.provider);
  }

  getProvider = (proxy = true): Web3Provider => {
    invariant(this.proxyProvider, 'Web3Provider is not initialized');
    invariant(this.provider, 'Web3Provider is not initialized');

    return proxy ? this.proxyProvider : this.provider;
  };

  getSigner = (proxy = true): JsonRpcSigner => {
    return this.getProvider(proxy).getSigner();
  };

  disconnect = (): void => {
    this.networkService.connector.disconnect();
  };

  private handleChainChange = (chainId: ChainID) => {
    this.chainId = chainId;
  };

  private handleProviderChange = (provider?: Web3Provider): void => {
    if (!provider) {
      this.proxyProvider = undefined;
      this.provider = undefined;

      return;
    }

    this.proxyProvider = createProxyProvider(provider, this.gqlClientService);
    this.provider = provider;
  };
}
