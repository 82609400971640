import { useTheme } from 'styles/themes/use-theme.hook';

import { NetworkInfo } from './network-info';

export const Footer = () => {
  const { features, socials } = useTheme();

  return (
    <div className="fixed bottom-0 w-full flex flex-row justify-between h-[35px] max-h-[35px] backdrop-filter backdrop-blur-sm px-[14px] py-0 items-center box-border z-[6] bg-[rgba(21,_25,_36,_0.7)] border-t border-outline">
      <div className="flex items-center justify-center gap-3.5">
        {features?.footerLinks && (
          <>
            {socials?.map(({ url, icon }) => (
              <a key={url} className="cursor-pointer" target="_blank" href={url} rel="noreferrer">
                {icon}
              </a>
            ))}
          </>
        )}
      </div>

      <NetworkInfo />
    </div>
  );
};
