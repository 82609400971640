import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgDownload = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.22 9.28a.75.75 0 1 1 1.061-1.061l1.72 1.72V.75a.75.75 0 0 1 1.5 0v9.189l1.72-1.72a.75.75 0 1 1 1.061 1.061l-3 3a.748.748 0 0 1-1.06 0l-3-3H5.22ZM16.75 11a.75.75 0 0 0-.75.75v3c0 .689-.561 1.25-1.25 1.25h-12c-.689 0-1.25-.561-1.25-1.25v-3a.75.75 0 0 0-1.5 0v3a2.752 2.752 0 0 0 2.75 2.75h12a2.752 2.752 0 0 0 2.75-2.75v-3a.75.75 0 0 0-.75-.75Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgDownload;
