import { AnimatePresence, motion } from 'framer-motion';
import { ComponentPropsWithoutRef, ReactNode, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { Avatar } from 'components/avatar';
import { ChangeChip } from 'components/change-chip';
import { Primary, Secondary } from 'components/typography';

import vaultAvatarSrc from 'assets/img/default-avatar.png';

export type VaultItemProps = ComponentPropsWithoutRef<'div'> & {
  title: string;
  subtitle: ReactNode;
  change?: number;
  avatar?: string | null;
  endIcon?: boolean | ReactNode;
  collapse?: boolean;
  minify?: boolean;
};

export const VaultItem = forwardRef<HTMLDivElement, VaultItemProps>((props, ref) => {
  const { title, subtitle, change, avatar, endIcon, minify, className, ...rest } = props;

  return (
    <div
      className={twMerge(
        'flex flex-1 justify-between items-center p-[17px] box-border select-none',
        props.onClick && 'cursor-pointer',
        className
      )}
      {...rest}
      ref={ref}
    >
      <Avatar minify={minify} src={avatar || vaultAvatarSrc} />

      <AnimatePresence>
        {!minify && (
          <motion.div
            className="flex justify-between flex-1 overflow-hidden"
            transition={{ duration: 0.3, easings: ['easeOut'] }}
            variants={{
              collapse: {
                opacity: 0,
                width: 0,
              },
              expand: {
                opacity: 1,
                width: '204px',
              },
            }}
            initial="collapse"
            exit="collapse"
            animate="expand"
          >
            <div className="flex flex-col overflow-hidden pl-[7px] pr-0.5">
              <Primary className="truncate">{title}</Primary>
              <Secondary className="truncate">{subtitle}</Secondary>
            </div>
            <div className="flex items-center gap-[9px]">
              <ChangeChip value={change} />
              {endIcon && <div className="flex justify-end w-[18px]">{endIcon}</div>}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});
