import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgSynthetixTrade = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.108 2.796a.561.561 0 0 0-.44-.204H.107a.103.103 0 0 1-.077-.03.091.091 0 0 1-.03-.066v-2.4C0 .072.01.05.03.03.049.01.075 0 .107 0H3.87c.95 0 1.769.388 2.457 1.164l.915 1.116L5.46 4.452 4.108 2.796Zm6.576-1.644C11.373.384 12.196 0 13.154 0h3.751c.032 0 .055.008.072.024.015.016.023.04.023.072v2.4a.104.104 0 0 1-.023.066c-.017.02-.04.03-.072.03h-3.562a.56.56 0 0 0-.438.204L10.28 5.988l2.636 3.216a.562.562 0 0 0 .428.192h3.561c.032 0 .055.01.072.03.015.02.023.046.023.078v2.4a.104.104 0 0 1-.023.066c-.017.02-.04.03-.072.03h-3.752c-.957 0-1.776-.388-2.457-1.164L8.512 8.172l-2.185 2.664C5.64 11.612 4.815 12 3.857 12H.108c-.032 0-.056-.01-.072-.03a.123.123 0 0 1-.024-.078v-2.4c0-.024.008-.046.024-.066.016-.02.04-.03.072-.03h3.561a.584.584 0 0 0 .44-.204l2.576-3.144 4-4.896Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgSynthetixTrade;
