import { useService } from '@redtea/react-inversify';
import { observer } from 'mobx-react-lite';
import { Link, NavLink } from 'react-router-dom';

import { WalletStore } from 'core/wallet/wallet.store';

import { Chip } from 'components/chip';
import { ChevronLeftIcon, ProfileIcon, SecondLogoIcon } from 'components/icons';
import { Secondary } from 'components/typography';

import { formatShortAddress } from 'utils/web3';

export const RevealHeader = observer(() => {
  const { address } = useService(WalletStore);

  if (!address) return null;

  return (
    <div className="box-border relative flex justify-between w-screen px-5 py-3 h-14 z-1">
      <div className="flex items-center gap-5">
        <Link
          data-testid="go-back-szn1"
          className="flex items-center justify-center border rounded-lg cursor-pointer w-9 h-9 border-outline"
          to="/szn0"
        >
          <ChevronLeftIcon />
        </Link>

        <NavLink to="/">
          <SecondLogoIcon className="text-[60px]" />
        </NavLink>

        <Chip label="SZN0" />
      </div>
      <div className="flex items-center gap-2.5">
        <Secondary>{formatShortAddress(address)} </Secondary>
        <ProfileIcon />
      </div>
    </div>
  );
});
