import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgVault = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 97 99" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#vault_svg__a)">
      <path
        d="m47.56 67.969-29.697-14.62a.627.627 0 0 0-.574.026.588.588 0 0 0-.208.202.552.552 0 0 0-.08.272v4.858c-.003.343.096.68.284.973.187.293.457.53.778.686l29.281 14.442c.322.156.591.393.78.686.187.293.285.63.284.973v4.514c0 .229-.055.473.042.68.05.106.131.194.235.255.103.06.223.09.344.083.238-.011.442-.169.656-.274L78.92 67.352a1.94 1.94 0 0 0 .787-.692c.19-.295.292-.635.292-.981V53.937a.552.552 0 0 0-.081-.272.586.586 0 0 0-.208-.201.623.623 0 0 0-.574-.026l-29.774 14.53a2.036 2.036 0 0 1-1.803 0m.932-36.088c-.301 0-.598-.066-.868-.193a.023.023 0 0 0-.033.02.023.023 0 0 1-.033.021L17.863 17.067a.61.61 0 0 0-.581.014.57.57 0 0 0-.209.207.536.536 0 0 0-.073.278v4.843c0 .345.1.683.287.978.188.295.456.535.776.695l29.374 14.429c.322.157.593.397.78.693.188.295.286.635.283.98v4.844a.537.537 0 0 0 .073.277.57.57 0 0 0 .209.207.608.608 0 0 0 .581.014l29.543-14.56c.32-.156.589-.394.776-.687.188-.292.287-.628.287-.971V17.566a.538.538 0 0 0-.074-.272.57.57 0 0 0-.203-.203.607.607 0 0 0-.57-.024L49.394 31.67c-.279.137-.588.21-.902.21Zm-30.629 3.328 29.692 14.66a.015.015 0 0 0 .02-.019c-.005-.013.008-.025.02-.02a2.038 2.038 0 0 0 1.768-.017l29.759-14.501a.609.609 0 0 1 .581.014c.087.05.159.122.208.207.05.085.075.18.074.278v11.742a1.799 1.799 0 0 1-.294.974c-.19.293-.462.53-.785.685L49.348 63.74a.609.609 0 0 1-.582-.014.57.57 0 0 1-.208-.207.537.537 0 0 1-.073-.278V58.4a1.8 1.8 0 0 0-.303-1.02 1.931 1.931 0 0 0-.838-.697l-29.28-14.429a1.952 1.952 0 0 1-.78-.702 1.826 1.826 0 0 1-.284-.986v-4.858a.536.536 0 0 1 .073-.278.572.572 0 0 1 .209-.207.608.608 0 0 1 .581-.014Z"
        stroke="#F7FAFF"
        strokeWidth={2}
      />
    </g>
    <defs>
      <filter
        id="vault_svg__a"
        x={0.233}
        y={0.233}
        width={96.533}
        height={98.533}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={7.883} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1552_17812" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={4.3} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect1_dropShadow_1552_17812" result="effect2_dropShadow_1552_17812" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.433} />
        <feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.266667 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="effect2_dropShadow_1552_17812" result="effect3_dropShadow_1552_17812" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_1552_17812" result="shape" />
      </filter>
    </defs>
  </svg>
));
export default SvgVault;
