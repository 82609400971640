import { makeObservable, observable } from 'mobx';

import { Disposable } from './disposable';

export abstract class Loadable extends Disposable {
  @observable.ref
  isLoading = false;

  loadingPromise?: Promise<void>;

  constructor() {
    super();
    makeObservable(this);
  }

  readonly wait = async (): Promise<void> => {
    if (!this.loadingPromise) {
      this.loadingPromise = this.load();
    }

    await this.loadingPromise;

    delete this.loadingPromise;
  };

  protected abstract load(): Promise<void>;
}
