/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../../schema';
import { RewardFragmentDoc } from './reward.fragment.generated';

export type RevealRewardMutationVariables = Types.Exact<{ [key: string]: never }>;

export type RevealRewardMutation = {
  __typename?: 'Mutation';
  revealReward: {
    __typename?: 'RewardType';
    rewardType?: Types.RewardEnumType | null;
    createdAt: string;
    revealedAt?: string | null;
  };
};

export const RevealRewardDocument = gql`
  mutation RevealReward {
    revealReward {
      ...Reward
    }
  }
  ${RewardFragmentDoc}
`;

export function RevealReward<R extends RevealRewardMutation, V extends RevealRewardMutationVariables>(
  request: Request<R, V>,
  variables?: V,
  headers?: HeadersInit
): Promise<R> {
  return request(RevealRewardDocument, variables, headers);
}
