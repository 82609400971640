import BigNumber from 'bignumber.js';
import { injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { Observable, ReplaySubject, filter, map, share } from 'rxjs';
import invariant from 'tiny-invariant';

import { Address, Addresses } from 'core/address';
import { ApiService } from 'core/api/api.service';
import { AssetUpdated } from 'core/api/gql/asset/asset-updated.gql.generated';
import { AssetFragment } from 'core/api/gql/asset/asset.fragment.generated';
import { GetAssets } from 'core/api/gql/asset/get-assets.gql.generated';
import { AssetTypeEnum } from 'core/api/schema';
import { ChainID, chains } from 'core/chain';
import { ChainService } from 'core/chain/chain.service';
import { Kwenta } from 'core/kwenta';

import { Collection } from 'utils/collection';
import { Disposable } from 'utils/disposable';
import { isSomething } from 'utils/is-something';
import { Percentage } from 'utils/percentage';

import { UnNullify } from 'types';

import { Asset } from './asset';

export type GetAssetBySymbol = (symbol: string, chainId?: ChainID, type?: AssetTypeEnum) => Asset | undefined;

type GetAssetPair = {
  fromSymbol: string;
  toSymbol: string;
};

type GetAssetPairResult = {
  price: BigNumber;
  change24h: Percentage;
};

export type AssetUpdated = UnNullify<Pick<AssetFragment, 'chainId' | 'symbol' | 'price' | 'priceChangePercentage'>>;

@injectable()
export class AssetsStore extends Disposable {
  @observable.ref
  private chainToAssetsMap = new Map<ChainID, Asset[]>();

  @observable.ref
  private symbolToAssetsMap = new Map<string, Asset[]>();

  readonly assets$: Observable<AssetFragment[]>;

  readonly assetUpdated$: Observable<AssetUpdated>;

  private assets$$ = new ReplaySubject<AssetFragment[]>(1);

  private loadingPromise?: Promise<void>;

  constructor(private readonly chainService: ChainService, private readonly apiService: ApiService) {
    super();
    makeObservable(this);

    this.assets$ = this.assets$$.asObservable();

    this.assetUpdated$ = this.apiService.subscription(AssetUpdated).pipe(
      map((result) => result.assetUpdated),
      filter(
        (assetUpdated): assetUpdated is AssetUpdated =>
          isSomething(assetUpdated.price) && isSomething(assetUpdated.priceChangePercentage)
      ),
      share()
    );

    this.autoDispose(this.assets$.subscribe(this.setAssetMap));
  }

  @computed
  get allCurrencies(): Asset[] {
    return Array.from(this.symbolToAssetsMap.values())
      .map((assets) => Collection.takeFirst(assets))
      .filter((item): item is Asset => isSomething(item) && item.address !== '' && item.type === AssetTypeEnum.Erc_20);
  }

  @computed
  get chainAssets(): Asset[] {
    return this.getAssetsByChain(this.chainService.chain.chainId);
  }

  @computed
  get nativeAsset(): Asset {
    const asset = this.getNativeAsset(this.chainService.chain.chainId);

    invariant(asset, 'Native asset is not available');

    return asset;
  }

  @action
  private setAssetMap = (assetsData: AssetFragment[]): void => {
    const chainToAssetsMap = new Map<ChainID, Asset[]>();
    const symbolToAssetsMap = new Map<string, Asset[]>();

    Object.values(chains).forEach((chain) => {
      const chainAssets = assetsData.filter((asset) => asset.chainId === chain.chainId);

      const assets = chainAssets.map((asset) => new Asset(asset, this.assetUpdated$));

      const nativeAssetData = chainAssets.find((asset) => asset.symbol === chain.symbolAlias);

      if (nativeAssetData) {
        const nativeAsset = new Asset({ ...nativeAssetData, symbol: chain.symbol }, this.assetUpdated$, {
          isNative: true,
        });

        assets.push(nativeAsset);
      }

      chainToAssetsMap.set(chain.chainId, assets);

      for (const asset of assets) {
        const assets = symbolToAssetsMap.get(asset.symbol) ?? [];

        assets.push(asset);

        symbolToAssetsMap.set(asset.symbol, assets);
      }
    });

    this.chainToAssetsMap = chainToAssetsMap;
    this.symbolToAssetsMap = symbolToAssetsMap;
  };

  getNativeAsset = (chainId: ChainID): Asset => {
    const asset = this.chainToAssetsMap.get(chainId)?.find((asset) => asset.isNative);

    invariant(asset, 'Native asset is not available');

    return asset;
  };

  getAssetsByChain = (chainId?: ChainID, includeNative = false): Asset[] => {
    if (!chainId) return [];

    return (
      this.chainToAssetsMap
        .get(chainId)
        ?.filter((item) => item.type === AssetTypeEnum.Erc_20 && (item.isNative === false || includeNative)) ?? []
    );
  };

  getSnxAssets = (): Asset[] => {
    return (
      this.chainToAssetsMap.get(Kwenta.MainChainID)?.filter((item) => item.type === AssetTypeEnum.SnxPerpsV2) ?? []
    );
  };

  getAssetBySymbol = (
    symbol: string,
    chainId: ChainID = this.chainService.chain.chainId,
    type?: AssetTypeEnum
  ): Asset | undefined => {
    const asset = this.symbolToAssetsMap
      .get(symbol)
      ?.find((asset) => asset.chain.chainId === chainId && (!type || asset.type === type));

    return asset;
  };

  findAssetBySymbol = (symbol: string): Asset | undefined => {
    return Collection.takeFirst(this.symbolToAssetsMap.get(symbol));
  };

  // TODO optimize find
  getAssetByAddress = (address: Address, chainId: ChainID = this.chainService.chain.chainId): Asset | undefined => {
    const asset = this.chainToAssetsMap.get(chainId)?.find((asset) => Addresses.areEqual(asset.address, address));

    return asset;
  };

  getAssetPair = (params: GetAssetPair): GetAssetPairResult => {
    const from = Collection.takeFirst(this.symbolToAssetsMap.get(params.fromSymbol));
    const to = Collection.takeFirst(this.symbolToAssetsMap.get(params.toSymbol));

    const paired = Collection.takeFirst(this.symbolToAssetsMap.get(params.fromSymbol + params.toSymbol));

    if (paired) {
      return {
        price: paired.price,
        change24h: paired.price24hChange,
      };
    }

    if (params.toSymbol === 'USD' && from) {
      return {
        price: from.price,
        change24h: from.price24hChange,
      };
    }

    if (params.fromSymbol === 'USD' && to) {
      return {
        price: to.price,
        change24h: to.price24hChange,
      };
    }

    invariant(from, `Asset ${params.fromSymbol} not found`);
    invariant(to, `Asset ${params.toSymbol} not found`);

    const price = from.price.dividedBy(to.price);
    const change24h = Percentage.fromNumber(from.price24hChange / to.price24hChange);

    return {
      price,
      change24h,
    };
  };

  readonly wait = async (): Promise<void> => {
    if (!this.loadingPromise) {
      this.loadingPromise = this.init();
    }

    await this.loadingPromise;
  };

  private init = async (): Promise<void> => {
    const { assets } = await this.apiService.query(GetAssets);

    this.assets$$.next(assets);
  };
}
