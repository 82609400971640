import { SVGProps } from 'react';

import { svgIcon } from 'components/svg-icon';

const SvgYearnOptions = svgIcon((props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 12 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m1.453.888-.789.875 1.924 2.133 1.924 2.133V10H6.747V6.03L8.67 3.896l1.923-2.134L9.8.882 9.004 0 7.328 1.858C6.406 2.881 5.642 3.717 5.63 3.717c-.013 0-.775-.834-1.693-1.852A181.816 181.816 0 0 0 2.254.013c-.007 0-.367.393-.8.875ZM.627 5.867C.347 6.404.089 7.36.018 8.125c-.035.377-.017 1.2.035 1.57.486 3.459 3.432 5.81 6.533 5.212 2.044-.393 3.725-1.993 4.379-4.165.215-.715.284-1.198.285-1.98 0-.846-.086-1.408-.338-2.184-.086-.266-.308-.792-.334-.792-.01 0-.396.418-.857.93-.775.86-.837.935-.826 1.002.006.04.028.158.05.26a4.276 4.276 0 0 1-.1 1.952c-.313 1.081-1.14 2.007-2.128 2.381-.377.143-.589.178-1.076.18-.496 0-.682-.028-1.066-.167-1.045-.377-1.896-1.348-2.202-2.514-.1-.379-.129-.62-.127-1.073.001-.42.013-.528.115-1.022.008-.04-.177-.259-.84-.992l-.85-.942-.044.086Z"
      fill="currentColor"
    />
  </svg>
));
export default SvgYearnOptions;
