import { BigNumberish } from 'ethers';

import { Debank } from 'core/debank/debank';

export namespace GasPrice {
  export const GAS_PRICE_BUFFER_PERCENTAGE = 20;

  export enum Labels {
    Standart = 'Standart',
    Fast = 'Fast',
    Rapid = 'Rapid',
    Recommend = 'Recommended',
  }

  export type Type = {
    name: Labels;
    price: BigNumberish;
  };

  export const dataToGasPrice = ({ data }: Debank.Data): Type[] => {
    return [
      { name: Labels.Standart, price: data.slow.price },
      { name: Labels.Fast, price: data.normal.price },
      { name: Labels.Rapid, price: data.fast.price },
    ];
  };
}
