import { ExecutorIntegration } from 'core/api/schema';

import { ShareCardProps } from './share-card';
import { ShareCardSpot } from './share-card.spot';
import { ShareCardVault } from './share-card.vault';

type ShareCardBodyProps = ShareCardProps;

export const ShareCardBody = (props: ShareCardBodyProps) => {
  const { data } = props;

  return <div className="flex flex-col gap-5">{getShareCardBody({ data })}</div>;
};

const getShareCardBody = ({ data }: ShareCardProps) => {
  if (!data) return <ShareCardVault />;

  switch (data.integration) {
    case ExecutorIntegration.ZeroX:
      return <ShareCardSpot data={data} />;
  }
};
