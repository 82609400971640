import { action, computed, makeObservable, observable } from 'mobx';

import { Logger } from 'core/logger';

import { PaginationProps } from 'components/table';

import { Disposable } from 'utils/disposable';

export type PaginatedResult<TValue> = {
  items: TValue[];
  numItems: number;
  numPages: number;
};

export enum NextItems {
  Replace = 'replace',
  Append = 'append',
}

export type PaginatedResourceOptions<TValue, TRaw> = {
  pageSize: number;
  fetch: (options: RequestOptions) => Promise<TRaw>;
  map: (raw: TRaw) => PaginatedResult<TValue>;
  nextItems?: NextItems;
};

export type RequestOptions = {
  page: number;
  pageSize: number;
};

const logger = new Logger('PaginatedResource');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class PaginatedResource<TValue, TRaw = any> extends Disposable {
  @observable.ref
  items: TValue[] = [];

  @observable
  currentPage = 0;

  @observable
  numPages = 0;

  @observable
  numItems = 0;

  @observable
  pageSize = 0;

  @observable
  loading = false;

  @observable
  loaded = false;

  @observable
  loadingMore = false;

  constructor(private readonly options: PaginatedResourceOptions<TValue, TRaw>) {
    super();
    makeObservable(this);

    this.pageSize = options.pageSize;
  }

  @computed
  get hasMore(): boolean {
    return this.currentPage < this.numPages - 1;
  }

  @action
  readonly changePageSize = (pageSize: number) => {
    this.pageSize = pageSize;

    this.fetch(0);
  };

  @action
  private setPage = (page: number) => {
    this.currentPage = page;
  };

  @action
  private update = (resource: PaginatedResult<TValue>) => {
    this.items = resource.items;
    this.numItems = resource.numItems;
    this.numPages = resource.numPages;
    this.loaded = true;

    logger.debug('resource', resource);
  };

  @action
  private setLoading = (loading: boolean) => {
    this.loading = loading;

    logger.debug('loading', loading);
  };

  @action
  private setLoadingMore = (loadingMore: boolean) => {
    this.loadingMore = loadingMore;
  };

  readonly fetch = async (page = 0) => {
    const { nextItems = NextItems.Replace } = this.options;

    if (nextItems === NextItems.Append && page === 0 && !this.loaded) {
      this.setLoading(true);
    }

    if (nextItems === NextItems.Replace) {
      this.setLoading(true);
    }

    this.setPage(page);

    try {
      const result = await this.options.fetch({ page, pageSize: this.pageSize });

      logger.debug('raw data', result);

      const paginatedResult = this.options.map(result);

      if (nextItems === NextItems.Append && page !== 0) {
        this.update({
          ...paginatedResult,
          items: [...this.items, ...paginatedResult.items],
        });
      } else {
        this.update(this.options.map(result));
      }
    } catch (e) {
      logger.error(e);
    }

    this.setLoading(false);
  };

  readonly refresh = () => {
    this.fetch(this.currentPage);
  };

  readonly loadMore = async () => {
    if (this.hasMore) {
      this.setLoadingMore(true);
      await this.fetch(this.currentPage + 1);
      this.setLoadingMore(false);
    }
  };

  readonly getPaginationProps = (): PaginationProps => ({
    pageSize: this.pageSize,
    numPages: this.numPages || 1,
    currentPage: this.currentPage,
    onPageSizeChange: this.changePageSize,
    onChange: this.fetch,
  });
}
